<template>
  <div class="g-page-container">
    <form class="form-signin" @submit.prevent="login">
      <h2 class="form-signin-heading">{{ $t("login.title") }}</h2>
      <div class="alert alert-danger" v-if="error">{{ error }}</div>
      <label for="inputClient">{{ $t("login.name") }}</label>
      <input autocapitalize="off" v-model="client" type="text" id="inputClient" class="form-control" :placeholder="$t('login.namePHolder')" required autofocus />
      <label style="margin-top: 10px" for="inputPassword">{{ $t("login.key") }}</label>
      <InputText
        required
        password="true"
        :autocomplete="false"
        :initInputText="initInputText"
        id="inputPassword"
        @handleChange="handleChange($event)"
        :placeholder="$t('login.keyPHolder')"
      >
      </InputText>
      <br />
      <button class="btn btn-perfcell btn-default" type="submit">
        {{ $t("login.login") }}
      </button>
    </form>
  </div>
</template>

<script>
import client from "@/stores/ClientState.js";
import socket from "@/stores/Socket.js";
import { axiosInstance } from "@/main";
import InputText from "@/components/InputText.vue";

export default {
  data() {
    return {
      client: "",
      password: "",
      error: false,
      initInputText: false,
    };
  },
  components: {
    InputText,
  },
  methods: {
    handleChange(payload) {
      this.password = payload;
    },
    login: function () {
      let client = this.client;
      let password = this.password;
      console.log("----- login = " + this.client + ", " + this.password);

      axiosInstance
        .post("/login", { client, password })
        .then((resp) => this.loginSuccessful(resp))
        .catch(() => this.loginFailed());
    },
    loginSuccessful: function (resp) {
      console.log("----- loginSuccessful = " + JSON.stringify(resp.data, null, 2));
      const theToken = resp.data.token;
      if (!theToken) {
        this.loginFailed();
      } else {
        client.logIn(theToken);
        this.$router.replace("/serverDown/1");
        socket.openSocket();
      }
    },
    loginFailed: function () {
      this.error = this.$i18n.t("login.error");
      this.password = "";
      this.initInputText = true;
      this.$nextTick(function () {
        this.initInputText = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/form.scss";
</style>
