<template>
  <div class="g-page-container">
    <div class="g-pageHeader" style="height: 63px">
      <h1 style="margin: auto">{{ $t("sendMsg.title") }}</h1>
    </div>

    <ReturnButtonContainer to="/config" />

    <form class="g-pageBody" @submit.prevent="onSubmit">
      <label class="radio-inline">
        <b-form-radio v-model="msgType" name="w" value="line">
          {{ $t("sendMsg.line") }}
        </b-form-radio>
      </label>

      <div class="form-group">
        <div>
          <MultipleSelect :data="linesList" v-model="selectedLines" multiple :options="optLines"> </MultipleSelect>
        </div>
      </div>

      <label class="radio-inline">
        <b-form-radio v-model="msgType" name="w" value="emp">
          {{ $t("sendMsg.emp") }}
        </b-form-radio>
      </label>

      <div class="form-group">
        <div>
          <MultipleSelect :data="usersList" v-model="selectedUsers" multiple :options="optUsers"> </MultipleSelect>
        </div>
      </div>

      <label class="radio-inline">
        <b-form-radio v-model="msgType" name="w" value="all">
          {{ $t("sendMsg.all") }}
        </b-form-radio>
      </label>

      <div class="form-group marginTop20px">
        <label for="inputMsg">{{ $t("sendMsg.message") }}</label>
        <b-form-textarea id="inputMsg" v-model="message" :placeholder="$t('sendMsg.enterText')" rows="3" max-rows="6"></b-form-textarea>
      </div>

      <button class="btn btn-perfcell float-right marginTop50px" @click.prevent="clickRetour">
        {{ $t("sendMsg.cancel") }}
      </button>

      <button v-if="sendEnabled" class="btn btn-perfcell float-right marginTop50px marginRight10px" type="submit">
        {{ $t("sendMsg.send") }}
      </button>
    </form>
  </div>
</template>

<script>
import { axiosInstance } from "@/main";
import ReturnButtonContainer from "../components/Return-button/Return-button-container.vue";

export default {
  data() {
    return {
      linesList: [],
      selectedLines: [],
      usersList: [],
      selectedUsers: [],
      optLines: {
        multiple: true,
        filter: true,
        placeholder: this.$i18n.t("sendMsg.linesPHolder"),
        multipleWidth: 500,
        width: 500,
        dropWidth: 500,
        locale: localStorage.getItem("locale"),
      },
      optUsers: {
        multiple: true,
        filter: true,
        placeholder: this.$i18n.t("sendMsg.usersPHolder"),
        multipleWidth: 500,
        width: 500,
        dropWidth: 500,
        locale: localStorage.getItem("locale"),
      },
      message: "",
      msgType: "line",
      sendEnabled: false,
    };
  },
  watch: {
    message: function () {
      //console.log("----- message = " + JSON.stringify(val, null, 2));
      this.checkSendButton();
    },
    selectedLines: function () {
      //console.log("----- selectedLines = " + JSON.stringify(val, null, 2));
      if (this.msgType == "line") this.setMsgLines();
      this.checkSendButton();
    },
    selectedUsers: function () {
      //console.log("----- selectedUsers = " + JSON.stringify(val, null, 2));
      if (this.msgType == "emp") this.setMsgUsers();
      this.checkSendButton();
    },
    msgType: function (val) {
      //console.log("----- msgType = " + val);
      if (val == "line") this.setMsgLines();
      else if (val == "emp") this.setMsgUsers();
      else this.setMsgAll();
      this.checkSendButton();
    },
  },
  methods: {
    setMsgLines() {
      let msg = this.$i18n.t("sendMsg.toLines");
      let first = true;
      for (let i = 0; i < this.linesList.length; i++) {
        if (this.selectedLines.indexOf(this.linesList[i].value) != -1) {
          if (!first) msg = msg + ", ";
          msg = msg + this.linesList[i].name;
          first = false;
        }
      }
      msg = msg + "\n----------------------------------------------\n";
      this.message = msg;
    },
    setMsgUsers() {
      let msg = this.$i18n.t("sendMsg.toUsers");
      let first = true;
      for (let i = 0; i < this.usersList.length; i++) {
        if (this.selectedUsers.indexOf(this.usersList[i].value) != -1) {
          if (!first) msg = msg + ", ";
          msg = msg + this.usersList[i].text;
          first = false;
        }
      }
      msg = msg + "\n----------------------------------------------\n";
      this.message = msg;
    },
    setMsgAll() {
      let msg = this.$i18n.t("sendMsg.toAll");
      msg = msg + "\n----------------------------------------------\n";
      this.message = msg;
    },
    checkSendButton() {
      let enabled = true;
      if (this.message.length == 0) enabled = false;
      if (this.msgType == "line" && this.selectedLines.length == 0) enabled = false;
      if (this.msgType == "emp" && this.selectedUsers.length == 0) enabled = false;
      this.sendEnabled = enabled;
    },
    onSubmit() {
      console.log("----- this.msgType = " + JSON.stringify(this.msgType, null, 2));
      let to = [];
      if (this.msgType == "line") to = this.selectedLines;
      else if (this.msgType == "emp") {
        for (let i = 0; i < this.usersList.length; i++)
          if (this.selectedUsers.indexOf(this.usersList[i].value) != -1) if (to.indexOf(this.usersList[i].onLineId) == -1) to.push(this.usersList[i].onLineId);
      }
      console.log("----- to = " + JSON.stringify(to, null, 2));
      axiosInstance
        .post("/sendmsg", { lines: to, msg: this.message })
        .then((resp) => {
          console.log("----- onSubmit ok = " + JSON.stringify(resp.data, null, 2));
          this.$bvModal.msgBoxOk("message sent ok");
        })
        .catch(() => {
          console.log("----- onSubmit error ");
        });
    },
    clickRetour() {
      this.$router.replace("/config");
    },
  },
  mounted() {
    axiosInstance
      .get("/lineList", { params: { isActive: true } })
      .then((resp) => {
        for (let i = 0; i < resp.data.length; i++) {
          if (resp.data[i].inOperation)
            this.linesList.push({
              text: resp.data[i].name + " - " + resp.data[i].description,
              value: resp.data[i]._id,
              name: resp.data[i].name,
            });
        }
        //console.log("----- get /lineList = " + JSON.stringify(this.lineList, null, 2));
      })
      .catch((err) => {
        console.log("----- get /lineList error = " + JSON.stringify(err, null, 2));
      });
    axiosInstance
      .get("/userList", { params: { isActive: true } })
      .then((resp) => {
        for (let i = 0; i < resp.data.length; i++) {
          if (resp.data[i].onLineId)
            this.usersList.push({
              text: resp.data[i].firstName + " " + resp.data[i].lastName,
              value: resp.data[i]._id,
              onLineId: resp.data[i].onLineId,
            });
        }
        //console.log("----- get /userList = " + JSON.stringify(this.userList, null, 2));
      })
      .catch((err) => {
        console.log("----- get /userList error = " + JSON.stringify(err, null, 2));
      });
  },
  components: { ReturnButtonContainer },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/colors.scss";

form {
  background: White;
  padding: 2rem 2rem 1rem;
  width: 100%;
}

label {
  font-weight: bold;
}
</style>
