<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask" v-show="showSched">
      <div class="modal-container">
        <div>
          <label v-if="schedulesList.length == 0" class="attention">{{
            $t("selectSched.noSchedule")
          }}</label>
          <label v-else>{{ $t("selectSched.sSchedule") }}</label>
          <b-table
            class="table-big-font"
            ref="selectSchedTable"
            hover
            small
            selectable
            select-mode="single"
            sticky-header="400px"
            primary-key="_id"
            selected-variant="success"
            :items="schedulesList"
            :fields="showFields"
            @row-selected="onSchedSelected"
          >
          </b-table>
        </div>

        <button
          class="btn btn-perfcell float-right marginTop50px"
          @click="clickCancel"
        >
          {{ $t("selectSched.cancel") }}
        </button>

        <button
          v-if="saveSchedEnabled"
          class="btn btn-perfcell float-right marginTop50px marginRight10px"
          @click="clickSave"
        >
          {{ $t("selectSched.save") }}
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import { axiosInstance } from "@/main";

export default {
  name: "SelectSchedule",
  template: "#modal-template",
  props: {
    showSched: Boolean,
    doInitSched: Boolean,
    schedulesList: Array,
    autoselectSchdId: String, // Add this line
  },
  data: function () {
    return {
      showFields: [
        {
          key: "description",
          label: this.$i18n.t("selectSched.desc"),
        },
      ],
      selectedSchedule: {},
      selectedScheduleId: "",
      lastSelectedScheduleId: "",
      saveSchedEnabled: false,
      inInit: false,
    };
  },
  watch: {
    doInitSched: function (val) {
      if (val) {
        console.log("----- init SelectSchedule");

        this.inInit = true;
        this.selectedSchedule = {};
        this.selectedScheduleId = "";

        this.lastSelectedScheduleId = localStorage.getItem(
          "lastSelectedScheduleId"
        );

        if (this.lastSelectedScheduleId) {
          // wait that table is updated before doing select
          this.$nextTick(function () {
            this.$refs.selectSchedTable.clearSelected();
            console.log(
              this.lastSelectedScheduleId,
              "lastSelectedScheduleId",
              this.autoselectSchdId
            );
            this.$nextTick(function () {
              for (let i = 0; i < this.schedulesList.length; i++) {
                if (this.lastSelectedScheduleId == this.schedulesList[i]._id) {
                  console.log(this.schedulesList[i], "check shed for");
                  this.$refs.selectSchedTable.selectRow(i);

                  if (i > 2)
                    this.$refs.selectSchedTable.$el.scrollTop = 34.6 * (i - 2);
                  break;
                }
              }
              this.$nextTick(function () {
                this.inInit = false;
              });
            });
          });
        } else {
          this.inInit = false;
        }
      }
    },
    autoselectSchdId: function (val) {
      if (val) {
        // this.selectedScheduleId = "";

        this.lastSelectedScheduleId = this.autoselectSchdId;

        axiosInstance.get("/scheduleRunning").then((resp) => {

          let schedlist=resp.data;

        this.selectedScheduleId = this.autoselectSchdId;

        if (this.lastSelectedScheduleId) {
          // wait that table is updated before doing select
          this.$nextTick(function () {
            this.$refs.selectSchedTable.clearSelected();

            this.$nextTick(function () {
              let selectedSched=schedlist.find( (schd) => schd._id == this.lastSelectedScheduleId);
             

              this.selectedSchedule = selectedSched;
              this.selectedScheduleId = selectedSched._id;
              this.saveSchedEnabled = true;
        setTimeout(() => {
         this.$emit("close", {
              cancel: false,
              selectedSchedule: this.selectedSchedule,
                });
          }, 1000);
             

            
                });
          });
        
        
      }
        })
    }},

    selectedScheduleId: function () {
      this.saveSchedEnabled = this.selectedScheduleId != "";
    },
  },
  methods: {
    onSchedSelected(item) {
      console.log(item, "schedule");
      //console.log("----- item = " + JSON.stringify(item, null, 2));
      //console.log("----- this.inInit = " + JSON.stringify(this.inInit, null, 2));
      if (item.length > 0) {
        this.selectedSchedule = item[0];
        this.selectedScheduleId = item[0]._id;
        this.saveSchedEnabled = true;

        if (!this.inInit) this.clickSave();
      } else {
        if (!this.inInit)
          // if we arrive here it mean that the user clicked the last selected schedule at first
          this.clickSave();
      }
    },
    clickSave: function () {
      console.log("check==", this.selectedSchedule);
      localStorage.setItem("lastSelectedScheduleId", this.selectedScheduleId);

      this.$emit("close", {
        cancel: false,
        selectedSchedule: this.selectedSchedule,
      });
    },
    clickCancel: function () {
      this.$emit("close", { cancel: true });
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.modal-mask {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}
.modal-container {
  width: 800px;
  height: 600px;
  margin: 40px auto 0;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
