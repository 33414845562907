<template>
  <div class="g-page-container">
    <form class="form-signin" @submit.prevent="admin">
      <h2 class="form-signin-heading">{{ $t("admin.title") }}</h2>
      <div class="alert alert-danger" v-if="error">{{ error }}</div>
      <InputText required password="true" :autocomplete="false" :initInputText="initInputText" @handleChange="handleChange($event)" :placeholder="$t('admin.placeholder')">
      </InputText>
      <br />
      <button class="btn btn-perfcell btn-default" type="submit">{{ $t("admin.login") }}</button>
    </form>
  </div>
</template>

<script>
import { axiosInstance } from "@/main";
import admin from "@/stores/AdminState.js";
import InputText from "@/components/InputText.vue";

export default {
  data() {
    return {
      password: "",
      error: false,
      initInputText: false,
      select: "1",
    };
  },
  components: {
    InputText,
  },
  methods: {
    handleChange(payload) {
      this.password = payload;
    },
    admin: function () {
      let password = this.password;
      console.log("----- admin = " + password);

      axiosInstance
        .post("/admin", { password })
        .then((resp) => this.loginSuccessful(resp))
        .catch(() => this.loginFailed());
    },
    loginSuccessful: function (resp) {
      if (!resp.data || !resp.data.admin) {
        this.loginFailed();
        return;
      }
      this.error = false;
      admin.logIn();

      if (this.select == "2") this.$router.replace("/qualityFollowUps");
      else this.$router.replace("/config");
    },
    loginFailed: function () {
      this.error = this.$i18n.t("admin.error");
      this.password = "";
      this.initInputText = true;
      this.$nextTick(function () {
        this.initInputText = false;
      });
    },
  },
  created() {
    this.select = this.$route.params.select;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/form.scss";
</style>
