<template>
  <ModalTemplate :showModal="showModal" @close="handleCloseModal">
    <div class="modal-container">
      <!-- HEADER -->
      <div class="modal-header">
        <h3>Assign clean score</h3>
      </div>

      <!-- BODY -->
      <div class="modal-body">
        <input @keypress.enter="handleSubmit" v-model="password" type="password" placeholder="Please enter the password" />
        <span v-if="showInvalidPasswordMsg" class="invalid-password">Invalid password, please, try again</span>
      </div>

      <div class="modal-footer">
        <button class="btn btn-cancel" @click="handleCancel">Cancel</button>
        <button class="btn btn-perfcell" @click="handleSubmit">Submit</button>
      </div>
    </div>
  </ModalTemplate>
</template>

<script>
import { axiosInstance } from "@/main";
import ModalTemplate from "./Modal-template.vue";

export default {
  components: {
    ModalTemplate,
  },
  data() {
    return {
      password: "",
      showInvalidPasswordMsg: false,
      timeout: null,
    };
  },
  methods: {
    handleCloseModal() {
      this.$emit("close");
    },
    handleCancel() {
      this.password = "";
      this.handleCloseModal();
    },
    loginSuccessfully(resp) {
      if (!resp.data || !resp.data.admin) {
        this.loginFailed();
        return;
      }
      this.handleCloseModal();
      setTimeout(() => this.$emit("successAuth"), 100);
    },
    loginFailed() {
      this.showInvalidPasswordMsg = true;
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => (this.showInvalidPasswordMsg = false), 3000);
    },
    handleSubmit() {
      axiosInstance
        .post("/admin", { password: this.password })
        .then((resp) => this.loginSuccessfully(resp))
        .catch(() => {
          this.loginFailed();
        })
        .finally(() => {
          this.password = "";
        });
    },
  },
  props: {
    showModal: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.modal-container {
  min-height: 500px;
  max-width: 400px;
  display: grid;
  grid-template-rows: 60px 1fr 70px;
}

.modal-container .modal-body input {
  width: 100%;
  padding: 5px;
}

.btn-cancel {
  border: 1px solid black;
}

.btn-perfcell {
  color: white !important;
  transition: all 300ms ease-in-out;
}
.btn-perfcell:hover {
  filter: brightness(1.2);
}

.invalid-password {
  color: red;
  font-weight: 600;
  padding: 1rem 0;
  margin-top: 20px;
}
</style>
