<template>
  <div class="g-page-container" ref="pageContainer">
    <div class="g-pageHeader" style="height: 63px">
      <h1 style="margin: auto">{{ $t("configStdsList.title") }}</h1>
    </div>

    <div style="padding-top: 10px">
      <div class="form-inline">
        <div class="form-group search-text">
          <label for="searchText">{{ $t("configStdsList.search") }}</label>
          <input id="searchText" v-model="config.searchField" type="text" @keyup.enter="clickSearch()" class="form-control" />
          <button @click="clickSearch()" class="btn btn-perfcell">
            <span class="glyphicon glyphicon-search"></span>
            {{ $t("search") }}
          </button>
        </div>
        <div class="form-group checkbox search-active">
          <label for="searchActive">
            <input id="searchActive" type="checkbox" v-model="config.withInactive" @change="clickInactive()" />
            {{ $t("inactive") }}
          </label>
        </div>
        <div class="form-group" style="margin-left: 30px">
          <ReturnButton to="/config" />
        </div>
      </div>
    </div>

    <div class="g-pageBody" style="padding: 10px">
      <table>
        <thead>
          <tr>
            <th class="t-part cursorPointer" :class="getArrow('partNumber')" @click="clickSort('partNumber')">
              {{ $t("configStdsList.part") }}
            </th>
            <th class="t-desc cursorPointer" :class="getArrow('description')" @click="clickSort('description')">
              {{ $t("configStdsList.desc") }}
            </th>
            <th class="t-sam cursorPointer" :class="getArrow('SAM')" @click="clickSort('SAM')">
              {{ $t("configStdsList.sam") }}
            </th>
            <th class="t-edit textAlignCenter">
              {{ $t("configStdsList.edit") }}
            </th>
            <th class="t-active-inactive textAlignCenter">
              {{ $t("configLinesList.activeInactive") }}
            </th>
            <th class="t-remove textAlignCenter">
              {{ $t("configStdsList.remove") }}
            </th>
            <th class="t-clone textAlignCenter">
              {{ $t("configLinesList.clone") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in screenList" :key="e._id">
            <td :class="e.isActive ? '' : 'redText'">{{ e.partNumber }}</td>
            <td :class="e.isActive ? '' : 'redText'">{{ e.description }}</td>
            <td :class="e.isActive ? '' : 'redText'">{{ e.SAM }}</td>
            <td class="textAlignCenter cursorPointer" @click="clickEdit(e._id)">
              <i class="fa fa-pencil"></i>
            </td>
            <td class="textAlignCenter cursorPointer">
              <input id="e._id" type="checkbox" @change="clickActiveInactive(e._id, e.isActive)" v-model="e.isActive" />
            </td>

            <td class="textAlignCenter cursorPointer" @click="clickRemove(e)">
              <i class="fa fa-remove"></i>
            </td>
            <td class="textAlignCenter cursorPointer" @click="clickClone(e)">
              <i class="fa fa-files-o"></i>
            </td>
          </tr>
          <tr v-for="index in emptyRows" :key="index">
            <td>.</td>
            <td>&#8232;</td>
            <td>&#8232;</td>
            <td>&#8232;</td>
            <td>&#8232;</td>
            <td>&#8232;</td>
            <td>&#8232;</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div style="margin-top: 10px">
      <b-pagination
        v-if="totalRows > pageSize"
        size="sm"
        ref="pagination"
        :total-rows="totalRows"
        :per-page="pageSize"
        v-model="config.currentPage"
        :prev-text="$t('configStdsList.previous')"
        :next-text="$t('configStdsList.next')"
      />
    </div>

    <button class="btn btn-perfcell float-left marginTop20px" @click="clickRetour">{{ $t("configStdsList.cancel") }}</button>
    <button class="btn btn-perfcell float-left marginLeft10px marginTop20px" @click="clickAddNew">{{ $t("configStdsList.addNew") }}</button>
    <button class="btn btn-perfcell float-left marginLeft10px marginTop20px" @click="clickImportStandards">{{ $t("configStdsList.import") }}</button>
    <input type="file" ref="importStandards" accept=".csv" style="display: none" @change="importStandardsGotFile" />

    <standardReport :doInitReport="standardReportDoInit" :showReport="showModalReport" :reportData="report" @close="closeModalReport"> </standardReport>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { axiosInstance } from "@/main";
import EventBus from "@/eventBus";
import standardReport from "@/components/StandardReport.vue";
import ReturnButton from "../components/Return-button/Return-button.vue";

export default {
  data() {
    return {
      standardReportDoInit: false,
      showModalReport: false,
      report: [],

      // the list received from the server
      completeList: [],
      // the list stripped by search and inactive and sorted by column headers
      strippedList: [],
      // the current elements on screen
      screenList: [],

      // computed items
      totalRows: 0,
      pageSize: 7,
      emptyRows: 0,

      // config is saved when looking at element detail then restored when coming back here
      config: {
        // when these fields are modified, the strippedList is rebuilt
        searchField: "",
        withInactive: false,

        // value modified when clicking pages navigation
        currentPage: 1,

        // values modifieds when clicking table headers
        currentSort: "partNumber",
        currentSortDir: "asc",
      },
    };
  },
  components: {
    ReturnButton,
    standardReport,
  },
  watch: {
    "config.currentPage": function () {
      //console.log("----- this.config.currentPage = " + this.config.currentPage);
      this.setScreen();
    },
  },
  methods: {
    clickActiveInactive: function (id, check) {
      if (id) {
        //comment this for time being
        //         this.$bvModal.msgBoxConfirm(this.$i18n.t('configStdsList.confirmStatus'), {
        //   okTitle: this.$i18n.t('yes'),
        //   cancelTitle: this.$i18n.t('no'),
        // })
        // .then(value => {
        //   if( value ) {
        axiosInstance
          .post("/standard/" + id, { isActive: check })
          .then((resp) => {
            if (resp.data == "partNumberExist") {
              this.errors = [];
              this.msgColor = "red";
              this.errors.push(this.$i18n.t("configStd.partExist"));
            } else {
              this.getList();
              this.errors.push(this.$i18n.t("configStd.success"));
              this.msgColor = "green";
            }
          })
          .catch(() => {
            console.log("----- onSubmit error ");
          });
        //     }else{
        //        this.getList();
        //     }

        //   })
      }
    },
    clickSort: function (s) {
      if (s === this.config.currentSort) {
        this.config.currentSortDir = this.config.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.config.currentSort = s;
      this.sortList();
    },
    clickInactive: function () {
      //console.log("----- " + this.config.withInactive);
      this.stripList();
    },
    clickSearch: function () {
      //console.log("----- search = " + this.config.searchField);
      this.config.currentPage = 1;
      this.stripList();
    },
    clickEdit(id) {
      //console.log("----- clickEdit = " + id);
      localStorage.setItem("configListSettings", JSON.stringify(this.config));
      this.$router.replace({ path: "/configStandard/" + id });
    },
    clickRemove(element) {
      //console.log("----- clickRemove = " + element._id);
      this.$bvModal
        .msgBoxConfirm(element.partNumber, {
          title: this.$i18n.t("configStdsList.confirm"),
          okTitle: this.$i18n.t("ok"),
          cancelTitle: this.$i18n.t("cancel"),
        })
        .then((value) => {
          //console.log("----- value = " + value);
          if (value) {
            //console.log("----- element._id = " + element._id);
            axiosInstance
              .delete("/standard/" + element._id)
              .then((result) => {
                //console.log("----- onDelete ok ");
                if (result.data.error) {
                  this.$bvModal.msgBoxOk(this.$i18n.t(result.data.error));
                } else {
                  this.getList();
                }
              })
              .catch(() => {
                console.log("----- onDelete error ");
              });
          }
        });
    },
    clickClone(element) {
      //console.log("----- clickClone = " + element._id);

      localStorage.setItem("configListSettings", JSON.stringify(this.config));
      this.$router.replace({ path: "/configStandard/-" + element._id });
    },
    clickRetour() {
      this.$router.replace("/config");
    },
    clickAddNew: function () {
      //console.log("----- clickAddNew");
      localStorage.setItem("configListSettings", JSON.stringify(this.config));
      this.$router.replace({ path: "/configStandard/0" });
    },
    clickImportStandards() {
      this.$refs.importStandards.click();
    },
    closeModalReport: function () {
      this.standardReportDoInit = false;
      this.showModalReport = false;
    },
    getArrow: function (s) {
      if (s === this.config.currentSort) {
        return this.config.currentSortDir === "asc" ? "headerSortDown" : "headerSortUp";
      } else return "none";
    },
    async importStandardsGotFile(event) {
      let file = event.target.files;
      if (!file.length) return;
      console.log("----- file name = " + file[0].name);

      let fr = new FileReader();
      fr.onload = async () => {
        let standardsList = fr.result.split(/[\r\n]+/);

        const categories = (await axiosInstance.get("/categoryList?isActive=false")).data;
        const categoriesData = { id: [], name: [], isActive: [], prodType: [] };
        for (let categoryData of categories) {
          categoriesData.id.push(categoryData._id);
          categoriesData.name.push(categoryData.name);
          categoriesData.isActive.push(categoryData.isActive);
          categoriesData.prodType.push(categoryData.prodType);
        }

        const standards = (await axiosInstance.get("/standardList")).data;
        const standardsData = { ids: [], partNumbers: [], descriptions: [], SAMs: [], categories: [] };
        for (let standard of standards) {
          standardsData.ids.push(standard._id);
          standardsData.partNumbers.push(standard.partNumber);
          standardsData.descriptions.push(standard.description);
          standardsData.SAMs.push(parseFloat(standard.SAM));
          standardsData.categories.push(standard.category);
        }

        const categoryErrors = [];
        const data = standardsList.slice(3);
        data.pop();
        this.report = [];
        for (let row of data) {
          row = row.replaceAll(", ", ",").split(",");
          let rowStandar = {};

          if (row[0] !== "") {
            rowStandar.partNumber = row[0];
          }

          if (row[1] !== "") rowStandar.description = row[1];

          if (row[2] !== "") rowStandar.SAM = parseFloat(row[2]);

          if (row[3] !== "") rowStandar.useEfficiencyNoTime = row[3].toLowerCase() === "true";

          if (row[4] !== "") {
            const categoryIndex = categoriesData.name.indexOf(row[4]);
            if (categoryIndex > -1) {
              rowStandar.category = categoriesData.id[categoryIndex];
              rowStandar.catName = row[4];
            } else {
              categoryErrors.push(row);
              console.log("----- Standar:", row[0], "-x- category not exist");
              this.report.push({ entry: row[0], status: this.$i18n.t("standardReport.categoryError") });
              continue;
            }
          }

          if (row[5] !== "") rowStandar.prodType = parseFloat(row[5]);

          if (row[6] !== "") rowStandar.isActive = row[6].toLowerCase() === "true";

          const partNumberIndex = standardsData.partNumbers.indexOf(rowStandar.partNumber);
          if (partNumberIndex > -1) {
            // Modify standard
            const response = await axiosInstance.put("/standard/" + standardsData.ids[partNumberIndex], rowStandar);
            console.log("----- Standar:", row[0], "updated");
            console.log("---------- Response", response);
            this.report.push({ entry: row[0], status: this.$i18n.t("standardReport.updated") });
          } else {
            // Create standards
            const response = await axiosInstance.post("/standard", rowStandar);
            console.log("----- Standar:", row[0], "created");
            console.log(response.data);
            this.report.push({ entry: row[0], status: this.$i18n.t("standardReport.created") });
          }
        }
        console.log("----- Category errors:", categoryErrors);

        this.getList();
        this.standardReportDoInit = true;
        this.showModalReport = true;
        this.$refs.importStandards.value = null;
      };

      fr.readAsText(file[0]);
    },

    stripList: function () {
      this.strippedList = [];
      let search = this.config.searchField.toLowerCase();
      for (let i = 0; i < this.completeList.length; i++) {
        console.log("LIST:", this.completeList[i].partNumber);
        let good = true;
        if (
          search != "" &&
          this.completeList[i].partNumber &&
          !this.completeList[i].partNumber.toLowerCase().includes(search) &&
          this.completeList[i].description &&
          !this.completeList[i].description.toLowerCase().includes(search)
        )
          good = false;
        if (!this.config.withInactive && !this.completeList[i].isActive) good = false;
        if (good) this.strippedList.push(this.completeList[i]);
      }
      this.sortList();
    },
    sortList: function () {
      this.strippedList.sort((a, b) => {
        let modifier = 1;
        if (this.config.currentSortDir === "desc") modifier = -1;
        if (a[this.config.currentSort] < b[this.config.currentSort]) return -1 * modifier;
        if (a[this.config.currentSort] > b[this.config.currentSort]) return 1 * modifier;
        return 0;
      });
      this.totalRows = this.strippedList.length;
      //console.log("----- strippedList.length = " + this.strippedList.length);
      this.setScreen();
    },
    setScreen: function () {
      this.screenList = this.strippedList.slice((this.config.currentPage - 1) * this.pageSize, this.config.currentPage * this.pageSize);
      this.emptyRows = this.pageSize - this.screenList.length;
    },
    computeNbRows: function (pageHeight) {
      if (pageHeight > 450) {
        let nbPages = 6 + Math.trunc((pageHeight - 450) / 38);
        if (this.pageSize != nbPages) {
          this.pageSize = nbPages;
          //console.log("----- this.pageSize = " + nbPages);
          this.setScreen();
        }
      }
    },
    getList: function () {
      //console.log("----- getList");
      axiosInstance
        .get("/standardList")
        .then((resp) => {
          //console.log("----- get /standardList = " + JSON.stringify(resp.data, null, 2));
          this.completeList = resp.data;

          this.completeList.forEach((std) => {
            let SAM = std.SAM;
            std.SAM = moment.unix(SAM).utc().format("H:mm:ss");
            if (Math.round((SAM % 1) * 10) != 0) std.SAM = std.SAM + "." + Math.round((SAM % 1) * 10);
          });

          this.stripList();
        })
        .catch((err) => {
          console.log("----- get /standardList error = " + JSON.stringify(err, null, 2));
        });
    },
    redimStdList: function (dim) {
      this.computeNbRows(dim.height);
    },
  },
  mounted() {
    if (localStorage.configListSettings) this.config = JSON.parse(localStorage.getItem("configListSettings"));

    this.getList();

    EventBus.$on("mainViewDim", this.redimStdList);
    this.computeNbRows(this.$refs.pageContainer.clientHeight);
  },
  beforeDestroy() {
    EventBus.$off("mainViewDim", this.redimStdList);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/global.scss";
@import "@/assets/styles/table.scss";

.t-part {
  width: 30%;
}
.t-desc {
  width: 33%;
}
.t-sam {
  width: 10%;
}
.t-edit {
  width: 10%;
}
.t-active-inactive {
  width: 15%;
}
.t-remove {
  width: 10%;
}
.t-clone {
  width: 10%;
}
</style>
