<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask" v-show="showMoveToLine">
      <div class="modal-container">

        <div>

          <div style="display: grid; grid-template-columns: 40% 60%; margin-bottom: 10px;">
            <div v-if="! gotList">
              <b-spinner label="Loading..."></b-spinner>
            </div>
            <div v-else>
              <label v-if="linesList.length == 0" class="attention">{{$t('selectSendToLine.noLine')}}</label>
              <label v-else>{{$t('selectLine.sLine')}}</label>
            </div>

            <div>
              <b-form-group
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    id="filterInput"
                    :placeholder="$t('selectStd.searchPHolder')"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''">{{$t('selectStd.clear')}}</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </div>

          <b-table
            class="table-big-font"
            ref="selectLineTable"
            hover
            small
            selectable
            select-mode="single"
            sticky-header="200px"
            primary-key="_id"
            selected-variant="success"
            :filter="filter"
            :filter-function="filterTable"
            :items="linesList"
            :fields="showFields"
            @row-selected="onLineSelected">
          </b-table>
        </div>

        <div v-if="taskVisible" class="form-group marginTop20px">
          <label for="task">{{$t('selectSendToLine.selectTask')}}</label>
          <div id="task">
            <div>
              <MultipleSelect
                :data="taskList"
                v-model="selectedTaskId"
                :options="opt" >
              </MultipleSelect>
            </div>
          </div>
        </div>

        <button class="btn btn-perfcell float-right marginTop50px" @click="clickCancel">
          {{$t('selectLine.cancel')}}
        </button>

        <button v-if="saveLineEnabled" class="btn btn-perfcell float-right marginTop50px marginRight10px" @click="clickSave">
          {{$t('selectLine.save')}}
        </button>

      </div>
    </div>
  </transition>
</template>

<script>
import { axiosInstance } from "@/main";
import oper from '@/stores/Operations.js';

export default {
  name: 'SelectMoveToLine',
  template: '#modal-template',
  props: {
    doInitMoveToLine: Boolean,
    showMoveToLine: Boolean
  },
  data: function() {
    return {
      filter: null,
      linesList: [],
      showFields: [
        {
          key: 'name',
          label: this.$i18n.t('selectLine.name')
        },
        {
          key: 'description',
          label: this.$i18n.t('selectLine.desc')
        }
      ],
      selectedLine: {},
      selectedLineId: '',
      saveLineEnabled: false,
      gotList: false,

      taskList: [],
      selectedTaskId: '',
      selectedTaskName: '',
      taskVisible: false,
      opt: {
        multiple: false,
        filter: true,
        placeholder: this.$i18n.t('selectTeam.taskPHolder'),
        multipleWidth: 375,
        width: 375,
        dropWidth: 375,
        position: 'top',
        locale: localStorage.getItem("locale")
      },
    }
  },
  watch: {
    'doInitMoveToLine': function(val) {
      if( val ) {
        //console.log("----- init MoveToLine");

        this.linesList = [];
        this.$refs.selectLineTable.refresh();
        this.gotList = false;

        axiosInstance.get('/linesInOperation/' + oper.getLineId())
        .then(resp => {
          //console.log("----- get /lineList = " + JSON.stringify(resp.data, null, 2));
          this.linesList = resp.data;
          this.gotList = true;

          this.selectedLine = {};
          this.selectedLineId = '';

        }).catch(err => {
            console.log("----- get /lineList error = " + JSON.stringify(err, null, 2));
        });
      }
    },
    'selectedTaskId': function() {
      this.saveLineEnabled = this.selectedTaskId != '';
    }
  },
  methods: {
    filterTable(row, filter) {

      let words = filter.split(" ");
      let found = false;
      words.forEach(( Word )=>{
        let word = Word.toLowerCase();
        if( word.length ) {
          if( row.name.toLowerCase().includes(word) )
            found = true;
          if( row.description.toLowerCase().includes(word) )
            found = true;
        }
      });
      return found;
    },
    async onLineSelected(item) {
      //console.log("----- item = " + JSON.stringify(item, null, 2));
      if( item.length > 0 ) {
        const needTasks = (await axiosInstance.get(`/line/${item[0]._id}`)).data.needTasks
        this.selectedLine = item[0];
        this.selectedLineId = item[0]._id;
        axiosInstance.get('/taskList', {"params": { "isActive": true, "onLine": this.selectedLineId }})
        .then(resp => {
          for( let i=0; i<resp.data.length; i++ ) {
            resp.data[i].text = resp.data[i].name;
            resp.data[i].value = resp.data[i]._id;
          }
          this.taskList = [];
          this.selectedTaskId = "";
          this.selectedTaskName = "";
          resp.data.forEach((task)=>{
            this.taskList.push( task );
          });
          this.taskVisible = true && needTasks;
          this.saveLineEnabled = !needTasks
        })
      }
      else {
        this.taskVisible = false;
        this.selectedTaskId = "";
        this.selectedTaskName = "";
      }
    },
    clickSave: function () {
      let selectedTask = {};
      for( let i=0; i<this.taskList.length; i++ ) {
        if( this.taskList[i]._id == this.selectedTaskId ) {
          selectedTask = this.taskList[i]
          break;
        }
      }
      this.$emit('close', {cancel: false, selectedLineId: this.selectedLineId, selectedTask: selectedTask});
      this.saveLineEnabled = false
    },
    clickCancel: function () {
      this.$emit('close', {cancel: true});
    }
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.modal-mask {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  transition: opacity .3s ease;
}
.modal-container {
  width: 800px;
  height: 600px;
  margin: 40px auto 0;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
