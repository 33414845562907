<template>
  <div class="g-page-container">

    <div class="g-pageHeader" style="height: 63px;">
      <h1 style="margin: auto;">{{$t('configChangeover.title')}}</h1>
    </div>

    <form class="g-pageBody" @submit.prevent="onSubmit">

      <div class="form-group">
        <label for="inputText">{{$t('configChangeover.text')}}</label>
        <input
          type="text"
          required
          v-model="changeover.text"
          class="form-control"
          id="inputText"
          :placeholder="$t('configChangeover.textPHolder')">
      </div>

      <div class="form-group">
        <label for="inputLines">{{$t('configChangeover.lines')}}</label>
        <div id="inputLines">
          <MultipleSelect
            :data="lineList"
            v-model="changeover.linesList"
            multiple
            :options="opt" >
          </MultipleSelect>
        </div>
      </div>

      <div class="form-group">
        <input type="checkbox" id="password" v-model="changeover.needPsw">
        <label class="checkboxLabel" for="password">{{$t('configChangeover.password')}}</label>
      </div>

      <div class="form-group">
        <input type="checkbox" id="isActive" v-model="changeover.isActive">
        <label class="checkboxLabel" for="isActive">{{$t('configChangeover.active')}}</label>
      </div>

      <button class="btn btn-perfcell float-left marginTop50px" @click.prevent="clickRetour">
        {{ $t('configChangeover.cancel' ) }}
      </button>

      <button class="btn btn-perfcell float-left marginTop50px marginLeft10px" type="submit">
        {{ $t('configChangeover.submit' ) }}
      </button>

      <p v-if="errors.length" class="float-left marginTop50px marginLeft30px" :style="{ color: msgColor }">
        <b v-if="msgColor == 'red'">{{$tc('configChangeover.error', errors.length)}}</b>
        <ul>
          <li v-for="error in errors" :key="error">{{ error }}</li>
        </ul>
      </p>

    </form>

  </div>
</template>

<script>
import { axiosInstance } from "@/main";

export default {
  data() {
    return {
      changeover: {
        _id: '',
        text: '',
        linesList: [],
        needPsw: false,
        isActive: true
      },
      id: '',
      errors: [],
      msgColor: "red",
      lineList: [],
      opt: {
        multiple: true,
        filter: true,
        placeholder: this.$i18n.t('configChangeover.linesPHolder'),
        multipleWidth: 500,
        width: 500,
        dropWidth: 500,
        locale: localStorage.getItem("locale")
      }
    }
  },
  methods: {
    onSubmit() {
      if( ! this.validate() )
        return;
      axiosInstance.post('/changeover/' + this.id, this.changeover)
      .then((resp) => {
        //console.log("----- onSubmit ok = " + JSON.stringify(resp.data, null, 2));

        this.changeover = resp.data;
        this.id = this.changeover._id;
        this.errors.push(this.$i18n.t('configChangeover.success'));
        this.msgColor = "green";
      }).catch((err) => {
        console.log("----- onSubmit error = " + JSON.stringify(err, null, 2));
      });
    },
    validate() {
      this.errors = [];
      this.msgColor = "red";

      this.changeover.text = this.changeover.text.trim();
      if( ! this.changeover.text.length )
        return false;

      return ! this.errors.length;
    },
    clickRetour() {
      this.$router.replace("/configChangeoversList");
    }
  },
  mounted() {
    if( this.id != "0" ) {
      axiosInstance.get('/changeover/' + this.id)
      .then(resp => {
        //console.log("----- get /changeover = " + JSON.stringify(resp.data, null, 2));
        this.changeover = resp.data;
      })
      .catch(err => {
        console.log("----- get /changeover error = " + JSON.stringify(err, null, 2));
      });
    }

    axiosInstance.get('/lineList', {"params": { "isActive": true }})
    .then(resp => {
        for( let i=0; i<resp.data.length; i++ ) {
          resp.data[i].text = resp.data[i].name + ' - ' + resp.data[i].description;
          resp.data[i].value = resp.data[i]._id;
        }
        //console.log("----- get /lineList = " + JSON.stringify(resp.data, null, 2));
        this.lineList = resp.data;
    }).catch(err => {
        console.log("----- get /lineList error = " + JSON.stringify(err, null, 2));
    });
  },
  created() {
    this.id = this.$route.params.id;
    //console.log("----- this.id = " + JSON.stringify(this.id, null, 2));
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/colors.scss';

form {
  background: White;
  padding: 2rem 2rem 1rem;
  width: 100%;
}

label {
  font-weight: bold;
}

.narrow {
  width: 70px;
}
</style>
