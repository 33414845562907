<template>
  <!-- TABLE -->
  <div class="g-pageBody" style="padding: 10px">
    <table>
      <thead style="width: 100%">
        <tr class="table-grid">
          <th>{{ $t("cleaningFactorList.table.name") }}</th>
          <th class="textAlignCenter">{{ $t("cleaningFactorList.table.edit") }}</th>
          <th class="textAlignCenter">{{ $t("cleaningFactorList.table.remove") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="cleaningFactor in cleaningsFactorsToShow" :key="cleaningFactor._id" class="table-grid">
          <td>{{ cleaningFactor.cleaningFactorName }}</td>
          <td class="textAlignCenter cursorPointer" @click="handleEdit(cleaningFactor._id)"><i class="fa fa-pencil"></i></td>
          <td class="textAlignCenter cursorPointer" @click="handleRemove(cleaningFactor._id)"><i class="fa fa-remove"></i></td>
        </tr>
        <tr v-for="key of emptyRows" :key="key">
          <td>.</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  computed: {
    cleaningsFactorsToShow() {
      const fromIndex = this.rowsPerPage * (this.actualPage - 1);
      const toIndex = this.rowsPerPage * (this.actualPage - 1) + this.rowsPerPage;

      return this.cleaningFactors.slice(fromIndex, toIndex);
    },
    emptyRows() {
      const emptyRows = this.rowsPerPage - this.cleaningsFactorsToShow.length;
      const keys = [];
      for (let i = 0; i < emptyRows; i++) keys.push(i);
      return keys;
    },
  },
  methods: {
    handleEdit(id) {
      this.$emit("editItem", id);
    },
    handleRemove(id) {
      this.$emit("removeItem", id);
    },
  },
  props: {
    cleaningFactors: {
      type: Array,
      default: () => [],
    },
    rowsPerPage: {
      type: Number,
      default: 15,
    },
    actualPage: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/global.scss";
@import "@/assets/styles/table.scss";
.table-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 80px 80px;
  align-items: center;
}

.table-grid > td:first-child {
  padding-left: 10px;
}

.table-grid > td {
  padding: 5px 0;
}
</style>
