<template>
  <div style="padding-top: 10px">
    <div class="form-inline">
      <div class="form-group search-text">
        <label for="searchText">{{ $t("cleaningFactorList.search") }}</label>
        <input id="searchText" type="text" class="form-control" v-model="searchValue" @keypress.enter="handleSearch" />
        <button @click="handleSearch()" class="btn btn-perfcell">
          <span class="glyphicon glyphicon-search"></span>
          {{ $t("search") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchValue: "",
    };
  },
  methods: {
    handleSearch() {
      this.$emit("search", this.searchValue);
    },
  },
};
</script>

<style></style>
