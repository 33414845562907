<template>
  <div class="g-page-container">

    <div class="g-pageHeader" style="height: 63px;">
      <h1 style="margin: auto;">{{$t('configMsg.title')}}</h1>
    </div>

    <form class="g-pageBody" @submit.prevent="onSubmit">

      <div class="form-group">
        <label for="inputText">{{$t('configMsg.text')}}</label>
        <input
          type="text"
          required
          v-model="smsMessage.text"
          class="form-control"
          id="inputText"
          :placeholder="$t('configMsg.textPHolder')">
      </div>

      <div class="form-group">
        <input type="checkbox" id="isActive" v-model="smsMessage.isActive">
        <label class="checkboxLabel" for="isActive">{{$t('configMsg.active')}}</label>
      </div>

      <button class="btn btn-perfcell float-left marginTop50px" @click.prevent="clickRetour">
        {{ $t('configMsg.cancel' ) }}
      </button>

      <button class="btn btn-perfcell float-left marginTop50px marginLeft10px" type="submit">
        {{ $t('configMsg.submit' ) }}
      </button>

      <p v-if="errors.length" class="float-left marginTop50px marginLeft30px" :style="{ color: msgColor }">
        <b v-if="msgColor == 'red'">{{$tc('configMsg.error', errors.length)}}</b>
        <ul>
          <li v-for="error in errors" :key="error">{{ error }}</li>
        </ul>
      </p>

    </form>

  </div>
</template>

<script>
import { axiosInstance } from "@/main";

export default {
  data() {
    return {
      smsMessage: {
        _id: '',
        text: '',
        isActive: true
      },
      id: '',
      errors: [],
      msgColor: "red"
    }
  },
  methods: {
    onSubmit() {
      if( ! this.validate() )
        return;
      axiosInstance.post('/smsMessage/' + this.id, this.smsMessage)
      .then((resp) => {
        //console.log("----- onSubmit ok = " + JSON.stringify(resp.data, null, 2));

        this.smsMessage = resp.data;
        this.id = this.smsMessage._id;
        this.errors.push(this.$i18n.t('configMsg.success'));
        this.msgColor = "green";
      }).catch((err) => {
        console.log("----- onSubmit error = " + JSON.stringify(err, null, 2));
      });
    },
    validate() {
      this.errors = [];
      this.msgColor = "red";

      this.smsMessage.text = this.smsMessage.text.trim();
      if( ! this.smsMessage.text.length )
        return false;

      return ! this.errors.length;
    },
    clickRetour() {
      this.$router.replace("/configSmsMessagesList");
    }
  },
  mounted() {
    if( this.id != "0" ) {
      axiosInstance.get('/smsMessage/' + this.id)
      .then(resp => {
        //console.log("----- get /smsMessage = " + JSON.stringify(resp.data, null, 2));
        this.smsMessage = resp.data;
      })
      .catch(err => {
        console.log("----- get /smsMessage error = " + JSON.stringify(err, null, 2));
      });
    }
  },
  created() {
    this.id = this.$route.params.id;
    //console.log("----- this.id = " + JSON.stringify(this.id, null, 2));
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/colors.scss';

form {
  background: White;
  padding: 2rem 2rem 1rem;
  width: 100%;
}

label {
  font-weight: bold;
}

.narrow {
  width: 70px;
}
</style>
