import Vue from "vue";
import Router from "vue-router";
import EventBus from "@/eventBus";

// all the screens definitions
import Config from "@/views/Config.vue";
import CleaningFactorConfigList from "../views/cleaning-factor-config-list/Cleaning-factor.vue";
import CleaningFactorConfig from "../views/cleaning-factor-config/Cleaning-factor-config.vue";
import ConfigFactory from "@/views/ConfigFactory.vue";
import ConfigFactorySetup from "@/views/ConfigFactorySetup.vue";
import ConfigIncentive from "@/views/ConfigIncentive.vue";
import Login from "@/views/Login.vue";
import Operation from "@/views/Operation.vue";
import Admin from "@/views/Admin.vue";
import ConfigSchedulesList from "@/views/ConfigSchedulesList.vue";
import ConfigSchedule from "@/views/ConfigSchedule.vue";
import ConfigLinesList from "@/views/ConfigLinesList.vue";
import ConfigLine from "@/views/ConfigLine.vue";
import ConfigStandardsList from "@/views/ConfigStandardsList.vue";
import ConfigStandard from "@/views/ConfigStandard.vue";
import ConfigUsersList from "@/views/ConfigUsersList.vue";
import ConfigUser from "@/views/ConfigUser.vue";
import ConfigLogsList from "@/views/ConfigLogsList.vue";
import ServerDown from "@/views/ServerDown.vue";
import Reports from "@/views/Reports.vue";
import ConfigDowntimesList from "@/views/ConfigDowntimesList.vue";
import ConfigDowntime from "@/views/ConfigDowntime.vue";
import ConfigChangeoversList from "@/views/ConfigChangeoversList.vue";
import ConfigChangeover from "@/views/ConfigChangeover.vue";
import ConfigCategoriesList from "@/views/ConfigCategoriesList.vue";
import ConfigCategory from "@/views/ConfigCategory.vue";
import Dashboard from "@/views/Dashboard.vue";
import Dashboard2 from "@/views/Dashboard2.vue";
import ChartsBI from "@/views/ChartsBI.vue";
import ConfigSmsMessagesList from "@/views/ConfigSmsMessagesList.vue";
import ConfigSmsMessage from "@/views/ConfigSmsMessage.vue";
import ConfigSmsRecipientsList from "@/views/ConfigSmsRecipientsList.vue";
import ConfigSmsRecipient from "@/views/ConfigSmsRecipient.vue";
import ConfigAndonsList from "@/views/ConfigAndonsList.vue";
import ConfigAndon from "@/views/ConfigAndon.vue";
import SendMessages from "@/views/SendMessages.vue";
import ConfigTasksList from "@/views/ConfigTasksList.vue";
import ConfigTask from "@/views/ConfigTask.vue";
import ConfigDefectsList from "@/views/ConfigDefectsList.vue";
import ConfigDefect from "@/views/ConfigDefect.vue";
import DailyProdChart from "@/views/DailyProdChart.vue";
import QteVsEffChart from "@/views/QteVsEffChart.vue";
import UnitsPerHourChart from "@/views/UnitsPerHourChart.vue";
import QualityFollowUps from "@/views/QualityFollowUps.vue";
import Inbound from "@/views/InboundDash.vue";
import admin from "@/stores/AdminState.js";
import client from "@/stores/ClientState.js";

Vue.use(Router);

let router = new Router({
  base: process.env.BASE_URL,
  mode: "history",
  routes: [
    {
      // the page to ask the client for his Perfcell key
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        public: true,
      },
    },
    {
      // this is the tablet main screen
      path: "/operation",
      name: "operation",
      component: Operation,
    },
    {
      // the page to ask the user for administrator password
      path: "/admin/:select",
      name: "admin",
      component: Admin,
    },
    {
      // the main configuration menu
      path: "/config",
      name: "config",
      component: Config,
      meta: {
        admin: true,
      },
    },
    {
      path: "/config/cleaning-factor",
      name: "cleaning-factor",
      component: CleaningFactorConfigList,
    },
    {
      path: "/config/cleaning-factor/:cleaningFactorId",
      name: "cleaning-factor",
      component: CleaningFactorConfig,
    },
    {
      path: "/reports",
      name: "reports",
      component: Reports,
    },
    {
      path: "/inbound",
      name: "Inbound Report",
      component: Inbound,
    },
    {
      path: "/configfactory",
      name: "configfactory",
      component: ConfigFactory,
      meta: {
        admin: true,
      },
    },
    {
      path: "/configIncentive",
      name: "configIncentive",
      component: ConfigIncentive,
      meta: {
        admin: true,
      },
    },
    {
      path: "/configFactorySetup",
      name: "configFactorySetup",
      component: ConfigFactorySetup,
      meta: {
        admin: true,
      },
    },
    {
      path: "/configSchedulesList",
      name: "configSchedulesList",
      component: ConfigSchedulesList,
      meta: {
        admin: true,
      },
    },
    {
      path: "/configSchedule/:id",
      name: "configSchedule",
      component: ConfigSchedule,
      meta: {
        admin: true,
      },
    },
    {
      path: "/configStandardsList",
      name: "configStandardsList",
      component: ConfigStandardsList,
      meta: {
        admin: true,
      },
    },
    {
      path: "/configStandard/:id",
      name: "configStandard",
      component: ConfigStandard,
      meta: {
        admin: true,
      },
    },
    {
      path: "/configUsersList",
      name: "configUsersList",
      component: ConfigUsersList,
    },
    {
      path: "/configUser/:id",
      name: "configUser",
      component: ConfigUser,
    },
    {
      path: "/configDowntimesList",
      name: "configDowntimesList",
      component: ConfigDowntimesList,
      meta: {
        admin: true,
      },
    },
    {
      path: "/configDowntime/:id",
      name: "configDowntime",
      component: ConfigDowntime,
      meta: {
        admin: true,
      },
    },
    {
      path: "/configChangeoversList",
      name: "configChangeoversList",
      component: ConfigChangeoversList,
      meta: {
        admin: true,
      },
    },
    {
      path: "/configChangeover/:id",
      name: "configChangeover",
      component: ConfigChangeover,
      meta: {
        admin: true,
      },
    },
    {
      path: "/configSmsRecipientsList",
      name: "configSmsRecipientsList",
      component: ConfigSmsRecipientsList,
      meta: {
        admin: true,
      },
    },
    {
      path: "/configSmsRecipient/:id",
      name: "configSmsRecipient",
      component: ConfigSmsRecipient,
      meta: {
        admin: true,
      },
    },
    {
      path: "/configSmsMessagesList",
      name: "configSmsMessagesList",
      component: ConfigSmsMessagesList,
      meta: {
        admin: true,
      },
    },
    {
      path: "/configSmsMessage/:id",
      name: "configSmsMessage",
      component: ConfigSmsMessage,
      meta: {
        admin: true,
      },
    },
    {
      path: "/configCategoriesList",
      name: "configCategoriesList",
      component: ConfigCategoriesList,
      meta: {
        admin: true,
      },
    },
    {
      path: "/configCategory/:id",
      name: "configCategory",
      component: ConfigCategory,
      meta: {
        admin: true,
      },
    },
    {
      path: "/configTasksList",
      name: "configTasksList",
      component: ConfigTasksList,
      meta: {
        admin: true,
      },
    },
    {
      path: "/configDefectsList",
      name: "configDefectsList",
      component: ConfigDefectsList,
      meta: {
        admin: true,
      },
    },
    {
      path: "/configLinesList",
      name: "configLinesList",
      component: ConfigLinesList,
      meta: {
        admin: true,
      },
    },
    {
      path: "/configLine/:id",
      name: "configLine",
      component: ConfigLine,
      meta: {
        admin: true,
      },
    },
    {
      path: "/configTask/:id",
      name: "configTask",
      component: ConfigTask,
      meta: {
        admin: true,
      },
    },
    {
      path: "/configDefect/:id",
      name: "configDefect",
      component: ConfigDefect,
      meta: {
        admin: true,
      },
    },
    {
      path: "/configAndonsList",
      name: "configAndonsList",
      component: ConfigAndonsList,
      meta: {
        admin: true,
      },
    },
    {
      path: "/configAndon/:id",
      name: "configAndon",
      component: ConfigAndon,
      meta: {
        admin: true,
      },
    },
    {
      path: "/sendMessages",
      name: "sendMessages",
      component: SendMessages,
      meta: {
        admin: true,
      },
    },
    {
      path: "/configLogsList",
      name: "configLogsList",
      component: ConfigLogsList,
      meta: {
        admin: true,
      },
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: Dashboard,
    },
    {
      path: "/dashboard2",
      name: "dashboard2",
      component: Dashboard2,
    },
    {
      path: "/chartsbi",
      name: "chartsbi",
      component: ChartsBI,
    },
    {
      path: "/dailyProdChart",
      name: "dailyProdChart",
      component: DailyProdChart,
    },
    {
      path: "/qteVsEffChart",
      name: "qteVsEffChart",
      component: QteVsEffChart,
    },
    {
      path: "/unitsPerHourChart",
      name: "unitsPerHourChart",
      component: UnitsPerHourChart,
    },
    {
      path: "/qualityFollowUps",
      name: "qualityFollowUps",
      component: QualityFollowUps,
      meta: {
        admin: true,
      },
    },
    {
      path: "/serverDown/:select",
      name: "serverDown",
      component: ServerDown,
    },
    {
      // the default page for all other links
      path: "*",
      name: "404",
      component: Login,
      meta: {
        public: true,
      },
    },
  ],
});

router.beforeResolve((to, from, next) => {
  console.log("----- beforeResolve, from " + from.name + " -> to " + to.name);
  admin.changePage(to.matched.some((record) => record.meta.admin));
  EventBus.$emit("changePage", to.name);
  next();
});

router.beforeEach((to, from, next) => {
  console.log("----- beforeEach, from " + from.name + " -> to " + to.name);
  const toIsPublic = to.matched.some((record) => record.meta.public);
  const toIsAdmin = to.matched.some((record) => record.meta.admin);

  //console.log('----- clientIsLoggedIn = ' + client.isLoggedIn() );
  //console.log('----- adminIsLoggedIn = ' + admin.isLoggedIn() );

  if (to.name == "admin" && admin.isLoggedIn()) {
    next("/config");
  } else if (toIsAdmin && !admin.isLoggedIn()) {
    if (from.name == "reports")
      // that's mean we are going to qualityFollowUps
      next("/admin/2");
    else next("/admin/1");
  } else if (toIsPublic && client.isLoggedIn()) {
    next("/operation");
  } else if (!toIsPublic && !client.isLoggedIn()) {
    next("/login");
  } else {
    next();
  }
});

export default router;
