<template>
  <div class="g-page-container">

    <div class="g-pageHeader" style="height: 63px;">
      <h1 style="margin: auto;">{{$t('configAndon.title')}}</h1>
    </div>

    <form class="g-pageBody" @submit.prevent="onSubmit">

      <div class="form-group">
        <label for="inputNodeId">{{$t('configAndon.nodeId')}}</label>
        <input
          type="text"
          v-model="andon.nodeId"
          class="form-control"
          id="inputNodeId"
          :placeholder="$t('configAndon.nodeIdPHolder')">
      </div>

      <div class="form-group">
        <label for="inputDescription">{{$t('configAndon.desc')}}</label>
        <input
          type="text"
          v-model="andon.description"
          class="form-control"
          id="inputDescription"
          :placeholder="$t('configAndon.descPHolder')">
      </div>

      <div class="form-group">
        <label for="associateLines">{{$t('configAndon.associateLines')}}</label>
        <div id="associateLines">
          <MultipleSelect
            :data="lineList"
            v-model="andon.associateLines"
            multiple
            :options="opt" >
          </MultipleSelect>
        </div>
      </div>

      <div class="form-group">
        <input type="checkbox" id="isActive" v-model="andon.isActive">
        <label class="checkboxLabel" for="isActive">{{$t('configAndon.active')}}</label>
      </div>

      <button class="btn btn-perfcell float-left marginTop50px" @click.prevent="clickRetour">
        {{ $t('configAndon.cancel' ) }}
      </button>

      <button class="btn btn-perfcell float-left marginTop50px marginLeft10px" type="submit">
        {{ $t('configAndon.submit' ) }}
      </button>

      <p v-if="errors.length" class="float-left marginTop50px marginLeft30px" :style="{ color: msgColor }">
        <b v-if="msgColor == 'red'">{{$tc('configAndon.error', errors.length)}}</b>
        <ul>
          <li v-for="error in errors" :key="error">{{ error }}</li>
        </ul>
      </p>

    </form>

  </div>
</template>

<script>
import { axiosInstance } from "@/main";

export default {
  data() {
    return {
      andon: {
        _id: '',
        nodeId: '',
        description: '',
        associateLines: [],
        isActive: true
      },
      id: '',
      errors: [],
      msgColor: "red",
      lineList: [],
      opt: {
        multiple: true,
        filter: true,
        placeholder: this.$i18n.t('configAndon.linesPHolder'),
        multipleWidth: 500,
        width: 500,
        dropWidth: 500,
        locale: localStorage.getItem("locale")
      }
    }
  },
  methods: {
    onSubmit() {
      if( ! this.validate() )
        return;
      axiosInstance.post('/andon/' + this.id, this.andon)
      .then((resp) => {
        //console.log("----- onSubmit ok = " + JSON.stringify(resp.data, null, 2));
        if( resp.data == "andonExist" ) {
          this.errors = [];
          this.msgColor = "red";
          this.errors.push(this.$i18n.t('configAndon.andonExist'));
        }
        else {
          this.andon = resp.data;
          this.id = this.andon._id;
          this.errors.push(this.$i18n.t('configAndon.success'));
          this.msgColor = "green";
        }
      }).catch(() => {
        console.log("----- onSubmit error ");
      });
    },
    validate() {
      this.errors = [];
      this.msgColor = "red";

      return ! this.errors.length;
    },
    clickRetour() {
      this.$router.replace("/configAndonsList");
    }
  },
  mounted() {
    if( this.id != "0" ) {
      axiosInstance.get('/andon/' + this.id)
      .then(resp => {
        //console.log("----- get /andon = " + JSON.stringify(resp.data, null, 2));
        this.andon = resp.data;
      })
      .catch(err => {
        console.log("----- get /andon error = " + JSON.stringify(err, null, 2));
      });
    }

    axiosInstance.get('/lineList', {"params": { "isActive": true }})
    .then(resp => {
        for( let i=0; i<resp.data.length; i++ ) {
          resp.data[i].text = resp.data[i].name + ' - ' + resp.data[i].description;
          resp.data[i].value = resp.data[i]._id;
        }
        //console.log("----- get /lineList = " + JSON.stringify(resp.data, null, 2));
        this.lineList = resp.data;
    }).catch(err => {
        console.log("----- get /lineList error = " + JSON.stringify(err, null, 2));
    });
  },
  created() {
    this.id = this.$route.params.id;
    //console.log("----- this.id = " + JSON.stringify(this.id, null, 2));
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/colors.scss';

form {
  background: White;
  padding: 2rem 2rem 1rem;
  width: 100%;
}

label {
  font-weight: bold;
}
</style>
