<template>
  <div class="g-page-container">

    <div class="g-pageHeader" style="height: 63px;">
      <h1 style="margin: auto;">{{$t('chartsbi.title')}}</h1>
    </div>


    <div class="col-md-12">
      <div class="row">
        <div class="col-md-4" @click="clickDailyProdChart">
          <div class="whiteBox">
            <img src="../assets/iconeBI/iconeBiDpGrey.png" />
            <div class="titre">{{ $t('chartsbi.d1' ) }}</div>
            <div style="margin-left: 15px;">
              {{ $t('chartsbi.d2' ) }}<br />
              {{ $t('chartsbi.d3' ) }}<br />
              {{ $t('chartsbi.d4' ) }}<br />
              {{ $t('chartsbi.d5' ) }}<br />
              {{ $t('chartsbi.d6' ) }}
            </div>
          </div>
        </div>
        <div class="col-md-4" @click="clickQteVsEffChart">
          <div class="whiteBox">
            <img src="../assets/iconeBI/iconeBiQveGrey.png" />
            <div class="titre">{{ $t('chartsbi.q1' ) }}</div>
            <div style="margin-left: 15px;">
              {{ $t('chartsbi.q2' ) }}
            </div>
          </div>
        </div>
        <div class="col-md-4" @click="clickUnitsPerHourChart">
          <div class="whiteBox">
            <img src="../assets/iconeBI/iconeBiUphGrey.png" />
            <div class="titre">{{ $t('chartsbi.u1' ) }}</div>
            <div style="margin-left: 15px;">
              {{ $t('chartsbi.u2' ) }}
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <button class="btn btn-perfcell float-left marginTop20px" @click="clickRetour">
          {{ $t('chartsbi.cancel' ) }}
        </button>
      </div>

    </div>

  </div>
</template>

<script>

export default {
  data() {
    return {
    }
  },
  methods: {
    clickDailyProdChart() {
      this.$router.replace("/dailyProdChart");
    },
    clickQteVsEffChart() {
      this.$router.replace("/qteVsEffChart");
    },
    clickUnitsPerHourChart() {
      this.$router.replace("/unitsPerHourChart");
    },
    clickRetour() {
      this.$router.replace("/config");
    }
  },
  mounted() {
    //console.log("----- configFactory mounted");
  },
  beforeDestroy() {
  }
}
</script>

<style lang="scss" scoped>

.whiteBox {
  float: left;
  width: 100%;
  background-color: white;
  height:270px;
  margin-top: 20px;
  margin-right: 2%;
  margin-bottom: 20px;
  font-size: 13px;
}

.whiteBox img {
  display: block;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.whiteBox .titre {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

</style>
