<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask" v-show="showChangeover">
      <div class="modal-container">
        <div>
          <div v-if="!gotList">
            <b-spinner label="Loading..."></b-spinner>
          </div>
          <div v-else>
            <label v-if="changeoversList.length == 0" class="attentionLeft">
              {{ $t("selectChangeover.noChangeover1") }}<br />
              {{ $t("selectChangeover.noChangeover2") }}
            </label>
            <label v-else>{{ $t("selectChangeover.sChangeover") }}</label>
          </div>
          <b-table
            class="table-big-font"
            ref="selectChangeoverTable"
            hover
            small
            selectable
            select-mode="single"
            selected-variant="success"
            sticky-header="200px"
            primary-key="_id"
            :items="changeoversList"
            :fields="showFields"
            @row-selected="onChangeoverSelected"
          >
          </b-table>
        </div>

        <div v-if="needPsw" class="form-group">
          <label for="inputPsw">{{ $t("selectChangeover.password") }}</label>
          <InputText
            required
            password="true"
            :autocomplete="false"
            id="inputPsw"
            :initInputText="initInputText"
            @handleChange="handleChange($event)"
            :placeholder="$t('selectChangeover.enterPsw')"
          >
          </InputText>
        </div>

        <p v-if="error" class="float-left" style="color: red">
          <b>{{ $t("selectChangeover.wrongPsw") }}</b>
        </p>

        <button class="btn btn-perfcell float-right marginTop40px" @click="clickCancel">
          {{ $t("selectChangeover.cancel") }}
        </button>

        <button v-if="saveChangeoverEnabled" class="btn btn-perfcell float-right marginTop40px marginRight10px" @click="clickSave">
          {{ $t("selectChangeover.save") }}
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import { axiosInstance } from "@/main";
import oper from "@/stores/Operations.js";
import InputText from "@/components/InputText.vue";

export default {
  name: "SelectChangeover",
  template: "#modal-template",
  props: {
    showChangeover: Boolean,
    doInitChangeover: Boolean,
  },
  data: function () {
    return {
      changeoversList: [],
      showFields: [
        {
          key: "text",
          label: this.$i18n.t("selectChangeover.texte"),
        },
      ],
      selectedChangeover: {},
      selectedChangeoverId: "",
      needPsw: false,
      gotList: false,
      password: "",
      saveChangeoverEnabled: false,
      error: false,
      initInputText: false,
    };
  },
  components: {
    InputText,
  },
  watch: {
    doInitChangeover: function (val) {
      if (val) {
        //console.log("----- init SelectChangeover");

        this.selectedChangeover = {};
        this.selectedChangeoverId = "";
        this.needPsw = false;
        this.password = "";
        this.saveChangeoverEnabled = false;

        this.changeoversList = [];
        this.$refs.selectChangeoverTable.refresh();
        this.gotList = false;

        axiosInstance
          .get("/changeoverList/" + oper.getLineId())
          .then((resp) => {
            //console.log("----- get /changeoverList = " + JSON.stringify(resp.data, null, 2));
            this.changeoversList = resp.data;
            this.gotList = true;

            this.selectedChangeover = {};
            this.selectedChangeoverId = "";
          })
          .catch((err) => {
            console.log("----- get /changeoverList error = " + JSON.stringify(err, null, 2));
          });
      }
    },
  },
  methods: {
    handleChange(payload) {
      this.password = payload;
    },
    onChangeoverSelected(item) {
      //console.log("----- item = " + JSON.stringify(item, null, 2));
      this.error = false;
      if (item.length) {
        this.needPsw = item[0].needPsw;
        this.selectedChangeover = item[0];
        this.selectedChangeoverId = item[0]._id;
        this.saveChangeoverEnabled = true;
      }
    },
    clickSave: function () {
      if (this.needPsw) {
        let password = this.password;
        axiosInstance
          .post("/admin", { password })
          .then(() => this.saveOk())
          .catch(() => {
            this.error = true;
            this.password = "";
            this.initInputText = true;
            this.$nextTick(function () {
              this.initInputText = false;
            });
          });
      } else this.saveOk();
    },
    saveOk: function () {
      this.$emit("close", {
        cancel: false,
        selectedChangeover: this.selectedChangeover,
      });
    },
    clickCancel: function () {
      this.$emit("close", { cancel: true });
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.modal-mask {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}
.modal-container {
  width: 500px;
  height: 500px;
  margin: 40px auto 0;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
