<template>
  <div class="tabMainContainer" ref="tabMainContainer">
    <div v-if="!tabStdOk || !tabTeamOk">
      <p>{{ $t("tabMain.notSetUp") }}</p>
    </div>
    <div v-else-if="!tabMainOk">
      <label class="attention cursorPointer" @click="clickStartProd">{{ $t("tabMain.startProd") }}</label>
    </div>

    <div v-else-if="inDownTime" class="downtimePopup" ref="downtimePopup">
      <table style="width: 100%; text-align: center">
        <tr>
          <td height="50">
            <h2>{{ $t("downtime.title") }}</h2>
          </td>
        </tr>
        <tr>
          <td height="100">
            <h3>{{ downTimeReason }}</h3>
          </td>
        </tr>
        <tr>
          <td height="50">
            <h2>{{ dTime }}</h2>
          </td>
        </tr>
      </table>
    </div>

    <div v-else-if="inChangeover" class="changeoverPopup" ref="changeoverPopup">
      <table style="width: 100%; text-align: center">
        <tr>
          <td height="50">
            <h2>{{ $t("changeover.title") }}</h2>
          </td>
        </tr>
        <tr>
          <td height="100">
            <h3>{{ changeoverReason }}</h3>
          </td>
        </tr>
        <tr>
          <td height="50">
            <h2>{{ coTime }}</h2>
          </td>
        </tr>
      </table>
    </div>

    <div v-else>
      <div v-if="inBreakTime" class="breakPopup">
        <div>
          <span class="title">{{ $t("tabMain.breakTitle") }}</span>
          <div class="information">
            <span
              >{{ $t("tabMain.breakStart") }}: <span class="accent">{{ startTimeOfBreak.format("HH:mm") }}</span>
              {{ Number(startTimeOfBreak.format("HH:mm").split(":")[0]) > 12 ? "PM" : "AM" }}</span
            >
            <span
              >{{ $t("tabMain.breakEnd") }}: <span class="accent">{{ endTimeOfBreak.format("HH:mm") }}</span>
              {{ Number(endTimeOfBreak.format("HH:mm").split(":")[0]) > 12 ? "PM" : "AM" }}</span
            >
            <span
              >{{ $t("tabMain.breakDuration") }}:<span class="accent">{{ durationOfBreak }}</span> {{ durationMeasurementUnit }}</span
            >
            <br />
            <span
              >{{ $t("tabMain.breakRemaining") }}: <span class="accent">{{ breakTimeLeft }}</span> {{ breakTimeLeftMeasurementUnit }}</span
            >
            <button class="end-break-button" @click="handleEndBreak">
              <span v-if="endBreakIsLoading">{{ $t("tabMain.loading") }}...</span>
              <span v-else>{{ $t("tabMain.endBreak") }}</span>
            </button>
          </div>
        </div>
      </div>
      <div :style="tabMainSize">
        <div class="tabMainRow0">
          <div class="tabMainColOneThird colored cursorPointer">
            <p id="comment6" class="title">
              {{ $t("tabMain.actual") }}<span style="color: red"> <i class="fa fa-info-circle"></i></span>
            </p>
            <b-popover target="comment6" triggers="hover" placement="top" variant="primary">
              {{ $t("tabMain.comment6") }}
            </b-popover>
            <ul class="flex-container">
              <li class="flex-item2">
                <p class="bigNumber">{{ nbProducts }}</p>
              </li>
            </ul>
          </div>
          <div class="tabMainColOneThird colored cursorPointer">
            <div class="halfCol">
              <div v-if="!tabNoEff">
                <p id="comment7" class="title">
                  {{ $t("tabMain.goal") }}<span style="color: red"> <i class="fa fa-info-circle"></i></span><br />
                </p>
                <b-popover target="comment7" triggers="hover" placement="top" variant="primary">
                  {{ $t("tabMain.comment7") }}
                </b-popover>
                <ul class="flex-container">
                  <li class="flex-item3">
                    <p class="bigNumber">{{ goal }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="tabMainColOneThird colored">
            <div class="halfCol">
              <div v-if="!tabNoEff && efficiency !== '--'">
                <p class="title">{{ $t("tabMain.eff") }}</p>
                <ul class="flex-container2 full">
                  <li v-if="!isNaN(efficiency)" class="flex-item5" :class="getColor(efficiency)">
                    <p class="bigNumber">{{ efficiency }}%</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="tabMainRow1">
          <div class="tabMainColOneThird colored cursorPointer">
            <p id="comment8" class="title">
              {{ $t("tabMain.actualAvrg") }}<span style="color: red"> <i class="fa fa-info-circle"></i></span>
            </p>
            <b-popover target="comment8" triggers="hover" placement="bottom" variant="primary">
              {{ $t("tabMain.comment8") }}
            </b-popover>
            <ul class="flex-container">
              <li class="flex-item2">
                <p class="bigNumber2">{{ nbProductsAvrg }}</p>
              </li>
            </ul>
          </div>
          <div class="tabMainColOneThird colored cursorPointer">
            <div class="halfCol">
              <div v-if="!tabNoEff">
                <p id="comment9" class="title">
                  {{ $t("tabMain.goalAvrg") }}<span style="color: red"> <i class="fa fa-info-circle"></i></span><br />
                </p>
                <b-popover target="comment9" triggers="hover" placement="bottom" variant="primary">
                  {{ $t("tabMain.comment9") }}
                </b-popover>
                <ul class="flex-container">
                  <li class="flex-item3">
                    <p class="bigNumber2">{{ goalAvrg }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="tabMainColOneThird colored" style="z-index: 1">
            <div class="halfCol">
              <div v-if="!tabNoEff">
                <p class="title">{{ $t("tabMain.cycle") }}</p>
                <ul class="flex-container">
                  <li v-if="!isNaN(cycleTime)" class="flex-item5 halfCol" :class="getColor(efficiency)">
                    <p class="smallNumber">{{ (cycleTime / 60).toFixed(2) }}</p>
                    <p class="smallNumber">{{ getCycleTime() }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="tabMainRow2">
          <div class="tabMainColOneThird colored cursorPointer">
            <ul class="flex-container full">
              <div v-if="!scannerOn" class="enter-product">
                <div class="row">
                  <div class="col-md-12 lastEntry">
                    {{ $t("tabMain.last") }}
                    {{ lastEntry }}
                  </div>
                </div>
                <div class="row marginLR">
                  <div class="col-md-6 cadrez" @click="addManyProducts">
                    <img src="../assets/calc.png" />
                  </div>
                  <div class="col-md-6 cadrez" @click="addProduct(lineBatchSize)">
                    <img src="../assets/add_big.png" />
                  </div>
                </div>
              </div>

              <div v-else class="enter-product-2">
                <div class="lastScanned">
                  <p>{{ $t("tabMain.lastScanned") }}</p>
                  <p>{{ lastScanned }}</p>
                </div>
              </div>
            </ul>
          </div>
          <div class="tabMainColOneThird colored">
            <div class="halfCol">
              <div class="toProduce">
                <div>
                  <p class="title">{{ $t("tabMain.produce") }}<br /></p>
                  <p v-if="toProduce > 0" class="bigNumber green" style="margin-top: -10px">
                    {{ toProduce }}
                  </p>
                  <p v-else-if="toProduce == 0" class="toProduceText" style="margin-top: -10px">
                    {{ $t("tabMain.done") }}
                  </p>
                  <p v-else class="Ptype-style">Add quantity to produce in the configuration module.<br />Contact Perfcell support.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="tabMainColOneThird toProduce colored ordersCountContainer">
            <p id="ordersCount" class="title" style="cursor: pointer">{{ $t("tabMain.orderScanned") }}<i class="fa fa-info-circle" style="color: red"></i></p>
            <p class="bigNumber green">{{ ordersCount }}</p>
            <b-popover target="ordersCount" triggers="hover" placement="bottom" variant="primary">
              {{ $t("tabMain.orderScannedTooltip") }}
            </b-popover>
          </div>
        </div>

        <div class="tabMainRow3">
          <div class="tabMainColOneThird">
            <ul class="flex-container">
              <li class="flex-item1">
                <p class="bigNumber">{{ nbProductsToday }}</p>
              </li>
            </ul>
          </div>
          <div class="tabMainColOneThird">
            <p class="todaydata">
              {{ $t("tabMain.today") }}
            </p>
          </div>
          <div v-if="!tabNoEff" class="tabMainColOneThird">
            <ul class="flex-container full">
              <li v-if="!isNaN(efficiencyToday)" class="flex-item4 inverse" :class="getColor(efficiencyToday)">
                <p class="bigNumber">{{ Math.trunc(efficiencyToday) }}%</p>
              </li>
            </ul>
          </div>
        </div>

        <div class="tabMainRow4 history-div">
          <table class="history">
            <thead>
              <tr>
                <th style="width: 30%">
                  {{ $t("tabMain.style") }}
                </th>
                <th style="width: 42%">
                  {{ $t("tabMain.desc") }}
                </th>
                <th style="width: 14%">
                  {{ $t("tabMain.actual") }}
                </th>
                <th v-if="!tabNoEff" style="width: 14%">
                  {{ $t("tabMain.goal") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(entry, i) in historic" :key="i">
                <td>
                  {{ entry.partNumber }}
                </td>
                <td class="align-left">
                  {{ entry.description }}
                </td>
                <td>
                  {{ entry.nbProducts }}
                </td>
                <td v-if="!tabNoEff">
                  {{ entry.goal }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <startProd :doInitStart="startDoInit" :showStart="showModalStart" @close="closeModalStart"></startProd>

    <addProdKeyPad :doInitKeyPad="keyPadDoInit" :showKeyPad="showModalKeyPad" @close="closeModalKeyPad"></addProdKeyPad>

    <selectDefect :doInitDefect="selectDefectDoInit" :showDefect="showModalDefect" :category="categoryForDefects" @close="closeModalSelectDefect"></selectDefect>

    <courierConfirm :showConfirm="showConfirm" :barcodeParts="barcode" :courierProcessType="courierProcess" @close="closeModalConfirm"> </courierConfirm>

    <modalIncorrectCourierValidation
      :showModal="showModalIncorrectCourierValidation"
      :message="incorrectCourierMessage"
      :matchId="matchId"
      :courierProcess="courierProcess"
      @close="closeModalIncorrectCourierValidation"
    >
    </modalIncorrectCourierValidation>

    <!-- TODO[epic=quantityP] -->
    <QuantityProducedModal :show="showQuantityProducedModal" @onAnswer="handleQuantityProducedAnswer" />

    <audio ref="audio1" :src="require('../assets/good.mp3')" preload="auto"></audio>
    <audio ref="audio2" :src="require('../assets/buzz.mp3')" preload="auto"></audio>
    <audio ref="blipAudio" :src="require('../assets/blip.mp3')" preload="auto"></audio>

    <audio ref="audioCarrierBlip" :src="require('../assets/audioCarrierBlip.mp3')" preload="auto"></audio>
    <audio ref="audioTourBlip" :src="require('../assets/audioTourBlip.mp3')" preload="auto"></audio>
  </div>
</template>

<script>
import moment from "moment-timezone";
import EventBus from "@/eventBus";
import { axiosInstance } from "@/main";
import oper from "@/stores/Operations.js";
import startProd from "@/components/StartProduction.vue";
import globals from "@/globals.js";
import addProdKeyPad from "@/components/KeyPad.vue";
import selectDefect from "@/components/SelectDefect.vue";
import courierConfirm from "@/components/modalCourierConfirm.vue";
import QuantityProducedModal from "./Modals/Quantity-produced.vue";
import modalIncorrectCourierValidation from "@/components/Modals/incorrectCourierValidation.vue";

export default {
  data: function () {
    return {
      modalOpen: false,
      ordersCount: 0,
      showModalIncorrectCourierValidation: false,
      incorrectCourierMessage: "",
      matchId: "",
      noHide: false,
      showConfirm: false,
      barcode: {
        orderId: "",
        type: "",
        box: "",
        courierId: "",
        routeNumber: "",
      },
      courierProcess: "",

      toastId: "bvToast",
      isFirstScann: true,

      confirmCourier: false,
      showModalConfirm: false,

      isCourierProcess: false,
      courierProcessType: "empty",

      tabMainSize: {
        height: 0,
        width: 0,
      },
      boxes: 0,
      multi_line: false,
      showModalKeyPad: false,
      keyPadDoInit: false,

      showModalStart: false,
      startDoInit: false,

      tabStdOk: false,
      tabTeamOk: false,
      tabMainOk: false,

      activity: {},
      actState: -1,

      scannerOn: false,
      scanned: "",
      lastScanned: "",

      inChangeover: false,
      changeoverReason: "",
      coTime: "",

      inDownTime: false,
      downTimeReason: "",
      dTime: "",

      inBreakTime: false,
      startTimeOfBreak: "",
      endTimeOfBreak: "",
      durationOfBreak: "",
      durationMeasurementUnit: "",
      breakTimeLeft: "",
      breakTimeLeftMeasurementUnit: "",
      breakInterval: null,

      minPercent: 85,
      ptime: 0,

      stdStartTime: moment(),
      SCT: 0,

      // first row
      nbProducts: 0,
      goal: 0,
      efficiency: "--",

      // second row
      nbProductsAvrg: 0,
      goalAvrg: 0,

      // third row
      lastEntry: 0,
      toProduce: -1,
      cycleTime: "--", // in seconds

      // fourth row
      nbProductsToday: 0,
      efficiencyToday: "--",

      // last row
      historic: [],

      lineBatchSize: 1,
      tabNoEff: false,

      selectDefectDoInit: false,
      showModalDefect: false,
      categoryForDefects: null,

      // End break
      endBreakIsLoading: false,

      // Quantity produced limite
      showQuantityProducedModal: false,
    };
  },
  components: {
    startProd,
    addProdKeyPad,
    selectDefect,
    courierConfirm,
    modalIncorrectCourierValidation,
    QuantityProducedModal,
  },
  methods: {
    closeModalConfirm({ isCorrectCourier }) {
      this.showConfirm = false;
      this.modalOpen = false;
      if (isCorrectCourier) {
        this.addProduct(1, this.scanned);
        this.isFirstScann = false;
      }
      this.scanned = "";
    },
    getCycleTime() {
      if (this.cycleTime == 0) return "--";
      else return moment(this.cycleTime * 1000).format("m[m]ss[s]");
    },
    addManyProducts: function () {
      //console.log("----- addManyProducts");
      this.showModalKeyPad = true;
      this.modalOpen = true;
      this.keyPadDoInit = true;
    },
    closeModalKeyPad: function (data) {
      //console.log("----- closeModalKeyPad = " + JSON.stringify(data, null, 2));
      this.showModalKeyPad = false;
      this.modalOpen = false;
      this.keyPadDoInit = false;

      if (data.gotQte) {
        let qte = Number(data.qte);
        if (this.nbProducts + qte < 0) qte = -this.nbProducts;
        if (qte != 0) this.addProduct(qte);
      }
    },
    addProduct: function (qte, scanned) {
      if (this.activity && moment().isBefore(moment(this.activity.startTime))) {
        this.$bvModal.msgBoxOk(this.$i18n.t("tabMain.tooEarly"));

        let log = {
          event: "tooEarly",
          line: oper.getLineId(),
          schedule: oper.getSchedId(),
          time: moment(),
          text1: moment().format(),
          text2: moment(this.activity.startTime).format(),
        };
        axiosInstance.post("/log", log);
        return;
      }
      if (this.actState == globals.STATELUNCH) {
        this.$bvModal.msgBoxOk(this.$i18n.t("tabMain.notInLunch"));
        return;
      }
      if (!oper.getBreaksIncluded() && (this.actState == globals.STATEBREAK1 || this.actState == globals.STATEBREAK2 || this.actState == globals.STATEBREAK)) {
        this.$bvModal.msgBoxOk(this.$i18n.t("tabMain.notInBreak"));
        return;
      }
      this.productData = {
        act: this.activity._id,
        qte: qte,
        lineId: oper.getLineId(),
        emp: this.activity.team.map((e) => e.emp) || [],
        std: this.activity.stds[0].std._id,
        barcodeParts: this.barcode,
        courierProcess: this.courierProcess,
        specialAction: "",
      };

      if (scanned) {
        this.productData.scanned = scanned;
      } else {
        this.lastEntry = qte;
      }

      //below check that it is already scanned or not......
      // if (scanned && this.lastScanned == scanned) {
      //   this.mainAlarmScanned(scanned, false);
      // } else
      {
        if (this.activity.line.inspectLine === true && scanned) {
          this.showModalDefect = true;
          this.modalOpen = true;
          this.selectDefectDoInit = true;
        } else {
          this.postAddProduct();
        }
      }
    },
    getLineAndBoxes: function () {
      axiosInstance.get("/findLineAndBoxes", this.productData).then((result) => {
        this.boxes = result.data.boxes;
      });
    },
    flagOrder: function () {
      axiosInstance.get("/flagOrder", this.productData).then((result) => {
        this.boxes = result.data.boxes;
      });
    },
    addProductAPICall: function () {
      axiosInstance.post("/addproduct", this.productData).then((result) => {
        if (result.data.error) {
          if (result.data.error == "scanned") {
            this.mainAlarmScanned(this.productData.scanned, false);
            this.noHide = false;
          } else oper.setCurrentActivity({});
        } else if (this.productData.scanned) {
          console.log("----- Good scann");
          this.lastScanned = this.productData.scanned;
          this.mainAlarmScanned(this.productData.scanned, true);
          this.noHide = false;
        }
      });
    },
    postAddProduct: function () {
      axiosInstance.post("/flagOrder", this.productData).then((result) => {
        let { statusScanned, statusStd, responseObj, courierProcessError, courierProcessData, maxProductQuantityReach } = result.data;
        if (responseObj || statusScanned || statusStd) console.log("----- Response Obj", responseObj, statusScanned, statusStd);

        if (courierProcessError) {
          this.courierProcess = courierProcessError.courierProcessType;
          let messagePart = "";
          const courierData = {
            inbound: {
              messageReplace: "COURIER",
              playRef: "audioCarrierBlip",
              i18n: "tabMain.notValidInbound",
            },
            outbound: {
              messageReplace: "ROUTE",
              playRef: "audioTourBlip",
              i18n: "tabMain.notValidOutbound",
            },
          };
          messagePart = this.$i18n.t(courierData[this.courierProcess].i18n);
          messagePart = messagePart.replace(`**ORDER_${courierData[this.courierProcess].messageReplace}**`, courierProcessError.value);
          messagePart = messagePart.replace(`**LINE_${courierData[this.courierProcess].messageReplace}**`, courierProcessError.matchId);
          this.incorrectCourierMessage = messagePart;
          this.matchId = courierProcessError.value;
          this.showModalIncorrectCourierValidation = true;
          this.modalOpen = true;
          this.$refs[courierData[this.courierProcess].playRef].play();

          return "";
        } else if (statusStd === "Packing" && statusScanned.length === 1 && statusScanned.includes("Packing")) {
          //order is packed but not picked
          this.mainAlarmScanned(`${this.$i18n.t("tabMain.notPicked.body")}`, false, `${this.$i18n.t("tabMain.notPicked.title")}`, true);
          this.noHide = true;
        } else if (statusStd === "Packing" && !statusScanned.length) {
          //first time scanned for pack but order is not picked up
          this.mainAlarmScanned(`${this.$i18n.t("tabMain.notPicked.body")}`, false, `${this.$i18n.t("tabMain.notPicked.title")}`, true);
          this.noHide = true;
        } else if (statusStd === "Packing" && statusScanned.length === 2) {
          // wo,//body
          // isOk,//sucess
          // customTitle,//title
          // customStyle,//large width
          // variant = "warning"//varaint custom
          let {
            scanned: { employee },
          } = responseObj;
          this.mainAlarmScanned(
            `${this.$i18n.t("tabMain.picker.body")} ${employee[0].firstName} ${employee[0].lastName} ${employee[0].alias}`,
            false,
            `${this.$i18n.t("tabMain.picker.title")}`,
            true,
            "info"
          );
          this.noHide = true;
        } else if (statusStd === "Picking" && statusScanned.length === 2) {
          // wo,//body
          // isOk,//sucess
          // customTitle,//title
          // customStyle,//large width
          // variant = "warning"//varaint custom
          let {
            scanned: { employee },
          } = responseObj;
          this.mainAlarmScanned(
            `${this.$i18n.t("tabMain.picker.body")} ${employee[0].firstName} ${employee[0].lastName} ${employee[0].alias}`,
            false,
            `${this.$i18n.t("tabMain.picker.title")}`,
            true,
            "info"
          );
          this.noHide = true;
        } else if (statusStd === "Packing" && statusScanned.includes("Picking") && statusScanned.length === 1) {
          // wo,//body
          // isOk,//sucess
          // customTitle,//title
          // customStyle,//large width
          // variant = "warning"//varaint custom
          let {
            scanned: { employee },
          } = responseObj;
          this.mainAlarmScanned(
            `${this.$i18n.t("tabMain.picker.body")} ${employee[0].firstName} ${employee[0].lastName} ${employee[0].alias}`,
            false,
            `${this.$i18n.t("tabMain.picker.title")}`,
            true,
            "info"
          );
          this.noHide = true;
        } else if (statusStd === "Picking" && statusScanned.includes("Picking") && statusScanned.length === 1) {
          let {
            scanned: { employee },
          } = responseObj;
          this.mainAlarmScanned(
            `${this.$i18n.t("tabMain.picker.body")} ${employee[0].firstName} ${employee[0].lastName} ${employee[0].alias}`,
            false,
            `${this.$i18n.t("tabMain.picker.title")}`,
            true,
            "info"
          );
          this.noHide = true;
        }
        if (courierProcessData.courierMethod !== "" && courierProcessData.matchId !== "") {
          EventBus.$emit("updateCourierProcess", courierProcessData);
        }

        if (maxProductQuantityReach) {
          this.showQuantityProducedModal = true;
          return;
        }
        this.addProductAPICall();
      });
      // this.addProductAPICall();
    },
    closeModalSelectDefect: function (data) {
      //console.log("----- closeModalSelectDefect = " + JSON.stringify(data, null, 2));
      this.selectDefectDoInit = false;
      this.showModalDefect = false;
      this.modalOpen = false;

      if (!data.cancel) {
        this.productData.defect = data.defect._id;
        if (data.comment) this.productData.comment = data.comment;

        this.postAddProduct();
      } else
        this.$bvModal.msgBoxOk(this.$i18n.t("tabMain.notSaved"), {
          okTitle: this.$i18n.t("ok"),
        });
    },
    closeModalIncorrectCourierValidation: function ({ method }) {
      console.log("CLICK:", method);
      switch (method) {
        case "skipValidation":
        case "changeMatchId":
          this.productData.specialAction = method;
          this.postAddProduct();
          break;
        case "close":
          break;
        default:
          return "";
      }
      this.showModalIncorrectCourierValidation = false;
      this.modalOpen = false;
    },
    clickStartProd: function () {
      this.showModalStart = true;
      this.modalOpen = true;
      this.startDoInit = true;
    },
    closeModalStart: function (data) {
      //console.log("----- closeModalStart = " + JSON.stringify(data, null, 2));
      this.showModalStart = false;
      this.modalOpen = false;
      this.startDoInit = false;

      if (!data.cancel) {
        const categoryName = this.activity.stds[0].std.category.name.toLowerCase();
        if (categoryName.includes("inbound")) this.courierProcess = "inbound";
        else if (categoryName.includes("outbound")) this.courierProcess = "outbound";

        let startData = {};
        startData.line = this.activity.line._id;
        startData.sched = this.activity.sched.sched._id;
        startData.std = this.activity.stds[0].std._id;
        startData.team = [];
        this.activity.team.forEach((emp) => {
          startData.team.push({
            empId: emp.emp._id,
            taskId: emp.taskId,
            taskName: emp.taskName,
            isSupport: emp.isSupport,
          });
        });
        startData.useTakt = this.activity.useTakt || false;
        startData.valueNAT = this.activity.valueNAT;
        startData.valueCD = this.activity.valueCD;
        startData.courierProcess = this.courierProcess;

        //console.log("----- startData = " + JSON.stringify(startData, null, 2));
        axiosInstance.post("/startprod", startData).then((result) => {
          //console.log("----- startprod result = " + JSON.stringify(result.data, null, 2));

          if (result.data.error) {
            let msg = this.$i18n.t("tabMain." + result.data.error);
            if (Array.isArray(result.data.list)) {
              result.data.list.forEach((emp) => {
                msg = msg + emp.firstName + " " + emp.lastName + " " + emp.alias + ", on line: " + emp.lineName + ", ";
              });
            }
            this.$bvModal.msgBoxOk(msg).then(() => {
              EventBus.$emit("teamRemoveEmps", result.data.list);
              EventBus.$emit("prodJustStart", true);
            });
          } else {
            EventBus.$emit("prodJustStart", true);
          }
        });
      }
    },
    mainUpdateActivity: function (data) {
      //console.log('----- mainUpdateActivity = ' + JSON.stringify(data, null, 2));
      this.activity = data;
      this.historic = [];
      if (data._id) {
        this.tabMainOk = true;
        let lastStd = data.stds[data.stds.length - 1].std;
        if (!lastStd.qteToProduce || lastStd.qteToProduce == 0) this.toProduce = -1;
      } else {
        this.tabMainOk = false;
        if (this.scannerOn) oper.scanner(false);
      }

      if (data.stds && data.stds.length > 0) {
        this.tabStdOk = true;
        this.stdStartTime = this.activity.stds[this.activity.stds.length - 1].startTime;
      } else this.tabStdOk = false;

      this.tabTeamOk = !!(data.team && data.team.length > 0);

      this.showModalStart = false;
      this.modalOpen = false;
      this.startDoInit = false;

      if (data.stds && data.stds.length >= 2) {
        for (let i = this.activity.stds.length - 2; i >= 0; i--) {
          let std = this.activity.stds[i];
          if (data.line.highEff) std.goal = std.goalOEE;
          this.historic.push({
            partNumber: std.std.partNumber,
            description: std.std.description,
            nbProducts: std.units,
            goal: Math.floor(std.goal),
          });
        }
      }

      if (data.line && data.line.batchSize) this.lineBatchSize = data.line.batchSize;
      else this.lineBatchSize = 1;

      this.tabNoEff = data.line && data.line.tabNoEff;

      this.computeCycleTime();

      this.lastScanned = "";

      if (data.stds && data.stds.length >= 1) this.categoryForDefects = data.stds[data.stds.length - 1].std.category;
    },
    redimTabMain: function () {
      this.tabMainSize.height = this.$refs.tabMainContainer.clientHeight + "px";
      this.tabMainSize.width = this.$refs.tabMainContainer.clientWidth + "px";
      //console.log("----- this.tabMainSize = " + JSON.stringify(this.tabMainSize, null, 2));
    },
    mainUpdateToProduce: function (data) {
      //console.log("----- mainUpdateToProduce = " + JSON.stringify(data, null, 2));
      this.toProduce = data;
    },
    mainUpdateGoal: function (data) {
      //console.log("----- mainUpdateGoal = " + JSON.stringify(data, null, 2));
      if (this.tabMainOk && this.activity.line && this.activity.line.highEff) {
        data.goal = data.goalOEE;
        data.eff = data.effOEE;
        data.totalEff = data.totalEffOEE;
      }
      this.goal = Math.floor(data.goal);
      this.goalAvrg = data.goalAvrg;
      this.nbProductsAvrg = data.nbProductsAvrg;
      if (data.eff != 0) this.efficiency = data.eff;
      else this.efficiency = "--";
      this.nbProductsToday = 0;
      for (let i = 0; i < globals.CATNB; i++) this.nbProductsToday += data.totalProducts[i];
      if (data.totalEff != 0) this.efficiencyToday = data.totalEff;
      else this.efficiencyToday = "--";
      this.minPercent = data.minPercent;
      this.ptime = data.ptime;
      this.computeCycleTime();
    },
    mainUpdateNbProducts: function (data) {
      //console.log("----- mainUpdateNbProducts = " + JSON.stringify(data, null, 2));
      this.nbProducts = data;
      if (this.nbProducts != 0) {
        this.nbProductsAvrg = Math.round((this.nbProducts / moment().diff(moment(this.stdStartTime))) * 3600000);
      } else {
        this.nbProductsAvrg = 0;
      }
    },

    mainUpdateOrderCount: function ({ quantity }) {
      this.ordersCount = quantity;
    },

    mainUpdateState: function (data) {
      //console.log("----- mainUpdateState = " + JSON.stringify(data, null, 2));
      this.actState = data.state;
      if (this.actState == globals.STATENOT) {
        // terminate operations on this line
        oper.setCurrentActivity({});
      }
      this.inDownTime = !!(this.actState == globals.STATEDOWN);
      this.downTimeReason = data.textDown;
      this.inChangeover = !!(this.actState == globals.STATECHANGEOVER);
      this.changeoverReason = data.textChangeover;
      if (this.actState == globals.STATEBREAK || this.actState == globals.STATEBREAK1 || this.actState == globals.STATEBREAK2 || this.actState == globals.STATELUNCH) {
        const breakInfo = data.breakInfo;
        this.inBreakTime = true;
        this.startTimeOfBreak = moment(breakInfo.startTimeOfBreak);
        this.endTimeOfBreak = moment(breakInfo.endTimeOfBreak);

        const hoursDuration = Math.floor(breakInfo.durationOfBreakInMinutes / 60);
        const minutesDurationString = (breakInfo.durationOfBreakInMinutes % 60).toLocaleString("en-US", { minimumIntegerDigits: 2 });
        this.durationMeasurementUnit = hoursDuration >= 1 ? "Hrs" : "Minutes";
        this.durationOfBreak = hoursDuration >= 1 ? `${hoursDuration.toLocaleString("en-US", { minimumIntegerDigits: 2 })}:${minutesDurationString}` : minutesDurationString;
        // this.durationOfBreak = `${Math.floor(breakInfo.durationOfBreakInMinutes / 60).toLocaleString("en-US", { minimumIntegerDigits: 2 })}:${(
        //   breakInfo.durationOfBreakInMinutes % 60
        // ).toLocaleString("en-US", { minimumIntegerDigits: 2 })}`;

        const setTimeLeft = () => {
          let minutesLeft = moment(this.endTimeOfBreak).diff(moment(), "minutes");
          let hoursLeft = 0;
          while (minutesLeft > 60) {
            hoursLeft++;
            minutesLeft -= 60;
          }
          minutesLeft++;
          if (hoursLeft > 0) {
            this.breakTimeLeft = `${hoursLeft.toLocaleString("en-US", { minimumIntegerDigits: 2 })}:${minutesLeft.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
            })}`;
            this.breakTimeLeftMeasurementUnit = "Hrs";
          } else {
            this.breakTimeLeft = `${minutesLeft.toLocaleString("en-US", { minimumIntegerDigits: 2 })}`;
            this.breakTimeLeftMeasurementUnit = "Minutes";
          }
        };

        setTimeLeft();
        this.breakInterval = setInterval(setTimeLeft, 1000);
      } else {
        clearInterval(this.breakInterval);
        this.inBreakTime = false;
      }
    },
    getColor: function (eff) {
      if (eff < this.minPercent) {
        return "under";
      } else if (eff >= 100) {
        return "styleGold";
      }
      return;
    },
    computeCycleTime: function () {
      if (!this.activity.stds || this.activity.stds.length == 0) return;
      if (this.nbProducts != 0) {
        this.cycleTime = this.ptime / 1000 / this.nbProducts;
      } else {
        this.cycleTime = "--";
      }
    },
    startScanner: function () {
      console.log("----- startScanner");
      this.$bvToast.hide(this.toastId);
      this.scanned = "";
      this.lastScanned = "";
      this.isFirstScann = true;
      window.addEventListener("keypress", this.onInputScanned);
    },
    stopScanner: function () {
      console.log("----- stopScanner");
      EventBus.$emit("closeTabletMain");
      this.courierProcess = "";
      window.removeEventListener("keypress", this.onInputScanned);
      this.$bvToast.hide(this.toastId);
    },
    onInputScanned(event) {
      if (this.actState == globals.STATEDOWN || this.actState == globals.STATECHANGEOVER) return;

      if (event.key == "Enter") {
        if (this.modalOpen) {
          this.$refs.audio2.play();
          return;
        }
        if (this.scanned != "") {
          // Old barcodes
          // 310800 OL1/1_G115MON1943
          //  290700_OL1/1_G22T44 = [orderID]_[Line]_[courierID (3digits)][route number]

          /* NOTE
            New barcode: 24173466WA1O2G23251008 =
              [orderId = 8 digits  ]
              [type][box (start with number) ({number}O{number})]
              [courierId (first 3 digits)]
              [routeNumber (remaining digits)]
          */
          console.log("----- Scanned:", this.scanned);
          this.formatBarcode();

          if (this.isFirstScann && this.courierProcess !== "" && !this.barcode.isOldBarcode) {
            this.showConfirm = true;
            this.modalOpen = true;
          } else {
            this.addProduct(1, this.scanned);
            this.scanned = "";
          }
        }
      } else {
        if (!this.modalOpen) this.scanned += event.key;
      }
    },
    formatBarcode() {
      this.barcode = {
        orderId: "",
        type: "",
        box: "",
        courierId: "",
        routeNumber: "",
      };

      if (this.scanned.length === 8) {
        this.barcode.isOldBarcode = true;
        this.barcode.orderId = this.scanned;
        return "Ok";
      }

      let manipulatedScanned = this.scanned;

      const indexOfEndOrderId = manipulatedScanned.search(/[a-z]|[A-Z]/);
      this.barcode.orderId = manipulatedScanned.substring(0, indexOfEndOrderId);
      manipulatedScanned = manipulatedScanned.substring(indexOfEndOrderId);

      const indexOfEndType = manipulatedScanned.search(/[0-9]/);
      this.barcode.type = manipulatedScanned.substring(0, indexOfEndType);
      manipulatedScanned = manipulatedScanned.substring(indexOfEndType);

      const indexOfBoxPart_1 = manipulatedScanned.search("O");
      const boxPart_1 = manipulatedScanned.substring(0, indexOfBoxPart_1);
      manipulatedScanned = manipulatedScanned.substring(indexOfBoxPart_1 + 1);

      const indexOfBoxPart_2 = manipulatedScanned.search(/[a-z]|[A-Z]/);
      const boxPart_2 = manipulatedScanned.substring(0, indexOfBoxPart_2);
      manipulatedScanned = manipulatedScanned.substring(indexOfBoxPart_2);
      this.barcode.box = `${boxPart_1}/${boxPart_2}`;

      this.barcode.courierId = manipulatedScanned.substring(0, 3);
      this.barcode.routeNumber = manipulatedScanned.substring(3);
      return "Ok";
    },
    mainUpdateScanner: function (state) {
      //console.log("----- mainUpdateScanner = " + state);
      if (this.scannerOn != state) {
        this.scannerOn = state;
        if (this.scannerOn) this.startScanner();
        else this.stopScanner();
      }
    },
    mainAlarmScanned: function (
      wo, //body
      isOk, //sucess
      customTitle, //title
      customStyle, //large width
      variant = "warning" //varaint custom
    ) {
      //console.log("----- mainAlarmScanned = " + wo + " " + isOk);
      //custom style is true or false
      if (!this.noHide) this.$bvToast.hide(this.toastId);

      let title = this.$i18n.t("tabMain.scanned");
      if (isOk) title = this.$i18n.t("tabMain.scanOk");
      const h = this.$createElement;
      const vNodesTitle = h("div", { class: [customStyle ? "toastHeadlg" : "toastHead"] }, customTitle ? customTitle : title);
      const vNodesMsg = h("div", { class: [customStyle ? "toastBaselg" : "toastBase"] }, wo);
      this.$bvToast.toast([vNodesMsg], {
        id: this.toastId,
        title: [vNodesTitle],
        variant: customStyle ? variant : isOk ? "success" : "danger",
        toaster: "b-toaster-top-center",
        noCloseButton: false,
        // autoHideDelay: 6000,
      });

      if (!customStyle) {
        let playPromise;
        if (isOk) playPromise = this.$refs.audio1.play();
        else playPromise = this.$refs.audio2.play();
        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              // Audio is playing
            })
            .catch(() => {
              this.$bvModal.msgBoxOk(this.$i18n.t("tabMain.niceDay"), {
                okTitle: this.$i18n.t("tabMain.thankYou"),
              });
            });
        }
      }
    },
    mainGotScanned: function (data) {
      console.log("----- mainGotScanned = " + JSON.stringify(data, null, 2));

      this.lastScanned = data.scanned;
      this.mainAlarmScanned(data.scanned, data.isOk);
    },
    updateDownTime: function (timeStartDown) {
      //console.log('-----> updateDownTime = ' + JSON.stringify( timeStartDown, null, 2) );
      this.dTime = moment.utc(moment().diff(moment(timeStartDown))).format("H:mm:ss");
    },
    updateChangeover: function (timeStartChangeover) {
      //console.log('-----> updateChangeover = ' + JSON.stringify( timeStartChangeover, null, 2) );
      this.coTime = moment.utc(moment().diff(moment(timeStartChangeover))).format("H:mm:ss");
    },
    newSCT: function (SCT) {
      //console.log('-----> newSCT = ' + JSON.stringify( SCT, null, 2) );
      this.SCT = SCT;
    },
    handleEndBreak() {
      this.endBreakIsLoading = true;
      const actId = this.activity._id;
      axiosInstance.post("/endbreak", { actId }).finally(() => {
        this.endBreakIsLoading = false;
      });
    },
    handleQuantityProducedAnswer(answer) {
      if (answer === "yes") this.addProductAPICall();
      this.showQuantityProducedModal = false;
    },
  },
  mounted() {
    this.mainUpdateActivity(oper.getCurrentActivity());
    EventBus.$on("updateActivity", this.mainUpdateActivity);

    // do this after oper.getCurrentActivity()
    // it will trigger an update of actual values via socket (nbProducts, goal, eff, state)
    if (this.tabMainOk) {
      //console.log('----- main actualTabletNumbers = ' + JSON.stringify(this.activity._id));
      axiosInstance.post("/actualTabletNumbers", { actId: this.activity._id });
    }
    this.redimTabMain();
    EventBus.$on("redimOperation", this.redimTabMain);

    EventBus.$on("goal", this.mainUpdateGoal);
    EventBus.$on("nbProducts", this.mainUpdateNbProducts);
    EventBus.$on("ordersCount", this.mainUpdateOrderCount);
    EventBus.$on("state", this.mainUpdateState);
    EventBus.$on("toProduce", this.mainUpdateToProduce);

    this.scannerOn = oper.getScanner();
    if (this.scannerOn) this.startScanner();
    EventBus.$on("scanner", this.mainUpdateScanner);
    EventBus.$on("scanned", this.mainGotScanned);

    EventBus.$on("updateDownTime", this.updateDownTime);
    EventBus.$on("updateChangeover", this.updateChangeover);

    EventBus.$on("newSCT", this.newSCT);
  },

  beforeDestroy() {
    console.log("----- TabMain beforeDestroy()");

    EventBus.$off("updateActivity", this.mainUpdateActivity);
    EventBus.$off("redimOperation", this.redimTabMain);
    EventBus.$off("goal", this.mainUpdateGoal);
    EventBus.$off("nbProducts", this.mainUpdateNbProducts);
    EventBus.$off("state", this.mainUpdateState);
    EventBus.$off("toProduce", this.mainUpdateToProduce);
    EventBus.$off("scanner", this.mainUpdateScanner);
    EventBus.$off("scanned", this.mainGotScanned);
    EventBus.$off("updateDownTime", this.updateDownTime);
    EventBus.$off("updateChangeover", this.updateChangeover);
    EventBus.$off("newSCT", this.newSCT);

    if (this.scannerOn) this.stopScanner();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/global.scss";

$very-light-green: #e7f1f2;

.tabMainContainer {
  width: 100%;
  height: 100%;
  float: left;
  position: relative;
}

.tabMainRow0 {
  height: 22%;
  width: 100%;
}
.tabMainRow1 {
  height: 22%;
  width: 100%;
}
.tabMainRow2 {
  height: 22%;
  width: 100%;
}
.tabMainRow3 {
  height: 19%;
  width: 100%;
}
.tabMainRow4 {
  height: 15%;
  width: 100%;
}

.tabMainColOneThird {
  text-align: center;
  width: calc(33.3333% - 2px);
  margin: 1px;
  display: inline-block;
  height: calc(100% - 2px);
  position: relative;
  vertical-align: top;

  &.colored {
    background-color: $very-light-green;
  }
}
.Ptype-style {
  font-size: 14px;
  margin-top: -2px;
}
.Ptype-style2 {
  font-size: 14px;
}

.tabMainColOneThird .title {
  font-size: 14px;
  line-height: 1;
}

.bigNumber {
  margin-top: -4px;
  font-size: 40px;
  line-height: 72px;
  font-weight: bold;
}
.bigNumber2 {
  margin-top: -4px;
  font-size: 40px;
  line-height: 72px;
  font-weight: bold;
}
.todaydata {
  font-size: 20px;
  margin-top: 20px;
}

@media only screen and (max-width: 800px) {
  .bigNumber {
    font-size: 40px;
  }
  .tabMainColOneThird .title {
    font-size: 12px;
  }
  .Ptype-style {
    font-size: 12px;
    margin-top: -2px;
  }
  .Ptype-style2 {
    font-size: 12px;
  }
  .todaydata {
    font-size: 16px;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 575px) {
  .bigNumber {
    font-size: 30px;
  }
  .tabMainColOneThird .title {
    font-size: 10px;
    line-height: 1;
    font-weight: bold;
  }
  .bigNumber2 {
    font-size: 30px;
  }
  .Ptype-style {
    font-size: 10px;
    margin-top: -2px;
  }
  .Ptype-style2 {
    font-size: 10px;
  }
  .todaydata {
    font-size: 12px;
    margin-top: 20px;
  }
}

.smallNumber {
  font-size: 30px;
  line-height: 30px;
  font-weight: 600;
  margin: 0;
}

.toProduce {
  p {
    margin: 0;

    &.toProduceText {
      font-size: 40px;
      font-weight: bold;
      color: $perfcell-green;
    }
    &.green {
      color: $perfcell-green;
    }
  }
}

.halfCol {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.flex-container {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;

  &.full {
    height: 100%;
  }
}
.flex-container2 {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;

  &.full {
    height: 65%;
  }
}
.flex-item1 {
  width: 100%;
  height: 80px;
  margin: auto;
  color: $perfcell-green;
}
.flex-item2 {
  width: 100%;
  height: 80px;
  margin-right: auto;
  margin-left: auto;
  margin-top: -20px;
  margin-bottom: auto;
  color: $perfcell-green;
}
.flex-item3 {
  width: 100%;
  height: 80px;
  margin-right: auto;
  margin-left: auto;
  margin-top: -20px;
  margin-bottom: auto;
  color: $perfcell-green;
}
.flex-item4 {
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  margin: auto;
  border: 4px solid $eff-green;
  border-radius: 15px;
  color: $eff-green;
  height: 100%;

  .bigNumber {
    margin-bottom: 4px;
    margin-left: auto;
    margin-right: auto;
  }

  &.halfCol {
    justify-content: space-between;
  }

  &.inverse {
    background-color: $eff-green;
    color: white;
  }

  &.under {
    border-color: $eff-red;
    color: $eff-red;
    &.inverse {
      background-color: $eff-red;
      color: white;
    }
  }
  &.styleGold {
    border-color: $eff-gold;
    color: $eff-gold;
    &.inverse {
      background-color: $eff-gold;
      color: white;
    }
  }
}

.flex-item5 {
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  margin-right: auto;
  margin-left: auto;
  margin-top: -10px;
  margin-bottom: auto;
  border: 4px solid $eff-green;
  border-radius: 15px;
  color: $eff-green;
  height: 100%;

  .bigNumber {
    margin-bottom: 4px;
    margin-left: auto;
    margin-right: auto;
  }

  &.halfCol {
    justify-content: space-between;
  }

  &.inverse {
    background-color: $eff-green;
    color: white;
  }

  &.under {
    border-color: $eff-red;
    color: $eff-red;
    &.inverse {
      background-color: $eff-red;
      color: white;
    }
  }
  &.styleGold {
    border-color: $eff-gold;
    color: $eff-gold;
    &.inverse {
      background-color: $eff-gold;
      color: white;
    }
  }
}

table {
  table-layout: fixed;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.history-div {
  overflow: auto;

  .history {
    width: 100%;
    height: 100%;

    thead {
      background-color: $very-light-green;
      color: $perfcell-green;
      font-weight: 600;
      text-align: center;

      th {
        height: 33px;
      }
    }

    tbody td {
      text-align: center;
      overflow: hidden;
      white-space: nowrap;

      &.align-left {
        text-align: left;
        padding-left: 10px;
      }

      &:not(:first-child) {
        border-left: 1px solid $light-grey;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 1em;
    height: 0.5em;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 8px;
    width: 75%;
  }

  &::-webkit-scrollbar-track {
    background: $light-grey;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: slategray;
    scrollbar-track-color: $light-grey;
  }
}

.enter-product {
  margin: 0;
  display: block;
  width: 100%;
  height: 88px;
}
.marginLR {
  margin-left: 5px;
  margin-right: 5px;
}
.lastEntry {
  background-color: $perfcell-green;
  color: white;
  margin-left: 15px;
}
.cadrez {
  border: 2px solid $light-grey;
}
.enter-product-2 {
  margin: 0;
  display: block;
  width: 100%;
  background-color: $perfcell-green;
  height: 80px;

  .lastScanned {
    color: white;
    float: left;
    margin-top: 10px;
    margin-right: 25px;
    margin-left: 10px;
    p {
      line-height: 20%;
      font-size: 12px;
      &:last-child {
        margin-left: 4px;
      }
    }
  }
}

.toastHead {
  width: 300px;
  height: 40px;
  font-size: 30px;
  text-align: center;
}

.toastBase {
  width: 300px;
  height: 50px;
  font-size: 24px;
  text-align: center;
}
.toastHeadlg {
  width: auto;
  font-size: 30px;
}
.toastBaselg {
  width: auto;
  font-size: 24px;
}
.downtimePopup {
  background-color: #fff;
  border: 5px solid red;
  position: absolute;
  width: 300px;
  height: 300px;
  top: 50%;
  left: 50%;
  margin-left: -150px;
  margin-top: -150px;
  z-index: 3;
}

.changeoverPopup {
  background-color: #fff;
  border: 5px solid blue;
  position: absolute;
  width: 300px;
  height: 300px;
  top: 50%;
  left: 50%;
  margin-left: -150px;
  margin-top: -150px;
  z-index: 3;
}

.ordersCountContainer {
  padding-top: 5px;
  z-index: 0;
}

.breakPopup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 2;
}

.breakPopup > div {
  height: 24rem;
  width: 20rem;
  margin-top: 1rem;
  border: 0.3rem solid #508084;
  border-radius: 5px;
  display: grid;
  grid-template-rows: 2rem 1fr;
  padding: 1rem;
  background: white;
  gap: 1rem;
}

.breakPopup .title {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
}

.breakPopup .information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-size: larger;
}

.breakPopup .accent {
  font-weight: 600;
  font-size: x-large;
}

.breakPopup .end-break-button {
  background: #508084;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.3rem 1.5rem;
  font-weight: 600;
}
</style>
