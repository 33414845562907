<template>
  <div class="g-page-container">

    <div class="g-pageHeader" style="height: 63px;">
      <h1 style="margin: auto;">{{$t('incentive.title')}}</h1>
    </div>

    <ReturnButtonContainer to="/config" />

    <form class="g-pageBody" @submit.prevent="onSubmit">

      <div class="form-group">
        <label for="inputIncentiveHourlyRate">{{$t('incentive.hourlyRate')}}</label>
        <input
          type="number"
          step="0.001" min="0"
          v-model="incentive.hourlyRate"
          class="form-control"
          id="inputIncentiveHourlyRate">
      </div>

      <div class="form-group">
        <label for="inputIncentiveMinimumPercent">{{$t('incentive.minimumPercent')}}<small>{{$t('incentive.smallInPercent')}}</small></label>
        <input
          type="number"
          step="1" min="0" max="99"
          v-model="incentive.minimumPercent"
          class="form-control"
          id="inputIncentiveMinimumPercent">
      </div>

      <div class="form-group">
        <label for="inputIncentiveBaseBonus">{{$t('incentive.baseBonus')}}<small>{{$t('incentive.smallHourlyRate')}}</small></label>
        <input
          type="number"
          step="0.01" min="0"
          v-model="incentive.baseBonus"
          class="form-control"
          id="inputIncentiveBaseBonus">
      </div>

      <div class="form-group">
        <label for="inputIncentiveMaximumBonus">{{$t('incentive.maximumBonus')}}<small>{{$t('incentive.smallHourlyRate')}}</small></label>
        <input
          type="number"
          step="0.01" min="0"
          v-model="incentive.maximumBonus"
          class="form-control"
          id="inputIncentiveMaximumBonus">
      </div>

      <div class="form-group">
        <label for="inputIncentiveEfficiencyNoTime">{{$t('incentive.efficiencyNoTime')}}<small>{{$t('incentive.smallInPercent')}}</small></label>
        <input
          type="number"
          step="1" min="0" max="999"
          v-model="incentive.efficiencyNoTime"
          class="form-control"
          id="inputIncentiveEfficiencyNoTime">
      </div>

      <button class="btn btn-perfcell float-left marginTop50px" @click.prevent="clickRetour">
        {{$t('incentive.cancel')}}
      </button>
      <button class="btn btn-perfcell float-left marginTop50px marginLeft10px" type="submit">
        {{$t('incentive.submit')}}
      </button>

      <p v-if="errors.length" class="float-left marginTop50px marginLeft30px" :style="{ color: msgColor }">
        <b v-if="msgColor == 'red'">{{$tc('incentive.error', errors.length)}}</b>
        <ul>
          <li v-for="error in errors" :key="error">{{error}}</li>
        </ul>
      </p>

    </form>

  </div>
</template>

<script>
import { axiosInstance } from "@/main";
import { axiosInstanceReports } from "@/main";
import ReturnButtonContainer from "../components/Return-button/Return-button-container.vue";

export default {
  components: {
    ReturnButtonContainer
  },
  data() {
    return {
      incentive: {
        hourlyRate: 0,
        minimumPercent: 0,
        baseBonus: 0,
        maximumBonus: 0,
        efficiencyNoTime: 0
      },
      errors: [],
      msgColor: "red"
    }
  },
  methods: {
    onSubmit() {
      if( ! this.validate() )
        return;
      axiosInstance.post('/incentive', this.incentive)
      .then(() => {
        this.errors.push(this.$i18n.t('incentive.success'));
        this.msgColor = "green";
        axiosInstanceReports.post('/rep/incentive', {});
      }).catch(() => {
        console.log("----- onSubmit error ");
      });
    },
    validate() {
      this.errors = [];
      this.msgColor = "red";

      return ! this.errors.length;
    },
    clickRetour() {
      this.$router.replace("/config");
    }
  },
  mounted() {
    axiosInstance.get('/incentive')
    .then((result) => {
      //console.log("----- result = " + JSON.stringify( result.data, null, 2));

      this.incentive = result.data;
    });
  },
  beforeDestroy() {
  }
}
</script>

<style lang="scss" scoped>

form {
  background: White;
  padding: 2rem 2rem 1rem;
  width: 100%;
}

label {
  font-weight: bold;
}
</style>
