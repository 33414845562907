import EventBus from "@/eventBus";
import socket from "@/stores/Socket.js";
import { axiosInstance } from "@/main";
import moment from "moment-timezone";

// ce mécanisme existe pour ne pas perdre les infos de setup de la ligne dans les deux cas suivant :
// 1- lorsque le serveur redémarre
// 2- lorsque l'on vas dans la config et reviens ici (dans ce cas Vue exécute destroyed() puis mounted())
// et c'est utilisé par le serveur pour synchroniser les tablettes qui sont sur la même ligne

let currentActivity = {};

let socketOpen = false;

// garder ici factory et factorySetup pour un accès rapide
// ceux-ci sont mis-à-jour en temps réel par le serveur

let factory = null;
let factorySetup = null;

let reqFactory = null;
let reqFactorySetup = null;

let dashboardOn = false;
let scannerOn = false;

let savedQReport = [];
let savedQData = {};
let savedMaxPcent = -1;

function getFactory() {
  //console.log("----- oper getFactory, factory = " + JSON.stringify(factory, null, 2));
  if (factory) {
    return factory;
  }
  if (!socketOpen) {
    return null;
  }
  // do not request server if last request was less than a second ago
  if (!reqFactory || moment().diff(moment(reqFactory), "seconds")) {
    reqFactory = moment();
    axiosInstance.get("/factory");
  }
  return null;
}

function getFormatData() {
  if (factory) {
    return factory.dateFormat;
  } else {
    return "MM/DD/YYYY";
  }
}

function getFactorySetup() {
  //console.log('----- getFactorySetup ' + reqFactorySetup + ' - ' + factorySetup );
  if (factorySetup) {
    return factorySetup;
  }
  if (!socketOpen) {
    return null;
  }
  // do not request server if last request was less than a second ago
  if (!reqFactorySetup || moment().diff(moment(reqFactorySetup), "seconds")) {
    reqFactorySetup = moment();
    axiosInstance.get("/factorySetup");
  }
  return null;
}

function getBreaksIncluded() {
  if (factorySetup && "breaksIncluded" in factorySetup) {
    return factorySetup.breaksIncluded;
  } else {
    return false;
  }
}

function getDecimals() {
  if (factorySetup && "decimalsForActual" in factorySetup) {
    return factorySetup.decimalsForActual;
  } else {
    return 0;
  }
}

function getMoveToLine() {
  if (factorySetup && "moveToLine" in factorySetup) {
    return factorySetup.moveToLine;
  } else {
    return false;
  }
}

function getNeedTask() {
  if (currentActivity.line && "needTasks" in currentActivity.line && currentActivity.line.needTasks === true) return true;
  else return false;
}

function storeQReport(qreport) {
  savedQReport = qreport;
}
function getQReport() {
  return savedQReport;
}
function storeQData(qdata) {
  savedQData = qdata;
}
function getQData() {
  return savedQData;
}
function setMaxPcent(maxPcent) {
  savedMaxPcent = maxPcent;
}
function getMaxPcent() {
  return savedMaxPcent;
}

EventBus.$on("socketOpen", () => {
  // TODO : chez goodfood il deconnectent et reconnect souvent
  // et a chaque fois ceci est envoye presque toujours inutilement
  socket.sendData({ act: currentActivity });
  socket.sendData({ dashboard: dashboardOn });
  socket.sendData({ scanner: scannerOn });

  socketOpen = true;

  // trigger get from server if we don't have them
  getFactory();
  getFactorySetup();

  // trigger an upade on actuals values
  if (currentActivity._id) {
    //console.log('----- oper actualTabletNumbers = ' + JSON.stringify(this.activity._id));
    axiosInstance.post("/actualTabletNumbers", { actId: currentActivity._id });
  }
});

function getCurrentActivity() {
  return currentActivity;
}

async function getPrevActivity(activity_id,line_id) {
 
  try {
    const response = await axiosInstance.post("/getActivityById", { actId: activity_id,lineId:line_id });
    return response.data; // Assuming the API response data is what you need
  } catch (error) {
    console.error('Error fetching activity:', error);
    throw error; // Re-throw the error after logging it
}

}

function setCurrentActivity(act) {
  currentActivity = act;
  socket.sendData({ act: currentActivity });
  EventBus.$emit("updateActivity", currentActivity);
}

function getActivityId() {
  return currentActivity._id ? currentActivity._id : "";
}
function getLineMultibox() {
  return currentActivity.line ? currentActivity.line.multiBox : "";
}
function getLineId() {
  return currentActivity.line ? currentActivity.line._id : "";
}

function getLineName() {
  return currentActivity.line ? currentActivity.line.name : "";
}

function getSchedId() {
  return currentActivity.sched && currentActivity.sched.sched ? currentActivity.sched.sched._id : "";
}

function dashboard(state) {
  dashboardOn = state;
  //console.log("----- dashboard " + dashboardOn);
  socket.sendData({ dashboard: dashboardOn });
}

function scanner(state) {
  scannerOn = state;
  //console.log("----- scanner " + scannerOn);
  socket.sendData({ scanner: scannerOn });
  EventBus.$emit("scanner", scannerOn);
}

function getScanner() {
  return scannerOn;
}

function messageReceived(msg) {
  /*z
  if( msg.actUpdate || msg.actUpdateDashboard || msg.lastActDashboard || msg.actDashboard )
    console.log('----- Data received from server: activity');
  else
    console.log('----- Data received from server: ' + JSON.stringify(msg));
  */

  if (Object.prototype.hasOwnProperty.call(msg, "notice")) {
    EventBus.$emit("notice", msg.notice);
  }
  if (Object.prototype.hasOwnProperty.call(msg, "scanned")) {
    EventBus.$emit("scanned", msg);
  } else if (Object.prototype.hasOwnProperty.call(msg, "msgToLine")) {
    EventBus.$emit("msgToLine", msg.msgToLine);
  } else if (Object.prototype.hasOwnProperty.call(msg, "actUpdate")) {
    currentActivity = msg.actUpdate;
    //console.log("----- emit('updateActivity");
    if (dashboardOn) EventBus.$emit("actUpdateDashboard", msg.actUpdate);
    EventBus.$emit("updateActivity", msg.actUpdate);
  } else if (Object.prototype.hasOwnProperty.call(msg, "actUpdateDashboard")) {
    //console.log("----- emit('actUpdateDashboard");
    EventBus.$emit("actUpdateDashboard", msg.actUpdateDashboard);
  } else if (Object.prototype.hasOwnProperty.call(msg, "state")) {
    //console.log("----- msg state = " + JSON.stringify(msg, null, 2));
    if (dashboardOn) EventBus.$emit("stateDashboard", msg);
    if (currentActivity.line && currentActivity.line._id.toString() == msg.lineId.toString()) EventBus.$emit("state", msg);
  } else if (Object.prototype.hasOwnProperty.call(msg, "x")) {
    //console.log("----- msg goal = " + msg);
    let x = msg.x;
    let goal = {
      goal: x[0],
      goalOEE: x[1],
      eff: x[2],
      effOEE: x[3],
      totalProducts: x[4],
      totalEarned: x[5] * 1000,
      totalWorked: x[6] * 1000,
      totalPtime: x[7] * 1000,
      ptime: x[8] * 1000,
      totalEff: x[9],
      totalEffOEE: x[10],
      minPercent: x[11],
      lineId: x[12],
      goalAvrg: x[13],
      nbProductsAvrg: x[14],
    };
    if (dashboardOn) EventBus.$emit("goalDashboard", goal);
    if (currentActivity.line && currentActivity.line._id.toString() == goal.lineId.toString()) EventBus.$emit("goal", goal);
  } else if (Object.prototype.hasOwnProperty.call(msg, "v")) {
    //console.log("----- msg nbProducts = " + msg);
    let nbProducts = {
      lineId: msg.l,
      nbProducts: msg.v,
    };
    if (dashboardOn) EventBus.$emit("nbProductsDashboard", nbProducts);
    if (currentActivity.line && currentActivity.line._id.toString() == nbProducts.lineId.toString()) EventBus.$emit("nbProducts", nbProducts.nbProducts);
  } else if (Object.prototype.hasOwnProperty.call(msg, "toProduce")) {
    //console.log("----- msg toProduce = " + msg);
    if (dashboardOn) EventBus.$emit("toProduceDashboard", msg);
    if (currentActivity.line && currentActivity.line._id.toString() == msg.lineId.toString()) EventBus.$emit("toProduce", msg.toProduce);
  } else if (Object.prototype.hasOwnProperty.call(msg, "factory")) {
    factory = msg.factory;
    //console.log("----- emit('factory = " + msg.factory);
    EventBus.$emit("factory", msg.factory);
  } else if (Object.prototype.hasOwnProperty.call(msg, "factorySetup")) {
    factorySetup = msg.factorySetup;
    //console.log("----- emit('factorySetup = " + msg.factorySetup);
    EventBus.$emit("factorySetup", msg.factorySetup);
  } else if (Object.prototype.hasOwnProperty.call(msg, "actDashboard")) {
    //console.log("----- emit('actDashboard = " + msg.actDashboard.line.name );
    EventBus.$emit("actDashboard", msg.actDashboard);
  } else if (Object.prototype.hasOwnProperty.call(msg, "lastActDashboard")) {
    //console.log("----- emit('lastActDashboard = " + msg );
    EventBus.$emit("lastActDashboard", msg);
  } else if (Object.prototype.hasOwnProperty.call(msg, "w")) {
    if (dashboardOn) {
      //console.log("----- emit('lastHourDashboard = " + msg );
      let lastHourDashboard = {
        lineId: msg.l,
        lastHourDashboard: msg.w,
      };
      EventBus.$emit("lastHourDashboard", lastHourDashboard);
    }
  } else if (Object.prototype.hasOwnProperty.call(msg, "ordersCount")) {
    const message = {
      lineId: msg.ordersCount.lineId,
      quantity: msg.ordersCount.value,
    };
    EventBus.$emit("ordersCount", message);
  } else {
    console.log("----- ERROR : unknow message");
  }
}

export default {
  getFactory,
  getFactorySetup,
  getBreaksIncluded,
  getDecimals,
  getMoveToLine,
  getCurrentActivity,
  setCurrentActivity,
  getActivityId,
  getLineId,
  getLineMultibox,
  getLineName,
  getSchedId,
  messageReceived,
  dashboard,
  scanner,
  getScanner,
  getNeedTask,
  getFormatData,
  storeQReport,
  getQReport,
  storeQData,
  getQData,
  setMaxPcent,
  getMaxPcent,
  getPrevActivity
};
