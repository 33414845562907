<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask" v-show="showModal">
      <div class="modal-container">
        <div>
          <span class="messageSpan">{{ message }}</span>
        </div>
        <footer class="footer-container">
          <button class="actionButton primary" @click="buttonAction('changeMatchId')">
            <span>
              {{
                $i18n.t("incorrectCourierValidation.acceptAndChange")
                  .replace('**MATCHID**', matchId)
                  .replace('**MATCHIDKEY**', courierProcess === 'inbound' ? 'carrier' : 'route')
              }}
            </span>
          </button>
          <button class="actionButton secondary" @click="buttonAction('skipValidation')">
            <span>{{ $i18n.t("incorrectCourierValidation.aceptBox") }}</span>
          </button>
          <button class="actionButton alert" @click="buttonAction('close')">
            <span>{{ $i18n.t("incorrectCourierValidation.cancel") }}</span>
          </button>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
import EventBus from "@/eventBus";

export default {
  name: 'IncorrectCourierValidation',
  template: '#modal-template',
  props: {
    showModal: Boolean,
    message: String,
    matchId: String,
    courierProcess: String
  },
  data: function () {
    return {

    }
  },
  methods: {
    buttonAction: function (button) {
      if (button === 'changeMatchId')
        EventBus.$emit("updateCourierProcess", ({ courierMethod: this.courierProcess, matchId: this.matchId }))
      this.$emit('close', { method: button });
    }
  }
}
</script>

<style scoped>
  * {
    box-sizing: border-box;
  }
  .modal-mask {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s ease;
  }
  .modal-container {
    display: grid;
    grid-template-rows: calc(100% - 40px) 40px;
    min-width: 700px;
    min-height: 280px;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }
  .modal-enter {
    opacity: 0;
  }
  .modal-leave-active {
    opacity: 0;
  }
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .footer-container {
    display: flex;
    flex-flow: row-reverse;
    gap: 1rem;
    justify-items: center;
  }

  footer.footer-container button.actionButton {
    display: flex;
    font-size: 1.2rem;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-weight: 600;
    min-width: 6rem;
    border: 2px solid;
    border-radius: 3px;
    transition: ease-in-out 300ms;
    outline: none;
  }

  .title {
    font-size: 1.5rem;
    font-weight: 600;
  }
  
  .section {
    margin: 0px;
    padding: 0px 10px;
    letter-spacing: -0.2rem;
  }

  .courierTitle {
    font-size: 1.2rem;
  }

  .courierProcess {
    font-size: 1.4rem;
    font-weight: bolder;
  }

  footer.footer-container button.actionButton.alert {
    min-width: 0;
    min-height: 100%;
    border-color: transparent;
    color: #555;
  }
  footer.footer-container button.actionButton.alert:hover {
    color: #777;
  }

  footer.footer-container button.actionButton.primary {
    font-size: 1rem;
    background: hsl(186, 26%, 42%);
    border-color: hsl(186, 26%, 70%);
    color: white;
  }

  footer.footer-container button.actionButton.primary:hover {
    background: hsl(186, 26%, 52%);
  }

  footer.footer-container button.actionButton.secondary {
    background: rgba(79, 129, 135, 0);
    border-color: hsl(186, 26%, 42%);
    color: hsl(186, 26%, 42%);
  }

  footer.footer-container button.actionButton.secondary:hover {
    border-color: hsl(186, 26%, 52%);;
    color: hsl(186, 26%, 52%);
  }

  .messageSpan {
    font-size: 2rem;
  }

  @media screen and (max-width: 600px) {
    .modal-mask {
      min-width: 100vw;
      width: 100vw;
      min-height: 40vh;
      height: 40vh;
      background: transparent;
    }

    .modal-container {
      min-width: 100%;
      min-height: 100%;
      grid-template-rows: calc(100% - 70px) 70px;
      padding-bottom: 20px;
    }

    .messageSpan {
      font-size: 1.5rem;
    }
  }
</style>