import EventBus from '@/eventBus'
import oper from '@/stores/Operations.js';

let weAreInAdminArea = false;
let pswTimeOut = 60;


// tells the caller if he is allowed to enter an admin page
function isLoggedIn() {
  //console.log("----- isLoggedIn weAreInAdminArea = " + weAreInAdminArea);

  if (weAreInAdminArea)
    return true;
  else
    return document.cookie && document.cookie.indexOf("isAdmin=true") != -1;
}

// called every time a new page page is loaded
function changePage( pageIsAdmin ) {
  EventBus.$emit('adminStateChange', pageIsAdmin);
  //console.log("----- weAreInAdminArea = " + weAreInAdminArea);
  //console.log("----- pageIsAdmin = " + pageIsAdmin);
  if( ! weAreInAdminArea && pageIsAdmin ) {
    weAreInAdminArea = pageIsAdmin;
    refreskCookie();
  }
  weAreInAdminArea = pageIsAdmin;
}

function logIn() {
  let factory = oper.getFactory();
  if( factory )
    pswTimeOut = factory.adminTimeout;
  changePage( true );
}

function logOut() {
  weAreInAdminArea = false;
  document.cookie = "isAdmin=; secure; expires=0"; // delete cookie
}

function refreskCookie() {
  if (weAreInAdminArea) {
    let maxAge = pswTimeOut > 5 ? pswTimeOut : 5;
    let nexRefresh = Math.trunc((pswTimeOut < 5) ? 2 : ((pswTimeOut < 30) ? (pswTimeOut / 3) : 15)) * 1000;
    //console.log("----- pswTimeOut = " + pswTimeOut);
    //console.log("----- maxAge = " + maxAge);
    //console.log("----- nexRefresh = " + nexRefresh);
    document.cookie = "isAdmin=true; secure; max-age=" + maxAge;
    setTimeout(refreskCookie, nexRefresh);
  }
}

export default { isLoggedIn, changePage, logIn, logOut };
