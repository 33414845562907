<template id="modal-template">
  <transition name="modal">
    <div>
      <div class="modal-mask" v-show="showMsg1">
        <div class="modal-container">
          <div>
            {{ $t("endProd.confirmOut1") }}
            <span>{{ cTime }}</span>
            <span>{{ $t("endProd.confirmOut2") }}</span>
          </div>

          <button class="btn btn-perfcell float-right marginTop40px" @click="clickCancel">
            {{ $t("endProd.cancel") }}
          </button>

          <button class="btn btn-perfcell float-right marginTop40px marginRight10px" @click="clickSave1">
            {{ $t("endProd.save") }}
          </button>
        </div>
      </div>

      <div class="modal-mask" v-show="showMsg2">
        <div class="modal-container">
          <div v-if="act.line.supApproval">{{ $t("endProd.confirmOut3") }}</div>

          <div v-if="act.line.supApproval" class="marginTop40px">
            <InputText
              required
              :key="initInputText"
              password="true"
              aria-autocomplete="off"
              :autocomplete="false"
              :initInputText="false"
              @handleChange="handleChange($event)"
              @submit="clickSave2"
              :placeholder="$t('endProd.enterPsw')"
            />
            <!-- <input type="text" v-model="password" class="form-control" :placeholder="$t('endProd.enterPsw')" /> -->
          </div>

          <p v-if="error" class="float-left" style="color: red">
            <b>{{ $t("endProd.wrongPsw") }}</b>
          </p>

          <button class="btn btn-perfcell float-right marginTop40px" @click="clickCancel">
            {{ $t("endProd.cancel") }}
          </button>

          <button class="btn btn-perfcell float-right marginTop40px marginRight10px" @click="clickSave2">
            {{ $t("endProd.save") }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { axiosInstance } from "@/main";
import oper from "@/stores/Operations.js";
import moment from "moment-timezone";
import InputText from "@/components/InputText.vue";

export default {
  name: "EndProduction",
  template: "#modal-template",
  components: {
    InputText,
  },
  props: {
    showEndProd: Boolean,
    doInitEndProd: Boolean,
  },
  data: function () {
    return {
      showMsg1: false,
      showMsg2: false,
      password: "",
      error: false,
      needSupApproval: false,
      // init act with supApproval to prevent warning
      act: { line: { supApproval: false } },
      cTime: "",
      initInputText: 0,
    };
  },
  watch: {
    doInitEndProd: function (val) {
      if (val) {
        //console.log("----- init EndProd");
        this.showMsg1 = true;
        this.act = oper.getCurrentActivity();
        this.showTimeNow();
      }
    },
  },
  methods: {
    handleChange(payload) {
      this.password = payload;
    },
    showTimeNow: function () {
      let self = this;
      // this test will prevent timeout running when out of end production screen
      if (self.showEndProd) {
        let timeBefore = Math.trunc(moment(self.act.endTime).diff(moment()) / 1000);
        //console.log("----- timeBefore = " + JSON.stringify(timeBefore, null, 2));

        // format("HH:mm:ss")
        let h = Math.floor(timeBefore / 3600);
        timeBefore -= h * 3600;
        let m = Math.floor(timeBefore / 60);
        let s = timeBefore - m * 60;
        if (h < 10) {
          h = "0" + h;
        }
        if (m < 10) {
          m = "0" + m;
        }
        if (s < 10) {
          s = "0" + s;
        }
        self.cTime = h + ":" + m + ":" + s;

        setTimeout(function () {
          self.showTimeNow();
        }, 1000);
      }
    },
    clickSave1: function () {
      this.showMsg1 = false;
      console.log(this.act.line.supervisorApprovalSchedules,"lines DEtttt",this.act.sched.sched._id)
     
     if (this.act.line.supApproval && this.act.line.supervisorApprovalSchedules.includes(this.act.sched.sched._id)) {
        this.showMsg2 = true;
      } else this.saveOk();

    },
    clickSave2: function () {
      let password = this.password;
      axiosInstance
        .post("/admin", { password })
        .then(() => this.saveOk())
        .catch(() => {
          this.resetValues();
          this.error = true;
        });
    },
    saveOk: function () {
      this.showMsg1 = false;
      this.showMsg2 = false;
      this.error = false;
      this.password = "";
      this.resetValues();
      this.$emit("close", { approved: true });
    },
    clickCancel: function () {
      this.showMsg1 = false;
      this.showMsg2 = false;
      this.error = false;
      this.password = "";
      this.resetValues();
      this.$emit("close", { approved: false });
    },
    resetValues: function () {
      this.initInputText++;
      this.password = "";
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.modal-mask {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}
.modal-container {
  width: 500px;
  height: 500px;
  margin: 40px auto 0;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
