import { render, staticRenderFns } from "./Cleaning-factor-config.vue?vue&type=template&id=dafbbe60&scoped=true&"
import script from "./Cleaning-factor-config.vue?vue&type=script&lang=js&"
export * from "./Cleaning-factor-config.vue?vue&type=script&lang=js&"
import style0 from "./Cleaning-factor-config.vue?vue&type=style&index=0&id=dafbbe60&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dafbbe60",
  null
  
)

export default component.exports