<template>
  <div class="card-body-custom">
    <div v-if="!tabLineOk || !tabSchedOk">
      <p>{{ $t("tabStd.emptyStd") }}</p>
    </div>
    <div v-else-if="!tabStdOk">
      <label class="attention cursorPointer" @click="clickSelectStd">{{
        $t("tabStd.noStd")
      }}</label>
    </div>
    <div v-else class="cursorPointer" @click="clickSelectStd">
      <b-row>
        <b-col cols="6">
          <h1>{{ actualStd.partNumber }}</h1>
          {{ actualStd.description }}
          <br />
          <span v-show="courierProcess.show" style="font-weight: bold">
            Scanning
            {{ courierProcess.method === "inbound" ? "carrier" : "route" }}
            {{ courierProcess.matchId }}
          </span>
        </b-col>
        <b-col cols="3" v-if="!tabNoEff">
          <b-row>
            <span id="SAMPOPOVER">
              <span>{{ $t("tabStd.SAM") }}&#8205;&#8205;&#8205;</span>
              <span style="color: red"><i class="fa fa-info-circle"></i></span>
            </span>
            <span style="margin-right: 0.2rem; margin-left: 0.1rem">:</span>
            <h1>{{ getSAM() }}</h1>
          </b-row>
          <b-row>
            <span id="SCTPOPOVER">
              <span>{{ $t("tabStd.SCT") }}&#8205;&#8205;&#8205;</span>
              <span style="color: red"><i class="fa fa-info-circle"></i></span>
            </span>
            <span style="margin-right: 0.2rem; margin-left: 0.1rem">:</span>
            <h1>{{ textSCT }}</h1>
          </b-row>
        </b-col>
        <b-popover
          target="SAMPOPOVER"
          triggers="hover"
          placement="left"
          variant="primary"
        >
          Standard Allowed Minutes
        </b-popover>
        <b-popover
          target="SCTPOPOVER"
          triggers="hover"
          placement="left"
          variant="primary"
        >
          Standard Cycle Time
        </b-popover>
        <b-col cols="3" v-show="useTakt" v-if="!tabNoEff">
          <b-row>
            {{ $t("tabStd.NAT") }}
            <span style="margin-left: 5px; display: inline-block">
              <h1>{{ getNAT }}</h1>
            </span>
            <span style="margin-left: 10px; display: inline-block">{{
              $t("tabStd.CD")
            }}</span>
            <span style="margin-left: 5px; display: inline-block">
              <h1>{{ getCD }}</h1>
            </span>
          </b-row>
          <b-row>
            {{ $t("tabStd.Takt") }}&#8205;&#8205;&#8205;
            <h1>{{ getTakt }}</h1>
          </b-row>
        </b-col>
      </b-row>
    </div>

    <selectStd
      :doInitStd="selectStdDoInit"
      :showStd="showModalStd"
      :inUseTakt="useTakt"
      :inValueNAT="valueNAT"
      :inValueCD="valueCD"
      :categoriesListParent="categoriesList"
      :gotCategoryList="true"
      :selectedCategoryId="selectedCategoryId"
      :selectedStdID="selectedStdID"
      @close="closeModalStd"
    ></selectStd>
  </div>
</template>

<script>
import { axiosInstance } from "@/main";
import selectStd from "@/components/SelectStandard.vue";
import EventBus from "@/eventBus";
import oper from "@/stores/Operations.js";
import moment from "moment-timezone";

export default {
  data: function () {
    return {
      teamLenght: 1,
      courierProcess: {
        show: false,
        matchId: "", // Example: G22 || 108002
        method: "", // inbound || outbound || ''
      },
      showModalStd: false,
      selectStdDoInit: false,

      tabLineOk: false,
      tabSchedOk: false,
      tabStdOk: false,
      tabMainOk: false,

      toastId: "notificationToast",

      useTakt: false,
      tabNoEff: false,
      highEff: false,
      valueNAT: 0,
      valueCD: 0,
      textSCT: "",
      nbEmp: 1,
      categoriesList: [],
      actualStd: {},
      selectedCategoryId: "", // Add this property to store the selected category ID
      selectedStdID: "",
    };
  },
  components: {
    selectStd,
  },
  computed: {
    getNAT() {
      return this.valueNAT + "m";
    },
    getTakt() {
      return this.valueCD
        ? (this.valueNAT / this.valueCD).toFixed(2) + "m"
        : "0m";
    },
    getCD() {
      return this.valueCD + this.$i18n.t("tabStd.units");
    },
  },
  methods: {
    setTeamLenght: function (amount) {
      if (amount === 0) {
        this.teamLenght = 1;
      } else {
        this.teamLenght = amount;
      }

      this.textSCT = this.highEff ? this.getSAM() : this.getSCT();

      let newSCT = this.actualStd.SAM;
      if (!this.highEff) newSCT /= this.teamLenght;
      EventBus.$emit("newSCT", newSCT);
    },
    getCategories: function () {
      axiosInstance
        .get("/standardOperation", {
          params: {
            isActive: true,
            lineId: localStorage.getItem("lastSelectedLineId"),
          },
        })
        .then((resp) => {
          this.categoriesList = [
            {
              _id: "0",
              name: this.$i18n.t("selectStd.allCatStds"),
            },
          ];
          this.categoriesList = this.categoriesList.concat(resp.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSAM() {
      let val = moment.utc(this.actualStd.SAM * 1000).format("HH[h]mm[m]ss");
      if (Math.round((this.actualStd.SAM % 1) * 10) != 0)
        val = val + "." + Math.round((this.actualStd.SAM % 1) * 10);
      val += "s";
      return val;
    },
    getSCT() {
      const sam = this.actualStd.SAM / this.teamLenght;
      let val = moment.utc(sam * 1000).format("HH[h]mm[m]ss");
      if (Math.round((sam % 1) * 10) != 0)
        val = val + "." + Math.round((sam % 1) * 10);
      val += "s";
      return val;
    },
    findLastMatchingObject(array) {
      // Iterate through the array in reverse order
      for (let i = array.length - 1; i >= 0; i--) {
        if (array[i].std.prodType == 0) {
          return array[i]; // Return the last object that satisfies the condition
        }
      }
      return null; // Return null if no object satisfies the condition
    },
    clickSelectStd: function () {
      this.showModalStd = true;
      this.selectStdDoInit = true;
      // Assuming you have the category ID available
      const categoryId = this.actualStd.category
        ? this.actualStd.category._id
        : "";
      this.selectedCategoryId = categoryId; // Store the category ID

      console.log(localStorage.getItem("re-call-categories"), "Second Call");
      if (localStorage.getItem("re-call-categories")) this.getCategories();
    },

    closeModalStd: function (data) {
      this.selectStdDoInit = false;
      this.showModalStd = false;

      if (!data.cancel) {
        if (data.selectedStandard._id != this.actualStd._id) {
          let changeStdData = data.selectedStandard;
          changeStdData.inProduction = this.tabMainOk;
          changeStdData.actId = oper.getActivityId();

          axiosInstance.post("/changestd", changeStdData);
        }

        if (
          this.useTakt != data.useTakt ||
          this.valueNAT != data.valueNAT ||
          this.valueCD != data.valueCD
        ) {
          let changeTaktData = {};
          changeTaktData.inProduction = this.tabMainOk;
          changeTaktData.actId = oper.getActivityId();
          changeTaktData.lineId = oper.getLineId();
          changeTaktData.useTakt = this.useTakt = data.useTakt;
          changeTaktData.valueNAT = this.valueNAT = Number(data.valueNAT);
          changeTaktData.valueCD = this.valueCD = Number(data.valueCD);

          axiosInstance.post("/changetakt", changeTaktData);
        }
      }
    },
    notifyChangeStandard(actualStandard, newStandard) {
      this.toastNotification(
        "Standard changed",
        `From ${actualStandard} to ${newStandard}`,
        "warning"
      );
    },
    stdUpdateActivity: function (data) {
      //console.log('----- standard stdUpdateActivity = ' + JSON.stringify(data, null, 2));

      this.courierProcess.method = data.courierProcessType || "";
      this.courierProcess.matchId = data.courierMatchId || "";

      if (
        this.courierProcess.method !== "" &&
        this.courierProcess.matchId !== ""
      )
        this.courierProcess.show = true;

      if (data.stds && data.stds.length > 0) {
        if (
          this.actualStd.partNumber &&
          this.actualStd.partNumber.toString() !==
            data.stds[data.stds.length - 1].std.partNumber.toString()
        )
          this.notifyChangeStandard(
            this.actualStd.partNumber,
            data.stds[data.stds.length - 1].std.partNumber
          );
        this.actualStd = data.stds[data.stds.length - 1].std;
        this.tabStdOk = true;
      } else {
        this.actualStd = {};
        this.tabStdOk = false;
      }
      this.tabLineOk = !!(data.line && data.line._id);
      this.tabSchedOk = !!(
        data.sched &&
        data.sched.sched &&
        data.sched.sched._id
      );
      this.tabMainOk = !!data._id;

      if (data.useTakt == true) {
        this.useTakt = true;
        this.valueNAT = data.valueNAT;
        this.valueCD = data.valueCD;
      } else this.useTakt = false;

      this.tabNoEff = data.line && data.line.tabNoEff;

      this.highEff = (data.line && data.line.highEff) || false;

      this.nbEmp = 0;
      if (this.tabStdOk) {
        if (data.team && data.team.length > 0 && !this.highEff) {
          data.team.forEach((emp) => {
            if (!emp.isSupport) this.nbEmp++;
          });

          let sct = this.actualStd.SAM / this.teamLenght;
          if (this.nbEmp != 0) sct = this.actualStd.SAM / this.nbEmp;

          EventBus.$emit("newSCT", sct);

          let val = moment.utc(sct * 1000).format("HH[h]mm[m]ss");
          if (Math.round((sct % 1) * 10) != 0)
            val = val + "." + Math.round((sct % 1) * 10);
          val += "s";
          this.textSCT = val;
        } else {
          this.textSCT = this.getSCT();

          EventBus.$emit("newSCT", this.actualStd.SAM / this.teamLenght);
        }
      }
    },
    toastNotification(title, description, variant) {
      // variant = 'success' | 'warning' | 'danger'
      const h = this.$createElement;
      const vNodesTitle = h("div", { class: "toastNotificationHead" }, title);
      const vNodesMsg = h(
        "div",
        { class: "toastNotificationBody" },
        description
      );
      this.$bvToast.toast([vNodesMsg], {
        id: this.toastId,
        title: [vNodesTitle],
        variant,
        toaster: "b-toaster-top-center",
        noCloseButton: false,
        autoHideDelay: 6000,
      });
    },
  },
  mounted() {
    this.stdUpdateActivity(oper.getCurrentActivity());
    EventBus.$on("TabletTeam::teamLenght", this.setTeamLenght);
    EventBus.$on("updateActivity", this.stdUpdateActivity);
    EventBus.$on("updateCourierProcess", ({ courierMethod, matchId }) => {
      console.log("----- updateCourierProcess");
      this.courierProcess.method = courierMethod;
      this.courierProcess.matchId = matchId;
      if (
        this.courierProcess.method !== "" &&
        this.courierProcess.matchId !== ""
      )
        this.courierProcess.show = true;
    });
    EventBus.$on("closeTabletMain", () => {
      this.courierProcess = {
        show: false,
        matchId: "", // Example: G22 || 108002
        method: "", // inbound || outbound || ''
      };
    });

    let runningLine = localStorage.getItem("runningLine");
    //console.log("----- runningLine = " + runningLine);
    let lastSelectedLineId = localStorage.getItem("lastSelectedLineId");

    if (runningLine && lastSelectedLineId) {
      oper
        .getPrevActivity(runningLine, lastSelectedLineId)
        .then((res) => {
          // this.teamUpdateActivity(res.data)
          console.log(res.data, "data team stand");
          return res.data;
          // self.showModalLine = true;
        })
        .then((data) => {
          // console.log(data?.line?.automaticStart, "REOLVE Standard");

          if (data && data.line && data.line.automaticStart) {
            let findLastStandardandCat = this.findLastMatchingObject(data.stds);
            // let getCategoryID = data.stds[data.stds.length - 1].std.category;
            // let getStandardID = data.stds[data.stds.length - 1].std._id;

            let getCategoryID = findLastStandardandCat.std.category;
            let getStandardID = findLastStandardandCat.std._id;
            // let getCategoryID=data.stds[0].std.category;
            this.selectedCategoryId = getCategoryID;

            this.showModalStd = true;
            this.selectStdDoInit = true;
            this.selectedStdID = getStandardID;
          }
          //  // Assuming you have the category ID available
          return false;
          // console.log(this.selectedCategoryId,"checkIDDD")
          // const prevTeam = data.team.map(member => member.emp._id);
          // this.selectedTeamIds = prevTeam; // Set the selected team IDs
          // if (this.$refs.selectTeam) {
          //  this.$refs.selectTeam.initTeamSelection(prevTeam);
          // }
        });
      // console.log(activityData,"activityData");
    }
  },
  beforeDestroy() {
    EventBus.$off("updateActivity", this.stdUpdateActivity);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/global.scss";

h1 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.1;
  color: $perfcell-green;
  margin: 0;
}

.card-body-custom {
  height: 64px;
}

.toastNotificationHead {
  font-size: 1.5rem;
  font-weight: 600;
}

.toastNotificationBody {
  font-size: 1rem;
  font-style: italic;
}
</style>
