<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask" v-show="showSms">
      <div class="modal-container">

        <div>
          <div v-if="! gotRecipList">
            <b-spinner label="Loading..."></b-spinner>
          </div>
          <div v-else>
            <label v-if="recipList.length == 0" class="attention">{{$t('selectSms.noRecip')}}</label>
            <label v-else style="font-weight: bold;">{{$t('selectSms.sRecip')}}</label>
          </div>
          <b-table
            class="table-big-font"
            ref="recipientsTable"
            hover
            small
            selectable
            select-mode="single"
            selected-variant="success"
            sticky-header="140px"
            primary-key="_id"
            :items="recipList"
            :fields="showRecipFields"
            @row-selected="onRecipSelected">
          </b-table>
        </div>

        <div v-if="selectedRecipId != ''">
          <div class="form-group">
            <label class="radio-inline"><b-form-radio v-model="msgType" name="msgType" value="fromList">{{$t('selectSms.fromList')}}</b-form-radio></label>
            <label class="radio-inline marginLeft30px"><b-form-radio v-model="msgType" name="msgType" value="typeText">{{$t('selectSms.typeText')}}</b-form-radio></label>
          </div>
          <div v-if="msgType == 'fromList'">
            <div v-if="! gotMsgList">
              <b-spinner label="Loading..."></b-spinner>
            </div>
            <div v-else>
              <label v-if="msgList.length == 0" class="attention">{{$t('selectSms.noMsg')}}</label>
              <label v-else style="font-weight: bold;">{{$t('selectSms.sMsg')}}</label>
            </div>
            <b-table
              class="table-big-font"
              ref="messagesTable"
              hover
              small
              selectable
              select-mode="single"
              selected-variant="success"
              sticky-header="140px"
              primary-key="_id"
              :items="msgList"
              :fields="showMsgFields"
              @row-selected="onMsgSelected">
            </b-table>
          </div>

          <div v-if="msgType == 'typeText'" class="form-group">
            <InputText
              password="false"
              @handleChange="handleChange($event)"
              :placeholder="$t('selectSms.enterText')">
            </InputText>
          </div>
        </div>

        <button class="btn btn-perfcell float-right marginTop40px" @click="clickCancel">
          {{$t('selectSms.cancel')}}
        </button>

        <button v-if="saveSmsEnabled" class="btn btn-perfcell float-right marginTop40px marginRight10px" @click="clickSave">
          {{$t('selectSms.save')}}
        </button>

      </div>
    </div>
  </transition>
</template>

<script>
import { axiosInstance } from "@/main";
import oper from '@/stores/Operations.js';
import EventBus from '@/eventBus'
import InputText from '@/components/InputText.vue';

export default {
  name: 'SelectSms',
  template: '#modal-template',
  props: {
    showSms: Boolean,
    doInitSms: Boolean
  },
  data: function() {
    return {
      recipList: [],
      msgList: [],
      showRecipFields: [
        {
          key: 'name',
          thStyle: { display: 'none' }
        }
      ],
      showMsgFields: [
        {
          key: 'text',
          thStyle: { display: 'none' }
        }
      ],
      msgType: 'fromList',
      selectedRecip: {},
      selectedRecipId: '',
      selectedMsg: {},
      selectedMsgId: '',
      gotRecipList: false,
      gotMsgList: false,
      message: '',
      saveSmsEnabled: false,
      didStopScanner: false
    }
  },
  components: {
    InputText
  },
  watch: {
    'msgType': function( value ) {
      if( value == 'fromList' && this.selectedMsgId != '' ) {
        this.$nextTick(function () {
          for( let i=0; i<this.msgList.length; i++ ) {
            if( this.selectedMsgId == this.msgList[i]._id ) {
              this.$refs.messagesTable.selectRow( i );
              break;
            }
          }
        })
      }
      this.checkSendButton();

      if( value == 'typeText' && oper.getScanner() ) {
        EventBus.$emit('scanner', false);
        this.didStopScanner = true;
      }
    },
    'message': function() {
      this.checkSendButton();
    },
    'doInitSms': function(val) {
      if( val ) {
        console.log("----- init SelectSms");

        this.msgType = 'fromList';
        this.selectedRecip = {};
        this.selectedRecipId = '';
        this.selectedMsg = {};
        this.selectedMsgId = '';
        this.message = '';
        this.saveSmsEnabled = false;

        this.recipList = [];
        //this.$refs.recipientsTable.refresh();
        this.gotRecipList = false;

        this.msgList = [];
        //this.$refs.messagesTable.refresh();
        this.gotMsgList = false;

        axiosInstance.get('/smsRecipientList/', {"params": { "isActive": true }})
        .then(resp => {
          //console.log("----- get /smsRecipientList = " + JSON.stringify(resp.data, null, 2));
          this.recipList = resp.data;
          this.gotRecipList = true;

          this.selectedRecip = {};
          this.selectedRecipId = '';

        }).catch(err => {
          console.log("----- get /smsRecipientList error = " + JSON.stringify(err, null, 2));
        });

        axiosInstance.get('/smsMessageList/', {"params": { "isActive": true }})
        .then(resp => {
          //console.log("----- get /smsMessageList = " + JSON.stringify(resp.data, null, 2));
          this.msgList = resp.data;
          this.gotMsgList = true;

          this.selectedMsg = {};
          this.selectedMsgId = '';

        }).catch(err => {
          console.log("----- get /smsMessageList error = " + JSON.stringify(err, null, 2));
        });
      }
    }
  },
  methods: {
    handleChange(payload) {
      this.message = payload;
    },
    onRecipSelected(item) {
      //console.log("----- item = " + JSON.stringify(item, null, 2));
      if( item.length ) {
        this.selectedRecip = item[0];
        this.selectedRecipId = item[0]._id;
      }
      else
        this.selectedRecipId = '';
      this.checkSendButton();
    },
    onMsgSelected(item) {
      //console.log("----- item = " + JSON.stringify(item, null, 2));
      if( item.length ) {
        this.selectedMsg = item[0];
        this.selectedMsgId = item[0]._id;
      }
      else
        this.selectedMsgId = '';
      this.checkSendButton();
    },
    checkSendButton: function () {
      let show = true;
      if( this.selectedRecipId == '' )
        show = false;
      if( this.msgType == 'fromList' && this.selectedMsgId == '' )
        show = false;
      if( this.msgType == 'typeText' && this.message == '' )
        show = false;
      this.saveSmsEnabled = show;
    },
    clickSave: function () {
      let data = {
        cancel: false,
        lineName: oper.getLineName(),
        to: this.selectedRecip.name,
        phone: this.selectedRecip.phone,
        text: this.msgType == 'fromList' ? this.selectedMsg.text : this.message
      };
      if( this.didStopScanner )
        EventBus.$emit('scanner', true);
      this.$emit('close', data);
    },
    clickCancel: function () {
      if( this.didStopScanner )
        EventBus.$emit('scanner', true);
      this.$emit('close', {cancel: true});
    }
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.modal-mask {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  transition: opacity .3s ease;
}
.modal-container {
  width: 800px;
  height: 600px;
  margin: auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
