<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask" v-show="showHelp">
      <div class="modal-container">

        <div>
          <div class="form-group">
            <label class="radio-inline"><b-form-radio v-model="helpType" name="helpType" value="0">{{$t('selectHelp.machine')}}</b-form-radio></label>
          </div>
          <div class="form-group">
            <label class="radio-inline"><b-form-radio v-model="helpType" name="helpType" value="1">{{$t('selectHelp.besoin')}}</b-form-radio></label>
          </div>
          <div class="form-group">
            <label class="radio-inline"><b-form-radio v-model="helpType" name="helpType" value="2">{{$t('selectHelp.question')}}</b-form-radio></label>
          </div>

          <div>______________________________________________________</div>
          <div class="marginTop20px"></div>

          <div class="form-group" v-if="lightsOn.red">
            <label class="radio-inline"><b-form-radio v-model="helpType" name="helpType" value="3">{{$t('selectHelp.red')}}</b-form-radio></label>
          </div>
          <div class="form-group" v-if="lightsOn.orange">
            <label class="radio-inline"><b-form-radio v-model="helpType" name="helpType" value="4">{{$t('selectHelp.orange')}}</b-form-radio></label>
          </div>
          <div class="form-group" v-if="lightsOn.green">
            <label class="radio-inline"><b-form-radio v-model="helpType" name="helpType" value="5">{{$t('selectHelp.green')}}</b-form-radio></label>
          </div>
        </div>

        <button class="btn btn-perfcell float-right marginTop40px" @click="clickCancel">
          {{$t('selectHelp.cancel')}}
        </button>

        <button class="btn btn-perfcell float-right marginTop40px marginRight10px" @click="clickSave">
          {{$t('selectHelp.save')}}
        </button>

      </div>
    </div>
  </transition>
</template>

<script>
import { axiosInstance } from "@/main";
import oper from '@/stores/Operations.js';

export default {
  name: 'SelectHelp',
  template: '#modal-template',
  props: {
    showHelp: Boolean,
    doInitHelp: Boolean
  },
  data: function() {
    return {
      helpType: 0,
      lightsOn: {
        red: 0,
        orange: 0,
        green: 0
      }
    }
  },
  watch: {
    'doInitHelp': function(val) {
      if( val ) {
        //console.log("----- init SelectHelp");

        this.helpType = 0;

        if( oper.getLineId() != "" ) {
          axiosInstance.get('/lightsOnLine/' + oper.getLineId())
          .then(resp => {
            //console.log("----- get /lightsOnLine = " + JSON.stringify(resp.data, null, 2));

            this.lightsOn = resp.data;

          }).catch(err => {
            console.log("----- get /lightsOnLine error = " + JSON.stringify(err, null, 2));
          });
        }
      }
    }
  },
  methods: {
    clickSave: function () {

      if( oper.getLineId() != "" ) {

        let controlLight = {
          state: 0,
          color: "Red",
          id: oper.getLineId()
        }
        if( this.helpType <= 2 )
          controlLight.state = 1;
        if( this.helpType == 1 || this.helpType == 4 )
          controlLight.color = "Orange";
        if( this.helpType == 2 || this.helpType == 5 )
          controlLight.color = "Green";

        axiosInstance.post('/controlLight', {controlLight: controlLight});
      }

      if( this.helpType >= 3 )
        this.clickCancel();
      else
        this.$emit('close', {cancel: false, helpType: this.helpType});
    },
    clickCancel: function () {
      this.$emit('close', {cancel: true});
    }
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.modal-mask {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  transition: opacity .3s ease;
}
.modal-container {
  width: 500px;
  height: 500px;
  margin: 40px auto 0;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
