<template>
  <div class="g-page-container noSelect">
    <div class="g-pageHeader" style="height: 63px;">
      <h1 style="margin: auto;">{{ $t("inboundDash.title") }}</h1>
    </div>

    <div style="width:100% !important">
      <b-table
        id="my-table"
        :items="items"
        :fields="fields"
        :busy="isLoading"
        class="mt-3"
        outlined
        :per-page="perPage"
        :current-page="currentPage"
        small
        sticky-header
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
      </b-table>
      <div class="mt-3">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          pills
        ></b-pagination>
      </div>
    </div>

    <div class="g-pageBody" style="margin-top: 20px;">
      <div class="row">
        <div class="col-md-12">
          <button
            class="btn btn-perfcell float-right marginTop10px marginRight10px"
          >
            {{ $t("dashboard2.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import oper from "@/stores/Operations.js";
import { axiosInstance } from "@/main";
import moment from "moment";
export default {
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      isLoading: false,
      // Note 'isActive' is left out and will not appear in the rendered table
      fields: [
        {
          key: "orderId",
          label: "Order Id",
        },
        {
          key: "bu",
          label: "BU",
        },
        {
          key: "namePick",
          label: "Name",

          // Variant applies to the whole column, including the header and footer
          variant: "danger",
        },
        {
          key: "datePick",
          label: "Date",

          // Variant applies to the whole column, including the header and footer
          variant: "danger",
        },
        {
          key: "timePick",
          label: "Time",

          // Variant applies to the whole column, including the header and footer
          variant: "danger",
        },
        {
          key: "associatePick",
          label: "Associate",

          // Variant applies to the whole column, including the header and footer
          variant: "danger",
        },
        {
          key: "namePack",
          label: "Name",

          // Variant applies to the whole column, including the header and footer
          variant: "active",
        },
        {
          key: "datePack",
          label: "Date",

          // Variant applies to the whole column, including the header and footer
          variant: "active",
        },
        {
          key: "timePack",
          label: "Time",

          // Variant applies to the whole column, including the header and footer
          variant: "active",
        },
        {
          key: "associatePack",
          label: "Associate",

          // Variant applies to the whole column, including the header and footer
          variant: "active",
        },
        {
          key: "nameInbound",
          label: "Name",

          // Variant applies to the whole column, including the header and footer
          variant: "success",
        },
        {
          key: "dateInbound",
          label: "Date",

          // Variant applies to the whole column, including the header and footer
          variant: "success",
        },
        {
          key: "timeInbound",
          label: "Time",

          // Variant applies to the whole column, including the header and footer
          variant: "success",
        },
        {
          key: "associateInbound",
          label: "Associate",

          // Variant applies to the whole column, including the header and footer
          variant: "success",
        },
      ],
      items: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    };
  },
  methods: {
    response: function(resp) {
      return resp.data.map((e) => {
        return {
          orderId: e.wo || "--",
          bu: "QC1" || "--",
          namePick: Object.keys(e.picking).length
            ? e.picking.line
            : "--" || "--",
          datePick: Object.keys(e.picking).length
            ? moment(e.picking.doneDate).format("dd/MM/yyyy")
            : "--" || "--",
          timePick: Object.keys(e.picking).length
            ? moment(e.picking.doneDate).format("HH:mm:ss")
            : "--" || "--",
          associatePick: Object.keys(e.picking).length
            ? e.picking.team.map((e) => `${e.fname} ${e.lname} ${e.alias}`)[0]
            : "--" || "--",
          namePack: Object.keys(e.packing).length
            ? e.packing.line
            : "--" || "--",
          datePack: Object.keys(e.packing).length
            ? moment(e.packing.doneDate).format("dd/MM/yyyy")
            : "--" || "--",
          timePack: Object.keys(e.packing).length
            ? moment(e.packing.doneDate).format("HH:mm:ss")
            : "--" || "--",
          associatePack: Object.keys(e.packing).length
            ? e.packing.team.map((e) => `${e.fname} ${e.lname} ${e.alias}`)[0]
            : "--" || "--",
          nameInbound: Object.keys(e.inbound).length
            ? e.inbound.line
            : "--" || "--",
          dateInbound: Object.keys(e.inbound).length
            ? moment(e.inbound.doneDate).format("dd/MM/yyyy")
            : "--" || "--",
          timeInbound: Object.keys(e.inbound).length
            ? moment(e.inbound.doneDate).format("HH:mm:ss")
            : "--" || "--",
          associateInbound: Object.keys(e.inbound).length
            ? e.inbound.team.map((e) => `${e.fname} ${e.lname} ${e.alias}`)[0]
            : "--" || "--",
        };
      });
    },
  },
  mounted() {
    oper.dashboard(true);
    this.isLoading = true;
    axiosInstance.get("/rep/getLatestScanned").then((resp) => {
      this.isLoading = !this.isLoading;
      this.items = this.response(resp);
    });
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
};
</script>
