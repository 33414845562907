<template>
  <div class="g-page-container">

    <div class="g-pageHeader" style="height: 95px;">
      <h1 style="margin: auto;">{{$t('biCharts.title')}}</h1>
      <h2 style="margin: auto;">{{$t('biChartsUPH.title')}}</h2>
    </div>

    <div class="g-pageBody">

      <b-row>
        <b-col cols="2">
          <div class="form-group">
            <label class="marginTop10px">{{$t('biCharts.date')}}</label><br>
            <input v-model="reportFrom" type="date" @input="dateChange">
          </div>
        </b-col>
        <b-col cols="3">
          <label class="marginTop10px">{{$t('biCharts.selectLine')}}</label><br>
          <MultipleSelect
            :data="lineList"
            v-model="lineResult"
            :options="getOptions('line')"
            @change="listChange('line')" >
          </MultipleSelect>
        </b-col>
        <b-col cols="3">
          <label class="marginTop10px">{{$t('biCharts.selectSched')}}</label><br>
          <MultipleSelect
            :data="schedList"
            v-model="schedResult"
            :options="getOptions('sched')"
            @change="listChange('sched')" >
          </MultipleSelect>
        </b-col>
        <b-col cols="4" class="text-center marginBottom10px">
          <button class="btn btn-perfcell marginTop20px" @click="clickGenerate">{{$t('biCharts.generate')}}</button>
          <button class="btn btn-perfcell marginTop20px marginLeft15px" @click="clickExport">{{$t('biCharts.export')}}</button>
          <button class="btn btn-perfcell marginTop20px marginLeft15px" @click="clickRetour">{{$t('biCharts.cancel')}}</button>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <label v-if="error" class="error">{{error}}</label>
        </b-col>
      </b-row>

    </div>

    <b-row>
      <b-col cols="6">
        <div class="g-pageBody" :style="{height: tableHeight}">
          <b-table
            small
            fixed
            striped
            :sticky-header="tableHeight"
            :items="chartData"
            :fields="showFields" >
          </b-table>
        </div>
      </b-col>
      <b-col cols="6" style="text-align: center; padding-left: 0;">
        <div class="g-pageBody">

          <b-row class="g-chart-header" style="display: inline-block">
            {{$t('biChartsUPH.chartTitle')}}
          </b-row>

          <div ref="chartContainer">
            <canvas id="theDailyProdChart" :style="{width: chartWidth + 'px', height: chartHeight + 'px'}"></canvas>
          </div>

        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { axiosInstance } from "@/main";
import { axiosInstanceReports } from "@/main";
import moment from 'moment-timezone';
import EventBus from '@/eventBus';
import { json2excel } from 'js2excel';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import oper from '@/stores/Operations.js';

export default {
  data() {
    return {
      reportFrom: '',
      error: '',

      lineList: [],
      schedList: [],

      lineResult: [],
      schedResult: [],

      chartData: [],

      chartWidth: 300,
      chartHeight: 300,
      tableHeight: '350px',

      Totalizer: null,
      ctx: null,

      doingResize: false,

      dateFormat: "MM/DD/YYYY"
    }
  },
  components: {
  },
  computed: {
    showFields() {
      return [
        {
          key: this.$i18n.t('biChartsUPH.headerDate'),
          thStyle: { width: '90px'}
        },
        {
          key: this.$i18n.t('biChartsUPH.headerTeam'),
          thStyle: { width: '200px'}
        },
        {
          key: this.$i18n.t('biChartsUPH.headerFrom'),
          thStyle: { width: '80px'}
        },
        {
          key: this.$i18n.t('biChartsUPH.headerTo'),
          thStyle: { width: '80px'}
        },
        {
          key: this.$i18n.t('biChartsUPH.headerRegular'),
          thStyle: { width: '80px'}
        },
        {
          key: this.$i18n.t('biChartsUPH.headerOther'),
          thStyle: { width: '80px'}
        },
        {
          key: this.$i18n.t('biChartsUPH.headerReject'),
          thStyle: { width: '80px'}
        }
      ]
    }
 },
  methods: {
    dateChange() {
      this.error = '';
    },
    listChange( name ) {
      console.log("----- listChange = " + name);
      this.error = '';
    },
    getOptions(i) {
      let opt = {
        multiple: false,
        filter: true,
        placeholder: '',
        multipleWidth: 200,
        width: 200,
        dropWidth: 200,
        maxHeight: this.chartHeight,
        locale: localStorage.getItem("locale")
      }
      if( i == 'line' ) opt.placeholder = this.$i18n.t('biCharts.linePHolder');
      if( i == 'sched' ) opt.placeholder = this.$i18n.t('biCharts.schedPHolder');
      return opt;
    },
    clickGenerate() {
      if( this.lineResult.length == 0 ) {
        this.error = this.$i18n.t('biCharts.noLine');
        return;
      }
      if( this.schedResult.length == 0 ) {
        this.error = this.$i18n.t('biCharts.noSched');
        return;
      }
      let data = {
        locale: localStorage.getItem("locale"),
        start: moment(this.reportFrom),
        lineData: this.lineResult,
        schedData: this.schedResult
      }
      axiosInstanceReports.post('/rep/unitsphchart', data )
      .then((result) => {
        console.log("----- /rep/ onSubmit ok = ");// + JSON.stringify(result.data, null, 2));

        this.chartData = [];

        for( let i=0; i<result.data.length; i++ ) {
          let oneData = result.data[i];

          let entry = {};
          entry[this.$i18n.t('biChartsUPH.headerDate')] = moment(oneData.from).format(this.dateFormat);
          entry[this.$i18n.t('biChartsUPH.headerTeam')] = oneData.emp;
          entry[this.$i18n.t('biChartsUPH.headerFrom')] = moment(oneData.from).format("HH:mm");
          entry[this.$i18n.t('biChartsUPH.headerTo')] = moment(oneData.to).format("HH:mm");
          entry[this.$i18n.t('biChartsUPH.headerRegular')] = oneData.Produced;
          entry[this.$i18n.t('biChartsUPH.headerOther')] = oneData.Other;
          entry[this.$i18n.t('biChartsUPH.headerReject')] = oneData.Reject;
          this.chartData.push( entry );
        }
        //console.log("----- this.chartData = " + JSON.stringify(this.chartData, null, 2));

        let rangeFrom = 0;
        let rangeTo = 0;
        if( result.data.length != 0 ) {
          rangeFrom = result.data[0].from;
          rangeTo = result.data[0].from;
        }

        for( let i=0; i<result.data.length; i++ ) {
          if( moment(result.data[i].from).isBefore(moment(rangeFrom)) )
            rangeFrom = result.data[i].from;
          if( moment(result.data[i].from).isAfter(moment(rangeTo)) )
            rangeTo = result.data[i].from;
        }
        rangeFrom = moment(rangeFrom).minutes(0).seconds(0).milliseconds(0);
        rangeTo = moment(rangeTo).minutes(0).seconds(0).milliseconds(0);
        //console.log("----- rangeFrom = " + JSON.stringify(rangeFrom, null, 2));
        //console.log("----- rangeTo = " + JSON.stringify(rangeTo, null, 2));

        let categories = [];
        let data1 = [];
        let data2 = [];
        let data3 = [];
        let totals = [];

        if( result.data.length != 0 )
          for( var h = moment(rangeFrom); h.diff(rangeTo, 'hours') <= 0; h.add(1, 'hour') ) {
            categories.push( h.clone() );
            data1.push( 0 );
            data2.push( 0 );
            data3.push( 0 );
            totals.push( 0 );
          }
        //console.log("----- categories = " + JSON.stringify(categories, null, 2));

        for( let i=0; i<result.data.length; i++ ) {
          let oneData = result.data[i];

          let h=categories.length-1;
          for( ; moment(categories[h]).isAfter(moment(oneData.from)); h-- );

          data1[h] += oneData.Produced;
          data2[h] += oneData.Other;
          data3[h] += oneData.Reject;
          totals[h] += (oneData.Produced + oneData.Other + oneData.Reject);
        }
        //console.log("----- data1 = " + JSON.stringify(data1, null, 2));
        //console.log("----- data2 = " + JSON.stringify(data2, null, 2));
        //console.log("----- data3 = " + JSON.stringify(data3, null, 2));
        //console.log("----- totals = " + JSON.stringify(totals, null, 2));

        for( let i=0; i<categories.length; i++ ) {
          categories[i] = moment(categories[i]).format("HH:mm");
        }
        //console.log("----- categories = " + JSON.stringify(categories, null, 2));

        new Chart( this.ctx, {
          plugins: [ChartDataLabels, this.Totalizer],
          type: 'bar',
          data: {
            labels: categories,
            datasets: [
              {
                type: 'bar',
                backgroundColor: 'rgba(68,114,196,1.0)',
                label: this.$i18n.t('biChartsUPH.headerRegular'),
                data: data1,
                stack: 'Stack 0'
              },
              {
                type: 'bar',
                backgroundColor: 'rgba(44,206,101,1.0)',
                label: this.$i18n.t('biChartsUPH.headerOther'),
                data: data2,
                stack: 'Stack 0'
              },
              {
                type: 'bar',
                backgroundColor: 'rgba(237,125,49,1.0)',
                label: this.$i18n.t('biChartsUPH.headerReject'),
                data: data3,
                stack: 'Stack 0'
              }
            ]
          },
          options: {
            tooltips: {
                 enabled: false
            },
            events: ['click'],
            plugins: {
              datalabels: {
                formatter: (value, ctx) => {
                  const total = ctx.chart.$Totalizer.totals[ctx.dataIndex];
                  return total;
                },
                align: 'end',
                anchor: 'end',
                display: function(ctx) {
                  return ctx.datasetIndex === ctx.chart.$Totalizer.utmost;
                }
              }
            },
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  suggestedMax: Math.round(Math.max(...totals) * 1.1)
                },
                stacked: true
              }]
            }
          }
        });

      }).catch(() => {
        console.log("----- /rep/ onSubmit error ");
      });
    },
    clickExport() {
      json2excel({
        data: this.chartData,
        name: 'UnitsPerHourTable'
      });
    },
    clickRetour() {
      this.$router.replace("/config");
    },
    chartUpdateFactory( data ) {
      this.dateFormat = data.dateFormat;
    },
    redimChart: function() {
      if( this.doingResize )
        return
      this.doingResize = true;
      let self = this;
      setTimeout(function() {
        self.chartWidth = self.$refs.chartContainer.clientWidth;
        self.chartHeight = Math.round(self.chartWidth * 0.7);
        self.tableHeight = (self.chartHeight + 46) + 'px';
        //console.log("----- tableHeight = " + self.tableHeight);
        self.doingResize = false;
      }
      , 100);
    }
  },
  mounted() {
    this.dateFormat = oper.getFormatData();

    this.reportFrom = moment().format(this.dateFormat);

    axiosInstance.get('/lineList', {"params": { "isActive": true }})
    .then(resp => {
        for( let i=0; i<resp.data.length; i++ ) {
          resp.data[i].text = resp.data[i].name + ' - ' + resp.data[i].description;
          resp.data[i].value = resp.data[i]._id;
        }
        //console.log("----- get /lineList = " + JSON.stringify(resp.data, null, 2));
        let list = JSON.stringify(resp.data);
        this.lineList = JSON.parse(list);
        this.lineResult = [];
    }).catch(err => {
        console.log("----- get /lineList error = " + JSON.stringify(err, null, 2));
    });

    axiosInstance.get('/scheduleList', {"params": { "isActive": true }})
    .then(resp => {
        for( let i=0; i<resp.data.length; i++ ) {
          resp.data[i].text = resp.data[i].description;
          resp.data[i].value = resp.data[i]._id;
        }
        //console.log("----- get /scheduleList = " + JSON.stringify(resp.data, null, 2));
        let list = JSON.stringify(resp.data);
        this.schedList = JSON.parse(list);
        this.schedResult = [];
    }).catch(err => {
        console.log("----- get /scheduleList error = " + JSON.stringify(err, null, 2));
    });

    this.Totalizer = {
      id: 'Totalizer',
      beforeUpdate: chart => {
        let totals = {}
        let utmost = 0
        chart.data.datasets.forEach((dataset, datasetIndex) => {
          if( chart.isDatasetVisible(datasetIndex) ) {
            utmost = datasetIndex
            dataset.data.forEach((value, index) => {
              totals[index] = (totals[index] || 0) + value
            })
          }
        })
        chart.$Totalizer = {
          totals: totals,
          utmost: utmost
        }
      }
    }
    this.ctx = document.getElementById('theDailyProdChart');

    this.redimChart();
    EventBus.$on('mainViewDim', this.redimChart);
    EventBus.$on('factory', this.chartUpdateFactory);
  },
  beforeDestroy(){
    EventBus.$off('mainViewDim', this.redimChart);
    EventBus.$off('factory', this.chartUpdateFactory);
  }
}
</script>

<style lang="scss">

.error {
  margin-left: 15px;
  color: red;
}

</style>
