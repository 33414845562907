<template>
  <div class="g-page-container">

    <div class="g-pageHeader" style="height: 63px;">
      <h1 style="margin: auto;">{{$t('followUps.title')}}</h1>
    </div>

    <form class="g-pageBody" @submit.prevent="onSubmit">

      <div v-if="! maxPcentOk">
        <div class="row">
          <div class="col-md-6">
            {{$t('followUps.week')}}<font size="+1"><b>{{week}}</b></font>{{$t('followUps.from')}}{{startF}}{{$t('followUps.to')}}{{endF}}
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            {{$t('followUps.maxPcent')}}
            <input
              style="margin-left: 10px;"
              type="number"
              min="0.01"
              max="99.99"
              step=".01"
              required
              v-model="maxPcent"
              id="inputMaxPcent">
          </div>
        </div>
      </div>

      <div v-else>
        <div class="row">
          <div class="col-md-6">
            {{$t('followUps.week')}}<font size="+1"><b>{{week}}</b></font>{{$t('followUps.from')}}{{startF}}{{$t('followUps.to')}}{{endF}}
            <div class="marginTop10px">
              {{$t('followUps.maxPcent')}}<font size="+1"><b>{{maxPcent}} %</b></font>
            </div>
          </div>
          <div class="col-md-6">
            {{$t('followUps.scheds')}}
            <MultipleSelect
              ref="schedSelect"
              :data="schedList"
              v-model="schedsSelected"
              multiple
              :options="optScheds" >
            </MultipleSelect>
          </div>
        </div>
      </div>

      <div v-if="maxPcentOk"> <!-- ************************************************************ -->

        <div class="row marginTop20px">
          <div class="col-md-6">
            <div>

              <div class="form-group">
                <label class="radio-inline" style="font-weight: normal; margin-left: -10px;"><b-form-radio v-model="sortType" name="selectSortType" value="pcent">{{$t('followUps.sortByPcent')}}</b-form-radio></label>
                <br>
                <label class="radio-inline" style="font-weight: normal; margin-left: -10px;"><b-form-radio v-model="sortType" name="selectSortType" value="name">{{$t('followUps.sortByName')}}</b-form-radio></label>
              </div>

              <div style="margin-top: -15px;">
                {{$t('followUps.employee')}}
                <MultipleSelect
                  ref="empSelect"
                  class="boldDropdown"
                  :data="empList"
                  v-model="empSelected"
                  :options="optEmpList">
                </MultipleSelect>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            {{$t('followUps.supervisor')}}
            <input
              style="margin-left: 10px; font-weight: bold;"
              type="text"
              required
              v-model="supervisor"
              id="supervisor">
          </div>
        </div>

        <div v-if="action == '' && empSelected !== 'undefined'" class="row marginTop10px">
          <div class="col-md-6">
            {{$t('followUps.suggest')}}<b>{{suggestion}}</b>
          </div>
          <div class="col-md-6">
            {{$t('followUps.action')}}
            <MultipleSelect
              class="boldDropdown"
              ref="action"
              :data="actionsList"
              v-model="action"
              :options="opt" >
            </MultipleSelect>
          </div>
        </div>
        <div v-else class="row marginTop10px">
          <div class="col-md-6">
            {{$t('followUps.suggest')}}<b>{{suggestion}}</b>
          </div>
          <div class="col-md-6">
            {{$t('followUps.actionChosed')}}<b>{{action}}</b>
          </div>
        </div>

        <div class="row marginTop20px">
          <div class="col-md-11">
            {{$t('followUps.comment')}}
            <input v-model="comments" style="width: 650px; font-weight: bold;">
          </div>
        </div>

        <div class="row marginTop10px">
          <div class="col-md-11">
            {{$t('followUps.history')}}
            <b-table
              hover
              small
              sticky-header="140px"
              primary-key="_id"
              :items="history"
              :fields="historyFields">
            </b-table>
          </div>
        </div>

      </div> <!-- ************************************************************ -->

      <button class="btn btn-perfcell float-left marginTop50px" @click.prevent="clickRetour">
        {{$t('followUps.cancel')}}
      </button>

      <button v-if="maxPcentOk" class="btn btn-perfcell float-left marginTop50px  marginLeft10px" @click.prevent="clickGenerate">
        <span v-if="showSpinner"> <b-spinner></b-spinner> </span>
        <span v-else > {{$t('followUps.generate')}} </span>
      </button>

      <button v-if="! maxPcentOk" class="btn btn-perfcell float-left marginTop50px marginLeft10px" type="submit">
        {{$t('followUps.saveMaxPcent')}}
      </button>

      <button v-else class="btn btn-perfcell float-left marginTop50px marginLeft10px" type="submit">
        {{$t('followUps.saveEmployee')}}
      </button>

      <p v-if="errors.length" class="float-left marginTop50px marginLeft30px" :style="{ color: msgColor }">
        <b v-if="msgColor == 'red'">{{$tc('configUser.error', errors.length)}}</b>
        <ul>
          <li v-for="error in errors" :key="error">{{error}}</li>
        </ul>
      </p>

    </form>

  </div>
</template>

<script>
import { axiosInstance } from "@/main";
import { axiosInstanceReports } from "@/main";
import moment from 'moment-timezone';
import oper from '@/stores/Operations.js';

export default {
  data() {
    return {
      qReport: [],
      empList: [],
      empSelected: null,
      lastEmpSelected: null,
      optEmpList: {
        filter: true,
        placeholder: this.$i18n.t('followUps.empPHolder'),
        multipleWidth: 300,
        width: 300,
        dropWidth: 300,
        locale: localStorage.getItem("locale")
      },
      maxPcent: null,
      maxPcentOk: false,
      suggestion: "",
      action: "",
      actionsList: [ "Congratulate",
                     "Positive note in file",
                     "Nothing",
                     "Coaching",
                     "Verbal warning",
                     "Written warning 1",
                     "Written warning 2",
                     "Termination"],
      opt: {
        multiple: false,
        placeholder: this.$i18n.t('followUps.actionPHolder'),
        multipleWidth: 375,
        width: 375,
        dropWidth: 375,
        locale: localStorage.getItem("locale")
      },
      week: 338,
      start: null,
      startF: "",
      end: null,
      endF: "",
      supervisor: "",
      splitLines: false,
      comments: "",
      history: [],
      historyFields: [
        {
          key: 'week',
          label: this.$i18n.t('followUps.weekHist'),
          thStyle: { fontWeight: 'normal' },
          tdClass: 'boldHistory'
        },
        {
          key: 'maxPcent',
          label: this.$i18n.t('followUps.maxHist'),
          thStyle: { fontWeight: 'normal' },
          tdClass: 'boldHistory'
        },
        {
          key: 'empPcent',
          label: this.$i18n.t('followUps.empHist'),
          thStyle: { fontWeight: 'normal' },
          tdClass: 'boldHistory'
        },
        {
          key: 'suggest',
          label: this.$i18n.t('followUps.suggestHist'),
          thStyle: { fontWeight: 'normal' },
          tdClass: 'boldHistory'
        },
        {
          key: 'action',
          label: this.$i18n.t('followUps.actionHist'),
          thStyle: { fontWeight: 'normal' },
          tdClass: 'boldHistory'
        },
        {
          key: 'comments',
          label: this.$i18n.t('followUps.commHist'),
          thStyle: { fontWeight: 'normal' },
          tdClass: 'boldHistory'
        }
      ],
      schedList: [],
      schedsSelected: [],
      lastSchedsSelected: null,
      optScheds: {
        multiple: true,
        filter: true,
        placeholder: this.$i18n.t('followUps.schedsPHolder'),
        multipleWidth: 375,
        width: 375,
        dropWidth: 375,
        locale: localStorage.getItem("locale")
      },
      errors: [],
      msgColor: "red",
      showSpinner: false,
      sortType: "pcent",
      lastSortType: "pcent",
      offset: 0
    }
  },
  watch: {
    'sortType': function(val) {
      if( val !== 'undefined' ) {
        this.checkToDo();
      }
    },
    'schedsSelected': function(val) {
      if( val !== 'undefined' ) {
        this.checkToDo();
      }
    },
    'empSelected': function(val) {
      if( val == this.lastEmpSelected ) {
        return;
      }
      this.errors = [];
      if( this.needToSave() ) {
        const h = this.$createElement;
        const vNodesMsg = h(
          'div',
          [
            h('p', this.$i18n.t('followUps.askSaveEmp')),
            h('p', this.$i18n.t('followUps.employee') + this.qReport[this.lastEmpSelected][4]),
            h('p', this.$i18n.t('followUps.actionChosed') + this.action),
            h('p', this.$i18n.t('followUps.comment') + this.comments),
            h('p', this.$i18n.t('followUps.supervisor') + this.supervisor),
          ]
        );
        this.$bvModal.msgBoxConfirm([vNodesMsg], {
          okTitle: this.$i18n.t('yes'),
          cancelTitle: this.$i18n.t('no'),
        })
        .then(value => {
          if( value ) {
            this.empSelected = this.lastEmpSelected;
            let status = this.onSubmit();
            if( status === false )
              return;
            this.empSelected = val;
          }
          this.setSelectedEmp( val );
        })
      }
      else {
        this.setSelectedEmp( val );
      }
    }
  },
  methods: {
    needToSave() {
      if( this.lastEmpSelected === null )
        return false;
      let emp = this.qReport[this.lastEmpSelected];
      if( emp[24+this.offset] != this.action || emp[26+this.offset] != this.comments ) {
        return true;
      }
      return false;
    },
    setSelectedEmp( empIndex ) {
      this.lastEmpSelected = this.empSelected;
      if( empIndex !== 'undefined' ) {
        //console.log("----- empIndex = " + JSON.stringify(empIndex, null, 2));
        let emp = this.qReport[empIndex];
        this.suggestion = emp[23+this.offset];
        this.action = emp[24+this.offset];
        if( emp[25+this.offset] )
          this.supervisor = emp[25+this.offset];
        this.comments = emp[26+this.offset];
        this.getHistory( emp[2] );
      }
    },
    onSubmit() {
      this.errors = [];

      if( ! this.maxPcentOk ) {
        axiosInstance.post('/setmaxPcent', {week: this.week, maxPcent: Number(this.maxPcent)})
        .then((result) => {
          //console.log("----- setmaxPcent result = " + JSON.stringify(result.data, null, 2));
          this.qReport = result.data;
          this.maxPcent = this.qReport.shift();
          oper.setMaxPcent( this.maxPcent );

          this.checkToDo( true );
        })
        .catch(() => {
        });
      }
      else {
        if( this.empSelected == null )
          return;
        if( this.supervisor == "" ) {
          this.errors.push(this.$i18n.t('followUps.needSupervisor'));
          this.msgColor = "red";
          return false;
        }
        let emp = this.qReport[this.empSelected];
        emp[24+this.offset] = this.action;
        emp[25+this.offset] = this.supervisor;
        emp[26+this.offset] = this.comments;
        let followUps = {          
          empId: emp[2],
          week: this.week,
          maxPcent: Number(this.maxPcent),
          empPcent: emp[22+this.offset],
          suggest: emp[23+this.offset],
          action: emp[24+this.offset],
          manager: emp[25+this.offset],
          comments: emp[26+this.offset]
        }
        axiosInstance.post('/saveFollowUps', followUps)
        .then((result) => {
          //console.log("----- saveFollowUps result = " + JSON.stringify(result.data, null, 2));

          oper.storeQReport( this.qReport );

          this.getHistory( this.qReport[this.empSelected][2] );

          if( result.data == "error" )
            console.log("----- *********************** saveFollowUps error");
          this.errors.push(this.$i18n.t('followUps.savedOk'));
          this.msgColor = "green";
        })
        .catch(() => {
        });
      }
    },
    checkToDo( didSetMaxPcent = false ) {

      if( this.maxPcent == -1 ) {
        this.maxPcent = null;
        this.maxPcentOk = false;
      }
      else {
        this.maxPcentOk = true;
      }
      if( didSetMaxPcent ) {
        this.schedList.forEach((sched)=>{
          this.schedsSelected.push( sched.value );
        });
        this.$nextTick(function () {
          if( this.$refs.schedSelect)
            this.$refs.schedSelect.checkAll();
        })
      }
      if( this.schedsSelected.length == 0 && this.lastSchedsSelected === null ) {
        return;
      }
      if( this.lastSchedsSelected === null ) {
        this.lastSchedsSelected = this.schedsSelected;
      }
      if( this.empList.length == 0 ) {
        this.setEmpList();
      }
      // le premier ( || ) est pour le cas que les 2 arrays sont vides
      if( (this.schedsSelected.length !== 0 || this.lastSchedsSelected.length !== 0) && (this.schedsSelected.length !== this.lastSchedsSelected.length || this.schedsSelected.every((v, i) => v !== this.lastSchedsSelected[i])) ) {
        this.setEmpList();
        this.lastSchedsSelected = JSON.parse(JSON.stringify(this.schedsSelected));
      }
      if( this.sortType != this.lastSortType ) {
        this.setEmpList();
        this.lastSortType = JSON.parse(JSON.stringify(this.sortType));
      }
    },
    setEmpList() {
      this.empList = [];
      for( let i=0; i<this.qReport.length; i++ ) {
        let emp = this.qReport[i];
        if( this.schedsSelected.includes(emp[3]) ) {        
          this.empList.push( {
            pcent: Number(emp[22+this.offset]),
            text: emp[4] + ', ' + emp[2] + ', ' + emp[22+this.offset] + ' % of issues',
            value: i
          });
        }
      }
      if( this.sortType == "name" ) {
        this.empList.sort(function (a, b) {
          return a.text.toLowerCase().localeCompare(b.text.toLowerCase());
        });
      }
      else {
        this.empList.sort(function (a, b) {
          return a.pcent < b.pcent ? 1 : -1;
        });
      }
      let self = this;
      this.$nextTick(function () {
        if( self.$refs.empSelect ) {
          self.$refs.empSelect.refresh();
        }
      })
    },
    getHistory( empID ) {
      axiosInstance.post('/getHistory', {empID: empID})
      .then((result) => {
        //console.log("----- getHistory result = " + JSON.stringify(result.data, null, 2));
        result.data.sort(function (a, b) {
          return a.week < b.week ? 1 : -1;
        });
        this.history = result.data;
      })
      .catch(() => {
      });
    },
    clickGenerate() {
      this.errors = [];

      if( this.needToSave() ) {
        this.$bvModal.msgBoxConfirm(this.$i18n.t('followUps.askSaveEmp') + this.qReport[this.empSelected][4], {
          okTitle: this.$i18n.t('yes'),
          cancelTitle: this.$i18n.t('no'),
        })
        .then(value => {
          if( value ) {
            this.onSubmit();
          }
          this.generateReport();
        })
      }
      else {
        this.generateReport();
      }
    },
    generateReport() {
      let data = {
        locale: localStorage.getItem("locale"),
      }
      data.start = this.start;
      data.end = this.end;
      data.week = this.week;
      data.splitLines = this.splitLines;
      data.getQReport = true;
      data.useQData = true;

      let formData = new FormData();
      formData.append('data', JSON.stringify(data));

      this.showSpinner = true;

      axiosInstanceReports.post('/rep/qreport', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((result) => {
        console.log("----- /rep/ onSubmit ok = ");// + result.data);

        if( result.data != "error" ) {
          let path = result.data.split("/");
          axiosInstanceReports.get('/' + path[1] + '/' + path[2], {responseType: 'blob'})
          .then((response) => {

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', path[2]);
            document.body.appendChild(link);
            link.click();
            link.remove();

            this.showSpinner = false;
          });
        }
        else {
          this.$bvModal.msgBoxOk( this.$i18n.t('reports.errQC') );
          this.showSpinner = false;
        }
      }).catch(() => {
        console.log("----- /rep/ onSubmit error ");
        this.showSpinner = false;
      });
    },
    clickRetour() {
      if( this.needToSave() ) {
        this.$bvModal.msgBoxConfirm(this.$i18n.t('followUps.askSaveEmp') + this.qReport[this.empSelected][4], {
          okTitle: this.$i18n.t('yes'),
          cancelTitle: this.$i18n.t('no'),
        })
        .then(value => {
          if( value ) {
            this.onSubmit();
          }
          this.$router.replace("/reports");
        })
      }
      else {
        this.$router.replace("/reports");
      }
    }
  },
  mounted() {
    if( process.env.VUE_APP_CLIENT_NAME.startsWith('goodfoodon1') )
      this.offset = 14;
   if( process.env.VUE_APP_CLIENT_NAME.startsWith('goodfoodmontreal') )
      this.offset = -3;

    this.qReport = oper.getQReport();

    let qData = oper.getQData();
    this.week = qData.week;
    this.start = qData.start;
    this.startF = moment(this.start).format(this.$i18n.t('followUps.fDate'));
    this.end = qData.end;
    this.endF = moment(this.end).format(this.$i18n.t('followUps.fDate'));
    this.splitLines = qData.splitLines;

    this.maxPcent = oper.getMaxPcent();

    this.checkToDo();
    /*
    console.log("----- week = " + JSON.stringify(this.week, null, 2));
    console.log("----- start = " + JSON.stringify(this.start, null, 2));
    console.log("----- end = " + JSON.stringify(this.end, null, 2));
    console.log("----- maxPcent = " + JSON.stringify(this.maxPcent, null, 2));
    console.log("----- qReport = " + JSON.stringify(this.qReport, null, 2));
    */

    axiosInstance.get('/scheduleList', {"params": { "isActive": true }})
    .then(resp => {
        for( let i=0; i<resp.data.length; i++ ) {
          resp.data[i].text = resp.data[i].description;
          resp.data[i].value = resp.data[i].description;
        }
        //console.log("----- get /scheduleList = " + JSON.stringify(resp.data, null, 2));
        this.schedList = resp.data;

        this.$nextTick(function () {
          if( this.$refs.schedSelect)
            this.$refs.schedSelect.checkAll();
        })
    }).catch(err => {
        console.log("----- get /scheduleList error = " + JSON.stringify(err, null, 2));
    });
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/colors.scss';

form {
  background: White;
  padding: 2rem 2rem 1rem;
  width: 100%;
}

label {
  font-weight: bold;
}

</style>

<style>

.boldDropdown span {
  font-weight: bold;
}

.boldHistory {
  font-weight: bold;
}

</style>
