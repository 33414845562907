<template>
  <div class="g-page-container noSelect">

    <div class="g-pageHeader" style="height: 63px;">
      <h1 style="margin: auto;">{{$t('dashboard2.title')}}</h1>
    </div>

    <div class="g-pageBody gvertical-center">

      <div v-if="! inInit">
        <div v-for="(table, index) in allTables" :key="index">
          <b-table
            :items="table"
            :fields="showFields"
            :tbody-tr-class="rowClass" >
            <template #table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{ width: field.key === 'col0' ? '160px' : '170px' }"
              >
            </template>
          </b-table>
        </div>
      </div>

    </div>

    <div class="g-pageBody" style="margin-top: 20px;">

      <div class="row">

        <div class="col-md-10">
          <div class="form-group">
            <label>{{$t('dashboard2.lineSelect')}}</label>
            <div>
              <MultipleSelect
                ref="lineSelect"
                :data="lineSelect"
                v-model="lineResult"
                multiple
                @change="lineSelectChange"
                :options="opt" >
              </MultipleSelect>
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <button class="btn btn-perfcell float-right marginTop10px marginRight10px" @click="clickClose">
            {{ $t('dashboard2.close' ) }}
          </button>
        </div>

     </div>

    </div>

  </div>
</template>

<script>
import EventBus from '@/eventBus'
import oper from '@/stores/Operations.js';
import { axiosInstance } from "@/main";
import globals from '@/globals.js';

export default {
  data() {
    return {
      numColumns: 4, // see 'hardcoded' in comments if changing that value
      rows: [], // each row have this.numColumns

      allTables: [],

      /* test data
      allTables: [
        [
          {
            col0: "",
            col1: "line 1",
            col2: "line 2",
            col3: "line 3",
            col4: "line 4"
          },
          {
            col0: "01-MK-Box & Liner",
            col1: "ijiji ijijiji",
            col2: "uhuh uhuhu huh",
            col3: "",
            col4: ""
          },
          {
            col0: "02-MK-Box & Liner",
            col1: "",
            col2: "uhuh uhuhu huh",
            col3: "dsfsdf sdf df",
            col4: "dsfsdf sdf df"
          },
          {
            col0: "03-MK-Box & Liner",
            col1: "idsfsdf sdf ji",
            col2: "rt ert ert ert",
            col3: "",
            col4: "erter ert ter"
          },
          {
            col0: "04-MK-Box & Liner",
            col1: "",
            col2: "uhuh uhuhu huh",
            col3: "fgh fgh fgh",
            col4: "cvcv cvcvvv"
          },
          {
            col0: "Total",
            col1: "3",
            col2: "3",
            col3: "4",
            col4: "2"
          }
        ],
        [
          {
            col0: "",
            col1: "line 5",
            col2: "",
            col3: "",
            col4: ""
          },
          {
            col0: "01-MK-Box & Liner",
            col1: "ijiji ijijiji",
            col2: "",
            col3: "",
            col4: ""
          },
          {
            col0: "02-MK-Box & Liner",
            col1: "dsfsdf sdf df",
            col2: "",
            col3: "",
            col4: ""
          },
          {
            col0: "03-MK-Box & Liner",
            col1: "idsfsdf sdf ji",
            col2: "",
            col3: "",
            col4: ""
          },
          {
            col0: "04-MK-Box & Liner",
            col1: "cvcv cvcvvv",
            col2: "",
            col3: "",
            col4: ""
          },
          {
            col0: "Total",
            col1: "3",
            col2: "",
            col3: "",
            col4: ""
          }
        ],
        [
          {
            col0: "Big Total",
            col1: "7",
            col2: "",
            col3: "",
            col4: ""
          }
        ]
      ],
      */
      showFields: [
        { key: 'col0',
          label: "",
          tdClass: 'styleForAll',
          thClass: 'styleForHeader'
        },
        { key: 'col1',
          label: "",
          tdClass: 'styleForAll',
          thClass: 'styleForHeader'
        },
        { key: 'col2',
          label: "",
          tdClass: 'styleForAll',
          thClass: 'styleForHeader'
        },
        { key: 'col3',
          label: "",
          tdClass: 'styleForAll',
          thClass: 'styleForHeader'
        },
        { key: 'col4', // hardcoded
          label: "",
          tdClass: 'styleForAll',
          thClass: 'styleForHeader'
        }
      ],


      empList: [], // list of all employees currently working
      empList2: [], // list of all employees currently working on lines in this.lineResult

      lineSelect: [],
      lineResult: [],
      opt: {
        multiple: true,
        filter: true,
        placeholder: this.$i18n.t('dashboard2.linesPHolder'),
        multipleWidth: 250,
        width: 250,
        dropWidth: 250,
        position: 'top',
        locale: localStorage.getItem("locale")
      },
      inInit: true
    }
  },
  methods: {
    rowClass(item, type) {
      if( ! item || type !== 'row' )
        return;
      //console.log('----- item ' + JSON.stringify(item, null, 2));

      if( item.col0 == "" )
        return 'colorBackLine';
      if( item.col0 == "Total" )
        return 'colorTotal';
      if( item.col0 == "Grand Total" )
        return 'colorGrandTotal';
      return '';
    },
    lineSelectChange() {
      if( ! this.inInit )
        this.rebuilsAll();
    },
    clickClose() {
      oper.dashboard( false );
      this.$router.replace("/operation");
    },
    newOrChangeAct( act ) {
      // remove all from this line

      for( let i=this.empList.length-1; i>=0; i-- ) {
        if( this.empList[i].lineId == act.line._id )
          this.empList.splice( i, 1 );
      }
      // add all emp from this line
      let self = this;
      act.team.forEach(function (emp) {
        let taskId = emp.taskId;
        let taskName = emp.taskName;
        if( taskId == undefined ) {
          taskId = " ";
          taskName = " ";
        }
        self.empList.push( {
          lineId: act.line._id,
          lineName: act.line.name,
          empId: emp.emp._id,
          empName: emp.emp.firstName + " " + emp.emp.lastName + " " + emp.emp.alias,
          taskId: taskId,
          taskName: taskName
        });
      });
    },
    rebuilsAll() {
      //console.log('----- empList ' + JSON.stringify(this.empList, null, 2));
      let self = this;

      // create and sort the list of all employees working on the selected lines
      self.empList2 = [];
      self.empList.forEach(function (emp) {
        if( self.lineResult.includes( emp.lineId ) )
          self.empList2.push( emp );
      });
      self.empList2.sort(function (a, b) {
        // sort by lineName
        let sort = a.lineName.toLowerCase().localeCompare(b.lineName.toLowerCase());
        if( sort != 0 )
          return sort;
        // sort by taskName inside each line
        sort = a.taskName.toLowerCase().localeCompare(b.taskName.toLowerCase());
        if( sort != 0 )
          return sort;
        // sort by empName for same task
        return a.empName.toLowerCase().localeCompare(b.empName.toLowerCase());
      });
      //console.log('----- empList2 ' + JSON.stringify(self.empList2, null, 2));

      // arrange the list of employees in a row/col array, each row/col is a line
      let line = self.empList2[0].lineId;
      let rowIndex = 0;
      let colIndex = 0;
      self.rows = [[[]]];
      self.empList2.forEach(function (emp) {
        if( emp.lineId != line ) {
          colIndex++;
          if( colIndex == self.numColumns ) {
            colIndex = 0;
            rowIndex++;
            self.rows.push( [] );
          }
          self.rows[rowIndex].push( [] );
          line = emp.lineId;
        }
        self.rows[rowIndex][colIndex].push( emp );
      });
      //console.log('----- rows ' + JSON.stringify(self.rows, null, 2));

      // transform the row/col array into display format
      self.allTables = [];

      // la ligne du total de tout les tableau
      let grandTotal = 0;

      self.rows.forEach(function (row) {
        self.allTables.push( [] );
        let currentTable = self.allTables[self.allTables.length-1];

        // get all tasks for this row
        let taskList = [];
        row.forEach(function (col) {
          col.forEach(function (emp) {
            if( ! taskList.includes( emp.taskName ) )
              taskList.push( emp.taskName );
          });
        });
        taskList.sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
        //console.log('----- taskList ' + JSON.stringify(taskList, null, 2));

        // nombre de lignes dans la table =
        //   1 for the lines header
        //   1 for each task
        //   1 for the total
        let numLignes = 1 + taskList.length + 1;
        for( let i=0; i<numLignes; i++ ) {
          currentTable.push( {
            col0: "",
            col1: "",
            col2: "",
            col3: "",
            col4: "" // hardcoded
          });
        }

        // ----- remplir chaque ligne avec les bonnes infos

        // utilisé pour remplir la dernière ligne à la fin
        let totals = [];
        for( let i=0; i<self.numColumns; i++ ) {
          totals.push( 0 );
        }
        // la dernieres ligne de la table
        currentTable[numLignes-1].col0 = "Total";
        // le "header" de la table
        for( let i=0; i<row.length; i++ ) {
          let rowName = "col" + (i+1).toString();
          currentTable[0][rowName] = row[i][0].lineName;
        }
        // les lignes des tasks
        for( let i=0, ligne=1; i<taskList.length; i++, ligne++ ) {
          currentTable[i+1].col0 = taskList[i];
          for( let colIndex=0; colIndex<row.length; colIndex++ ) {
            row[colIndex].forEach(function (emp) {
              if( emp.taskName == taskList[i] ) {
                let colName = "col" + (colIndex+1).toString();
                if( currentTable[i+1][colName] == "" )
                  currentTable[i+1][colName] = emp.empName;
                else
                  currentTable[i+1][colName] = currentTable[i+1][colName] + ", " + emp.empName;
                totals[colIndex] ++;
                grandTotal ++;
              }
            });
          }
        }
        // ligne des totals
        for( let i=0; i<row.length; i++ ) {
          let colName = "col" + (i+1).toString();
          currentTable[numLignes-1][colName] = totals[i].toString();
        }
        //console.log('----- allTables ' + JSON.stringify(self.allTables, null, 2));
      });

      // la ligne du grand total
      self.allTables.push( [{
        col0: "Grand Total",
        col1: grandTotal.toString(),
        col2: "",
        col3: "",
        col4: "" // hardcoded
      }]);
    },
    actDashboard2( act ) {
      //console.log('----- actDashboard ' + JSON.stringify(act, null, 2));

      this.newOrChangeAct( act );
      if( ! this.inInit )
        this.rebuilsAll();
    },
    actUpdateDashboard2( act ) {
      //console.log('----- actUpdateDashboard ' + JSON.stringify(act, null, 2));

      this.newOrChangeAct( act );
      if( ! this.inInit )
        this.rebuilsAll();
    },
    dashboard2StateUpdate( data ) {
      //console.log('----- dashboardStateUpdate ' + JSON.stringify(data, null, 2));
      if( data.state == globals.STATENOT ) {
        // remove all from this line
        for( let i=this.empList.length-1; i>=0; i-- ) {
          if( this.empList[i].lineId == data.lineId )
            this.empList.splice( i, 1 );
        }
      }
    }
  },
  mounted() {
    oper.dashboard( true );

    axiosInstance.get('/getdashboard')
    .then(() => {
    });
    axiosInstance.get('/lineList', {"params": { "isActive": true }})
    .then(resp => {
      for( let i=0; i<resp.data.length; i++ ) {
        resp.data[i].text = resp.data[i].name + ' - ' + resp.data[i].description;
        resp.data[i].value = resp.data[i]._id;
      }
      //console.log("----- get /lineList = " + JSON.stringify(resp.data, null, 2));
      this.lineSelect = resp.data;
      let self = this;
      this.$nextTick(function () {
        self.$refs.lineSelect.checkAll();
        self.inInit = false;
        self.rebuilsAll();
      })
    });

    EventBus.$on('actDashboard', this.actDashboard2);
    EventBus.$on('actUpdateDashboard', this.actUpdateDashboard2);
    EventBus.$on('stateDashboard', this.dashboard2StateUpdate);
  },
  beforeDestroy() {
    EventBus.$off('actDashboard', this.actDashboard2);
    EventBus.$off('actUpdateDashboard', this.actUpdateDashboard2);
    EventBus.$off('stateDashboard', this.dashboard2StateUpdate);
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/global.scss';

.colorBackLine {
  font-weight: bold;
  font-size: 20px;
  background-color: #d1dec8;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.colorTotal {
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.colorGrandTotal {
  font-weight: bold;
  font-size: 22px;
  background-color: #c8dade;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.styleForAll {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.styleForHeader {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  height: 0;
}


</style>
