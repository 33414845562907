<template>
  <div class="g-page-container">

    <div class="g-pageHeader" style="height: 95px;">
      <h1 style="margin: auto;">{{$t('biCharts.title')}}</h1>
      <h2 style="margin: auto;">{{$t('biChartsDP.title')}}</h2>
    </div>

    <div class="g-pageBody">

      <b-row>
        <b-col cols="12">
          <div class="form-group oneElement">
            <label class="marginLeft10px">{{$t('biCharts.from')}}</label><br>
            <input class="marginLeft10px marginBottom10px" v-model="reportFrom" type="date" @input="dateChange">
          </div>

          <div class="form-group oneElement">
            <label class="marginLeft15px">{{$t('biCharts.to')}}</label><br>
            <input class="marginLeft15px" v-model="reportTo" type="date" @input="dateChange">
          </div>

          <div class="form-group oneElement">
            <label class="marginLeft15px">{{$t('biCharts.selectLine')}}</label><br>
            <MultipleSelect
              class="marginLeft15px"
              :data="lineList"
              v-model="lineResult"
              :options="getOptions('line')"
              @change="listChange('line')" >
            </MultipleSelect>
          </div>

          <div class="form-group oneElement">
            <label class="marginLeft15px">{{$t('biCharts.selectSched')}}</label><br>
            <MultipleSelect
              class="marginLeft15px"
              :data="schedList"
              v-model="schedResult"
              :options="getOptions('sched')"
              @change="listChange('sched')" >
            </MultipleSelect>
          </div>

          <div class="oneElement">
            <button class="btn btn-perfcell marginTop20px marginLeft15px" @click="clickGenerate">{{$t('biCharts.generate')}}</button>
          </div>
          <div v-if="chartBase64" class="oneElement">
            <button class="btn btn-perfcell marginTop20px marginLeft15px" @click="clickExport">{{$t('biCharts.export')}}</button>
          </div>
          <div v-if="chartBase64" class="oneElement">
            <button class="btn btn-perfcell marginTop20px marginLeft15px" @click="clickPdf">{{$t('biChartsDP.exportPdf')}}</button>
          </div>
          <div class="oneElement">
            <button class="btn btn-perfcell marginTop20px marginLeft15px" @click="clickRetour">{{$t('biCharts.cancel')}}</button>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <label v-if="error" class="error">{{error}}</label>
        </b-col>
      </b-row>

    </div>

    <b-row>
      <b-col cols="6">
        <div class="g-pageBody" :style="{height: tableHeight}">
          <b-table
            small
            fixed
            striped
            :sticky-header="tableHeight"
            :items="chartData"
            :fields="showFields" >
          </b-table>
        </div>
      </b-col>
      <b-col cols="6" style="text-align: center; padding-left: 0;">
        <div class="g-pageBody">

          <b-row class="g-chart-header" style="display: inline-block;">
            {{$t('biChartsDP.chartTitle')}}
          </b-row>

          <div ref="chartContainer">
            <canvas id="theDailyProdChart" :style="{width: chartWidth + 'px', height: chartHeight + 'px'}"></canvas>
          </div>

        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { axiosInstance } from "@/main";
import { axiosInstanceReports } from "@/main";
import moment from 'moment-timezone';
import { json2excel } from 'js2excel';
import EventBus from '@/eventBus';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import oper from '@/stores/Operations.js';

export default {
  data() {
    return {
      reportFrom: '',
      reportTo: '',
      error: '',

      lineList: [],
      schedList: [],

      lineResult: [],
      schedResult: [],

      chartData: [],

      chartWidth: 300,
      chartHeight: 300,
      tableHeight: '350px',

      Totalizer: null,
      ctx: null,

      doingResize: false,

      getPdf: false,
      chartBase64: "",
      widthBase64: 300,
      heightBase64: 300,

      dateFormat: "MM/DD/YYYY"
    }
  },
  components: {
  },
  computed: {
    showFields() {
      return [
        {
          key: this.$i18n.t('biChartsDP.headerDate'),
          thStyle: { width: '90px'}
        },
        {
          key: this.$i18n.t('biChartsDP.headerTeam'),
          thStyle: { width: '250px'}
        },
        {
          key: this.$i18n.t('biChartsDP.headerNbTeam'),
          thStyle: { width: '80px'}
        },
        {
          key: this.$i18n.t('biChartsDP.headerRegular'),
          thStyle: { width: '80px'}
        },
        {
          key: this.$i18n.t('biChartsDP.headerOther'),
          thStyle: { width: '80px'}
        },
        {
          key: this.$i18n.t('biChartsDP.headerReject'),
          thStyle: { width: '80px'}
        }
      ]
    }
  },
  methods: {
    dateChange() {
      this.error = '';
    },
    listChange( name ) {
      console.log("----- listChange = " + name);
      this.error = '';
    },
    getOptions(i) {
      let opt = {
        multiple: false,
        filter: true,
        placeholder: '',
        multipleWidth: 200,
        width: 200,
        dropWidth: 200,
        maxHeight: this.chartHeight,
        locale: localStorage.getItem("locale")
      }
      if( i == 'line' ) opt.placeholder = this.$i18n.t('biCharts.linePHolder');
      if( i == 'sched' ) opt.placeholder = this.$i18n.t('biCharts.schedPHolder');
      return opt;
    },
    clickPdf() {
      this.getPdf = true;
      this.clickGenerate();
    },
    clickGenerate() {
      if( moment(this.reportFrom).isAfter(moment(this.reportTo)) ) {
        this.error = this.$i18n.t('biCharts.before');
        return;
      }
      let maxDays = 365;
      if( moment(this.reportTo).diff(this.reportFrom, 'days') > maxDays ) {
        this.error = maxDays.toString() + this.$i18n.t('biCharts.nbDays');
        return;
      }
      if( this.lineResult.length == 0 ) {
        this.error = this.$i18n.t('biCharts.noLine');
        return;
      }
      if( this.schedResult.length == 0 ) {
        this.error = this.$i18n.t('biCharts.noSched');
        return;
      }
      let data = {
        locale: localStorage.getItem("locale"),
        start: moment(this.reportFrom),
        end: moment(this.reportTo),
        lineData: this.lineResult,
        schedData: this.schedResult
      }
      if( this.getPdf ) {
        data.chartBase64 = this.chartBase64;
        data.widthBase64 = this.widthBase64;
        data.heightBase64 = this.heightBase64;
        for( let i=0; i<this.lineList.length; i++ ) {
          if( this.lineList[i]._id == this.lineResult[0] ) {
            data.lineName = this.lineList[i].name;
            break;
          }
        }
        for( let i=0; i<this.schedList.length; i++ ) {
          if( this.schedList[i]._id == this.schedResult[0] ) {
            data.schedName = this.schedList[i].description;
            break;
          }
        }
      }
      axiosInstanceReports.post('/rep/dailyprodchart', data )
      .then((result) => {
        console.log("----- /rep/ onSubmit ok = ");// + JSON.stringify(result.data, null, 2));

        if( this.getPdf ) {
          this.getPdf = false;
          let path = result.data.split("/");
          axiosInstanceReports.get('/' + path[1] + '/' + path[2], {responseType: 'blob'})
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', path[2]);
            document.body.appendChild(link);
            link.click();
            link.remove();
          });
        }
        else {
          let categories = [];
          let data0 = [];
          let data1 = [];
          let data2 = [];
          let data3 = [];
          let totals = [];
          this.chartData = [];

          let lineName = "";
          for( let i=0; i<this.lineList.length; i++ ) {
            if( this.lineList[i]._id == this.lineResult[0] ) {
              lineName = this.lineList[i].name;
              break;
            }
          }
          let schedeName = "";
          for( let i=0; i<this.schedList.length; i++ ) {
            if( this.schedList[i]._id == this.schedResult[0] ) {
              schedeName = this.schedList[i].description;
              break;
            }
          }

          for( let i=0; i<result.data.length; i++ ) {
            let day = result.data[i];

            if( day.avTeam == 0 )
              continue;

            day.date = moment(day.date).format(this.dateFormat);
            day.avTeam = (Math.round(day.avTeam * 10) / 10).toFixed(1);

            categories.push( day.date );
            data0.push( day.avTeam );
            data1.push( day.regUnits );
            data2.push( day.otherUnits );
            data3.push( day.rejectUnits );
            totals.push( day.regUnits + day.otherUnits + day.rejectUnits );

            let entry = {};
            entry[this.$i18n.t('biChartsDP.headerPlant')] = process.env.VUE_APP_CLIENT_NAME;
            entry[this.$i18n.t('biChartsDP.headerLine')] = lineName;
            entry[this.$i18n.t('biChartsDP.headerSched')] = schedeName;
            entry[this.$i18n.t('biChartsDP.headerDate')] = day.date;
            entry[this.$i18n.t('biChartsDP.headerTeam')] = day.team;
            entry[this.$i18n.t('biChartsDP.headerNbTeam')] = day.avTeam;
            entry[this.$i18n.t('biChartsDP.headerRegular')] = day.regUnits;
            entry[this.$i18n.t('biChartsDP.headerOther')] = day.otherUnits;
            entry[this.$i18n.t('biChartsDP.headerReject')] = day.rejectUnits;
            this.chartData.push( entry );
          }
          //console.log("----- this.chartData = " + JSON.stringify(this.chartData, null, 2));

          let self = this;
          new Chart( this.ctx, {
            plugins: [ChartDataLabels, this.Totalizer],
            type: 'bar',
            data: {
              labels: categories,
              datasets: [
                {
                  type: 'line',
                  borderColor: 'rgba(100,100,100,1.0)',
                  pointBackgroundColor: 'rgba(100,100,100,1.0)',
                  pointBorderColor: 'rgba(100,100,100,1.0)',
                  pointRadius: 5,
                  label: this.$i18n.t('biChartsDP.headerNbTeam'),
                  data: data0,
                  fill: false,
                  yAxisID: '2ndAxis'
                },
                {
                  type: 'bar',
                  backgroundColor: 'rgba(68,114,196,1.0)',
                  label: this.$i18n.t('biChartsDP.headerRegular'),
                  data: data1,
                  stack: 'Stack 0'
                },
                {
                  type: 'bar',
                  backgroundColor: 'rgba(44,206,101,1.0)',
                  label: this.$i18n.t('biChartsDP.headerOther'),
                  data: data2,
                  stack: 'Stack 0'
                },
                {
                  type: 'bar',
                  backgroundColor: 'rgba(237,125,49,1.0)',
                  label: this.$i18n.t('biChartsDP.headerReject'),
                  data: data3,
                  stack: 'Stack 0'
                }
              ]
            },
            options: {
              tooltips: {
                   enabled: false
              },
              events: ['click'],
              lineTension: 1,
              animation: {
                onComplete: self.doneChart,
              },
              plugins: {
                datalabels: {
                  formatter: (value, ctx) => {
                    const total = ctx.chart.$Totalizer.totals[ctx.dataIndex];
                    return total;
                  },
                  align: 'end',
                  anchor: 'end',
                  display: function(ctx) {
                    return ctx.datasetIndex === ctx.chart.$Totalizer.utmost;
                  }
                }
              },
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true,
                    suggestedMax: Math.round(Math.max(...totals) * 1.1)
                  },
                  stacked: true
                }, {
                  id: '2ndAxis',
                  position: 'right',
                  ticks: {
                    beginAtZero: true,
                    //suggestedMax: Math.max(...data0) + 1
                  }
                }]
              }
            }
          });
        }
      }).catch(() => {
        console.log("----- /rep/ onSubmit error ");
      });
    },
    clickExport() {
      json2excel({
        data: this.chartData,
        name: 'DailyProdTable'
      });
    },
    doneChart() {
      this.chartBase64 = this.ctx.toDataURL();
      this.widthBase64 = this.chartWidth;
      this.heightBase64 = this.chartHeight;

      //console.log("----- this.chartBase64 = " + this.chartBase64);
    },
    clickRetour() {
      this.$router.replace("/config");
    },
    chartUpdateFactory( data ) {
      this.dateFormat = data.dateFormat;
    },
    redimChart: function() {
      if( this.doingResize )
        return
      this.doingResize = true;
      let self = this;
      setTimeout(function() {
        self.chartWidth = self.$refs.chartContainer.clientWidth;
        self.chartHeight = Math.round(self.chartWidth * 0.7);
        self.tableHeight = (self.chartHeight + 46) + 'px';
        //console.log("----- tableHeight = " + self.tableHeight);
        self.doingResize = false;
      }
      , 100);
    }
  },
  mounted() {
    this.dateFormat = oper.getFormatData();

    this.reportFrom = moment().format(this.dateFormat);
    this.reportTo = moment().format(this.dateFormat);

    axiosInstance.get('/lineList', {"params": { "isActive": true }})
    .then(resp => {
        for( let i=0; i<resp.data.length; i++ ) {
          resp.data[i].text = resp.data[i].name + ' - ' + resp.data[i].description;
          resp.data[i].value = resp.data[i]._id;
        }
        //console.log("----- get /lineList = " + JSON.stringify(resp.data, null, 2));
        let list = JSON.stringify(resp.data);
        this.lineList = JSON.parse(list);
        this.lineResult = [];
    }).catch(err => {
        console.log("----- get /lineList error = " + JSON.stringify(err, null, 2));
    });

    axiosInstance.get('/scheduleList', {"params": { "isActive": true }})
    .then(resp => {
        for( let i=0; i<resp.data.length; i++ ) {
          resp.data[i].text = resp.data[i].description;
          resp.data[i].value = resp.data[i]._id;
        }
        //console.log("----- get /scheduleList = " + JSON.stringify(resp.data, null, 2));
        let list = JSON.stringify(resp.data);
        this.schedList = JSON.parse(list);
        this.schedResult = [];
    }).catch(err => {
        console.log("----- get /scheduleList error = " + JSON.stringify(err, null, 2));
    });

    this.Totalizer = {
      id: 'Totalizer',
      beforeUpdate: chart => {
        let totals = {}
        let utmost = 0
        chart.data.datasets.forEach((dataset, datasetIndex) => {
          if( datasetIndex > 0 && chart.isDatasetVisible(datasetIndex) ) {
            utmost = datasetIndex
            dataset.data.forEach((value, index) => {
              totals[index] = (totals[index] || 0) + value
            })
          }
        })
        chart.$Totalizer = {
          totals: totals,
          utmost: utmost
        }
      }
    }
    this.ctx = document.getElementById('theDailyProdChart');

    this.redimChart();
    EventBus.$on('mainViewDim', this.redimChart);
    EventBus.$on('factory', this.chartUpdateFactory);
  },
  beforeDestroy(){
    EventBus.$off('mainViewDim', this.redimChart);
    EventBus.$off('factory', this.chartUpdateFactory);
  }
}
</script>

<style lang="scss">

.error {
  margin-left: 15px;
  color: red;
}

.oneElement {
  display: table-cell;
}

</style>
