<template>
  <div class="g-page-container">

    <div class="centerOnPage">
      <div v-if="select == 1">
        <h4>{{$t('serverDown.ligne0')}}</h4>
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <div v-else>
        <h3>{{$t('serverDown.ligne1')}}</h3>
        <h4>{{$t('serverDown.ligne2')}}</h4>
        <h4>{{$t('serverDown.ligne3')}}</h4>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  data() {
    return {
      select: '1'
    };
  },
  methods: {
  },
  created() {
    this.select = this.$route.params.select;
    //console.log("----- this.select = " + JSON.stringify(this.select, null, 2));
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/form.scss';

.centerOnPage {
  width: 600px;
  height: 200px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
}
</style>
