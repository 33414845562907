<template>
  <div>
    <div style="margin-top: 10px">
      <b-pagination
        v-if="pages > 1"
        v-model="currentPage"
        size="sm"
        ref="pagination"
        :total-rows="pages"
        :per-page="1"
        :prev-text="$t('cleaningFactorList.footer.previousPage')"
        :next-text="$t('cleaningFactorList.footer.nextPage')"
      />
    </div>
    <button class="btn btn-perfcell float-left marginTop20px" @click="handleReturnClick">{{ $t("cleaningFactorList.footer.cancel") }}</button>
    <button class="btn btn-perfcell float-left marginLeft10px marginTop20px" @click="handleNewCleaningFactoryClick">{{ $t("cleaningFactorList.footer.new") }}</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPage: 1,
    };
  },

  methods: {
    handleReturnClick() {
      this.$router.replace("/config");
    },
    handleNewCleaningFactoryClick() {
      this.$router.replace("/config/cleaning-factor/new");
    },
  },

  props: {
    pages: {
      type: Number,
      default: 1,
    },
  },

  watch: {
    currentPage(value, prevValue) {
      if (prevValue !== value) this.$emit("changePage", value);
    },
  },
};
</script>

<style></style>
