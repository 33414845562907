import { render, staticRenderFns } from "./ConfigAndon.vue?vue&type=template&id=079f96ec&scoped=true&"
import script from "./ConfigAndon.vue?vue&type=script&lang=js&"
export * from "./ConfigAndon.vue?vue&type=script&lang=js&"
import style0 from "./ConfigAndon.vue?vue&type=style&index=0&id=079f96ec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "079f96ec",
  null
  
)

export default component.exports