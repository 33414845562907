import { render, staticRenderFns } from "./Quantity-produced.vue?vue&type=template&id=0b68a604&scoped=true&"
import script from "./Quantity-produced.vue?vue&type=script&lang=js&"
export * from "./Quantity-produced.vue?vue&type=script&lang=js&"
import style0 from "./Quantity-produced.vue?vue&type=style&index=0&id=0b68a604&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b68a604",
  null
  
)

export default component.exports