<template>
  <div class="g-page-container">

    <div class="g-pageHeader" style="height: 63px;">
      <h1 style="margin: auto;">{{$t('configStd.title')}}</h1>
    </div>

    <form class="g-pageBody" @submit.prevent="onSubmit">

      <div class="form-group">
        <label for="inputPart">{{$t('configStd.partNumber')}}</label>
        <input
          type="text"
          v-model="std.partNumber"
          class="form-control"
          id="inputPart"
          :placeholder="$t('configStd.partPHolder')">
      </div>

      <div class="form-group">
        <label for="inputDescription">{{$t('configStd.desc')}}</label>
        <input
          type="text"
          v-model="std.description"
          class="form-control"
          id="inputDescription"
          :placeholder="$t('configStd.descPHolder')">
      </div>

      <div class="form-group">
        <label for="SAM">{{$t('configStd.SAM')}}</label>
        <div id="SAM" class="display: table">
          <input v-model="hoursSAM" type="number" min="0" max="23" class="form-control; display: table-cell; narrow">
          <label class="giveMeRoomToBreathe">{{$t('configStd.hour')}}</label>
          <input v-model="minSAM" type="number" min="0" max="59" class="form-control; display: table-cell; narrow">
          <label class="giveMeRoomToBreathe">{{$t('configStd.min')}}</label>
          <input v-model="secSAM" type="number" min="0" max="59.9" step="0.1" class="form-control; display: table-cell; narrow">
          <label class="giveMeRoomToBreathe">{{$t('configStd.sec')}}</label>
        </div>
      </div>
  <div class="form-group">
        <label for="inputLines">{{$t('configStd.linesHeading')}}</label>
        <div id="inputLines">
          <MultipleSelect
            :data="lineList"
            v-model="std.lineList"
            multiple            
            :options="optList" >
          </MultipleSelect>
        </div>
      </div>
      <div class="row">
        <div class="col-md-7">
          <div class="form-group">
            <label for="category">{{$t('configStd.category')}}</label>
            <div id="category">
              <MultipleSelect
                :data="catList"
                v-model="std.category"
                :options="opt" >
              </MultipleSelect>
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="form-group">
            <label for="toProduce">{{$t('configStd.toProduce')}}</label>
            <br>
            <input v-model="std.qteToProduce" id="toProduce" type="number" min="0" max="999999" :disabled="std.qteToProduceHasEdited">
            <br>
            <span class="no-changes-allowed">{{ $t('configStd.noChangesAllowed') }}</span>
          </div>
        </div>
      </div>

      <div class="form-group">
        <input type="checkbox" id="effNoTime" v-model="std.useEfficiencyNoTime">
        <label class="checkboxLabel" for="effNoTime">{{$t('configStd.effNoTime')}}</label>
      </div>

      <div class="form-group">
        <input type="checkbox" id="isActive" v-model="std.isActive">
        <label class="checkboxLabel" for="isActive">{{$t('configStd.active')}}</label>
      </div>

      <button class="btn btn-perfcell float-left marginTop50px" @click.prevent="clickRetour">
        {{ $t('configStd.cancel' ) }}
      </button>

      <button class="btn btn-perfcell float-left marginTop50px marginLeft10px" type="submit">
        {{ $t('configStd.submit' ) }}
      </button>

      <p v-if="errors.length" class="float-left marginTop50px marginLeft30px" :style="{ color: msgColor }">
        <b v-if="msgColor == 'red'">{{$tc('configStd.error', errors.length)}}</b>
        <ul>
          <li v-for="error in errors" :key="error">{{ error }}</li>
        </ul>
      </p>

    </form>

  </div>
</template>

<script>
import { axiosInstance } from "@/main";

export default {
  data() {
    return {
      std: {
        _id: '',
        partNumber: '',
        description: '',
        SAM: '',
        useEfficiencyNoTime: false,
        category: '',
        catName: '',
        prodType: 0,
        isActive: true,
        clonedFrom: '',
        lineList: [],
        qteToProduceHasEdited: false,
        qteToProduce: 0
      },
      lineList: [],
      hoursSAM: 0,
      minSAM: 0,
      secSAM: 0,
      catList: [],
      opt: {
        multiple: false,
        filter: true,
        placeholder: this.$i18n.t('configStd.catPHolder'),
        multipleWidth: 375,
        width: 375,
        dropWidth: 375,
        locale: localStorage.getItem("locale")
      },
      optList: {
        multiple: true,
        filter: true,
        placeholder: this.$i18n.t('configStd.linesPH'),
        multipleWidth: 500,
        width: 500,
        dropWidth: 500,
        locale: localStorage.getItem("locale")
      },
      id: '',
      clone: false,
      errors: [],
      msgColor: "red"
    }
  },
  methods: {
    onSubmit() {
      if( ! this.validate() )
        return;
      axiosInstance.post('/standard/' + this.id, this.std)
      .then((resp) => {
        //console.log("----- onSubmit ok = " + JSON.stringify(resp.data, null, 2));

        if( resp.data == "partNumberExist" ) {
          this.errors = [];
          this.msgColor = "red";
          this.errors.push(this.$i18n.t('configStd.partExist'));
        }
        else {
          this.std = resp.data;
          this.id = this.std._id;
          this.errors.push(this.$i18n.t('configStd.success'));
          this.msgColor = "green";
        }
      }).catch((err) => {
        console.log("----- onSubmit error = " + JSON.stringify(err, null, 2));
      });
    },
    validate() {
      this.errors = [];
      this.msgColor = "red";

      if( this.std.partNumber.length < 3 )
        this.errors.push(this.$i18n.t('configStd.partLength'));

      if( this.std.category == '' ) {
        this.errors.push(this.$i18n.t('configStd.missingCat'));
      }
      else {
        let i;
        for( i=0; i<this.catList.length; i++ )
          if( this.catList[i]._id.toString() == this.std.category.toString() )
            break;
        if( i < this.catList.length ) {
          this.std.prodType = this.catList[i].prodType;
          this.std.catName = this.catList[i].name;
        }
        else
          this.errors.push(this.$i18n.t('configStd.missingCat'));
      }

      this.std.SAM = parseInt(this.hoursSAM) * 3600 + parseInt(this.minSAM) * 60 + parseFloat(this.secSAM);
      if( this.std.SAM > 86399.9 ) // max SAM = 24 heures moins .1 seconde
        this.errors.push(this.$i18n.t('configStd.bigSAM'));
      if( this.std.SAM <= 0 ) // min SAM = .1 seconde
        this.errors.push(this.$i18n.t('configStd.smallSAM'));

      return ! this.errors.length;
    },
    clickRetour() {
      this.$router.replace("/configStandardsList");
    }
  },
  mounted() {
    axiosInstance.get('/lineList', {"params": { "isActive": true }})
    .then(resp => {
        for( let i=0; i<resp.data.length; i++ ) {
          resp.data[i].text = resp.data[i].name + ' - ' + resp.data[i].description;
          resp.data[i].value = resp.data[i]._id;
        }
        //console.log("----- get /lineList = " + JSON.stringify(resp.data, null, 2));
        this.lineList = resp.data;

    }).catch(err => {
        console.log("----- get /lineList error = " + JSON.stringify(err, null, 2));
    });
    if( this.id.startsWith('-') ) {
      console.log("----- clone");
      this.clone = true;
      this.id = this.id.substring(1);
    }
    if( this.id != "0" ) {
      axiosInstance.get('/standard/' + this.id)
      .then(resp => {
        //console.log("----- get /standard = " + JSON.stringify(resp.data, null, 2));
        this.std = resp.data;
        if( this.clone ) {
          this.std.clonedFrom = this.std._id;
          this.std._id = '';
          this.std.partNumber = '';
        }
        let t = this.std.SAM;
        this.hoursSAM = Math.floor(t / 3600);
        t -= (this.hoursSAM * 3600);
        this.minSAM = Math.floor(t / 60);
        this.secSAM = Math.round((t - (this.minSAM * 60)) * 10) / 10;

        if (typeof this.std.qteToProduceHasEdited !== 'boolean') this.std.qteToProduceHasEdited = false
      })
      .catch(err => {
        console.log("----- get /standard error = " + JSON.stringify(err, null, 2));
      });
    }

    axiosInstance.get('/categoryList', {"params": { "isActive": true }})
    .then(resp => {
        for( let i=0; i<resp.data.length; i++ ) {
          resp.data[i].text = resp.data[i].name;
          resp.data[i].value = resp.data[i]._id;
        }
        //console.log("----- get /categoryList = " + JSON.stringify(resp.data, null, 2));
        this.catList = resp.data;
    }).catch(err => {
        console.log("----- get /categoryList error = " + JSON.stringify(err, null, 2));
    });
  },
  created() {
    this.id = this.$route.params.id;
    //console.log("----- this.id = " + JSON.stringify(this.id, null, 2));
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/colors.scss';

form {
  background: White;
  padding: 2rem 2rem 1rem;
  width: 100%;
}

label {
  font-weight: bold;
}

.narrow {
  width: 70px;
}

.giveMeRoomToBreathe {
  font-weight: normal;
  margin-left: 10px;
  margin-right: 20px;
}

.no-changes-allowed {
  font-size: 14px;
  color: #f55;
}
</style>
