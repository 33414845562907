<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask" v-show="showStart">
      <div class="modal-container">
        <div>{{ $t("startProd.confirm") }}</div>
        <div>
          _____________________________________________________________________________
        </div>
        <div>
          <b>{{ $t("startProd.lineName") }}</b>
          <span>{{ startActivity.line.name }}</span>
        </div>
        <div>
          <b>{{ $t("startProd.schedDesc") }}</b>
          <span>{{ startActivity.sched.sched.description }}</span>
          <span class="marginLeft15px"
            >( {{ timeToString(startActivity.sched.sched.startTime) }}</span
          >
          <span>, {{ timeToString(startActivity.sched.sched.endTime) }} )</span>
        </div>
        <div v-if="lunchTime != ''" style="margin-left: 15px;">
          <b>{{ $t("startProd.lunchTime") }}</b
          >{{ lunchTime }}
        </div>
        <div v-if="breakTime1 != ''" style="margin-left: 15px;">
          <b>{{ $t("startProd.breakTime") }}</b
          >{{ breakTime1 }}
        </div>
        <div v-if="breakTime2 != ''" style="margin-left: 15px;">
          <b>{{ $t("startProd.breakTime") }}</b
          >{{ breakTime2 }}
        </div>
        <div>
          <b>{{ $t("startProd.stdPart") }}</b>
          <span>{{ startActivity.stds[0].std.partNumber }}</span>
          <span class="marginLeft15px">{{ $t("startProd.sam") }}</span>
          <span>{{ timeHMSToString(startActivity.stds[0].std.SAM) }}</span>
        </div>
        <div>
          <b>{{ $t("startProd.teamList") }}</b>
          <span
            v-for="(emp, index) in startActivity.team"
            style="margin-left: 10px;"
            :key="emp.emp._id"
          >
            {{ emp.emp.firstName }} {{ emp.emp.lastName
            }}<span v-if="index < startActivity.team.length - 1">,</span>
          </span>
        </div>
        <div>
          _____________________________________________________________________________
        </div>
        <div>
          <div v-if="inGraceTime">
            {{ $t("startProd.startTime") }}
            <span>{{ startAt }}</span>
            <span>{{ $t("startProd.timeLimit") }}</span>
            <span>{{ limit }}</span>
          </div>
          <div v-else>
            {{ $t("startProd.startTime") }}
            <span>{{ cTime }}</span>
            <span>{{ $t("startProd.startNow") }}</span>
          </div>
        </div>

        <button
          class="btn btn-perfcell float-right marginTop50px"
          @click="clickCancel"
        >
          {{ $t("startProd.cancel") }}
        </button>

        <button
          class="btn btn-perfcell float-right marginTop50px marginRight10px"
          @click="clickSave"
        >
          {{ $t("startProd.start") }}
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import moment from "moment-timezone";
import oper from "@/stores/Operations.js";
import EventBus from "@/eventBus";

export default {
  name: "StartProduction",
  template: "#modal-template",
  props: {
    showStart: Boolean,
    doInitStart: Boolean,
  },
  data: function() {
    return {
      startActivity: {
        line: {},
        sched: { sched: {} },
        stds: [{ std: {} }],
        team: [{ emp: {} }],
      },
      inGraceTime: false,
      startAt: "",
      limit: "",
      cTime: "",
      lunchTime: "",
      breakTime1: "",
      breakTime2: "",
    };
  },
  watch: {
    doInitStart: function(val) {
      if (val) {
        //console.log("----- init StartProduction = " + JSON.stringify(this.startActivity, null, 2));

        this.startActivity = oper.getCurrentActivity();

        this.inGraceTime =
          this.startActivity.sched.sched.schedTimes.inSched == 1;

        if (this.startActivity.sched.sched.schedTimes.lunchTime)
          this.lunchTime = moment(
            this.startActivity.sched.sched.schedTimes.lunchTime
          ).format("D MMM h:mm A");
        else this.lunchTime = "";
        if (this.startActivity.sched.sched.schedTimes.breakTime1)
          this.breakTime1 = moment(
            this.startActivity.sched.sched.schedTimes.breakTime1
          ).format("D MMM h:mm A");
        else this.breakTime1 = "";
        if (this.startActivity.sched.sched.schedTimes.breakTime2)
          this.breakTime2 = moment(
            this.startActivity.sched.sched.schedTimes.breakTime2
          ).format("D MMM h:mm A");
        else this.breakTime2 = "";

        this.startAt = moment(
          this.startActivity.sched.sched.schedTimes.scheduleStart
        ).format("h:mm A");
        this.limit = moment(
          this.startActivity.sched.sched.schedTimes.lateScheduleStart
        ).format("h:mm A");
        this.showTimeNow();
      }
    },
  },
  methods: {
    showTimeNow: function() {
      let self = this;
      if (
        moment().isBefore(
          moment(this.startActivity.sched.sched.schedTimes.lateScheduleStart)
        )
      ) {
        if (this.inGraceTime == false) this.inGraceTime = true;
      } else {
        if (this.inGraceTime == true) this.inGraceTime = false;
      }
      // this test will prevent timeout running when out of start production screen
      if (self.showStart) {
        self.cTime = moment().format("h:mm:ss A");
        setTimeout(function() {
          self.showTimeNow();
        }, 1000);
      }
    },
    timeToString: function(t) {
      return moment
        .unix(t * 60)
        .utc()
        .format("h:mm A");
    },
    timeHMSToString: function(t) {
      let val = moment
        .unix(t)
        .utc()
        .format("HH:mm:ss");
      if (Math.round((t % 1) * 10) != 0)
        val = val + "." + Math.round((t % 1) * 10);
      return val;
    },
    clickSave: function() {
      console.log("WORKEDDDD")
      this.$emit("close", { cancel: false });
    },
    clickCancel: function() {
      this.$emit("close", { cancel: true });
    },
    startProductionAuto:function(){
        console.log("WORKEDDDD")
        
        setTimeout(() => {
                this.$emit("close", { cancel: false });

        }, 1500);
    }
  },
   created() {
    EventBus.$on('startProduction', this.startProductionAuto);
  },
   beforeDestroy() {
    EventBus.$off('startProduction', this.startProductionAuto);
  }
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.modal-mask {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}
.modal-container {
  width: 66%;
  height: 450px;
  margin: 40px auto 0;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
@media only screen and (max-width: 800px) {
  .modal-container {
    width: 100%;
  }
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
