<template>
  <div class="g-page-container">

    <div class="g-pageHeader" style="height: 63px;">
      <h1 style="margin: auto;">{{$t('configTask.title')}}</h1>
    </div>

    <form class="g-pageBody" @submit.prevent="onSubmit">

      <div class="form-group">
        <label for="inputName">{{$t('configTask.name')}}</label>
        <input
          type="text"
          required
          v-model="task.name"
          class="form-control"
          id="inputName"
          :placeholder="$t('configTask.namePHolder')">
      </div>

      <div class="form-group">
        <label for="inputDescription">{{$t('configTask.desc')}}</label>
        <input
          type="text"
          v-model="task.description"
          class="form-control"
          id="inputDescription"
          :placeholder="$t('configTask.descPHolder')">
      </div>

      <div class="form-group">
        <label for="inputLines">{{$t('configTask.lines')}}</label>
        <div id="inputLines">
          <MultipleSelect
            :data="lineList"
            v-model="task.linesList"
            multiple
            :options="opt" >
          </MultipleSelect>
        </div>
      </div>

      <div class="form-group">
        <input type="checkbox" id="isSupport" v-model="task.isSupport">
        <label class="checkboxLabel" style="margin-bottom: 0px;" for="isSupport">{{$t('configTask.isSupport1')}}</label>
        <p>{{$t('configTask.isSupport2')}}</p>
        <p>{{$t('configTask.isSupport3')}}</p>
        <p>{{$t('configTask.isSupport4')}}</p>
      </div>

      <div class="form-group">
        <input type="checkbox" id="isActive" v-model="task.isActive">
        <label class="checkboxLabel" for="isActive">{{$t('configTask.active')}}</label>
      </div>

      <button class="btn btn-perfcell float-left marginTop50px" @click.prevent="clickRetour">
        {{ $t('configTask.cancel' ) }}
      </button>

      <button class="btn btn-perfcell float-left marginTop50px marginLeft10px" type="submit">
        {{ $t('configTask.submit' ) }}
      </button>

      <p v-if="errors.length" class="float-left marginTop50px marginLeft30px" :style="{ color: msgColor }">
        <b v-if="msgColor == 'red'">{{$tc('configTask.error', errors.length)}}</b>
        <ul>
          <li v-for="error in errors" :key="error">{{ error }}</li>
        </ul>
      </p>

    </form>

  </div>
</template>

<script>
import { axiosInstance } from "@/main";

export default {
  data() {
    return {
      task: {
        _id: '',
        name: '',
        description: '',
        linesList: [],
        isSupport: false,
        isActive: true,
        clonedFrom: ''
      },
      id: '',
      clone: false,
      errors: [],
      msgColor: "red",

      lineList: [],
      opt: {
        multiple: true,
        filter: true,
        placeholder: this.$i18n.t('configDown.linesPHolder'),
        multipleWidth: 500,
        width: 500,
        dropWidth: 500,
        locale: localStorage.getItem("locale")
      }
    }
  },
  methods: {
    onSubmit() {
      if( ! this.validate() )
        return;
      axiosInstance.post('/task/' + this.id, this.task)
      .then((resp) => {
        //console.log("----- onSubmit ok = " + JSON.stringify(resp.data, null, 2));
        if( resp.data == "taskNameExist" ) {
          this.errors = [];
          this.msgColor = "red";
          this.errors.push(this.$i18n.t('configTask.taskExist'));
        }
        else {
          this.task = resp.data;
          this.id = this.task._id;
          this.errors.push(this.$i18n.t('configTask.success'));
          this.msgColor = "green";
        }
      }).catch(() => {
        console.log("----- onSubmit error ");
      });
    },
    validate() {
      this.errors = [];
      this.msgColor = "red";

      if( this.task.name.length < 3 )
        this.errors.push(this.$i18n.t('configTask.nameLength'));

      return ! this.errors.length;
    },
    clickRetour() {
      this.$router.replace("/configTasksList");
    }
  },
  mounted() {
    if( this.id.startsWith('-') ) {
      console.log("----- clone");
      this.clone = true;
      this.id = this.id.substring(1);
    }
    if( this.id != "0" ) {
      axiosInstance.get('/task/' + this.id)
      .then(resp => {
        //console.log("----- get /task = " + JSON.stringify(resp.data, null, 2));
        this.task = resp.data;
        if( this.clone ) {
          this.task.clonedFrom = this.task._id;
          this.task._id = '';
          this.task.name = '';
        }
        if( ! this.task.linesList ) {
          this.task.linesList = [];
        }
      })
      .catch(err => {
        console.log("----- get /task error = " + JSON.stringify(err, null, 2));
      });
    }
    axiosInstance.get('/lineList', {"params": { "isActive": true }})
    .then(resp => {
        for( let i=0; i<resp.data.length; i++ ) {
          resp.data[i].text = resp.data[i].name + ' - ' + resp.data[i].description;
          resp.data[i].value = resp.data[i]._id;
        }
        //console.log("----- get /lineList = " + JSON.stringify(resp.data, null, 2));
        this.lineList = resp.data;
    }).catch(err => {
        console.log("----- get /lineList error = " + JSON.stringify(err, null, 2));
    });
  },
  created() {
    this.id = this.$route.params.id;
    //console.log("----- this.id = " + JSON.stringify(this.id, null, 2));
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/colors.scss';

form {
  background: White;
  padding: 2rem 2rem 1rem;
  width: 100%;
}

label {
  font-weight: bold;
}

.form-group p {
  margin-left: 30px;
  margin-bottom: 0px;
}

.form-group p::last-child {
}


</style>
