<template>
  <div class="g-page-container">

    <div class="g-pageHeader" style="height: 63px;">
      <h1 style="margin: auto;">{{$t('factory.title')}}</h1>
    </div>

    <ReturnButtonContainer to="/config" />

    <form class="g-pageBody" @submit.prevent="onSubmit">

      <div class="form-group">
        <label for="inputFactoryName">{{$t('factory.name')}}</label>
        <input
          type="text"
          v-model="factory.name"
          class="form-control"
          id="inputFactoryName"
          :placeholder="$t('factory.namePHolder')">
      </div>

      <div class="form-group">
        <div class="input-logo">
          <label for="inputLogo">{{$t('factory.logo')}}</label>
          <input
            type="file"
            accept="image/png"
            class="form-control-file"
            id="inputLogo"
            ref="browseFile"
            @change="previewLogo">
        </div>
        <img :src="logoFile" @click="clickLogoImage">
      </div>

      <div class="form-group">
        <label for="inputAdminPsw">{{$t('factory.psw')}}</label>
        <input
          type="text"
          v-model="factory.admin_psw"
          class="form-control"
          id="inputAdminPsw" >
      </div>

      <div class="form-group">
        <label for="adminTimeout">{{$t('factory.adminTimeout')}}<small>{{$t('factory.inSeconds')}}</small></label>
        <input v-model="factory.adminTimeout" id="adminTimeout" type="number" min="0" max="999999" value="60" class="form-control">
      </div>

      <div class="form-group">
        <label for="zonesSelect">{{$t('factory.timeZone')}}</label>
        <select id="zonesSelect" class="form-control" v-model="factory.timeZone">
          <option value="" @click="factory.timeZone = ''">{{$t('factory.tzPHolder')}}</option>
          <option v-for="tz in zonesList" :key="tz" @click="factory.timeZone = tz">
            {{tz}}
          </option>
        </select>
      </div>



      <div class="form-group">
        <label for="dateFormat">{{$t('factory.dateFormat')}}</label>
        <div id="dateFormat">
          <MultipleSelect
            :data="dateFormatList"
            v-model="factory.dateFormat"
            :options="opt" >
          </MultipleSelect>
        </div>
      </div>



      <div class="form-group">
        <label>{{$t('factory.version')}}</label>
        <span class="marginLeft15px">{{ version }}</span>
        <span class="marginLeft15px">{{ release }}</span>
      </div>

      <button class="btn btn-perfcell float-left marginTop50px" @click.prevent="clickRetour">
        {{ $t('factory.cancel' ) }}
      </button>

      <button class="btn btn-perfcell float-left marginTop50px marginLeft10px" type="submit">
        {{ $t('factory.submit' ) }}
      </button>

      <p v-if="errors.length" class="float-left marginTop50px marginLeft30px" :style="{ color: msgColor }">
        <b v-if="msgColor == 'red'">{{$tc('factory.error', errors.length)}}</b>
        <ul>
          <li v-for="error in errors" :key="error">{{ error }}</li>
        </ul>
      </p>

    </form>

  </div>
</template>

<script>
import { axiosInstance } from "@/main";
import { axiosInstanceReports } from "@/main";
import { baseURL } from '../main';
import { appVersion } from "@/main";
import { appReleaseDate } from "@/main";
import EventBus from '@/eventBus'
import oper from '@/stores/Operations.js';
import ReturnButtonContainer from "../components/Return-button/Return-button-container.vue";

export default {
    data() {
        return {
            factory: {
                name: "",
                adminTimeout: "",
                timeZone: "",
                admin_psw: "",
                dateFormat: ""
            },
            errors: [],
            logoFile: baseURL + "/images/logo_client.png",
            fileName: "logo_client.png",
            newLogoFile: false,
            msgColor: "red",
            version: appVersion,
            release: appReleaseDate,
            opt: {
                multiple: false,
                filter: false,
                multipleWidth: 200,
                width: 200,
                dropWidth: 200,
                locale: localStorage.getItem("locale")
            },
            dateFormatList: [],
            zonesList: [
                "Canada/Atlantic",
                "Canada/Central",
                "Canada/Eastern",
                "Canada/Mountain",
                "Canada/Newfoundland",
                "Canada/Pacific",
                "Canada/Saskatchewan",
                "Canada/Yukon",
                "US/Alaska",
                "US/Aleutian",
                "US/Arizona",
                "US/Central",
                "US/East-Indiana",
                "US/Eastern",
                "US/Hawaii",
                "US/Indiana-Starke",
                "US/Michigan",
                "US/Mountain",
                "US/Pacific",
                "US/Pacific-New",
                "US/Samoa",
                "America/Aruba",
                "America/Asuncion",
                "America/Atikokan",
                "America/Atka",
                "America/Bahia",
                "America/Bahia_Banderas",
                "America/Barbados",
                "America/Belem",
                "America/Belize",
                "America/Blanc-Sablon",
                "America/Boa_Vista",
                "America/Bogota",
                "America/Boise",
                "America/Buenos_Aires",
                "America/Cambridge_Bay",
                "America/Campo_Grande",
                "America/Cancun",
                "America/Caracas",
                "America/Catamarca",
                "America/Cayenne",
                "America/Cayman",
                "America/Chicago",
                "America/Chihuahua",
                "America/Coral_Harbour",
                "America/Cordoba",
                "America/Costa_Rica",
                "America/Creston",
                "America/Cuiaba",
                "America/Curacao",
                "America/Danmarkshavn",
                "America/Dawson",
                "America/Dawson_Creek",
                "America/Denver",
                "America/Detroit",
                "America/Dominica",
                "America/Edmonton",
                "America/Eirunepe",
                "America/El_Salvador",
                "America/Ensenada",
                "America/Fort_Nelson",
                "America/Fort_Wayne",
                "America/Fortaleza",
                "America/Glace_Bay",
                "America/Godthab",
                "America/Goose_Bay",
                "America/Grand_Turk",
                "America/Grenada",
                "America/Guadeloupe",
                "America/Guatemala",
                "America/Guayaquil",
                "America/Guyana",
                "America/Halifax",
                "America/Havana",
                "America/Hermosillo",
                "America/Indiana/Indianapolis",
                "America/Indiana/Knox",
                "America/Indiana/Marengo",
                "America/Indiana/Petersburg",
                "America/Indiana/Tell_City",
                "America/Indiana/Vevay",
                "America/Indiana/Vincennes",
                "America/Indiana/Winamac",
                "America/Indianapolis",
                "America/Inuvik",
                "America/Iqaluit",
                "America/Jamaica",
                "America/Jujuy",
                "America/Juneau",
                "America/Kentucky/Louisville",
                "America/Kentucky/Monticello",
                "America/Knox_IN",
                "America/Kralendijk",
                "America/La_Paz",
                "America/Lima",
                "America/Los_Angeles",
                "America/Louisville",
                "America/Lower_Princes",
                "America/Maceio",
                "America/Managua",
                "America/Manaus",
                "America/Marigot",
                "America/Martinique",
                "America/Matamoros",
                "America/Mazatlan",
                "America/Mendoza",
                "America/Menominee",
                "America/Merida",
                "America/Metlakatla",
                "America/Mexico_City",
                "America/Miquelon",
                "America/Moncton",
                "America/Monterrey",
                "America/Montevideo",
                "America/Montreal",
                "America/Montserrat",
                "America/Nassau",
                "America/New_York",
                "America/Nipigon",
                "America/Nome",
                "America/Noronha",
                "America/North_Dakota/Beulah",
                "America/North_Dakota/Center",
                "America/North_Dakota/New_Salem",
                "America/Ojinaga",
                "America/Panama",
                "America/Pangnirtung",
                "America/Paramaribo",
                "America/Phoenix",
                "America/Port-au-Prince",
                "America/Port_of_Spain",
                "America/Porto_Acre",
                "America/Porto_Velho",
                "America/Puerto_Rico",
                "America/Punta_Arenas",
                "America/Rainy_River",
                "America/Rankin_Inlet",
                "America/Recife",
                "America/Regina",
                "America/Resolute",
                "America/Rio_Branco",
                "America/Rosario",
                "America/Santa_Isabel",
                "America/Santarem",
                "America/Santiago",
                "America/Santo_Domingo",
                "America/Sao_Paulo",
                "America/Scoresbysund",
                "America/Shiprock",
                "America/Sitka",
                "America/St_Barthelemy",
                "America/St_Johns",
                "America/St_Kitts",
                "America/St_Lucia",
                "America/St_Thomas",
                "America/St_Vincent",
                "America/Swift_Current",
                "America/Tegucigalpa",
                "America/Thule",
                "America/Thunder_Bay",
                "America/Tijuana",
                "America/Toronto",
                "America/Tortola",
                "America/Vancouver",
                "America/Virgin",
                "America/Whitehorse",
                "America/Winnipeg",
                "America/Yakutat",
                "America/Yellowknife",
                "Asia/Bangkok"
            ]
        };
    },
    methods: {
        previewLogo(event) {
            var file = event.target.files;
            //console.log("----- file = " + JSON.stringify(file, null, 2));
            if (!file.length)
                return;
            let fName = file[0].name;
            //console.log("----- file name = " + fName);
            this.fileName = fName;
            this.newLogoFile = true;
            const reader = new FileReader();
            reader.readAsDataURL(file[0]);
            reader.onload = e => {
                // store the base64 image for display on screen and later on for transmit to server
                this.logoFile = e.target.result;
            };
        },
        onSubmit() {
            if (!this.validate())
                return;
            let factory = this.factory;
            if (this.newLogoFile)
                factory.logo = this.logoFile;
            factory.dateFormat = factory.dateFormat.replace(/J/g, "D");
            factory.dateFormat = factory.dateFormat.replace(/A/g, "Y");
            //console.log("----- factory = " + JSON.stringify(factory, null, 2));
            axiosInstance.post("/factory", factory)
                .then(() => {
                //console.log("----- onSubmit ok ");
                this.errors.push(this.$i18n.t("factory.success"));
                this.msgColor = "green";
                axiosInstanceReports.post("/rep/factory", {});
            }).catch(() => {
                console.log("----- onSubmit error ");
            });
        },
        validate() {
            this.errors = [];
            this.msgColor = "red";
            if (this.fileName.substr(this.fileName.length - 4, 4).toLowerCase() != ".png") {
                this.errors.push(this.$i18n.t("factory.png"));
            }
            if (this.factory.admin_psw.length < 5) {
                this.errors.push(this.$i18n.t("factory.pswLength"));
            }
            if (this.factory.timeZone == "") {
                this.errors.push(this.$i18n.t("factory.timeZoneErr"));
            }
            return !this.errors.length;
        },
        clickRetour() {
            this.$router.replace("/config");
        },
        clickLogoImage: function () {
            //console.log("----- clickLogoImage");
            this.$refs.browseFile.click();
        },
        configUpdateFactory(factory) {
            //console.log("----- config configUpdateFactory = " + JSON.stringify(factory, null, 2));
            if (factory) {
                this.factory = JSON.parse(JSON.stringify(factory));
                if (this.$i18n.locale == "fr") {
                    this.factory.dateFormat = this.factory.dateFormat.replace(/D/g, "J");
                    this.factory.dateFormat = this.factory.dateFormat.replace(/Y/g, "A");
                }
            }
        }
    },
    mounted() {
        //console.log("----- configFactory mounted");
        this.configUpdateFactory(oper.getFactory());
        if (this.$i18n.locale == "fr")
            this.dateFormatList = [
                "JJ/MM/AAAA",
                "MM/JJ/AAAA",
                "AAAA/MM/JJ",
                "AAAA/JJ/MM"
            ];
        else
            this.dateFormatList = [
                "DD/MM/YYYY",
                "MM/DD/YYYY",
                "YYYY/MM/DD",
                "YYYY/DD/MM"
            ];
        EventBus.$on("factory", this.configUpdateFactory);
    },
    beforeDestroy() {
        EventBus.$off("factory", this.configUpdateFactory);
    },
    components: { ReturnButtonContainer }
}
</script>

<style lang="scss" scoped>

form {
  background: White;
  padding: 2rem 2rem 1rem;
  width: 100%;
}

label {
  font-weight: bold;
}

.input-logo {
  display: inline-block;
}

img {
  display: inline-block;
  max-height: 100px;
}
</style>
