<template>
  <div class="g-page-container">
    <div class="g-pageHeader" style="height: 63px">
      <h1 style="margin: auto">{{ $t("config.title") }}</h1>
    </div>

    <table class="configBody">
      <tr>
        <div class="boxConfigMenu" @click="clickBI">
          <img src="../assets/iconeConfig/BI.png" />
          <div class="titre">{{ $t("config.BI") }}</div>
        </div>
        <div class="boxConfigMenu" @click="clickDashboard">
          <img src="../assets/iconeConfig/Dashboard.png" />
          <div class="titre">{{ $t("config.Dashboard") }}</div>
        </div>
        <div class="boxConfigMenu" @click="clickDashboard2">
          <img src="../assets/iconeConfig/Dashboard.png" />
          <div class="titre">{{ $t("config.Dashboard2") }}</div>
        </div>
        <div class="boxConfigMenu" @click="clickReports">
          <img src="../assets/iconeConfig/Reports.png" />
          <div class="titre">{{ $t("config.Reports") }}</div>
        </div>
        <div class="boxConfigMenu" @click="clickFactory">
          <img src="../assets/iconeConfig/Company.png" />
          <div class="titre">{{ $t("config.Company") }}</div>
        </div>
        <div class="boxConfigMenu empty" @click="clickLogs"></div>
      </tr>

      <tr>
        <div class="boxConfigMenu marginTop30px" @click="clickAndon">
          <img src="../assets/iconeConfig/oneAndon.png" />
          <div class="titre">{{ $t("config.Andon") }}</div>
        </div>
        <div class="boxConfigMenu marginTop30px" @click="clickStandardCategory">
          <img src="../assets/iconeConfig/Categories.png" />
          <div class="titre">{{ $t("config.Categories") }}</div>
        </div>
        <div class="boxConfigMenu marginTop30px" @click="clickChangeover">
          <img src="../assets/iconeConfig/Changeovers.png" />
          <div class="titre">{{ $t("config.Changeovers") }}</div>
        </div>
        <div class="boxConfigMenu marginTop30px" @click="clickDowntime">
          <img src="../assets/iconeConfig/Downtimes.png" />
          <div class="titre">{{ $t("config.Downtimes") }}</div>
        </div>
        <div class="boxConfigMenu marginTop30px" @click="clickUser">
          <img src="../assets/iconeConfig/Employees.png" />
          <div class="titre">{{ $t("config.Employees") }}</div>
        </div>
        <div class="boxConfigMenu marginTop30px" @click="clickIncentive">
          <img src="../assets/iconeConfig/Incentive.png" />
          <div class="titre">{{ $t("config.Incentive") }}</div>
        </div>
      </tr>

      <tr>
        <div class="boxConfigMenu" @click="clickLine">
          <img src="../assets/iconeConfig/Line.png" />
          <div class="titre">{{ $t("config.Lines") }}</div>
        </div>
        <div class="boxConfigMenu" @click="clickSchedule">
          <img src="../assets/iconeConfig/Schedules.png" />
          <div class="titre">{{ $t("config.Schedules") }}</div>
        </div>
        <div class="boxConfigMenu" @click="clickFactorySetup">
          <img src="../assets/iconeConfig/Setups.png" />
          <div class="titre">{{ $t("config.SetupTimes") }}</div>
        </div>
        <div class="boxConfigMenu" @click="clickStandard">
          <img src="../assets/iconeConfig/Standards.png" />
          <div class="titre">{{ $t("config.Standards") }}</div>
        </div>
        <div class="boxConfigMenu" @click="clickTask">
          <img src="../assets/iconeConfig/Task.png" />
          <div class="titre">{{ $t("config.Tasks") }}</div>
        </div>
        <div class="boxConfigMenu" @click="clickDefect">
          <img src="../assets/iconeConfig/Defect.png" />
          <div class="titre">{{ $t("config.Defects") }}</div>
        </div>
      </tr>

      <tr>
        <div class="boxConfigMenu marginTop30px" @click="clickSmsRecipient">
          <img src="../assets/iconeConfig/Recipients.png" />
          <div class="titre">{{ $t("config.MessageRec") }}</div>
        </div>
        <div class="boxConfigMenu marginTop30px" @click="clickSmsMessage">
          <img src="../assets/iconeConfig/Textos.png" />
          <div class="titre">{{ $t("config.TextMessages") }}</div>
        </div>
        <div class="boxConfigMenu marginTop30px" @click="clickMessage">
          <img src="../assets/iconeConfig/Recipients.png" />
          <div class="titre">{{ $t("config.Message") }}</div>
        </div>
        <div class="boxConfigMenu cleaning-factor marginTop30px" @click="navigateTo('/config/cleaning-factor')">
          <svg
            enable-background="new 0 0 64 64"
            height="64px"
            id="Layer_1"
            version="1.1"
            viewBox="0 0 64 64"
            width="64px"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g>
              <path
                d="M51.9,1.1c-0.5-0.3-1.1-0.2-1.4,0.3L32.5,28.3l-1.5-1c-0.5-0.3-1.1-0.2-1.4,0.3l-2.5,3.8l-1.5-1c-0.4-0.3-0.9-0.2-1.2,0.1   l-17,15.4C7.1,46,7,46.3,7,46.6c0,0.3,0.2,0.6,0.4,0.8l23.1,15.5c0.2,0.1,0.4,0.2,0.6,0.2c0.1,0,0.2,0,0.3,0   c0.3-0.1,0.5-0.3,0.6-0.6l7.8-21.6c0.2-0.4,0-0.9-0.4-1.2l-1.5-1l2.5-3.8c0.3-0.5,0.2-1.1-0.3-1.4l-1.5-1L56.8,5.6   C57,5.4,57,5.1,57,4.9c-0.1-0.3-0.2-0.5-0.4-0.6L51.9,1.1z M30.6,60.5l-2.8-1.9c0.1-0.1,0.2-0.2,0.3-0.3l1.6-2.3   c0.3-0.5,0.2-1.1-0.3-1.4c-0.5-0.3-1.1-0.2-1.4,0.3l-1.6,2.3c-0.1,0.1-0.1,0.2-0.2,0.4l-3.1-2.1c0.1-0.1,0.2-0.2,0.3-0.3l1.6-2.3   c0.3-0.5,0.2-1.1-0.3-1.4c-0.5-0.3-1.1-0.2-1.4,0.3l-1.6,2.3c-0.1,0.1-0.1,0.2-0.2,0.4l-3.1-2.1c0.1-0.1,0.2-0.2,0.3-0.3l1.5-2.3   c0.3-0.5,0.2-1.1-0.3-1.4c-0.5-0.3-1.1-0.2-1.4,0.3L17.2,51c-0.1,0.1-0.1,0.2-0.2,0.4l-3.1-2.1c0.1-0.1,0.2-0.2,0.3-0.3l1.6-2.3   c0.3-0.5,0.2-1.1-0.3-1.4c-0.5-0.3-1.1-0.2-1.4,0.3l-1.6,2.3c-0.1,0.1-0.1,0.2-0.1,0.4l-2.8-1.9l15.5-14l1.6,1.1c0,0,0,0,0,0   l9.2,6.2c0,0,0,0,0,0l1.6,1.1L30.6,60.5z M36.3,37.5l-7.6-5.1l2-3l1.5,1c0,0,0,0,0,0l4.6,3.1c0,0,0,0,0,0l1.5,1L36.3,37.5z    M34.1,29.4L47.8,9l2.9,2L37.1,31.4L34.1,29.4z M51.9,9.4l-2.9-2l2.7-4l3,2L51.9,9.4z"
              />
            </g>
          </svg>
          <div class="titre">{{ $t("config.CleaningFactor") }}</div>
        </div>
        <div class="boxConfigMenu empty" @click="clickLogs"></div>
        <div class="boxConfigMenu empty" @click="clickLogs"></div>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nbLogs: 0,
    };
  },
  methods: {
    clickBI: function () {
      //console.log("----- clickBI");
      this.$router.replace("/chartsbi");
    },
    clickDashboard: function () {
      //console.log("----- clickDashboard");
      this.$router.replace("/dashboard");
    },
    clickDashboard2: function () {
      //console.log("----- clickDashboard2");
      this.$router.replace("/dashboard2");
    },
    clickReports: function () {
      //console.log("----- clickReports");
      this.$router.replace("/reports");
    },
    clickFactory: function () {
      //console.log("----- clickFactory");
      this.$router.replace("/configfactory");
    },
    clickStandardCategory: function () {
      //console.log("----- clickStandardCategory");
      this.$router.replace("/configCategoriesList");
    },
    clickDowntime: function () {
      //console.log("----- clickDowntime");
      this.$router.replace("/configDowntimesList");
    },
    clickChangeover: function () {
      //console.log("----- clickChangeover");
      this.$router.replace("/configChangeoversList");
    },
    clickUser: function () {
      //console.log("----- clickUser");
      this.$router.replace("/configUsersList");
    },
    clickIncentive: function () {
      //console.log("----- clickIncentive");
      this.$router.replace("/configIncentive");
    },
    clickLine: function () {
      //console.log("----- clickLine");
      this.$router.replace("/configLinesList");
    },
    clickSmsRecipient: function () {
      //console.log("----- clickSmsRecipient");
      this.$router.replace("/configSmsRecipientsList");
    },
    clickSchedule: function () {
      //console.log("----- clickSchedule");
      this.$router.replace("/configSchedulesList");
    },
    clickFactorySetup: function () {
      //console.log("----- clickFactorySetup");
      this.$router.replace("/configFactorySetup");
    },
    clickStandard: function () {
      //console.log("----- clickStandard");
      this.$router.replace("/configStandardsList");
    },
    clickTask: function () {
      //console.log("----- clickTask");
      this.$router.replace("/configTasksList");
    },
    clickDefect: function () {
      //console.log("----- clickDefect");
      this.$router.replace("/configDefectsList");
    },
    clickSmsMessage: function () {
      //console.log("----- clickSmsMessage");
      this.$router.replace("/configSmsMessagesList");
    },
    clickAndon: function () {
      //console.log("----- clickAndon");
      this.$router.replace("/configAndonsList");
    },
    clickLogs: function () {
      //console.log("----- clickLogs = " + this.nbLogs);
      if (++this.nbLogs == 7) this.$router.replace("/configLogsList");
    },
    clickMessage: function () {
      //console.log("----- clickMessage");
      this.$router.replace("/sendMessages");
    },
    navigateTo: function (to) {
      this.$router.replace(to);
    },
  },
  mounted() {
    localStorage.removeItem("configListSettings");
  },
};
</script>

<style lang="scss" scoped>
.configBody {
  width: 100%;
  margin-top: 2%;
}

.boxConfigMenu {
  float: left;
  background-color: white;
  width: 15%;
  height: 120px;
  margin-left: 1.5%;
  margin-bottom: 20px;
  font-size: 13px;
}

.boxConfigMenu.empty {
  background-color: transparent;
}

.boxConfigMenu img {
  display: block;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 10px;
}

.boxConfigMenu .titre {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.cleaning-factor {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cleaning-factor > svg {
  widows: auto;
  height: 50px;
  margin-top: 10px;
}
</style>
