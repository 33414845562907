import { render, staticRenderFns } from "./StartProduction.vue?vue&type=template&id=713d20b3&scoped=true&"
import script from "./StartProduction.vue?vue&type=script&lang=js&"
export * from "./StartProduction.vue?vue&type=script&lang=js&"
import style0 from "./StartProduction.vue?vue&type=style&index=0&id=713d20b3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "713d20b3",
  null
  
)

export default component.exports