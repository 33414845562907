<template>
  <div class="g-page-container" ref="pageContainer">

    <div class="g-pageHeader" style="height: 63px;">
      <h1 style="margin: auto;">LOGS</h1>
    </div>

    <div class="g-pageBody" style="padding:10px">
      <table>
        <thead>
          <tr>
            <th
              class="t-event cursorPointer"
              :class="getArrow('event')"
              @click="clickSort('event')">
              Event
            </th>
            <th
              class="t-time cursorPointer"
              :class="getArrow('time')"
              @click="clickSort('time')">
              Time
            </th>
            <th
              class="t-line cursorPointer"
              :class="getArrow('line')"
              @click="clickSort('line')">
              Line
            </th>
            <th
              class="t-schedule cursorPointer"
              :class="getArrow('schedule')"
              @click="clickSort('schedule')">
              Schedule
            </th>
            <th
              class="t-number1 cursorPointer"
              :class="getArrow('number1')"
              @click="clickSort('number1')">
              Number1
            </th>
            <th
              class="t-number2 cursorPointer"
              :class="getArrow('number2')"
              @click="clickSort('number2')">
              Number2
            </th>
            <th
              class="t-text1 cursorPointer"
              :class="getArrow('text1')"
              @click="clickSort('text1')">
              Text1
            </th>
            <th
              class="t-text2 cursorPointer"
              :class="getArrow('text2')"
              @click="clickSort('text2')">
              Text2
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in screenList" :key="e._id">
            <td>{{e.event}}</td>
            <td>{{e.time}}</td>
            <td>{{e.line}}</td>
            <td>{{e.schedule}}</td>
            <td>{{e.number1}}</td>
            <td>{{e.number2}}</td>
            <td>{{e.text1}}</td>
            <td>{{e.text2}}</td>
          </tr>
          <tr v-for="index in emptyRows" :key="index">
            <td>.</td>
            <td>&#8232;</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div style="margin-top: 10px;">
      <b-pagination
        v-if="totalRows > pageSize"
        size="sm"
        ref="pagination"
        :total-rows="totalRows"
        :per-page="pageSize"
        v-model="config.currentPage"
        :prev-text="$t('configLinesList.previous')"
        :next-text="$t('configLinesList.next')"
      />
    </div>

    <button
      class="btn btn-perfcell float-left marginTop20px"
      @click="clickRetour">
      {{ $t('configLinesList.cancel' ) }}
    </button>

  </div>
</template>

<script>
import moment from 'moment-timezone';
import { axiosInstance } from "@/main";
import EventBus from '@/eventBus';

export default {
  data() {
    return {
      // the list received from the server
      completeList: [],
      // the list stripped by search and inactive and sorted by column headers
      strippedList: [],
      // the current elements on screen
      screenList: [],

      // computed items
      totalRows: 0,
      pageSize: 7,
      emptyRows: 0,

      // config is not saved for logs list
      config: {
        // value modified when clicking pages navigation
        currentPage: 1,

        // values modifieds when clicking table headers
        currentSort:'name',
        currentSortDir:'asc'
      }
    };
  },
  watch: {
    'config.currentPage': function() {
      //console.log("----- this.config.currentPage = " + this.config.currentPage);
      this.setScreen();
    }
  },
  methods: {
    clickRetour() {
      this.$router.replace("/config");
    },
    clickSort:function(s) {
      if(s === this.config.currentSort) {
        this.config.currentSortDir = this.config.currentSortDir==='asc'?'desc':'asc';
      }
      this.config.currentSort = s;
      this.sortList();
    },
    getArrow:function(s) {
      if(s === this.config.currentSort) {
        return( this.config.currentSortDir==='asc'?'headerSortDown':'headerSortUp' );
      }
      else return( 'none' );
    },
    stripList:function() {
      this.strippedList = this.completeList;
      this.sortList();
    },
    sortList:function() {
      this.strippedList.sort((a,b) => {
        let modifier = 1;
        if(this.config.currentSortDir === 'desc') modifier = -1;
        if(a[this.config.currentSort] < b[this.config.currentSort]) return -1 * modifier;
        if(a[this.config.currentSort] > b[this.config.currentSort]) return 1 * modifier;
        return 0;
      });
      this.totalRows = this.strippedList.length;
      //console.log("----- strippedList.length = " + this.strippedList.length);
      this.setScreen();
    },
    setScreen:function() {
      this.screenList = this.strippedList.slice(
        (this.config.currentPage-1)*this.pageSize,
        this.config.currentPage*this.pageSize
      );
      this.emptyRows = this.pageSize - this.screenList.length;
    },
    computeNbRows: function( pageHeight ) {
      if( pageHeight > 450 ) {
        let nbPages = 7 + Math.trunc(((pageHeight - 450) / 30));
        if( this.pageSize != nbPages ) {
          this.pageSize = nbPages;
          //console.log("----- this.pageSize = " + nbPages);
          this.setScreen();
        }
      }
    },
    getList: function() {
      //console.log("----- getList");
      axiosInstance.get('/logs')
      .then(resp => {
          //console.log("----- get /logs = " + JSON.stringify(resp.data, null, 2));
          this.completeList = resp.data;

          this.completeList.forEach((log) => {
            log.time = moment(log.time).format('MM/DD/YYYY HH:mm:ss');
          });

          this.stripList();
      }).catch(err => {
          console.log("----- get /logs error = " + JSON.stringify(err, null, 2));
      });
    },
    redimLogsList: function(dim) {
      this.computeNbRows( dim.height );
    }
  },
  mounted() {
    this.getList();

    EventBus.$on('mainViewDim', this.redimLogsList);
    this.computeNbRows( this.$refs.pageContainer.clientHeight );
  },
  beforeDestroy(){
    EventBus.$off('mainViewDim', this.redimLogsList);
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/global.scss';
@import '@/assets/styles/table.scss';

.t-event {
  width: 15%;
}
.t-time {
  width: 15%;
}
.t-line {
  width: 15%;
}
.t-schedule {
  width: 15%;
}
.t-number1 {
  width: 10%;
}
.t-number2 {
  width: 10%;
}
.t-text1 {
  width: 10%;
}
.t-text2 {
  width: 10%;
}
</style>
