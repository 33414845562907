<template>
  <div class="g-page-container">

    <div class="g-pageHeader" style="height: 63px;">
      <h1 style="margin: auto;">{{$t('configSched.title')}}</h1>
    </div>

    <form class="g-pageBody" @submit.prevent="onSubmit">

      <div class="form-group">
        <label for="inputDescription">{{$t('configSched.desc')}}</label>
        <input
          type="text"
          v-model="schedule.description"
          class="form-control"
          id="inputDescription"
          :placeholder="$t('configSched.descPHolder')">
      </div>

      <div class="form-group">
        <div>
          <label>{{$t('configSched.weekdays')}}</label>
        </div>
        <div class="marginLeft15px">
          <div class="btn-group" id="weekdays" v-for="(n, index) in 7" :key="index">
            <label class="btn" :class="schedule.weekDays[index]?'btn-dayIn':'btn-dayOut'">
              <input type="checkbox" v-model="schedule.weekDays[index]">{{$t('days.' + index)}}
            </label>
          </div>
          <span class="selectedDays">{{$t('configSched.info')}}<b>{{$t('configSched.green')}}</b></span>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="startTime">{{$t('configSched.start')}}</label>
            <input id="startTime" type="time" class="form-control" v-model="startTimeString">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="endTime">{{$t('configSched.end')}}</label>
            <input id="endTime" type="time" class="form-control" v-model="endTimeString">
          </div>
        </div>
      </div>

      <div class="row break-title-container">
        <span class="title">Breaks</span>
        <button class="sort-button" type="button" @click="handleSortBreaks">Sort breaks</button>
      </div>
      <div v-for="(breakInfo, index) of (breaks || [])" :key="`${index}${keyOfBreaks}`" class="row breaks-container">
        <div class="">
          <div class="form-group">
            <label :for="`break-${index}`">{{$t('configSched.break')}} {{ index + 1 }}</label>
            <input :id="`break-${index}`" type="time" class="form-control" v-model="breakInfo.startTimeString" @change="handleBreakChange(index)">
          </div>

        </div>
        <div class="">
          <div class="form-group">
            <label :for="`break-length-${index}`">{{ $t('configSched.breakLength') }} <small>{{ $t('configSched.maxBreak') }}</small></label>
            <input :id="`break-length-${index}`" type="number" class="form-control" v-model="breakInfo.durationInMinutes">
          </div>
        </div>
        <div class="close-button">
            <button @click="handleCloseBreak(index)">X</button>
        </div>
      </div>
      <div class="row add-break-button">
        <div>
          <button type="button" @click="addDefaultBreakIntoBreaks">Add a break</button>
        </div>
      </div>

      <!-- <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="lunchTime">{{$t('configSched.lunch')}}</label>
            <input id="lunchTime" type="time" class="form-control" v-model="lunchTimeString">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="lunchLength">{{$t('configSched.lunchLength')}}<small>{{$t('configSched.inMinutes90')}}</small></label>
            <input id="lunchLength" type="number" class="form-control" v-model="lunchLength">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="breakLength">{{$t('configSched.break')}}<small>{{$t('configSched.inMinutes90')}}</small></label>
            <input id="breakLength" type="number" class="form-control" v-model="breakLength">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="breakTime1">{{$t('configSched.break1')}}</label>
            <input id="breakTime1" type="time" class="form-control" v-model="breakTime1String">
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="breakTime2">{{$t('configSched.break2')}}</label>
            <input id="breakTime2" type="time" class="form-control" v-model="breakTime2String">
          </div>
        </div>
      </div> -->

      <div v-if="overlapDay" class="form-group">
        <label>{{$t('configSched.overlap')}}</label>
        <div class="row">
          <div class="col-md-12">
            <label class="radio-inline"><b-form-radio v-model="schedule.whichDay" name="w" value="0" @change="change">{{$t('configSched.startDay')}}</b-form-radio></label>
          </div>
        </div>
        <div v-if="schedule.whichDay == SCHEDSTARTDAY" class="row">
          <div class="col-md-1">
          </div>
          <div class="col-md-4">
            <label class="radio-inline"><b-form-radio v-model="schedule.useReal" name="r1" value="true" @change="change">{{$t('configSched.realStart')}}</b-form-radio></label>
          </div>
          <div class="col-md-7">
            <label class="radio-inline" style="margin-left:-30px;"><b-form-radio v-model="schedule.useReal" name="r1" value="false" @change="change">{{$t('configSched.configStart')}}</b-form-radio></label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label class="radio-inline"><b-form-radio v-model="schedule.whichDay" name="w" value="1" @change="change">{{$t('configSched.endDay')}}</b-form-radio></label>
          </div>
        </div>
        <div v-if="schedule.whichDay == SCHEDENDDAY" class="row">
          <div class="col-md-1">
          </div>
          <div class="col-md-4">
            <label class="radio-inline"><b-form-radio v-model="schedule.useReal" name="r2" value="true" @change="change">{{$t('configSched.realEnd')}}</b-form-radio></label>
          </div>
          <div class="col-md-7">
            <label class="radio-inline" style="margin-left:-30px;"><b-form-radio v-model="schedule.useReal" name="r2" value="false" @change="change">{{$t('configSched.configEnd')}}</b-form-radio></label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label class="radio-inline"><b-form-radio v-model="schedule.whichDay" name="w" value="2" @change="change">{{$t('configSched.split')}}</b-form-radio></label>
          </div>
        </div>
      </div>



      <div class="form-group">
        <label>{{$t('configSched.autoLoggin')}}</label>
        <div class="form-group">
          <input type="checkbox" style="margin-left: 30px;" v-model="autoLoggin1">
          <label class="checkboxLabel">{{$t('configSched.beginShift')}}</label>
        </div>
        <div class="form-group" style="margin-top: -20px;">
          <input type="checkbox" style="margin-left: 30px;" v-model="autoLoggin2">
          <label class="checkboxLabel">{{$t('configSched.endShift')}}</label>
        </div>
      </div>

      <div class="form-group">
        <input type="checkbox" id="isActive" v-model="schedule.isActive">
        <label class="checkboxLabel" for="isActive">{{$t('configSched.active')}}</label>
      </div>

      <button class="btn btn-perfcell float-left marginTop50px" @click.prevent="clickRetour">
        {{ $t('configSched.cancel' ) }}
      </button>

      <button class="btn btn-perfcell float-left marginTop50px marginLeft10px" type="submit">
        {{ $t('configSched.submit' ) }}
      </button>

      <p v-if="errors.length" class="float-left marginTop50px marginLeft30px" :style="{ color: msgColor }">
        <b v-if="msgColor == 'red'">{{$tc('configSched.error', errors.length)}}</b>
        <ul>
          <li v-for="error in errors" :key="error">{{ error }}</li>
        </ul>
      </p>

    </form>

  </div>
</template>

<script>
import { axiosInstance } from "@/main";
import EventBus from '@/eventBus'
import oper from '@/stores/Operations.js';
import globals from '@/globals.js';

export default {
  data() {
    return {
      keyOfBreaks: 0,
      schedule: {
        _id: '',
        description: '',
        weekDays: [],
        breaks: undefined,
        startTime: -1,
        endTime: -1,
        lunchTime: -1,
        lunchLength: -1,
        breakLength: -1,
        breakTime1: -1,
        breakTime2: -1,
        whichDay: 0,
        useReal: true,
        teamSnapshot: 0,
        isActive: true
      },
      startTimeString: '',
      endTimeString: '',
      breaks: [],
      lunchTimeString: '',
      lunchLength: 0,
      breakLength: 0,
      breakTime1String: '',
      breakTime2String: '',
      id: '',
      errors: [],
      msgColor: "red",
      factorySetup: {},
      overlapDay: false,
      SCHEDSTARTDAY: globals.SCHEDSTARTDAY,
      SCHEDENDDAY: globals.SCHEDENDDAY,
      autoLoggin1: false,
      autoLoggin2: false
    }
  },
  watch: {
    'startTimeString': function() {
      this.setOverlapDay();
    },
    'endTimeString': function() {
      this.setOverlapDay();
    },
    'autoLoggin1': function() {
      if( this.autoLoggin1 )
        this.autoLoggin2 = false;
      if( ! this.autoLoggin1 && ! this.autoLoggin2 )
        this.schedule.teamSnapshot = 0;
      else if( this.autoLoggin1 )
        this.schedule.teamSnapshot = 1;
      else
        this.schedule.teamSnapshot = 2;
    },
    'autoLoggin2': function() {
      if( this.autoLoggin2 )
        this.autoLoggin1 = false;
      if( ! this.autoLoggin1 && ! this.autoLoggin2 )
        this.schedule.teamSnapshot = 0;
      else if( this.autoLoggin1 )
        this.schedule.teamSnapshot = 1;
      else
        this.schedule.teamSnapshot = 2;
    },
  },
  methods: {
    change( checked ) {
      // this appends before the update of this.selected (that still contain the previous selection)
      console.log("----- --------------------- checked = " + checked);
    },
    setOverlapDay() {
      if( this.startTimeString.toString() != '' && this.endTimeString.toString() != '' ) {
        let start = this.timeStringToNumber( this.startTimeString );
        let end = this.timeStringToNumber( this.endTimeString );
        this.overlapDay = (start >= end);
      }
      else
        this.overlapDay = false;
    },
    onSubmit() {
      if( ! this.validate() )
        return;
      axiosInstance.post('/schedule/' + this.id, this.schedule)
      .then((resp) => {
        //console.log("----- onSubmit ok = " + JSON.stringify(resp.data, null, 2));
        this.schedule = resp.data;
        this.id = this.schedule._id;
        this.errors.push(this.$i18n.t('configSched.success'));
        this.msgColor = "green";
      }).catch(() => {
        console.log("----- onSubmit error ");
      });
    },
    validate() {
      this.errors = [];
      this.msgColor = "red";

      this.schedule.startTime = this.timeStringToNumber( this.startTimeString );
      this.schedule.endTime = this.timeStringToNumber( this.endTimeString );
      this.schedule.lunchTime = this.timeStringToNumber( this.lunchTimeString );
      this.schedule.lunchLength = Number(this.lunchLength)
      this.schedule.breakLength = Number(this.breakLength)
      this.schedule.breakTime1 = this.timeStringToNumber( this.breakTime1String );
      this.schedule.breakTime2 = this.timeStringToNumber( this.breakTime2String );

      let s = this.schedule;

      let found = false;
      for( let i=0; i<7; i++ )
        if( s.weekDays.length < i+1 )
          s.weekDays.push( 0 );
        else if( s.weekDays[i] == null )
          s.weekDays[i] = 0;
        else if( s.weekDays[i] == 1 )
          found = true;
      if( ! found )
        this.errors.push(this.$i18n.t('configSched.noWeekDay'));
      if( s.startTime == -1 )
        this.errors.push(this.$i18n.t('configSched.noStartTime'));
      if( s.endTime == -1 )
        this.errors.push(this.$i18n.t('configSched.noEndTime'));
      if( s.description.length < 3 )
        this.errors.push(this.$i18n.t('configSched.descLength'));

      if( this.schedule.lunchTime != -1 && this.factorySetup.lunchMinutes == 0 )
        this.errors.push(this.$i18n.t('configSched.missLunch'));
      if ( this.schedule.lunchLength > 90 )
        this.errors.push(this.$i18n.t('configSched.maxLunch'))
      if ( this.schedule.breakLength > 90 )
        this.errors.push(this.$i18n.t('configSched.maxBreak'))
      if( (this.schedule.breakTime1 != -1 || this.schedule.breakTime2 != -1) && this.factorySetup.breakMinutes == 0 )
        this.errors.push(this.$i18n.t('configSched.missBreak'));
      if( ! this.errors.length && ! this.verifyTimes() )
        this.errors.push(this.$i18n.t('configSched.badTimes'));

      this.schedule.breaks = this.breaks.map((breakInfo) => {
        if (breakInfo.durationInMinutes > 90) this.errors.push(this.$i18n.t('configSched.maxBreak'))
        breakInfo.startTimeInMinutes = this.timeStringToNumber(breakInfo.startTimeString)
        breakInfo.durationInMinutes = Number(breakInfo.durationInMinutes)
        return breakInfo
      })
      return ! this.errors.length;
    },
    clickRetour() {
      this.$router.replace("/configSchedulesList");
    },
    timeStringToNumber( t ) {
      if( t.length != 5 )
        return -1;
      let h = parseInt(t);
      let m = parseInt(t.substring(3));
      if( ! isNaN(h) && ! isNaN(m) )
        return h*60+m;
      else
        return -1;
    },
    timeNumberToString( t ) {
      if( typeof t === 'number' && ! isNaN(t) && t != -1 ) {
        let h = Math.floor(t / 60);
        let m = t - (h * 60);
        if (h < 10) {h = "0"+h;}
        if (m < 10) {m = "0"+m;}
        return h+':'+m;
      }
      else
        return '';
    },
    verifyTimes() {
      this.keyOfBreaks++
      if (this.breaks.length <= 0) return true

      let endOfSchedule = this.schedule.endTime
      let endInTheNextDay = false

      if (this.schedule.startTime > this.schedule.endTime) {
        endOfSchedule += (60 * 24)
        endInTheNextDay = true
      }

      const sortedBreaks = this.breaks.sort((a, b) => {
        if (a.startTimeInMinutes > b.startTimeInMinutes) return 1
        if (b.startTimeInMinutes > a.startTimeInMinutes) return -1
        return  0
      })

      let previousBreak =  sortedBreaks[0]

      for (let i = 1; i < sortedBreaks.length; i++) {
        const breakInfo = sortedBreaks[i]
        let breakEndTime = Number(breakInfo.startTimeInMinutes) + Number(breakInfo.durationInMinutes)
        const previousEndTime = Number(previousBreak.startTimeInMinutes) + Number(previousBreak.durationInMinutes)

        if (breakInfo.startTimeInMinutes < this.schedule.startTime && endInTheNextDay) {
          breakInfo.startTimeInMinutes += (60 * 24)
          breakEndTime = Number(breakInfo.startTimeInMinutes) + Number(breakInfo.durationInMinutes)
        }

        // The break start before schedule begin
        if (breakInfo.startTimeInMinutes < this.schedule.startTime) {
          return false
        }
        
        // The break end after schedule end
        if (endOfSchedule < breakEndTime) {
          return false
        }
        
        // The breaks overlap
        if (previousEndTime > breakInfo.startTimeInMinutes) {
          return false
        }
        previousBreak = breakInfo
      }


      return true;
    },
    schedUpdateFactorySetup( factorySetup ) {
      if( factorySetup )
        this.factorySetup = factorySetup;
    },
    handleSortBreaks () {
      this.breaks = this.breaks.sort((a, b) => {
        if (a.startTimeInMinutes > b.startTimeInMinutes) return 1
        if (b.startTimeInMinutes > a.startTimeInMinutes) return -1
        return  0
      })
    },
    handleBreakChange(indexOfBreak) {
      this.breaks[indexOfBreak].startTimeInMinutes = this.timeStringToNumber(this.breaks[indexOfBreak].startTimeString)
    },
    handleCloseBreak(indexOfBreak) {
      this.breaks.splice(indexOfBreak, 1)
    },
    addDefaultBreakIntoBreaks() {
      const now = new Date()
      const startTimeString = `${now.getHours().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}:${now.getMinutes().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}`
      this.breaks.push({
        startTimeInMinutes: now.getHours() * 60 + now.getMinutes(),
        durationInMinutes: 15,
        startTimeString
      })
    }
  },
  mounted() {
    if( this.id != "0" ) {
      axiosInstance.get('/schedule/' + this.id)
      .then(resp => {
        Object.assign( this.schedule, resp.data );
        this.startTimeString = this.timeNumberToString( this.schedule.startTime );
        this.endTimeString = this.timeNumberToString( this.schedule.endTime );
        
        this.lunchTimeString = this.timeNumberToString( this.schedule.lunchTime );
        this.lunchLength = this.schedule.lunchLength
        this.breakLength = this.schedule.breakLength
        this.breakTime1String = this.timeNumberToString( this.schedule.breakTime1 );
        this.breakTime2String = this.timeNumberToString( this.schedule.breakTime2 );
        
        this.autoLoggin1 = false;
        this.autoLoggin2 = false;
        if( this.schedule.teamSnapshot == 1 )
          this.autoLoggin1 = true;
        if( this.schedule.teamSnapshot == 2 )
          this.autoLoggin2 = true;
        
        const updateBreaks = () => {
          this.breaks = []
          if (this.schedule.lunchStart > 0) this.breaks.push({
              startTimeInMinutes: this.schedule.lunchStart,
              durationInMinutes: this.lunchLength,
              startTimeString: this.timeNumberToString(this.schedule.lunchStart)
            },)

          if (this.schedule.breakTime1 > 0) this.breaks.push({
            startTimeInMinutes: this.schedule.breakTime1,
            durationInMinutes: this.breakLength,
            startTimeString: this.timeNumberToString(this.schedule.breakTime1)
          })

          if (this.schedule.breakTime2 > 0) this.breaks.push({
            startTimeInMinutes: this.schedule.breakTime2,
            durationInMinutes: this.breakLength,
            startTimeString: this.timeNumberToString(this.schedule.breakTime2)
          })
        }

        if (this.schedule.breaks) this.breaks = this.schedule.breaks.map(breakInfo => ({ ...breakInfo, startTimeString: this.timeNumberToString(breakInfo.startTimeInMinutes) }))
        else updateBreaks()

        const makeFactorySetupRequest = (this.lunchLength < 0 || this.breakLength < 0) && !this.schedule.breaks
        if (makeFactorySetupRequest) axiosInstance.get('/factorySetup').then(({ data: factorySetupData }) => {
          if (this.lunchLength < 0) {
            if (factorySetupData.lunchMinutes) this.lunchLength = factorySetupData.lunchMinutes
            else this.lunchLength = 60
          }
          if (this.breakLength < 0) {
            if (factorySetupData.breakMinutes) this.breakLength = factorySetupData.breakMinutes
            else this.breakLength = 15
          }
          updateBreaks()
        })

      })
      .catch(err => {
        console.log("----- get /schedule error = " + JSON.stringify(err, null, 2));
      });
    }

    this.schedUpdateFactorySetup( oper.getFactorySetup() );

    EventBus.$on('factorySetup', this.schedUpdateFactorySetup);
  },
  beforeDestroy() {
    EventBus.$off('factorySetup', this.schedUpdateFactorySetup);
  },
  created() {
    this.id = this.$route.params.id;
    //console.log("----- this.id = " + JSON.stringify(this.id, null, 2));
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/colors.scss';

form {
  background: White;
  padding: 2rem 2rem 1rem;
  width: 100%;
}

label {
  font-weight: bold;
}

.btn-group > .btn {
  border-radius: 0;
  border-color: $light-grey;
}
.btn-group input {
  display: none;
}

.btn-dayOut {
  background-color: #f0ad4e !important;
  color: White !important;
  font-weight: bold !important;
}
.btn-dayIn {
  background-color: #449d44 !important;
  color: Black !important;
  font-weight: bold !important;
}
.selectedDays {
  margin-left: 15px;
  color: #449d44;
}

.break-title-container {
  display: flex;
  align-items: center;
}
.break-title-container .sort-button {
  margin-left: 1rem;
  font-size: .8rem;
  height: 2rem;
}

.breaks-container {
  display: grid;
  grid-template-columns: 1fr 1fr 4rem;
  justify-content: center;
  align-items: center;
}

.breaks-container .close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
}

.add-break-button > div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
}

.add-break-button button {
  width: 100%;
  height: 2.5rem;
  border-radius: 5px;
}

</style>
