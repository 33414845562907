<template>
  <div class="g-page-container">
    <div class="g-pageHeader" style="height: 63px">
      <h1 style="margin: auto">{{ $t("reports.title") }}</h1>
    </div>
    <form class="g-pageBody fontsReport" @submit.prevent="onSubmit">
      <b-form-group>
        <!-- Cleaning report -->
        <div class="row">
          <div class="col-md-3">
            <label>Cleaning report</label>
          </div>
          <div class="col-md-4">
            <label>
              <b-form-radio
                v-model="selected"
                name="rap"
                @change="change"
                value="cleaning-excel"
              >
                Excel
              </b-form-radio>
            </label>
          </div>
        </div>
        <!-- Daily report -->
        <div class="row">
          <div class="col-md-3">
            <label>{{ $t("reports.daily") }}</label>
          </div>
          <div class="col-md-4">
            <label id="comment2" class="radio-inline"
              ><b-form-radio
                v-model="selected"
                name="rap"
                @change="change"
                value="pdfdailyregreport"
                >{{ $t("reports.pdfDailyStd")
                }}<span style="color: red"> &#128712;</span></b-form-radio
              ></label
            >
          </div>
          <div class="col-md-3">
            <label id="comment3" class="radio-inline" style="margin-left: -30px"
              ><b-form-radio
                v-model="selected"
                name="rap"
                @change="change"
                value="pdfdailyoeereport"
                >{{ $t("reports.pdfDailyOEE")
                }}<span style="color: red"> &#128712;</span></b-form-radio
              ></label
            >
          </div>
        </div>
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-4">
            <label class="radio-inline"
              ><b-form-radio
                v-model="selected"
                name="rap"
                @change="change"
                value="xlsdailyreport"
                >{{ $t("reports.xlsDaily") }}</b-form-radio
              ></label
            >
          </div>
          <div class="col-md-3">
            <label class="radio-inline" style="margin-left: -30px"
              ><b-form-radio
                v-model="selected"
                name="rap"
                @change="change"
                value="xlsdailyoeereport"
                >{{ $t("reports.xlsDailyOEE") }}</b-form-radio
              ></label
            >
          </div>
        </div>

        <!-- Downtime report -->
        <div class="row">
          <div class="col-md-12">
            <b-form-radio
              v-model="selected"
              name="rap"
              @change="change"
              value="downtimereport"
              >{{ $t("reports.downtime") }}</b-form-radio
            >
          </div>
        </div>

        <!-- Employees list -->
        <div class="row" style="margin-top: 10px">
          <div class="col-md-12">
            <b-form-radio
              v-model="selected"
              name="rap"
              @change="change"
              value="emplistreport"
              >{{ $t("reports.emplist") }}</b-form-radio
            >
          </div>
        </div>

        <!-- Employees report -->
        <div class="row" style="margin-top: 10px">
          <div class="col-md-3">
            <label>{{ $t("reports.emp") }}</label>
          </div>
          <div class="col-md-3">
            <label class="radio-inline"
              ><b-form-radio
                v-model="selected"
                name="rap"
                @change="change"
                value="empdailyreport"
                >{{ $t("reports.empDaily") }}</b-form-radio
              ></label
            >
          </div>
          <div class="col-md-6">
            <label class="radio-inline" style="margin-left: -30px"
              ><b-form-radio
                v-model="selected"
                name="rap"
                @change="change"
                value="empdetailreport"
                >{{ $t("reports.empDetail") }}</b-form-radio
              ></label
            >
          </div>
        </div>

        <!-- Products report -->
        <div class="row">
          <div class="col-md-3">
            <label>{{ $t("reports.products") }}</label>
          </div>
          <div class="col-md-3">
            <label class="radio-inline"
              ><b-form-radio v-model="selected" name="rap" @change="change" value="effreport">{{ $t("reports.eff") }}</b-form-radio></label
            >
          </div>
          <div class="col-md-6">
            <label class="radio-inline" style="margin-left: -30px"
              ><b-form-radio
                v-model="selected"
                name="rap"
                @change="change"
                value="prodreport"
                >{{ $t("reports.prod") }}</b-form-radio
              ></label
            >
          </div>
        </div>

        <!-- Quality report -->
        <div class="row">
          <div class="col-md-3">
            <label>{{ $t("reports.quality") }}</label>
          </div>
          <div class="col-md-3">
            <label class="radio-inline"
              ><b-form-radio
                v-model="selected"
                name="rap"
                @change="change"
                value="qreport"
                >{{ $t("reports.review") }}</b-form-radio
              ></label
            >
            <input
              type="file"
              ref="chooseQcFile"
              accept=".xlsx"
              style="display: none"
              @change="selectedQcFile"
            />
          </div>
          <div class="col-md-6">
            <label class="radio-inline" style="margin-left: -30px"
              ><b-form-radio
                v-model="selected"
                name="rap"
                @change="change"
                value="qereport"
                >{{ $t("reports.qemp") }}</b-form-radio
              ></label
            >
          </div>
        </div>

        <!-- Shipping report -->
        <div class="row">
          <div class="col-md-3">
            {{ $t("reports.ship") }}
          </div>
          <div class="col-md-3">
            <b-form-radio
              v-model="selected"
              name="rap"
              @change="change"
              value="shipreport"
              >{{ $t("reports.byOID") }}</b-form-radio
            >
          </div>
          <div class="col-md-3">
            <label class="radio-inline" style="margin-left: -30px">
              <b-form-radio
                name="rap"
                v-model="selected"
                value="shipreportID"
                >{{ $t("reports.byO_journey") }}</b-form-radio
              >
            </label>
          </div>
          <div class="col-md-3">
            <label class="radio-inline" style="margin-left: -30px">
              <b-form-radio
                name="rap"
                v-model="selected"
                value="shipReportJourney"
                >{{ $t("reports.singgle_order_journey") }}</b-form-radio
              >
            </label>
          </div>
        </div>

        <!-- Standards report -->
        <div class="row" style="margin-top: 10px">
          <div class="col-md-12">
            <b-form-radio
              v-model="selected"
              name="rap"
              @change="change"
              value="stdreport"
              >{{ $t("reports.std") }}</b-form-radio
            >
          </div>
        </div>

        <!-- System report -->
        <div class="row" style="margin-top: 10px">
          <div class="col-md-12">
            <b-form-radio
              v-model="selected"
              name="rap"
              @change="change"
              value="sysreport"
              >{{ $t("reports.sys") }}</b-form-radio
            >
          </div>
        </div>
      </b-form-group>

      <b-popover
        target="comment2"
        triggers="hover"
        placement="top"
        variant="primary"
      >
        {{ $t("reports.comment2") }}
      </b-popover>
      <b-popover
        target="comment3"
        triggers="hover"
        placement="top"
        variant="primary"
      >
        {{ $t("reports.comment3") }}
      </b-popover>
    </form>
    <form class="g-pageBody" @submit.prevent="onSubmit">
      <!-- Quality Performance / Review -->
      <div v-if="selected == 'qreport'" class="marginTop10px">
        <div class="row">
          <div class="col-md-6">
            <MultipleSelect
              :data="weekList"
              v-model="weekResult"
              :options="optQreportWeek"
            >
            </MultipleSelect>
          </div>
          <div class="col-md-2"></div>
          <div class="col-md-4">
            <div class="form-group">
              <input type="checkbox" id="splitLines" v-model="splitLines" />
              <label class="checkboxLabel" for="splitLines">{{
                $t("reports.splitLines")
              }}</label>
            </div>
            <div v-if="haveFileQC" class="form-group" style="margin-top: -15px">
              <input type="checkbox" id="newFileQC" v-model="newFileQC" />
              <label class="checkboxLabel" for="newFileQC">{{
                $t("reports.newFileQC")
              }}</label>
            </div>
          </div>
        </div>
      </div>

      <!-- Quality Performance / Employee report -->
      <div
        v-else-if="selected == 'qereport'"
        class="marginTop10px"
        style="margin-bottom: 10px"
      >
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="greenBoldText" style="width: 100px">{{
                $t("reports.fromWeek")
              }}</label>
              <MultipleSelect
                :data="weekListFrom"
                v-model="weekResultFrom"
                @change="dateChange()"
                :options="optQreportWeek"
              >
              </MultipleSelect>
            </div>
            <div class="form-group marginTop10px">
              <label class="greenBoldText" style="width: 100px">{{
                $t("reports.toWeek")
              }}</label>
              <MultipleSelect
                :data="weekListTo"
                v-model="weekResultTo"
                @change="dateChange()"
                :options="optQreportWeek"
              >
              </MultipleSelect>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="greenBoldText" style="width: 100px">{{
                $t("reports.qEmps")
              }}</label>
              <MultipleSelect
                :data="qEmpList"
                v-model="qEmpResult"
                multiple
                :options="optQreportEmp"
              >
              </MultipleSelect>
            </div>
          </div>
        </div>
      </div>
      <!-- shipping Report By ID -->
      <div
        v-else-if="selected == 'shipreportID'"
        class="marginTop10px"
        style="margin-bottom: 10px"
      >
        <div class="row">
          <div class="col-md-4">
            <!-- ==================== column 1 ==================== -->

            <div v-if="selected != 'emplistreport'" class="form-group">
              <label class="greenBoldText" style="width: 45px">{{
                $t("reports.from")
              }}</label>
              <input v-model="reportFrom" type="date" @input="dateChange" />
            </div>
            <div
              v-if="selected != 'emplistreport'"
              style="margin-top: -10px"
              class="form-group"
            >
              <label class="greenBoldText" style="width: 45px">{{
                $t("reports.to")
              }}</label>
              <input v-model="reportTo" type="date" @input="dateChange" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="row">
              <span class="warning-message" style="font-weight: 600">{{
                $t("reports.waitingNotice")
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- Shipping Report By Journey-->
      <div
        v-else-if="selected == 'shipReportJourney'"
        class="marginTop10px"
        style="margin-bottom: 10px"
      >
        <div class="row">
          <div class="col-md-4">
            <!-- ==================== column 1 ==================== -->

            <div class="form-group">
              <label class="greenBoldText" style="width: 45px">{{
                $t("reports.from")
              }}</label>
              <input v-model="reportFrom" type="date" @input="dateChange" />
            </div>
            <div style="margin-top: -10px" class="form-group">
              <label class="greenBoldText" style="width: 45px">{{
                $t("reports.to")
              }}</label>
              <input v-model="reportTo" type="date" @input="dateChange" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="row">
              <div class="col-lg-12">
                <label class="greenBoldText">{{
                  $t("reports.searchID")
                }}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <input
                  type="text"
                  v-model="search"
                  placeholder="Enter the Order"
                  required
                />
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="row">
              <span class="warning-message" style="font-weight: 600">{{
                $t("reports.waitingNotice")
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <div
        v-else-if="selected == 'cleaning-excel'"
        class="marginTop10px"
        style="margin-bottom: 10px"
      >
        <div class="row">
          <div class="col-md-4">
            <div v-if="selected != 'emplistreport'" class="form-group">
              <label class="greenBoldText" style="width: 45px">{{
                $t("reports.from")
              }}</label>
              <input v-model="reportFrom" type="date" @input="dateChange" />
            </div>
            <div
              v-if="selected != 'emplistreport'"
              style="margin-top: -10px"
              class="form-group"
            >
              <label class="greenBoldText" style="width: 45px">{{
                $t("reports.to")
              }}</label>
              <input v-model="reportTo" type="date" @input="dateChange" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <MultipleSelect
                :data="qEmpList"
                v-model="qEmpResult"
                multiple
                :options="optQreportEmp"
              >
              </MultipleSelect>
            </div>
          </div>
        </div>
      </div>

      <div v-else-if="selected != ''" class="marginTop10px">
        <div class="row">
          <div class="col-md-4">
            <!-- ==================== column 1 ==================== -->

            <div v-if="selected != 'emplistreport'" class="form-group">
              <label class="greenBoldText" style="width: 45px">{{
                $t("reports.from")
              }}</label>
              <input v-model="reportFrom" type="date" @input="dateChange" />
            </div>
            <div
              v-if="selected != 'emplistreport'"
              style="margin-top: -10px"
              class="form-group"
            >
              <label class="greenBoldText" style="width: 45px">{{
                $t("reports.to")
              }}</label>
              <input v-model="reportTo" type="date" @input="dateChange" />
            </div>

            <div v-if="selected == 'xlsdailyreport'">
              <MultipleSelect
                ref="schedSelect"
                :data="schedList"
                v-model="schedResult"
                multiple
                :options="optSched"
              >
              </MultipleSelect>
            </div>
          </div>
          <div class="col-md-4">
            <!-- ==================== column 2 ==================== -->

            <div
              v-if="
                selected == 'pdfdailyregreport' ||
                selected == 'pdfdailyoeereport' ||
                selected == 'xlsdailyoeereport'
              "
            >
              <MultipleSelect
                ref="lineSelect0"
                :data="lineList[0]"
                v-model="lineResult[0]"
                multiple
                @change="clearError()"
                :options="optLine"
              >
              </MultipleSelect>
            </div>

            <div
              v-if="selected == 'xlsdailyreport'"
              style="margin-bottom: 20px"
            >
              <MultipleSelect
                ref="lineSelect0"
                :data="lineList[0]"
                v-model="lineResult[0]"
                multiple
                :options="optLine"
                @change="listChange(0)"
              >
              </MultipleSelect>
              <MultipleSelect
                class="marginTop10px"
                ref="lineSelect1"
                :data="lineList[1]"
                v-model="lineResult[1]"
                multiple
                :options="optLine"
                @change="listChange(1)"
              >
              </MultipleSelect>
              <MultipleSelect
                class="marginTop10px"
                ref="lineSelect2"
                :data="lineList[2]"
                v-model="lineResult[2]"
                multiple
                :options="optLine"
                @change="listChange(2)"
              >
              </MultipleSelect>
              <MultipleSelect
                class="marginTop10px"
                ref="lineSelect3"
                :data="lineList[3]"
                v-model="lineResult[3]"
                multiple
                :options="optLine"
                @change="listChange(3)"
              >
              </MultipleSelect>
            </div>

            <div
              v-if="selected == 'downtimereport' || selected == 'shipreport'"
            >
              <MultipleSelect
                ref="lineSelect0"
                :data="lineList[0]"
                v-model="lineResult[0]"
                multiple
                :options="optLine"
              >
              </MultipleSelect>
            </div>

            <div v-if="selected == 'effreport' || selected == 'prodreport'">
              <MultipleSelect
                ref="stdCatSelect"
                :data="stdCatList"
                v-model="stdCatResult"
                multiple
                :options="optStdCat"
              >
              </MultipleSelect>
              <MultipleSelect
                class="marginTop20px"
                ref="stdSelect"
                :data="stdList"
                v-model="stdResult"
                multiple
                :options="optStd"
              >
              </MultipleSelect>
            </div>

            <div
              v-if="
                selected == 'empdailyreport' || selected == 'empdetailreport'
              "
            >
              <MultipleSelect
                ref="empSelect"
                :data="empList"
                v-model="empResult"
                multiple
                :options="optEmp"
              >
              </MultipleSelect>
            </div>
          </div>
          <div class="col-md-4">
            <!-- ==================== column 3 ==================== -->

            <div
              v-if="
                selected == 'pdfdailyregreport' ||
                selected == 'pdfdailyoeereport'
              "
              style="margin-bottom: -30px"
            >
              <div class="row">
                <div class="form-group">
                  <label class="radio-inline">
                    <b-form-radio
                      v-model="addPageJump"
                      name="addPageJump"
                      value="0"
                      >{{ $t("reports.addPageJump0") }}
                    </b-form-radio>
                  </label>
                </div>
              </div>
              <div class="row" style="margin-top: -20px">
                <div class="form-group">
                  <label class="radio-inline">
                    <b-form-radio
                      v-model="addPageJump"
                      name="addPageJump"
                      value="1"
                      >{{ $t("reports.addPageJump1") }}
                    </b-form-radio>
                  </label>
                </div>
              </div>
              <div class="row" style="margin-top: -20px">
                <div class="form-group">
                  <label class="radio-inline">
                    <b-form-radio
                      v-model="addPageJump"
                      name="addPageJump"
                      value="2"
                      >{{ $t("reports.addPageJump2") }}
                    </b-form-radio>
                  </label>
                </div>
              </div>
            </div>

            <div v-if="selected == 'xlsdailyreport'">
              <MultipleSelect
                ref="lineSelect4"
                :data="lineList[4]"
                v-model="lineResult[4]"
                multiple
                :options="optLine"
                @change="listChange(4)"
              >
              </MultipleSelect>
              <MultipleSelect
                class="marginTop10px"
                ref="lineSelect5"
                :data="lineList[5]"
                v-model="lineResult[5]"
                multiple
                :options="optLine"
                @change="listChange(5)"
              >
              </MultipleSelect>
              <MultipleSelect
                class="marginTop10px"
                ref="lineSelect6"
                :data="lineList[6]"
                v-model="lineResult[6]"
                multiple
                :options="optLine"
                @change="listChange(6)"
              >
              </MultipleSelect>
              <MultipleSelect
                class="marginTop10px"
                ref="lineSelect7"
                :data="lineList[7]"
                v-model="lineResult[7]"
                multiple
                :options="optLine"
                @change="listChange(7)"
              >
              </MultipleSelect>
            </div>

            <div v-if="selected == 'effreport' || selected == 'prodreport'">
              <div class="form-group">
                <input
                  type="checkbox"
                  id="withInactive"
                  v-model="withInactive"
                />
                <label class="checkboxLabel" for="withInactive">{{
                  $t("reports.withInactive")
                }}</label>
              </div>
            </div>
            <div v-if="selected == 'effreport' || selected == 'prodreport'">
              <div class="form-group" style="display: flex">
                <input
                  type="checkbox"
                  id="unCompletedOrders"
                  v-model="unCompletedOrders"
                />
                <label class="checkboxLabel" for="unCompletedOrders">
                  {{ $t("reports.uncompleteOrder") }}
                </label>
              </div>
            </div>

            <div v-if="selected === 'shipreport'">
              <div class="row">
                <span class="warning-message" style="font-weight: 600">{{
                  $t("reports.waitingNotice")
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="width: 50%; margin: auto">
        <label v-if="error" class="error">{{ error }}</label>
      </div>

      <div style="width: 32%; margin: auto">
        <button class="btn btn-perfcell" @click.prevent="clickRetour">
          {{ $t("reports.cancel") }}
        </button>

        <button v-if="showSpinner" class="btn btn-perfcell marginLeft10px">
          <b-spinner></b-spinner>
        </button>
        <button v-else class="btn btn-perfcell marginLeft10px" type="submit">
          {{ $t("reports.submit") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { axiosInstance } from "@/main";
import { axiosInstanceReports } from "@/main";
import moment from "moment-timezone";
import oper from "@/stores/Operations.js";
// import VueSuggestion from "vue-suggestion";
// import "vue-suggestion/dist/vue-suggestion.css";
// import VueSuggestion from "../components/Autocomplete/Autocomplete.vue";
export default {
  data() {
    return {
      search: "",
      selected: "pdfdailyregreport",
      lastSelected: "pdfdailyregreport",
      reportFrom: "",
      reportTo: "",
      error: "",
      orderId: "",

      lineList: [[], [], [], [], [], [], [], []],
      lineListOEE: [],
      lineListNotOEE: [],
      schedList: [],
      stdCatList: [],
      stdList: [],
      empList: [],
      qEmpList: [],
      weekList: [],
      weekListFrom: [],
      weekListTo: [],

      lineResult: [[], [], [], [], [], [], [], []],
      schedResult: [],
      stdCatResult: [],
      stdResult: [],
      empResult: [],
      qEmpResult: [],
      weekResult: null,
      weekResultFrom: null,
      weekResultTo: null,

      showSpinner: false,

      fileQC: null,
      qcFilesList: [],

      splitLines: false,
      newFileQC: false,
      haveFileQC: false,

      receivedStdList: [],

      withInactive: false,
      unCompletedOrders: false,

      addPageJump: 0,

      optSched: {
        multiple: true,
        filter: true,
        placeholder: this.$i18n.t("reports.schedPHolder"),
        multipleWidth: 200,
        width: 200,
        dropWidth: 200,
        position: "top",
        locale: localStorage.getItem("locale"),
      },
      optLine: {
        multiple: true,
        filter: true,
        placeholder: this.$i18n.t("reports.linePHolder"),
        multipleWidth: 200,
        width: 200,
        dropWidth: 200,
        position: "top",
        locale: localStorage.getItem("locale"),
      },
      optStdCat: {
        multiple: true,
        filter: true,
        placeholder: this.$i18n.t("reports.stdCatPHolder"),
        multipleWidth: 200,
        width: 200,
        dropWidth: 200,
        position: "top",
        locale: localStorage.getItem("locale"),
      },
      optStd: {
        multiple: true,
        filter: true,
        placeholder: this.$i18n.t("reports.stdPHolder"),
        multipleWidth: 200,
        width: 200,
        dropWidth: 200,
        position: "top",
        locale: localStorage.getItem("locale"),
      },
      optEmp: {
        multiple: true,
        filter: true,
        placeholder: this.$i18n.t("reports.empPHolder"),
        multipleWidth: 200,
        width: 200,
        dropWidth: 200,
        position: "top",
        locale: localStorage.getItem("locale"),
      },
      optQreportWeek: {
        filter: true,
        placeholder: this.$i18n.t("reports.weekPHolder"),
        multipleWidth: 260,
        width: 260,
        dropWidth: 260,
        position: "top",
        locale: localStorage.getItem("locale"),
      },
      optQreportEmp: {
        filter: true,
        placeholder: this.$i18n.t("reports.empPHolder"),
        multipleWidth: 260,
        width: 260,
        dropWidth: 260,
        position: "top",
        locale: localStorage.getItem("locale"),
      },
    };
  },
  // components: { VueSuggestion },
  watch: {
    weekResult: function (val) {
      if (val !== "undefined") {
        this.setHaveFileQC();
        this.newFileQC = false;
      }
    },
    withInactive: function (val) {
      this.setStdList(this.stdCatResult, val);
    },
    stdCatResult: function (val) {
      this.setStdList(val, this.withInactive);
    },
  },
  methods: {
    onChange() {
      // Let's warn the parent that a change was made
      this.$emit("input", this.search);
      this.getOrdersList(this.search);
      // Is the data given by an outside ajax request?
      if (this.isAsync) {
        this.isLoading = true;
      } else {
        // Let's search our flat array
        this.filterResults();
        this.isOpen = true;
      }
    },
    onSubmitOrderId() {
      console.log(moment(this.reportFrom).isBefore(moment(this.reportTo)));
    },
    onSelectId(data) {
      console.log("Data from Child", data);
    },

    setHaveFileQC() {
      if (this.qcFilesList.length == 0 || this.weekResult == null) return;
      this.haveFileQC = this.qcFilesList.includes(
        this.weekList[this.weekResult].week.toString()
      );
    },
    setStdList(catList, withInactive) {
      this.stdResult = [];
      if (catList.length == 0 || catList.length == this.stdCatList.length) {
        // if no category selected or all categories selected
        if (withInactive) this.stdList = this.receivedStdList;
        else {
          this.stdList = [];
          this.receivedStdList.forEach((entry) => {
            if (entry.isActive) {
              this.stdList.push(entry);
            }
          });
        }
      } else {
        this.stdList = [];
        this.receivedStdList.forEach((entry) => {
          if (
            catList.includes(entry.category) &&
            (entry.isActive || withInactive)
          ) {
            this.stdList.push(entry);
          }
        });
      }
    },
    listChange(index) {
      //console.log("----- index = " + JSON.stringify(index, null, 2));
      //console.log("----- lineResult[0] = " + JSON.stringify(this.lineResult[0], null, 2));
      //console.log("----- lineResult[1] = " + JSON.stringify(this.lineResult[1], null, 2));
      //console.log("----- lineResult[2] = " + JSON.stringify(this.lineResult[2], null, 2));
      for (let i = 0; i < 8; i++) {
        if (i != index) {
          let test = JSON.parse(JSON.stringify(this.lineResult[i]));
          this.lineResult[index].forEach((sel) => {
            if (test.indexOf(sel) != -1) test.splice(test.indexOf(sel), 1);
          });
          if (test.length != this.lineResult[i].length)
            this.$refs["lineSelect" + i].setSelects(test);
        }
      }
    },
    clearError() {
      this.error = "";
    },
    dateChange() {
      console.log("----- dateChange");
      this.error = "";
      this.fileQC = null;
    },
    change(checked) {
      // this appends before the update of this.selected (that still contain the previous selection)
      // but that seem not true so I have created this.lastSelected
      console.log("----- selected report = " + checked);
      //console.log("----- this.selected = " + this.selected);
      //console.log("----- this.lastSelected = " + this.lastSelected);
      this.error = "";
      this.fileQC = null;

      if (checked == "pdfdailyregreport") {
        this.lineList[0] = JSON.parse(JSON.stringify(this.lineListNotOEE));
      } else if (
        checked == "pdfdailyoeereport" ||
        checked == "xlsdailyoeereport"
      ) {
        this.lineList[0] = JSON.parse(JSON.stringify(this.lineListOEE));
      } else if (
        this.lastSelected == "pdfdailyregreport" ||
        this.lastSelected == "pdfdailyoeereport" ||
        this.lastSelected == "xlsdailyoeereport"
      ) {
        this.lineList[0] = JSON.parse(JSON.stringify(this.lineList[1]));
      }
      if (this.$refs.lineSelect0) {
        this.$refs.lineSelect0.uncheckAll();
      }
      this.$nextTick(function () {
        if (this.$refs.lineSelect0) {
          this.$refs.lineSelect0.refresh();
        }
      });

      this.lastSelected = checked;
    },
    selectedQcFile(event) {
      let file = event.target.files;
      if (!file.length) return;
      //console.log("----- QC file name = " + file[0].name);
      //console.log("----- QC file size = " + file[0].size);
      if (!file[0].name.length) return;
      if (file[0].size > 1048576) {
        this.$bvModal.msgBoxOk(this.$i18n.t("reports.toBigQC"));
        return;
      }
      this.fileQC = file[0];
      this.onSubmit();
    },
    async makeCleaningReportExcel() {
      this.showSpinner = true;
      const generationFileResponse = await axiosInstanceReports.post(
        "/rep/cleaning-report",
        {
          headers: { "Content-Type": "multipart/form-data" },
          employees: this.qEmpResult,
          type: "excel",
          from: this.reportFrom,
          to: this.reportTo,
        }
      );

      axiosInstanceReports
        .get(generationFileResponse.data.path, { responseType: "blob" })
        .then((fileResponse) => {
          const url = window.URL.createObjectURL(new Blob([fileResponse.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${generationFileResponse.data.filename}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
      this.showSpinner = false;
    },
    onSubmit() {
      console.log("unCompletedOrders ", this.unCompletedOrders);

      //console.log("----- selected-report = " + this.selected);
      //console.log("----- reportFrom = " + this.reportFrom);
      //console.log("----- reportTo = " + this.reportTo);

      if (moment(this.reportFrom).isAfter(moment(this.reportTo))) {
        this.error = this.$i18n.t("reports.before");
        return;
      }
      if (
        this.selected == "qereport" &&
        this.weekResultFrom < this.weekResultTo
      ) {
        this.error = this.$i18n.t("reports.before");
        return;
      }
      let maxDays = 31;
      if (
        this.selected == "shipreport" &&
        moment(this.reportTo).diff(this.reportFrom, "days") >= maxDays
      ) {
        this.error = maxDays.toString() + this.$i18n.t("reports.nbDays");
        return;
      }
      let max_days_shipreport_by_ID = 7;
      if (
        this.selected == "shipreportID" &&
        moment(this.reportTo).diff(this.reportFrom, "days") >=
          max_days_shipreport_by_ID
      ) {
        this.error =
          max_days_shipreport_by_ID.toString() + this.$i18n.t("reports.nbDays");
        return;
      }
      if (this.selected === "cleaning-excel")
        return this.makeCleaningReportExcel();
      if (
        this.selected == "pdfdailyregreport" ||
        this.selected == "pdfdailyoeereport" ||
        this.selected == "xlsdailyoeereport"
      ) {
        if (this.lineResult[0].length == 0) {
          this.error = this.$i18n.t("reports.noLine");
          return;
        }
      }

      if (!this.weekResult) {
        this.weekResult = 0;
      }
      let data = {
        locale: localStorage.getItem("locale"),
      };
      if (this.selected == "qreport") {
        data.start = this.weekList[this.weekResult].from;
        data.end = this.weekList[this.weekResult].to;
        data.week = this.weekList[this.weekResult].week;
        data.splitLines = this.splitLines;
        data.getQData = true;
      } else if (this.selected != "qereport") {
        data.start = moment(this.reportFrom);
        data.end = moment(this.reportTo);
      }

      let formData = new FormData();
      formData.append("data", JSON.stringify(data));

      if (this.selected == "qreport") {
        if (this.fileQC === null) {
          if (
            this.qcFilesList.includes(data.week.toString()) &&
            !this.newFileQC
          )
            this.fileQC = true;
        }
        if (!this.fileQC) {
          this.$refs.chooseQcFile.click();
          return;
        } else {
          formData.append("file", this.fileQC);
          this.fileQC = null;
        }
      }

      if (this.selected == "xlsdailyreport") {
        data.schedData = this.schedResult;
        data.lineData = this.lineResult;
      }
      if (this.selected == "downtimereport") {
        data.lineData = this.lineResult[0];
      }
      if (
        this.selected == "pdfdailyregreport" ||
        this.selected == "pdfdailyoeereport" ||
        this.selected == "xlsdailyoeereport"
      ) {
        data.lineData = this.lineResult[0];
        data.addPageJump = this.addPageJump;
        data.isOEE =
          this.selected == "pdfdailyoeereport" ||
          this.selected == "xlsdailyoeereport";
        data.isExcel = this.selected == "xlsdailyoeereport";
      }
      if (this.selected == "shipreport") {
        formData.append("lineData", JSON.stringify(this.lineResult[0]));
        formData.append("byID", JSON.stringify(false));
      }
      if (this.selected == "shipreportID") {
        formData.append("byID", JSON.stringify(true));
        formData.append("lineData", JSON.stringify([]));
      }

      if (this.selected == "shipReportJourney") {
        formData.append("scanned", this.search);
        formData.append("byID", JSON.stringify(true));
      }
      if (
        this.selected == "empdailyreport" ||
        this.selected == "empdetailreport"
      ) {
        data.empData = this.empResult;
      }
      if (this.selected == "effreport" || this.selected == "prodreport") {
        data.stdCatData = this.stdCatResult;
        data.stdData = this.stdResult;
        data.unCompletedOrders = this.unCompletedOrders;
      }
      if (this.selected == "qereport") {
        data.start = this.weekList[this.weekResultFrom].from;
        data.end = this.weekList[this.weekResultTo].to;
        data.fromWeek = this.weekList[this.weekResultFrom].week;
        data.toWeek = this.weekList[this.weekResultTo].week;
        data.empData = this.qEmpResult;
      }
      //console.log("----- data = " + JSON.stringify(data, null, 2));
      this.showSpinner = true;

      let rapName = this.selected;

      if (
        rapName == "pdfdailyregreport" ||
        rapName == "pdfdailyoeereport" ||
        rapName == "xlsdailyoeereport"
      )
        rapName = "pdfdailyreport";
      axiosInstanceReports
        .post(
          "/rep/" + rapName,
          this.selected == "shipreport" ||
            this.selected == "qreport" ||
            this.selected == "shipreportID" ||
            this.selected == "shipReportJourney"
            ? formData
            : data,
          this.selected == "shipreport" ||
            this.selected == "qreport" ||
            this.selected == "shipreportID"
            ? { headers: { "Content-Type": "multipart/form-data" } }
            : {}
        )
        .then((result) => {
          console.log("----- /rep/ onSubmit ok = "); // + result.data);

          this.$refs.chooseQcFile.value = "";
          if (result.data != "error") {
            let path = result.data.split("/");
            console.log("Path -", path);
            axiosInstanceReports
              .get("/" + path[1] + "/" + path[2], { responseType: "blob" })
              .then((response) => {
                if (path[1] == "qreports") {
                  let reader = new FileReader();
                  reader.readAsText(new Blob([response.data]));
                  let self = this;
                  reader.onload = function () {
                    let qreport = JSON.parse(reader.result);
                    let maxPcent = qreport.shift();
                    oper.setMaxPcent(maxPcent);
                    oper.storeQReport(qreport);

                    let qdata = {
                      splitLines: data.splitLines,
                      end: data.end,
                      start: data.start,
                      week: data.week,
                    };
                    oper.storeQData(qdata);

                    self.$router.replace("/qualityFollowUps").catch(() => {});
                  };
                  reader.onerror = function () {
                    console.log("----- reader.error = " + reader.error);
                  };
                } else {
                  const url = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", path[2]);
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                }
                this.showSpinner = false;
                if (this.selected == "qreport") this.getQcFilesList();
              });
          } else {
            this.$bvModal.msgBoxOk(this.$i18n.t("reports.errQC"));
            this.showSpinner = false;
          }
        })
        .catch(() => {
          console.log("----- /rep/ onSubmit error ");
          this.showSpinner = false;
        });
    },

    clickRetour() {
      this.$router.replace("/operation");
    },
    getQcFilesList() {
      axiosInstanceReports
        .get("/rep/qcFilesList")
        .then((resp) => {
          //console.log("----- get /qcFilesList = " + JSON.stringify(resp.data, null, 2));
          this.qcFilesList = resp.data;
          this.setHaveFileQC();
          this.newFileQC = false;
        })
        .catch((err) => {
          console.log(
            "----- get /qcFilesList error = " + JSON.stringify(err, null, 2)
          );
        });
    },
  },
  mounted() {
    this.reportFrom = moment().format("YYYY-MM-DD");
    this.reportTo = moment().format("YYYY-MM-DD");
    this.fileQC = null;

    axiosInstance
      .get("/lineList", { params: { isActive: true } })
      .then((resp) => {
        this.lineListOEE = [];
        this.lineListNotOEE = [];
        for (let i = 0; i < resp.data.length; i++) {
          resp.data[i].text =
            resp.data[i].name + " - " + resp.data[i].description;
          resp.data[i].value = resp.data[i]._id;
          this.lineListOEE.push(resp.data[i]);
          this.lineListNotOEE.push(resp.data[i]);
        }
        //console.log("----- get /lineList = " + JSON.stringify(resp.data, null, 2));
        let list = JSON.stringify(resp.data);
        for (let i = 0; i < 8; i++) this.lineList[i] = JSON.parse(list);
        console.log("linelist----->", this.lineList[0]);
        this.change("pdfdailyregreport");
      })
      .catch((err) => {
        console.log(
          "----- get /lineList error = " + JSON.stringify(err, null, 2)
        );
      });

    axiosInstance
      .get("/scheduleList", { params: { isActive: true } })
      .then((resp) => {
        for (let i = 0; i < resp.data.length; i++) {
          resp.data[i].text = resp.data[i].description;
          resp.data[i].value = resp.data[i]._id;
        }
        //console.log("----- get /scheduleList = " + JSON.stringify(resp.data, null, 2));
        let list = JSON.stringify(resp.data);
        this.schedList = JSON.parse(list);
      })
      .catch((err) => {
        console.log(
          "----- get /scheduleList error = " + JSON.stringify(err, null, 2)
        );
      });

    axiosInstance
      .get("/categoryList", { params: { isActive: true } })
      .then((resp) => {
        for (let i = 0; i < resp.data.length; i++) {
          resp.data[i].text = resp.data[i].name;
          resp.data[i].value = resp.data[i]._id;
        }
        //console.log("----- get /categoryList = " + JSON.stringify(resp.data, null, 2));
        let list = JSON.stringify(resp.data);
        this.stdCatList = JSON.parse(list);
      })
      .catch((err) => {
        console.log(
          "----- get /categoryList error = " + JSON.stringify(err, null, 2)
        );
      });

    axiosInstance
      .get("/standardList")
      .then((resp) => {
        for (let i = 0; i < resp.data.length; i++) {
          resp.data[i].text =
            resp.data[i].partNumber + " - " + resp.data[i].description;
          resp.data[i].value = resp.data[i]._id;
        }
        //console.log("----- get /standardList = " + JSON.stringify(resp.data, null, 2));
        let list = JSON.stringify(resp.data);
        this.receivedStdList = JSON.parse(list);
        this.stdList = [];
        this.receivedStdList.forEach((entry) => {
          if (entry.isActive) {
            this.stdList.push(entry);
          }
        });
      })
      .catch((err) => {
        console.log(
          "----- get /standardList error = " + JSON.stringify(err, null, 2)
        );
      });

    axiosInstance
      .get("/userList", { params: { isActive: true } })
      .then((resp) => {
        //console.log("----- get /userList = " + JSON.stringify(resp.data, null, 2));
        for (let i = 0; i < resp.data.length; i++) {
          resp.data[i].text =
            resp.data[i].firstName +
            " " +
            resp.data[i].lastName +
            ", " +
            resp.data[i].alias;
          resp.data[i].value = resp.data[i]._id;
        }
        let list = JSON.stringify(resp.data);
        this.empList = JSON.parse(list);

        for (let i = 0; i < resp.data.length; i++) {
          resp.data[i].value = resp.data[i].alias;
        }
        let list2 = JSON.stringify(resp.data);
        this.qEmpList = JSON.parse(list2);
      })
      .catch((err) => {
        console.log(
          "----- get /userList error = " + JSON.stringify(err, null, 2)
        );
      });

    this.weekList = [];
    /* mettre "for( let i=1;" pour utiliser
    this.weekList.push({
      value: 0,
      text: "TEST 13-14 juillet",
      week: 999,
      from: moment("13-07-2020", "DD-MM-YYYY"),
      to: moment("14-07-2020", "DD-MM-YYYY")
    });
    */
    // week 287 is from friday july 3 2020 to thursday july 9 2020
    let now = moment();
    let nbDaysFromStartOfWeek287 = now.diff(
      moment("03-07-2020", "DD-MM-YYYY"),
      "days"
    );
    let weekNumber = Math.trunc(nbDaysFromStartOfWeek287 / 7) + 287;
    let startOfToday = now.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    let firstDayThisWeek = startOfToday.day(startOfToday.day() >= 5 ? 5 : -2);
    let lastDayThisWeek = firstDayThisWeek.clone().add(6, "days");
    let NUMBER_OF_WEEK_BACK = 52;
    for (let i = 0; i < NUMBER_OF_WEEK_BACK; i++) {
      this.weekList.push({
        value: i,
        text:
          this.$i18n.t("reports.week") +
          weekNumber +
          this.$i18n.t("reports.fromWFD") +
          firstDayThisWeek.format(this.$i18n.t("reports.weekDate")) +
          this.$i18n.t("reports.toWLD") +
          lastDayThisWeek.format(this.$i18n.t("reports.weekDate")) +
          lastDayThisWeek.format(" YYYY"),
        week: weekNumber,
        from: firstDayThisWeek,
        to: lastDayThisWeek,
      });
      weekNumber -= 1;
      firstDayThisWeek = firstDayThisWeek.clone().subtract(7, "d");
      lastDayThisWeek = lastDayThisWeek.clone().subtract(7, "d");
    }

    this.weekListFrom = JSON.parse(JSON.stringify(this.weekList));
    this.weekListTo = JSON.parse(JSON.stringify(this.weekList));

    this.getQcFilesList();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/global.scss";

.fontsReport {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

form {
  background: White;
  padding: 2rem 2rem 1rem;
  width: 100%;
}

label {
  margin-right: 20px;
}

.error {
  text-align: center;
  color: red;
}

.ms-drop {
  width: 100%;
}
.ms-drop > li > label {
  width: 100%;
  color: blue;
}

.warning-message {
  color: #f14a16;
}
</style>
