<template>
  <div class="g-page-container">

    <div class="g-pageHeader" style="height: 95px;">
      <h1 style="margin: auto;">{{$t('biCharts.title')}}</h1>
      <h2 style="margin: auto;">{{$t('biChartsQVE.title')}}</h2>
    </div>

    <div class="g-pageBody">

      <b-row>
        <b-col cols="2">
          <div class="form-group">
            <label class="marginTop10px">{{$t('biCharts.from')}}</label><br>
            <input v-model="reportFrom" type="date" @input="dateChange">
          </div>
        </b-col>
        <b-col cols="2">
          <div class="form-group">
            <label class="marginTop10px">{{$t('biCharts.to')}}</label><br>
            <input v-model="reportTo" type="date" @input="dateChange">
          </div>
        </b-col>
        <b-col cols="3">
          <label class="marginTop10px">{{$t('biCharts.selectStd')}}</label><br>
          <MultipleSelect
            :data="stdList"
            v-model="stdResult"
            multiple
            :options=opt
            @change="listChange('std')" >
          </MultipleSelect>
        </b-col>
        <b-col cols="4" offset="1" class="text-center marginBottom10px">
          <button class="btn btn-perfcell marginTop20px" @click="clickGenerate">{{$t('biCharts.generate')}}</button>
          <button class="btn btn-perfcell marginTop20px marginLeft15px" @click="clickExport">{{$t('biCharts.export')}}</button>
          <button class="btn btn-perfcell marginTop20px marginLeft15px" @click="clickRetour">{{$t('biCharts.cancel')}}</button>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <label v-if="error" class="error">{{error}}</label>
        </b-col>
      </b-row>

    </div>

    <b-row>
      <b-col cols="6">
        <div class="g-pageBody" :style="{height: tableHeight}">
          <b-table
            small
            fixed
            striped
            :sticky-header="tableHeight"
            :items="tableData"
            :fields="showFields" >
          </b-table>
        </div>
      </b-col>
      <b-col cols="6" style="text-align: center; padding-left: 0;">
        <div class="g-pageBody">

          <b-row class="g-chart-header" style="display: inline-block">
            {{$t('biChartsQVE.chartTitle')}}
          </b-row>

          <div ref="chartContainer">
            <canvas id="theDailyProdChart" :style="{width: chartWidth + 'px', height: chartHeight + 'px'}"></canvas>
          </div>

        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { axiosInstance } from "@/main";
import { axiosInstanceReports } from "@/main";
import moment from 'moment-timezone';
import { json2excel } from 'js2excel';
import EventBus from '@/eventBus';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import oper from '@/stores/Operations.js';

export default {
  data() {
    return {
      reportFrom: '',
      reportTo: '',
      error: '',

      stdList: [],
      stdResult: [],

      tableData: [],

      chartWidth: 300,
      chartHeight: 300,
      tableHeight: '350px',

      ctx: null,

      doingResize: false,

      opt: {
        multiple: true,
        filter: true,
        placeholder: this.$i18n.t('biCharts.stdPHolder'),
        multipleWidth: 200,
        width: 200,
        dropWidth: 200,
        maxHeight: this.chartHeight,
        locale: localStorage.getItem("locale")
      },

      dateFormat: "MM/DD/YYYY"
    }
  },
  components: {
  },
  computed: {
    showFields() {
      return [
        {
          key: this.$i18n.t('biChartsQVE.headerDate'),
          thStyle: { width: '90px'}
        },
        {
          key: this.$i18n.t('biChartsQVE.headerPart'),
          thStyle: { width: '200px'}
        },
        {
          key: this.$i18n.t('biChartsQVE.headerDesc'),
          thStyle: { width: '200px'}
        },
        {
          key: this.$i18n.t('biChartsQVE.headerQte'),
          thStyle: { width: '70px'}
        },
        {
          key: this.$i18n.t('biChartsQVE.headerEff'),
          thStyle: { width: '90px'}
        },
        {
          key: this.$i18n.t('biChartsQVE.headerSAM'),
          thStyle: { width: '80px'}
        }
      ]
    }
  },
  methods: {
    dateChange() {
      this.error = '';
    },
    listChange( name ) {
      console.log("----- listChange = " + name);
      this.error = '';
    },
    clickGenerate() {
      if( moment(this.reportFrom).isAfter(moment(this.reportTo)) ) {
        this.error = this.$i18n.t('biCharts.before');
        return;
      }
      let maxDays = 31;
      if( moment(this.reportTo).diff(this.reportFrom, 'days') > maxDays ) {
        this.error = maxDays.toString() + this.$i18n.t('biCharts.nbDays');
        return;
      }
      if( this.stdResult.length == 0 ) {
        this.error = this.$i18n.t('biCharts.noStd');
        return;
      }
      let data = {
        locale: localStorage.getItem("locale"),
        start: moment(this.reportFrom),
        end: moment(this.reportTo),
        stdData: this.stdResult
      }
      axiosInstanceReports.post('/rep/qtevseffchart', data )
      .then((result) => {
        console.log("----- /rep/ onSubmit ok = ");// + JSON.stringify(result.data, null, 2));

        result.data.sort(function (a, b) {
          return a.qte > b.qte;
        });

        this.tableData = [];

        for( let i=0; i<result.data.length; i++ ) {
          let oneData = result.data[i];

          let entry = {};
          entry[this.$i18n.t('biChartsQVE.headerDate')] = moment(oneData.date).format(this.dateFormat);
          entry[this.$i18n.t('biChartsQVE.headerPart')] = oneData.partNum;
          entry[this.$i18n.t('biChartsQVE.headerDesc')] = oneData.desc;
          entry[this.$i18n.t('biChartsQVE.headerQte')] = oneData.qte;
          entry[this.$i18n.t('biChartsQVE.headerEff')] = (Math.round(oneData.eff * 1000) / 10).toFixed(1) + " %";
          entry[this.$i18n.t('biChartsQVE.headerSAM')] = oneData.SAM;
          this.tableData.push( entry );
        }
        console.log("----- this.tableData = " + JSON.stringify(this.tableData, null, 2));

        let chartData = [];
        for( let i=0; i<result.data.length; i++ ) {
          let oneData = result.data[i];

          chartData.push({
            x: oneData.qte,
            y: Math.round(oneData.eff * 1000) / 10
          });
        }

        new Chart( this.ctx, {
          plugins: [ChartDataLabels],
          type: 'scatter',
          data: {
            datasets: [{
              borderWidth: 3,
              borderColor: 'rgba(0,0,0,1.0)',
              backgroundColor: 'rgba(0,0,0,1.0)',
              data: chartData
            }]
          },
          options: {
            plugins: {
              datalabels: {
                display: false
              }
            },
            legend: {
              display: false
            },
            tooltips: {
              enabled: false
            },
            events: ['click'],
            scales: {
              xAxes: [{
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t('biChartsQVE.headerQte')
                },
                type: 'linear',
                position: 'bottom'
              }],
              yAxes: [{
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t('biChartsQVE.headerEff')
                }
              }]
            }
          }
        });
      }).catch(() => {
        console.log("----- /rep/ onSubmit error ");
      });
    },
    clickExport() {
      json2excel({
        data: this.tableData,
        name: 'QteVsEffTable'
      });
    },
    clickRetour() {
      this.$router.replace("/config");
    },
    chartUpdateFactory( data ) {
      this.dateFormat = data.dateFormat;
    },
    redimChart: function() {
      if( this.doingResize )
        return
      this.doingResize = true;
      let self = this;
      setTimeout(function() {
        self.chartWidth = self.$refs.chartContainer.clientWidth;
        self.chartHeight = Math.round(self.chartWidth * 0.7);
        self.tableHeight = (self.chartHeight + 46) + 'px';
        //console.log("----- tableHeight = " + self.tableHeight);
        self.doingResize = false;
      }
      , 100);
    }
  },
  mounted() {
    this.dateFormat = oper.getFormatData();

    this.reportFrom = moment().format(this.dateFormat);
    this.reportTo = moment().format(this.dateFormat);

    axiosInstance.get('/standardList', {"params": { "query": { "isActive": true } }})
    .then(resp => {
        for( let i=0; i<resp.data.length; i++ ) {
          resp.data[i].text = resp.data[i].partNumber + ' - ' + resp.data[i].description;
          resp.data[i].value = resp.data[i]._id;
        }
        //console.log("----- get /standardList = " + JSON.stringify(resp.data, null, 2));
        let list = JSON.stringify(resp.data);
        this.stdList = JSON.parse(list);
        this.stdResult = [];
    }).catch(err => {
        console.log("----- get /standardList error = " + JSON.stringify(err, null, 2));
    });

    this.ctx = document.getElementById('theDailyProdChart');

    this.redimChart();
    EventBus.$on('mainViewDim', this.redimChart);
    EventBus.$on('factory', this.chartUpdateFactory);
  },
  beforeDestroy(){
    EventBus.$off('mainViewDim', this.redimChart);
    EventBus.$off('factory', this.chartUpdateFactory);
  }
}
</script>

<style lang="scss">

.error {
  margin-left: 15px;
  color: red;
}

</style>
