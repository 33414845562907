<template>
  <div class="g-page-container" ref="pageContainer">
    <div class="g-pageHeader" style="height: 63px">
      <h1 style="margin: auto">{{ $t("configRecipList.title") }}</h1>
    </div>

    <div style="padding-top: 10px">
      <div class="form-inline">
        <div class="form-group search-text">
          <label for="searchText">{{ $t("configRecipList.search") }}</label>
          <input id="searchText" v-model="config.searchField" type="text" @keyup.enter="clickSearch()" class="form-control" />
          <button @click="clickSearch()" class="btn btn-perfcell">
            <span class="glyphicon glyphicon-search"></span>
            {{ $t("search") }}
          </button>
        </div>
        <div class="form-group checkbox search-active">
          <label for="searchActive">
            <input id="searchActive" type="checkbox" v-model="config.withInactive" @change="clickInactive()" />
            {{ $t("inactive") }}
          </label>
        </div>
        <div class="form-group" style="margin-left: 30px">
          <ReturnButton to="/config" />
        </div>
      </div>
    </div>

    <div class="g-pageBody" style="padding: 10px">
      <table>
        <thead>
          <tr>
            <th class="t-name cursorPointer" :class="getArrow('name')" @click="clickSort('name')">
              {{ $t("configRecipList.name") }}
            </th>
            <th class="t-tel cursorPointer" :class="getArrow('tel')" @click="clickSort('tel')">
              {{ $t("configRecipList.tel") }}
            </th>
            <th class="t-edit textAlignCenter">{{ $t("configRecipList.edit") }}</th>
            <th class="t-remove textAlignCenter">{{ $t("configRecipList.remove") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in screenList" :key="e._id">
            <td :class="e.isActive ? '' : 'redText'">{{ e.name }}</td>
            <td :class="e.isActive ? '' : 'redText'">{{ e.phone }}</td>
            <td class="textAlignCenter cursorPointer" @click="clickEdit(e._id)"><i class="fa fa-pencil"></i></td>
            <td class="textAlignCenter cursorPointer" @click="clickRemove(e)"><i class="fa fa-remove"></i></td>
          </tr>
          <tr v-for="index in emptyRows" :key="index">
            <td>.</td>
            <td>&#8232;</td>
            <td>&#8232;</td>
            <td>&#8232;</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div style="margin-top: 10px">
      <b-pagination
        v-if="totalRows > pageSize"
        size="sm"
        ref="pagination"
        :total-rows="totalRows"
        :per-page="pageSize"
        v-model="config.currentPage"
        :prev-text="$t('configRecipList.previous')"
        :next-text="$t('configRecipList.next')"
      />
    </div>

    <button class="btn btn-perfcell float-left marginTop20px" @click="clickRetour">{{ $t("configRecipList.cancel") }}</button>
    <button class="btn btn-perfcell float-left marginLeft10px marginTop20px" @click="clickAddNew">{{ $t("configRecipList.addNew") }}</button>
  </div>
</template>

<script>
import { axiosInstance } from "@/main";
import EventBus from "@/eventBus";
import ReturnButton from "../components/Return-button/Return-button.vue";

export default {
  components: {
    ReturnButton,
  },
  data() {
    return {
      // the list received from the server
      completeList: [],
      // the list stripped by search and inactive and sorted by column headers
      strippedList: [],
      // the current elements on screen
      screenList: [],

      // computed items
      totalRows: 0,
      pageSize: 7,
      emptyRows: 0,

      // config is saved when looking at element detail then restored when coming back here
      config: {
        // when these fields are modified, the strippedList is rebuilt
        searchField: "",
        withInactive: false,

        // value modified when clicking pages navigation
        currentPage: 1,

        // values modifieds when clicking table headers
        currentSort: "name",
        currentSortDir: "asc",
      },
    };
  },
  watch: {
    "config.currentPage": function () {
      //console.log("----- this.config.currentPage = " + this.config.currentPage);
      this.setScreen();
    },
  },
  methods: {
    clickSort: function (s) {
      if (s === this.config.currentSort) {
        this.config.currentSortDir = this.config.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.config.currentSort = s;
      this.sortList();
    },
    clickInactive: function () {
      //console.log("----- " + this.config.withInactive);
      this.stripList();
    },
    clickSearch: function () {
      //console.log("----- search = " + this.config.searchField);
      this.config.currentPage = 1;
      this.stripList();
    },
    clickEdit(id) {
      //console.log("----- clickEdit = " + id);
      localStorage.setItem("configListSettings", JSON.stringify(this.config));
      this.$router.replace({ path: "/configSmsRecipient/" + id });
    },
    clickRemove(element) {
      //console.log("----- clickRemove = " + element._id);
      this.$bvModal
        .msgBoxConfirm(element.name, {
          title: this.$i18n.t("configRecipList.confirm"),
          okTitle: this.$i18n.t("ok"),
          cancelTitle: this.$i18n.t("cancel"),
        })
        .then((value) => {
          //console.log("----- value = " + value);
          if (value) {
            //console.log("----- element._id = " + element._id);
            axiosInstance
              .delete("/smsRecipient/" + element._id)
              .then(() => {
                //console.log("----- onDelete ok ");
                this.getList();
              })
              .catch(() => {
                console.log("----- onDelete error ");
              });
          }
        });
    },
    clickRetour() {
      this.$router.replace("/config");
    },
    clickAddNew: function () {
      //console.log("----- clickAddNew");
      localStorage.setItem("configListSettings", JSON.stringify(this.config));
      this.$router.replace({ path: "/configSmsRecipient/0" });
    },
    getArrow: function (s) {
      if (s === this.config.currentSort) {
        return this.config.currentSortDir === "asc" ? "headerSortRecip" : "headerSortUp";
      } else return "none";
    },
    stripList: function () {
      this.strippedList = [];
      let search = this.config.searchField.toLowerCase();
      for (let i = 0; i < this.completeList.length; i++) {
        let good = true;
        if (search != "" && !this.completeList[i].name.toLowerCase().includes(search) && !this.completeList[i].phone.toLowerCase().includes(search)) good = false;
        if (!this.config.withInactive && !this.completeList[i].isActive) good = false;
        if (good) this.strippedList.push(this.completeList[i]);
      }
      this.sortList();
    },
    sortList: function () {
      this.strippedList.sort((a, b) => {
        let modifier = 1;
        if (this.config.currentSortDir === "desc") modifier = -1;
        if (a[this.config.currentSort] < b[this.config.currentSort]) return -1 * modifier;
        if (a[this.config.currentSort] > b[this.config.currentSort]) return 1 * modifier;
        return 0;
      });
      this.totalRows = this.strippedList.length;
      //console.log("----- strippedList.length = " + this.strippedList.length);
      this.setScreen();
    },
    setScreen: function () {
      this.screenList = this.strippedList.slice((this.config.currentPage - 1) * this.pageSize, this.config.currentPage * this.pageSize);
      this.emptyRows = this.pageSize - this.screenList.length;
    },
    computeNbRows: function (pageHeight) {
      if (pageHeight > 450) {
        let nbPages = 6 + Math.trunc((pageHeight - 450) / 38);
        if (this.pageSize != nbPages) {
          this.pageSize = nbPages;
          //console.log("----- this.pageSize = " + nbPages);
          this.setScreen();
        }
      }
    },
    getList: function () {
      //console.log("----- getList");
      axiosInstance
        .get("/smsRecipientList")
        .then((resp) => {
          //console.log("----- get /smsRecipientList = " + JSON.stringify(resp.data, null, 2));
          this.completeList = resp.data;

          this.stripList();
        })
        .catch((err) => {
          console.log("----- get /smsRecipientList error = " + JSON.stringify(err, null, 2));
        });
    },
    redimSmsRecipientList: function (dim) {
      this.computeNbRows(dim.height);
    },
  },
  mounted() {
    if (localStorage.configListSettings) this.config = JSON.parse(localStorage.getItem("configListSettings"));

    this.getList();

    EventBus.$on("mainViewDim", this.redimSmsRecipientList);
    this.computeNbRows(this.$refs.pageContainer.clientHeight);
  },
  beforeDestroy() {
    EventBus.$off("mainViewDim", this.redimSmsRecipientList);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/global.scss";
@import "@/assets/styles/table.scss";

.t-name {
  width: 40%;
}
.t-phone {
  width: 40%;
}
.t-edit {
  width: 10%;
}
.t-remove {
  width: 10%;
}
</style>
