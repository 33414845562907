<template>
  <table class="employees" :style="customHeight !== '' ? `height: ${customHeight}` : ''">
    <div
      :class="{ hidden: !isLoading }"
      class="overlay"
      :style="`
        width: ${overlaywidth};
        height: ${overlayHeight}`"
    >
      <!-- ${customHeight !== '' ? `height: ${customHeight}`: ''}` -->
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <th class="header">
      <!-- <div class="fields" :style="`grid-template-columns: repeat(auto-fill, ${columnPercent}%);`"> -->
      <div class="fields" :style="`grid-template-columns: ${gridtemplatecolumns}`">
        <div :key="field._id" v-for="field of tableFields" class="oneField" :class="[{ selected: sortData.field === field.key }]" @click="setSortData(field.key)">
          <span>{{ field.label }}</span>
          <figure class="sortIcon">
            <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" :class="[{ rotate180: sortData.field === field.key && sortData.direction === 'asc' }]">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
          </figure>
        </div>
      </div>
    </th>
    <tbody class="content" :style="customHeight !== '' ? `height: calc(${customHeight} - 100px)` : `height: 200px;`">
      <!-- :style="`grid-template-columns: repeat(auto-fill, ${columnPercent}%);`" -->
  <div
  :key="rowData._id"
  v-for="rowData of rowsToShow"
  class="item"
  :class="[
    {
      selected: dataSelected.includes(rowData),
    },
  ]"
  :style="`grid-template-columns: ${gridtemplatecolumns}`"
  @click="selectData(rowData)"
>
  <span :key="i" v-for="(key, i) of keys">
    {{ getCellValue(rowData, key) }}
  </span>
</div>
    </tbody>
  </table>
</template>

<script>
import EventBus from "@/eventBus";
const debounce = require("lodash.debounce");

export default {
  name: "Table",
  data: function () {
    return {
      isLoading: false,
      dataSelected: [],
      sortData: {
        field: "",
        direction: "",
      },
      originalDataRow: undefined,
      rowsToShow: [],
    };
  },
  props: {
    tableFields: {
      type: Array,
      required: true,
    },
    rowsData: {
      type: Array,
      required: true,
    },
    gridtemplatecolumns: {
      type: String,
      default: "repeat(auto-fill, 19%);",
    },
    customHeight: {
      type: String,
      default: "",
    },
    keys: {
      type: Array,
      required: true,
    },
    returnClickOnArray: {
      type: Boolean,
      require: false,
    },
    tableid: {
      type: String,
      default: "defaultId",
    },
    overlaywidth: {
      type: String,
      default: "62%",
    },
    overlayHeight: {
      type: String,
      default: "60%",
    },
  },
  created: function () {
    this.rowsToShow = JSON.parse(JSON.stringify(this.rowsData));
    const channel = `table::${this.tableid}`;
    EventBus.$on(`${channel}::clearAll`, this.clearAll);
    EventBus.$on(`${channel}::removeItem`, (id) => this.removeSelectedItem(id));
    EventBus.$on(`${channel}::setSort`, (sortData) => {
      this.sortData = sortData;
      this.sortRows();
      console.log("----- Set sort data", sortData);
    });
    EventBus.$on(`${channel}::search`, this.startSearch);
  },
  methods: {
   
    getCellValue(rowData, key) {
      if (typeof key === 'object') {
        return rowData && rowData[key[0]] && rowData[key[0]][key[1]] !== undefined
          ? rowData[key[0]][key[1]]
          : '';
      } else if (typeof rowData === 'object' && rowData[key] && typeof rowData[key] === 'object') {
        return rowData[key][0] !== undefined ? rowData[key][0] : '';
      } else {
        return rowData && rowData[key] !== undefined ? rowData[key] : '';
      }
    },
  
  
    selectData(rowData) {
      const index = this.dataSelected.indexOf(rowData);
      if (index >= 0) {
        this.dataSelected.splice(index, 1);
        // this.removeEmployee(rowData._id)
      } else {
        this.dataSelected.push(rowData);
        // this.onEmpSelected(this.dataSelected)
      }
      this.returnClickOnArray ? this.$emit("rowclick", [rowData]) : this.$emit("rowclick", rowData);
    },
    removeSelectedItem(id) {
      for (let selectedData of this.dataSelected) {
        const index = Object.values(selectedData).indexOf(id);
        if (index > -1) {
          this.dataSelected.splice(index, 1);
          return;
        }
      }
    },
    clearAll: function () {
      this.dataSelected = [];
    },
    setSortData(sortBy) {
      if (this.sortData.field === sortBy)
        if (this.sortData.direction === "desc") this.sortData.direction = "asc";
        else this.sortData.direction = "desc";
      else {
        this.sortData.field = sortBy;
        this.sortData.direction = "desc";
      }
      this.sortRows();
    },
    async sortRows() {
      this.isLoading = true;
      await this.sleep(100);
      await this.sort();
      this.isLoading = false;
    },
    sort() {
      return new Promise((resolve) => {
        if (this.sortData.direction === "desc") {
          this.rowsToShow.sort((firstValue, secondValue) => {
            if (firstValue[this.sortData.field] < secondValue[this.sortData.field]) return -1;
            else if (firstValue[this.sortData.field] > secondValue[this.sortData.field]) return 1;
            else return 0;
          });
          resolve();
        } else {
          this.rowsToShow.reverse();
          resolve();
        }
      });
    },
    sleep(delay) {
      return new Promise((resolve) => setTimeout(() => resolve(), delay));
    },
    searchData(input) {
      console.log("----- table: Searching:", input.target.value);
      if (!this.originalDataRow) this.originalDataRow = JSON.parse(JSON.stringify(this.rowsToShow));
      else this.rowsToShow = JSON.parse(JSON.stringify(this.originalDataRow));

      const word = input.target.value.toLowerCase();
      if (word !== "") {
        this.rowsToShow = this.rowsToShow.filter((rowData) => {
          for (let value of Object.values(rowData)) {
            if (typeof value === "string" && value.toLowerCase().includes(word)) return true;
            else if (typeof value === "object") {
              if (value === null) return false;

              for (let objectValues of Object.values(value)) {
                if (typeof objectValues === "string" && objectValues.toLowerCase().includes(word)) return true;
              }
            }
          }
          return false;
        });
      }
    },
    startSearch: debounce(function (input) {
      this.searchData(input);
    }, 500),
  },
  watch: {
    rowsData(value) {
      this.rowsToShow = value;
    },
  },
};
</script>

<style scoped>
table.employees {
  min-width: 100%;
  min-height: 18.75rem;
  align-self: center;
}

table.employees,
table.employees > tbody.content {
  display: flex;
  flex-flow: column;
}

table.employees > th.header > div.fields {
  min-width: 100%;
  min-height: 4rem;
  align-items: center;
  padding: 0 1rem;
}

table.employees > th.header > div.fields span {
  font-size: 22px;
}

table.employees > th.header > div.fields > div.oneField {
  display: flex;
  flex-flow: row;
  align-items: center;
  background: transparent;
  padding: 0 0.3rem;
  border-radius: 5px;
  gap: 0.3rem;
  cursor: pointer;
  border: 2px solid transparent;
  transition: background, border ease-in-out 300ms;
}

table.employees > th.header > div.fields > div.oneField:hover {
  background: hsl(0, 0%, 91%);
}

table.employees > th.header > div.fields > div.oneField.selected {
  background: hsl(0, 0%, 93%);
  border-bottom: 2px solid hsl(190, 100%, 30%);
}

table.employees > th.header > div.fields > div.oneField > figure.sortIcon {
  min-width: 1rem;
  width: 1.4rem;
  max-width: 1.5rem;
  height: auto;
  padding-top: 0.5rem;
}

table.employees > th.header > div.fields > div.oneField > figure.sortIcon > svg {
  transition: all ease-in-out 300ms;
}

table.employees > th.header > div.fields > div.oneField > figure.sortIcon > svg.rotate180 {
  transform: rotate(-180deg);
}

table.employees > th.header > div.fields,
table.employees > tbody.content > div.item {
  display: grid;
  gap: 1%;
  font-size: 22px;
}

table.employees > tbody.content {
  min-width: 100%;
  min-height: 200px;
  overflow: scroll;
  padding: 0 1rem;
}

table.employees > tbody.content > div.item {
  border-bottom: 2px solid hsl(0, 0%, 90%);
  padding: 0.7rem 0rem 0.2rem 1rem;
  cursor: pointer;
  border-radius: 10px;
  transition: background ease-in-out 300ms;
}

table.employees > tbody.content > div.item:hover {
  background: hsl(0, 0%, 90%);
}

table.employees > tbody.content > div.item.selected {
  background: hsl(0, 0%, 80%);
}

.spinnerContainer {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  height: 15.8rem;
  background: #555;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  opacity: 0.25;
  z-index: 999;
  transition: opacity ease-in-out 300ms;
}

.overlay > * {
  opacity: 1;
  color: white;
}

.overlay.hidden {
  opacity: 0;
  z-index: 0;
}

@media only screen and (max-width: 800px) {
  table.employees > th.header > div.fields > div.oneField {
    flex-flow: column;
  }
  .overlay {
    width: 95%;
    height: 17.2rem;
  }
}
</style>
