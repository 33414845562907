import client from "@/stores/ClientState.js";
import EventBus from "@/eventBus";
import oper from "@/stores/Operations.js";
import router from "@/router";
//import { axiosInstance } from "@/main";
//import moment from "moment-timezone";
import { appVersion } from "@/main";

let myId = null;
let socket = null;
let socketOk = 0;
let shouldBeOpen = false;
let tryOpenSocket = 0;
let pingTimeoutHandle = null;

function openSocket() {
  //if( socket != null )
  //console.log("----- socket openSocket(), socket.readyState = " + socket.readyState + " (0 CONNECTING, 1 OPEN, 2 CLOSING, 3 CLOSED)");
  shouldBeOpen = true;

  if (socket != null && socket.readyState !== WebSocket.CLOSED) {
    //console.log("----- wait WebSocket.CLOSED");
    // wait that socket is closed before trying to reopen
    setTimeout(openSocket, 500);
    return;
  }
  // client might have been logged out while we were wating for WebSocket.CLOSED
  if (!client.isLoggedIn()) {
    shouldBeOpen = false;
    return;
  }

  let openSocketAt = new Date().getTime();
  //console.log("----- tryOpenSocket = " + tryOpenSocket);
  //console.log("-----  openSocketAt = " + openSocketAt);
  // if we did try to open the socket less that a second ago, make sure serverDown is shown
  if (openSocketAt - tryOpenSocket < 1000) {
    if (!router.currentRoute.path.startsWith("/serverDown")) {
      //console.log("----- server down");
      router.push("/serverDown/2").catch(() => {});
    }
  }
  tryOpenSocket = openSocketAt;

  //console.log("----- socket opening");

  var serverUrl;
  if (process.env.NODE_ENV === "production")
    serverUrl = process.env.VUE_APP_SERVER.replace(/http/, "ws");
  else serverUrl = "ws://" + window.location.hostname + ":3000";
  // serverUrl = "https://www.perfcell.com:3091".replace(/http/, "ws");

  socket = new WebSocket(serverUrl);
  setTimeout(function() {
    if (
      socket.readyState !== WebSocket.OPEN &&
      socket.readyState !== WebSocket.CONNECTING
    ) {
      if (!router.currentRoute.path.startsWith("/serverDown")) {
        //console.log("----- server down 2");
        router.push("/serverDown/2").catch(() => {});
      }
    }
  }, 1000);

  socket.onopen = function() {
    //console.log("----- socket Connection established");
    socketOk = 5; // changed from 3 to 5 on june 24
    if (!myId) myId = client.getMyId() + "-" + new Date().getTime();
    //console.log("----- myId = " + myId);
    socket.send(JSON.stringify({ myId: myId, version: appVersion }));
    if (router.currentRoute.path.startsWith("/serverDown")) {
      if (router.currentRoute.path.startsWith("/serverDown/2")) router.go(-1);
      else router.replace("/operation");
    }

    //console.log("----- socket setTimeout(ping, 3002);");
    clearTimeout(pingTimeoutHandle);
    pingTimeoutHandle = setTimeout(ping, 3002);
  };

  socket.onclose = function(/*event*/) {
    //console.log('----- socket Connection closed, code = ' + event.code + ', reason = ' + event.reason);
    if (shouldBeOpen) {
      //console.log("----- /serverDown/2");
      //router.replace("/serverDown/2").catch(() => {});
      //console.log("----- socket setTimeout(openSocket, 501);");
      setTimeout(openSocket, 501);
    }
    socketOk = 0;
  };
  socket.onerror = function(/*error*/) {
    //console.log('----- socket Connection error = ' + error.message);
    socketOk = 0;
  };

  socket.onmessage = function(event) {
    //console.log('----- RAW = ' + event.data);
    socketOk = 5; // changed from 3 to 5 on june 24
    let msg = JSON.parse(event.data);
    if (msg.y) {
      // msg.ping
      //console.log('----- socket ping from server ');
      //console.log("----- socket.readyState = " + socket.readyState + " (0 CONNECTING, 1 OPEN, 2 CLOSING, 3 CLOSED)");
      try {
        socket.send(JSON.stringify({ z: 1 })); // { "pong" : true }
      } catch (ex) {
        //console.log('----- socket.send : ' + ex);
      }
    } else if (msg.z) {
      // msg.pong
      //console.log("----- socket pong from server");
      socketOk = 5; // changed from 3 to 5 on june 24
    } else if (msg.ready) {
      //console.log("----- socket ready from server");
      EventBus.$emit("socketOpen");
    } else {
      oper.messageReceived(msg);
    }
  };
}

function getSocketId() {
  if (!myId) return "unidentified";
  else return myId;
}

function closeSocket() {
  //console.log("----- socket closeSocket()");
  shouldBeOpen = false;

  if (
    socket.readyState !== WebSocket.CLOSED &&
    socket.readyState !== WebSocket.CLOSING
  )
    socket.close(1000, "Pong not received");
}

function ping() {
  socketOk--;
  //console.log("----- socket ping() ");
  //console.log("----- socket.readyState = " + socket.readyState + " (0 CONNECTING, 1 OPEN, 2 CLOSING, 3 CLOSED)");
  //console.log("----- shouldBeOpen " + shouldBeOpen);
  //console.log("----- socketOk " + socketOk);
  if (shouldBeOpen) {
    if (socketOk <= 0) {
      /*
      let log = {
        event: "pongTimeout",
        time: moment(),
        text1: getSocketId()
      }
      axiosInstance.post('/log', log);
      */
      //console.log("----- /serverDown/2");
      //router.replace("/serverDown/2").catch(() => {});
      if (
        socket.readyState !== WebSocket.CLOSED &&
        socket.readyState !== WebSocket.CLOSING
      )
        socket.close(1000, "Client gone");
    } else {
      socket.send(JSON.stringify({ y: 1 })); // { "ping" : true }
      //console.log("----- socket setTimeout(ping, 3003);");
      clearTimeout(pingTimeoutHandle);
      pingTimeoutHandle = setTimeout(ping, 3003);
    }
  }
}

function sendData(msg) {
  //console.log('----- socket Data sent to server: ' + JSON.stringify(msg, null, 2));
  //console.log('----- socket Data sent to server');
  socket.send(JSON.stringify(msg));
}

export default { sendData, openSocket, closeSocket, getSocketId };
