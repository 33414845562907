<template>
  <div class="g-page-container">
    <!-- <div class="topLeft">
      <TabletLine></TabletLine>
    </div> -->
    <div class="row">
      <div class="col-12 col-sm-12 col-lg-3 col-xl-3 col-xxl-3 col-md-12">
        <b-card bg-variant="light" class="first-row-1nd-col">
          <TabletLine></TabletLine>
        </b-card>
      </div>
      <div class="col-12 col-md-12 col-sm-12 col-xl-9 col-lg-9 col-xxl-9">
        <b-card bg-variant="light" class="first-row-2nd-col">
          <TabletStandard></TabletStandard>
        </b-card>
      </div>
    </div>
    <div class="row midrow">
      <div class="col-sm-12 col-12 col-md-12 col-xl-3 col-lg-3 col-xxl-3">
        <b-card bg-variant="light" class="First-column">
          <TabletTeam></TabletTeam>
        </b-card>
      </div>
      <div class="col-sm-12 col-12 col-md-12 col-xl-7 col-lg-7 col-xxl-7">
        <b-card bg-variant="light" class="Mid-Column">
          <TabletMain></TabletMain>
        </b-card>
      </div>
      <div class="col-sm-12 col-12 col-md-12 col-xl-2 col-lg-2 col-xxl-2">
        <div class="Last-column">
          <TabletControl @AssignCleanScore="showAuthModal = true"></TabletControl>
        </div>
      </div>
    </div>
    <AssignCleanScore :showModal="showAssignCleanScoreModal" @close="showAssignCleanScoreModal = false" />
    <AuthModal :showModal="showAuthModal" @close="showAuthModal = false" @successAuth="showAssignCleanScoreModal = true" />
  </div>
</template>

<script>
import EventBus from "@/eventBus";
import TabletLine from "@/components/TabletLine.vue";
import TabletStandard from "@/components/TabletStandard.vue";
import TabletTeam from "@/components/TabletTeam.vue";
import TabletMain from "@/components/TabletMain.vue";
import TabletControl from "@/components/TabletControl.vue";
import AssignCleanScore from "../components/Modals/Assign-clean-score.vue";
import AuthModal from "../components/Modals/Auth.vue";

export default {
  data: function () {
    return {
      pageWidth: 0,
      pageHeight: 0,

      showAuthModal: false,
      showAssignCleanScoreModal: false,
    };
  },
  components: {
    AssignCleanScore,
    AuthModal,
    TabletLine,
    TabletStandard,
    TabletTeam,
    TabletMain,
    TabletControl,
  },
  methods: {
    redimOperation: function (dim) {
      this.pageWidth = dim.width;
      this.pageHeight = dim.height;
      this.$nextTick(function () {
        EventBus.$emit("redimOperation");
      });
    },
  },
  mounted() {
    EventBus.$on("mainViewDim", this.redimOperation);
  },
  beforeDestroy() {
    EventBus.$off("mainViewDim", this.redimOperation);
  },
};
</script>

<style lang="scss" scoped>
.first-row-2nd-col {
  height: 13vh;
}
.first-row-1nd-col {
  height: 13vh;
}
.Last-column {
  display: flex;
  flex-direction: row;

  height: 64vh;
}
.Mid-Column {
  display: flex;
  flex-direction: row;
  height: 64vh;
}
.First-column {
  display: flex;
  flex-direction: row;
  height: 64vh;
  overflow-x: hidden;
  overflow-y: scroll;
}
.midrow {
  margin-top: 11px;
}
// $topRowHeight: 15%;
// $betweenRows: 2%;
// $bottomRowHeight: 81%;

// $rightColumn: 22%;
// $betweenColumns: 2%;
// $leftColumn: 76%;

// .topLeft {
//   float: left;
//   height: $topRowHeight;
//   background-color: white;
//   width: $rightColumn;
//   padding: 1% 1.5% 1.5% 1.5%;
// }

// .topRight {
//   float: left;
//   margin-left: $betweenColumns;
//   height: $topRowHeight;
//   background-color: white;
//   width: $leftColumn;
//   padding: 0.8% 1.5% 1.5% 1.5%;
// }

// .bottomLeft {
//   float: left;
//   margin-top: $betweenRows;
//   height: $bottomRowHeight;
//   background-color: white;
//   width: $rightColumn;
//   padding: 1.5%;
// }

// .bottomCenter {
//   float: left;
//   margin-top: $betweenRows;
//   margin-left: $betweenColumns;
//   height: $bottomRowHeight;
//   background-color: white;
//   width: 57%;
//   padding: 1%;
// }

// .bottomRight {
//   float: right;
//   margin-top: $betweenRows;
//   height: $bottomRowHeight;
//   width: 18.5%;
//   padding: 0;
// }
</style>
