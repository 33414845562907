<template>
  <form action="g-pageBody" @submit.prevent="handleSubmit">
    <div class="form-group">
      <label for="inputPart">{{ $t("cleaningFactorDetail.form.factorName") }}</label>
      <input v-model="cleaningFactorName" type="text" class="form-control" id="inputPart" :placeholder="$t('cleaningFactorDetail.form.factorNamePlaceholder')" />
    </div>
    <div class="row" style="margin: 30px 0" v-for="([, item2], index) in cleaningFactorItemNames" :key="index">
      <div class="col-md-6">
        <label for="inputPart">{{ $t("cleaningFactorDetail.form.itemName") }} {{ (index * 2) + 1 }}</label>
        <input v-model="cleaningFactorItemNames[index][0]" type="text" class="form-control" id="inputPart" :placeholder="$t('cleaningFactorDetail.form.itemNamePlaceholder')" />
      </div>
      <div v-if="item2 !== undefined" class="col-md-6">
        <label for="inputPart">{{ $t("cleaningFactorDetail.form.itemName") }} {{ (index * 2) + 2 }}</label>
        <input v-model="cleaningFactorItemNames[index][1]" type="text" class="form-control" id="inputPart" :placeholder="$t('cleaningFactorDetail.form.itemNamePlaceholder')" />
      </div>
    </div>
    <div class="row">
      <label for="inputLines" style="padding-top: 3px">{{ $t("cleaningFactorDetail.form.linesLabel") }}</label>
      <div id="inputLines" style="padding-left: 10px; padding-bottom: 5px">
        <MultipleSelect :data="lineNames" v-model="linesToSelect" multiple :options="opt"> </MultipleSelect>
      </div>
    </div>

    <button class="btn btn-perfcell float-left marginTop50px" @click.prevent="handleClickReturn">
      {{ $t("cleaningFactorDetail.form.cancel") }}
    </button>

    <button class="btn btn-perfcell float-left marginTop50px marginLeft10px" type="submit">
      {{ $t("cleaningFactorDetail.form.submit") }}
    </button>

    <p v-if="message.length" class="float-left marginTop50px marginLeft30px" :style="{ color: messageColor }">
        <b v-if="messageColor == 'red'">{{ $tc("configStd.error", message.length) }}</b>
        <ul>
          <li v-for="error in message" :key="error">{{ error }}</li>
        </ul>
    </p>
  </form>
</template>

<script>
import { axiosInstance } from "../../../main";
export default {
  data() {
    return {
      cleaningFactorName: "",
      cleaningFactorItemNames: [],
      lines: [],
      lineNames: [],
      linesToSelect: [],

      message: [],
      messageColor: "green",

      opt: {
        multiple: true,
        filter: true,
        placeholder: this.$i18n.t("cleaningFactorDetail.form.linesPlaceholder"),
        multipleWidth: 500,
        width: 500,
        dropWidth: 500,
        locale: localStorage.getItem("locale"),
      },
    };
  },
  props: {
    cleaningFactorId: {
      type: String,
      required: true,
    },
    cleaningFactorInfo: {
      type: Object,
      default: () => ({ cleaningFactorName: "", itemNames: ["", "", "", "", ""] }),
    },
    selectedLines: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    sucessfullyMessage() {
      this.messageColor = "green";
      this.message = [`${this.$i18n.t("cleaningFactorDetail.form.changesSaved")}`];
    },
    errorMessage (...errors) {
      this.messageColor = 'red'
      this.message = [...errors]
    },
    handleClickReturn() {
      this.$router.replace("/config/cleaning-factor");
    },
    async handleSubmit() {
      this.message = [];
      const itemNames = [];
      this.cleaningFactorItemNames.forEach((itemName) => {
        itemNames.push(itemName[0]);
        if (itemName[1] !== undefined) itemNames.push(itemName[1]);
      });

      if (this.cleaningFactorName === '') {
        this.errorMessage(this.$i18n.t("cleaningFactorDetail.form.cleaningFactorNameCannotBeEmpty"))
        return
      }

      if (itemNames.some((itemName) => itemName === '.' || itemName === '')) {
        this.errorMessage(this.$i18n.t("cleaningFactorDetail.form.cleaningFactorItemsCannotBeEmpty"))
        return;
      }

      const linesInfo = this.lines.filter((line) => this.linesToSelect.includes(line.name));
      const body = {
        cleaningFactorName: this.cleaningFactorName,
        itemNames,
        lines: linesInfo.map(({ _id }) => _id),
      };

      try {
        if (this.cleaningFactorId.toString() !== "new") {
        await axiosInstance.put("/cleaning-factor", {
          id: this.cleaningFactorId,
          ...body,
        });

        this.sucessfullyMessage();
      } else {
        const createCleaningFactorResponse = await axiosInstance.post("/cleaning-factor", body);

        const createdCleaningFactor = createCleaningFactorResponse.data.createdCleaningFactor;
        this.sucessfullyMessage();
        this.$emit("redirectToCleaningfactorId", createdCleaningFactor._id);
      }
      } catch (error) {
        if (error.response.status === 404) {
          this.errorMessage(error.response.data.error)
        }
      }
    },
  },
  mounted() {
    this.cleaningFactorName = this.cleaningFactorInfo.cleaningFactorName;

    this.linesToSelect = this.selectedLines;
    this.cleaningFactorItemNames = [];

    const itemsNamesLength = this.cleaningFactorInfo.itemNames.length ? this.cleaningFactorInfo.itemNames.length : 0;
    for (let i = 0; i < itemsNamesLength; i += 2) {
      if (this.cleaningFactorInfo.itemNames[i + 1] || this.cleaningFactorInfo.itemNames[i + 1] === "")
        this.cleaningFactorItemNames.push([this.cleaningFactorInfo.itemNames[i], this.cleaningFactorInfo.itemNames[i + 1]]);
      else this.cleaningFactorItemNames.push([this.cleaningFactorInfo.itemNames[i]]);
    }

    const asyncMounted = async () => {
      const linesReponse = await axiosInstance.get("/lineList", { params: { isActive: true } });
      this.lines = linesReponse.data;
      this.lineNames = this.lines.map(({ name }) => name);
    };
    asyncMounted();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/colors.scss";

form {
  background: White;
  padding: 2rem 2rem 1rem;
  width: 100%;
}

label {
  font-weight: bold;
}

.form-group-grid {
  display: grid;
  grid-template-rows: 1fr 1fr;
}
</style>
