<template>
  <div class="g-page-container noSelect">
    <div class="g-pageHeader" style="height: 63px">
      <h1 style="margin: auto">{{ $t("dashboard.title") }}</h1>
    </div>

    <div class="g-pageBody">
      <b-table ref="linesListTable" hover small fixed primary-key="lineId" :items="linesList" :tbody-tr-class="rowClass" :fields="showFields" @row-clicked="expandAdditionalInfo">
        <template v-slot:thead-top="">
          <b-tr>
            <b-th colspan="2" class="textAlignCenter">
              {{ $t("dashboard.lineAndSchedule") }}
            </b-th>
            <b-th colspan="7" class="dashboardBackCurrent textAlignCenter">{{ $t("dashboard.currProd") }}</b-th>
            <b-th colspan="6" class="dashboardBackDay textAlignCenter">{{ $t("dashboard.dayProd") }}</b-th>
            <b-th colspan="4" class="textAlignCenter">
              {{ $t("dashboard.stats") }}
            </b-th>
          </b-tr>
        </template>

        <template v-slot:cell(trendArrow)="slot">
          <img :src="slot.value" width="25" height="25" class="centerArrow" />
        </template>

        <template slot="row-details" slot-scope="row">
          <b-card>
            <div v-for="key in 5" :key="key">
              {{ getDetail(row, key) }}
            </div>
          </b-card>
        </template>

        <template #head(allDayAverageUnit)="data">
          <span v-html="data.label"></span>
        </template>
      </b-table>

      <b-popover target="comment1" triggers="hover" placement="top" variant="primary">
        <template #title>{{ $t("dashboard.title1") }}</template>
        {{ $t("dashboard.comment1") }}
      </b-popover>
    </div>

    <div class="g-pageBody" style="margin-top: 20px">
      <div class="row">
        <div class="col-md-5">
          <div class="form-group">
            <label for="dashboardLines">{{ $t("dashboard.lineSelect") }}</label>
            <div id="dashboardLines">
              <MultipleSelect ref="lineSelect" :data="lineSelect" v-model="lineResult" multiple @change="lineSelectChange" :options="opt"> </MultipleSelect>
            </div>
          </div>
        </div>

        <div class="col-md-5">
          <div class="form-group" style="margin-top: 30px">
            <input type="checkbox" id="fullText" v-model="fullText" />
            <label class="checkboxLabel" for="fullText">{{ $t("dashboard.fullText") }}</label>
          </div>
          <!--
          <div class="form-group">
            <label for="dashboardManminute">{{$t('dashboard.mmGoal')}}</label>
            <div id="dashboardManminute">
              <MultipleSelect
                ref="manminuteSelect"
                :data="manminuteSelect"
                v-model="manminuteResult"
                multiple
                @change="manminuteChange"
                :options="optMM" >
              </MultipleSelect>
            </div>
          </div>
          -->
        </div>

        <div class="col-md-2">
          <button class="btn btn-perfcell float-right marginTop10px marginRight10px" @click="clickClose">
            {{ $t("dashboard.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "@/eventBus";
import oper from "@/stores/Operations.js";
import { axiosInstance } from "@/main";
import globals from "@/globals.js";
import moment from "moment-timezone";
import { getSAM } from "../helpers/helper/operation";
export default {
  data() {
    return {
      linesList: [],
      lineSelected: 0,
      // showFields: [
      //   {
      //     key: "lineName",
      //     formatter: "formatFullText",
      //     tdClass: "getDashboardPtrunc",
      //     thClass: "textAlignCenter",
      //     label: this.$i18n.t("dashboard.head[0]"),
      //   },
      //   {
      //     key: "schedDesc",
      //     tdClass: "getDashboardPtrunc",
      //     thClass: "textAlignCenter",
      //     label: this.$i18n.t("dashboard.head[1]"),
      //   },
      //   { tdClass: "getDashboardPtrunc", thClass: "textAlignCenter" },
      //   {
      //     key: "partNumber",
      //     tdClass: "specialCell",
      //     thClass: ["dashboardBackCurrent", "textAlignCenter"],
      //     label: this.$i18n.t("dashboard.head[2]"),
      //   },
      //   {
      //     key: "teamNumber",
      //     tdClass: ["textAlignCenter", "dashboardBackCurrent"],
      //     thClass: ["textAlignCenter", "dashboardBackCurrent"],
      //     label: this.$i18n.t("dashboard.head[4]"),
      //   },
      //   {
      //     key: "nbProducts",
      //     formatter: "formalNbDecimals",
      //     tdClass: "specialCell",
      //     thClass: ["textAlignCenter", "dashboardBackCurrent"],
      //     label: this.$i18n.t("dashboard.head[5]"),
      //   },
      //   {
      //     key: "goal",
      //     formatter: "formalNbDecimals",
      //     tdClass: ["textAlignCenter", "dashboardBackCurrent"],
      //     thClass: ["textAlignCenter", "dashboardBackCurrent"],
      //     label: this.$i18n.t("dashboard.head[6]"),
      //   },
      //   {
      //     key: "eff",
      //     formatter: "formalEff",
      //     tdClass: "getColor",
      //     thClass: ["textAlignCenter", "dashboardBackCurrent"],
      //     label: this.$i18n.t("dashboard.head[7]"),
      //   },
      //   {
      //     key: "avgUnitsHour",
      //     formatter: "formalNbDecimals",
      //     tdClass: ["textAlignCenter", "dashboardBackCurrent"],
      //     thClass: ["textAlignCenter", "dashboardBackCurrent"],
      //     label: this.$i18n.t("dashboard.head[8]"),
      //   },
      //   {
      //     key: "goalUnitsHour",
      //     formatter: "formalNbDecimals",
      //     tdClass: ["textAlignCenter", "dashboardBackCurrent"],
      //     thClass: ["textAlignCenter", "dashboardBackCurrent"],
      //     label: this.$i18n.t("dashboard.head[9]"),
      //   },
      //   {
      //     key: "dayRegUnits",
      //     formatter: "formalNbDecimals",
      //     tdClass: ["textAlignCenter", "dashboardBackDay"],
      //     thClass: ["textAlignCenter", "dashboardBackDay"],
      //     label: this.$i18n.t("dashboard.head[10]"),
      //   },
      //   {
      //     key: "dayOtherUnits",
      //     formatter: "formalNbDecimals",
      //     tdClass: ["textAlignCenter", "dashboardBackDay"],
      //     thClass: ["textAlignCenter", "dashboardBackDay"],
      //     label: this.$i18n.t("dashboard.head[11]"),
      //   },
      //   {
      //     key: "dayRejectUnits",
      //     formatter: "formalNbDecimals",
      //     tdClass: ["textAlignCenter", "dashboardBackDay"],
      //     thClass: ["textAlignCenter", "dashboardBackDay"],
      //     label: this.$i18n.t("dashboard.head[12]"),
      //   },
      //   {
      //     key: "dayEff",
      //     formatter: "formalEff",
      //     tdClass: "getColor",
      //     thClass: ["textAlignCenter", "dashboardBackDay"],
      //     label: this.$i18n.t("dashboard.head[13]"),
      //   },
      //   {
      //     key: "taktTime",
      //     tdClass: ["textAlignCenter", "dashboardBackDay"],
      //     thClass: ["textAlignCenter", "dashboardBackDay"],
      //     label: this.$i18n.t("dashboard.head[14]"),
      //   },
      //   {
      //     key: "cycleTime",
      //     formatter: "formatToFixedTwo",
      //     tdClass: ["textAlignCenter", "dashboardBackDay"],
      //     thClass: ["textAlignCenter", "dashboardBackDay"],
      //     label: this.$i18n.t("dashboard.head[15]"),
      //   },
      //   {
      //     key: "allDayAverageUnit",
      //     formatter: "formalNbDecimals",
      //     tdClass: "textAlignCenter",
      //     thClass: "textAlignCenter",
      //     label: this.$i18n.t("dashboard.head[16]"),
      //   },
      //   {
      //     key: "lastHourUnit",
      //     formatter: "formalNbDecimals",
      //     tdClass: "textAlignCenter",
      //     thClass: "textAlignCenter",
      //     label: this.$i18n.t("dashboard.head[17]"),
      //   },
      //   {
      //     key: "previousShift",
      //     tdClass: "textAlignCenter",
      //     thClass: "textAlignCenter",
      //     label: this.$i18n.t("dashboard.head[18]"),
      //   },
      //   {
      //     key: "trendArrow",
      //     thClass: "textAlignCenter",
      //     label: this.$i18n.t("dashboard.head[19]"),
      //   },
      // ],
      lineSelect: [],
      lineResult: [],
      // opt: {
      //   multiple: true,
      //   filter: true,
      //   placeholder: this.$i18n.t("dashboard.linesPHolder"),
      //   multipleWidth: 250,
      //   width: 250,
      //   dropWidth: 250,
      //   position: "top",
      //   locale: localStorage.getItem("locale"),
      // },
      optMM: {
        multiple: true,
        filter: true,
        placeholder: this.$i18n.t("dashboard.mmGoalPHolder"),
        multipleWidth: 250,
        width: 250,
        dropWidth: 250,
        position: "top",
        locale: localStorage.getItem("locale"),
      },
      manminuteSelect: [],
      manminuteResult: [],
      minPercent: 80,
      arrowUp: require("@/assets/TrendArrowUp.png"),
      arrowDown: require("@/assets/TrendArrowDown.png"),
      noArrow: require("@/assets/noArrow.png"),
      inInit: true,
      downsAndChangeoversTimer: null,
      fullText: false,
    };
  },
  computed: {
    showFields() {
      // Accessing this.$i18n.locale in this computed prop tells Vue that
      // this computed prop should update whenever this.$i18n.locale
      // changes it value, even though we don't use the value.
      const lang = this.$i18n.locale;
      // We add the following just so that minification optimizers
      // do not remove the above statement because the return value isn't used.
      if (!lang) {
        return [];
      }
      // Return the array of translated values
      return [
        {
          key: "lineName",
          formatter: "formatFullText",
          tdClass: "getDashboardPtrunc",
          thClass: "textAlignCenter",
          label: this.$i18n.t("dashboard.head[0]"),
        },
        {
          key: "schedDesc",
          tdClass: "getDashboardPtrunc",
          thClass: "textAlignCenter",
          label: this.$i18n.t("dashboard.head[1]"),
        },
        { tdClass: "getDashboardPtrunc", thClass: "textAlignCenter" },
        {
          key: "partNumber",
          tdClass: "specialCell",
          thClass: ["dashboardBackCurrent", "textAlignCenter"],
          label: this.$i18n.t("dashboard.head[2]"),
        },
        {
          key: "teamNumber",
          tdClass: ["textAlignCenter", "dashboardBackCurrent"],
          thClass: ["textAlignCenter", "dashboardBackCurrent"],
          label: this.$i18n.t("dashboard.head[4]"),
        },
        {
          key: "nbProducts",
          formatter: "formalNbDecimals",
          tdClass: "specialCell",
          thClass: ["textAlignCenter", "dashboardBackCurrent"],
          label: this.$i18n.t("dashboard.head[5]"),
        },
        {
          key: "goal",
          formatter: "formalNbDecimals",
          tdClass: ["textAlignCenter", "dashboardBackCurrent"],
          thClass: ["textAlignCenter", "dashboardBackCurrent"],
          label: this.$i18n.t("dashboard.head[6]"),
        },
        {
          key: "eff",
          formatter: "formalEff",
          tdClass: "getColor",
          thClass: ["textAlignCenter", "dashboardBackCurrent"],
          label: this.$i18n.t("dashboard.head[7]"),
        },
        {
          key: "avgUnitsHour",
          formatter: "formalNbDecimals",
          tdClass: ["textAlignCenter", "dashboardBackCurrent"],
          thClass: ["textAlignCenter", "dashboardBackCurrent"],
          label: this.$i18n.t("dashboard.head[8]"),
        },
        {
          key: "goalUnitsHour",
          formatter: "formalNbDecimals",
          tdClass: ["textAlignCenter", "dashboardBackCurrent"],
          thClass: ["textAlignCenter", "dashboardBackCurrent"],
          label: this.$i18n.t("dashboard.head[9]"),
        },
        {
          key: "dayRegUnits",
          formatter: "formalNbDecimals",
          tdClass: ["textAlignCenter", "dashboardBackDay"],
          thClass: ["textAlignCenter", "dashboardBackDay"],
          label: this.$i18n.t("dashboard.head[10]"),
        },
        {
          key: "dayOtherUnits",
          formatter: "formalNbDecimals",
          tdClass: ["textAlignCenter", "dashboardBackDay"],
          thClass: ["textAlignCenter", "dashboardBackDay"],
          label: this.$i18n.t("dashboard.head[11]"),
        },
        {
          key: "dayRejectUnits",
          formatter: "formalNbDecimals",
          tdClass: ["textAlignCenter", "dashboardBackDay"],
          thClass: ["textAlignCenter", "dashboardBackDay"],
          label: this.$i18n.t("dashboard.head[12]"),
        },
        {
          key: "dayEff",
          formatter: "formalEff",
          tdClass: "getColor",
          thClass: ["textAlignCenter", "dashboardBackDay"],
          label: this.$i18n.t("dashboard.head[13]"),
        },
        {
          key: "taktTime",
          tdClass: ["textAlignCenter", "dashboardBackDay"],
          thClass: ["textAlignCenter", "dashboardBackDay"],
          label: this.$i18n.t("dashboard.head[14]"),
        },
        {
          key: "cycleTime",
          formatter: "formatToFixedTwo",
          tdClass: ["textAlignCenter", "dashboardBackDay"],
          thClass: ["textAlignCenter", "dashboardBackDay"],
          label: this.$i18n.t("dashboard.head[15]"),
        },
        {
          key: "allDayAverageUnit",
          formatter: "formalNbDecimals",
          tdClass: "textAlignCenter",
          thClass: "textAlignCenter",
          label: this.$i18n.t("dashboard.head[16]"),
        },
        {
          key: "lastHourUnit",
          formatter: "formalNbDecimals",
          tdClass: "textAlignCenter",
          thClass: "textAlignCenter",
          label: this.$i18n.t("dashboard.head[17]"),
        },
        {
          key: "previousShift",
          tdClass: "textAlignCenter",
          thClass: "textAlignCenter",
          label: this.$i18n.t("dashboard.head[18]"),
        },
        {
          key: "trendArrow",
          thClass: "textAlignCenter",
          label: this.$i18n.t("dashboard.head[19]"),
        },
      ];
    },
    opt() {
      const lang = this.$i18n.locale;
      // We add the following just so that minification optimizers
      // do not remove the above statement because the return value isn't used.
      if (!lang) {
        return {};
      }
      // Return the array of translated values
      return {
        multiple: true,
        filter: true,
        placeholder: this.$i18n.t("dashboard.linesPHolder"),
        multipleWidth: 250,
        width: 250,
        dropWidth: 250,
        position: "top",
        locale: localStorage.getItem("locale"),
      };
    },
  },
  methods: {
    //     getSam:function(){
    // return getSAM()
    //     },
    setLineValues(index, act, update) {
      let entry = this.linesList[index];
      let lastStd = act.stds[act.stds.length - 1];

      entry.lineName = act.line.name + " - " + act.line.description;
      entry.highEff = (act.line && act.line.highEff) || false;
      entry.schedDesc = act.sched.sched.description;
      entry.partNumber = lastStd.std.partNumber;
      entry.partNumberSaved = lastStd.std.partNumber + " - " + lastStd.std.description + " - " + getSAM(lastStd.std.SAM);
      //  lastStd.std.partNumber;
      entry.stdDesc = lastStd.std.description;
      entry.stdDescSaved = lastStd.std.partNumber + " - " + lastStd.std.description + " - " + getSAM(lastStd.std.SAM);
      entry.SAM = lastStd.std.SAM;
      entry.inspectLine = (act.line && act.line.inspectLine) || false;

      entry.useTakt = act.useTakt;
      entry.valueNAT = act.valueNAT;
      entry.valueCD = act.valueCD;

      entry.stdStartTime = lastStd.startTime;
      entry.nbProducts = act.nbProdLastStd;
      entry.nbProductsSaved = act.nbProdLastStd;
      if (entry.inspectLine) entry.dayRejectUnits = act.nbProducts;
      this.computeAvgUnitsHour(entry);

      if (oper.getNeedTask()) {
        let noTask = this.$i18n.t("selectTeam.enterTask");
        // sort by task name
        act.team.sort(function (a, b) {
          if (!a.taskName) a.taskName = noTask;
          if (!b.taskName) b.taskName = noTask;
          var emp1 = a.taskName.toLowerCase(),
            emp2 = b.taskName.toLowerCase();
          return emp1.localeCompare(emp2);
        });
      } else {
        // sor by name
        act.team.sort(function (a, b) {
          var emp1 = a.emp.firstName.toLowerCase(),
            emp2 = b.emp.firstName.toLowerCase();
          return emp1.localeCompare(emp2);
        });
      }

      entry.team = "";
      entry.teamNumber = 0;
      entry.teamNumberNotSupport = 0;
      let first = true;
      for (let i = 0; i < act.team.length; i++) {
        if (!first) entry.team += ", ";
        first = false;
        entry.team += act.team[i].emp.firstName + " " + act.team[i].emp.lastName + " " + act.team[i].emp.alias;
        if (oper.getNeedTask() && act.team[i].taskName) entry.team += " (" + act.team[i].taskName + ")";
        entry.teamNumber++;

        if (act.team[i].isSupport !== true) entry.teamNumberNotSupport++;
      }

      entry.taktTime = "--";
      entry.cycleTime = 0;
      if (update) {
        this.computeCycleTime(entry);
      } else {
        entry.goal = 0;
        entry.eff = "--";
        entry.state = -1;
        entry.goalUnitsHour = 0;
        entry.dayRegUnits = 0;
        entry.dayOtherUnits = 0;
        entry.dayRejectUnits = 0;
        entry.dayEff = "--";
        entry.allDayAverageUnit = 0;
        entry.lastHourUnit = 0;
        entry.previousShift = "--";
        entry.trendArrow = this.noArrow;

        if (entry.inspectLine) {
          entry.goal = "--";
          entry.eff = "--";
          entry.goalUnitsHour = "--";
          entry.dayRegUnits = "--";
          entry.dayOtherUnits = "--";
          entry.dayEff = "--";
          entry.taktTime = "--";
          entry.cycleTime = "--";
        }

        entry._showDetails = false;
        entry.ready = 0;

        this.linesList.sort(function (a, b) {
          var entry1 = a.lineName.toLowerCase(),
            entry2 = b.lineName.toLowerCase();
          return entry1.localeCompare(entry2);
        });
      }
    },
    formalEff(value) {
      if (isNaN(value)) return value;
      else return (value * 100).toFixed(1) + "%";
    },
    formalNbDecimals(value) {
      if (value) {
        if (isNaN(value)) return value;
        else return Math.round(value);
      }
      return 0;
    },
    formatToFixedTwo(value) {
      if (isNaN(value)) return value;
      else return value.toFixed(2);
    },
    formatFullText(value) {
      if (this.fullText) {
        let newValue = JSON.parse(JSON.stringify(value));
        let p = newValue.search(" - ");
        if (p != -1) return newValue.slice(0, p);
        else return value;
      } else return value;
    },
    getDetail(row, key) {
      let tex = this.$i18n.t("dashboard.head[" + Number(key - 1) + "]") + " : ";
      if (key === 4) return;
      if (key === 5) return tex + row.item.team;
      else return tex + row.item[row.fields[key - 1].key];
    },
    expandAdditionalInfo(row) {
      row._showDetails = !row._showDetails;
      this.$refs.linesListTable.refresh();
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (this.lineResult.indexOf(item.lineId) < 0 || item.ready < 31) {
        item._showDetails = false;
        return "dashboardHideRow";
      }
    },
    specialCell(value, key, item) {
      let cellColor = "blackLine";
      if (item.lineColor == "red") {
        cellColor = "redLine";
      }
      if (item.lineColor == "blue") {
        cellColor = "blueLine";
      }
      if (key == "partNumber" || key == "stdDesc") {
        if (this.fullText) return ["dashboardPtruncFullText", "dashboardBackCurrent", cellColor];
        else return ["dashboardPtrunc", "dashboardBackCurrent", cellColor];
      }
      if (key == "nbProducts") {
        return ["textAlignCenter", "dashboardBackCurrent", cellColor];
      }
    },
    getDashboardPtrunc() {
      if (this.fullText) return "dashboardPtruncFullText";
      else return "dashboardPtrunc";
    },
    getColor(value, key, item) {
      if (key.toString() == "eff") {
        let color = ["dashboardBackCurrent"];
        if (!isNaN(item.eff)) {
          color.push("dashboardStylePercent");
          if (item.eff * 100 < this.minPercent) {
            color.push("dashboardStyleRed");
          } else if (item.eff >= 1) {
            color.push("dashboardStyleGold");
          }
        }
        return color;
      } else {
        let color = ["dashboardBackDay"];
        if (!isNaN(item.dayEff)) {
          color.push("dashboardDayPercent");
          if (item.dayEff * 100 < this.minPercent) {
            color.push("dashboardDayRed");
          } else if (item.dayEff >= 1) {
            color.push("dashboardDayGold");
          }
        }
        return color;
      }
    },
    manminuteChange() {
      //console.log('----- manminuteChange');
    },
    lineSelectChange() {
      if (!this.inInit) {
        this.linesList.forEach((line) => {
          if (this.lineResult.indexOf(line.lineId) < 0) line._showDetails = false;
        });
        this.$refs.linesListTable.refresh();
      }
    },
    clickClose() {
      oper.dashboard(false);
      this.$router.replace("/operation");
    },
    actUpdateDashboard(act) {
      //console.log('----- actUpdateDashboard ');// + JSON.stringify(act, null, 2));
      if (!act.line) {
        console.log("----- ***** ERROR ! act.line");
        return;
      }
      let index = this.actIndex(act.line._id);
      if (index != -1) {
        this.setLineValues(index, act, true);
        this.$refs.linesListTable.refresh();
      } else console.log("----- ***** ERROR index == -1");
    },
    dashboardStateUpdate(data) {
      //console.log('----- dashboardStateUpdate ' + JSON.stringify(data, null, 2));
      let index = this.actIndex(data.lineId);
      if (index != -1) {
        let entry = this.linesList[index];
        entry.ready |= 16;
        entry.state = data.state;
        if (data.state == globals.STATENOT) {
          // remove this line at end of production
          this.linesList.splice(index, 1);
        } else if (data.state == globals.STATEDOWN) {
          entry.partNumber = this.$i18n.t("dashboard.inDownTime");
          entry.stdDesc = data.textDown;
          entry.lineColor = "red";
          entry.startSpecialState = data.timeStartDown;
        } else if (data.state == globals.STATECHANGEOVER) {
          entry.partNumber = this.$i18n.t("dashboard.inChangeover");
          entry.stdDesc = data.textChangeover;
          entry.lineColor = "blue";
          entry.startSpecialState = data.timeStartChangeover;
        } else {
          entry.partNumber = entry.partNumberSaved;
          entry.stdDesc = entry.stdDescSaved;
          entry.nbProducts = entry.nbProductsSaved;
          entry.lineColor = "black";
          this.$refs.linesListTable.refresh();
        }
      } else console.log("----- ***** ERROR index == -1");
    },
    checkDownsAndChangeovers() {
      this.linesList.forEach((line) => {
        if (line.state == globals.STATEDOWN || line.state == globals.STATECHANGEOVER) {
          line.nbProducts = moment.utc(moment().diff(moment(line.startSpecialState))).format("H:mm:ss");
          this.$refs.linesListTable.refresh();
        }
      });
    },
    dashboardGoalUpdate(data) {
      //console.log('----- dashboardGoalUpdate ' + JSON.stringify(data, null, 2));
      this.minPercent = data.minPercent;
      let index = this.actIndex(data.lineId);
      if (index != -1) {
        let entry = this.linesList[index];
        if (entry.highEff) {
          data.goal = data.goalOEE;
          data.eff = data.effOEE;
          data.totalEff = data.totalEffOEE;
        }
        entry.goal = data.goal;
        if (data.eff == 0) entry.eff = "--";
        else entry.eff = data.eff / 100;
        entry.dayRegUnits = data.totalProducts[globals.CATNORMAL];
        entry.dayOtherUnits = data.totalProducts[globals.CATOTHER];
        entry.dayRejectUnits = data.totalProducts[globals.CATREJECT1] + data.totalProducts[globals.CATREJECT2];
        if (data.totalEff == 0) entry.dayEff = "--";
        else entry.dayEff = data.totalEff / 100;
        entry.earnedToday = data.totalEarned;
        entry.workedToday = data.totalWorked;
        entry.ptime = data.ptime;
        entry.totalPtime = data.totalPtime;

        if (entry.inspectLine) {
          entry.goal = "--";
          entry.eff = "--";
          entry.goalUnitsHour = "--";
          entry.dayRegUnits = "--";
          entry.dayOtherUnits = "--";
          entry.dayEff = "--";
          entry.taktTime = "--";
          entry.cycleTime = "--";
        }

        this.computeGoalUnitsHour(entry);
        this.computeCycleTime(entry);
        this.computeAllDayAverageUnit(entry);

        entry.ready |= 8;
        this.$refs.linesListTable.refresh();
      } else console.log("----- ***** ERROR index == -1");
    },
    dashboardNbProducts(data) {
      //console.log('----- dashboardNbProducts ' + JSON.stringify(data, null, 2));
      // data = { "nbProducts" : nbProducts, "lineId" : lineId }
      let index = this.actIndex(data.lineId);
      if (index != -1) {
        let entry = this.linesList[index];
        entry.nbProducts = data.nbProducts;
        entry.nbProductsSaved = data.nbProducts;
        if (entry.inspectLine) entry.dayRejectUnits = data.nbProducts;

        this.computeAvgUnitsHour(entry);

        entry.ready |= 4;
        this.$refs.linesListTable.refresh();
      } else console.log("----- ***** ERROR index == -1");
    },
    actIndex(lineId) {
      for (let i = 0; i < this.linesList.length; i++) if (lineId.toString() == this.linesList[i].lineId.toString()) return i;
      return -1;
    },
    lastActDashboard(data) {
      //console.log('----- lastActDashboard ' + JSON.stringify(data, null, 2));
      let index = this.actIndex(data.lineId);
      if (index != -1) {
        let entry = this.linesList[index];

        if (data.lastActDashboard == -1) entry.previousShift = "--";
        else entry.previousShift = Math.round(data.lastActDashboard).toString();

        entry.ready |= 2;
        this.$refs.linesListTable.refresh();
      } else console.log("----- ***** ERROR index == -1");
    },
    actDashboard(act) {
      //console.log('----- actDashboard ' + JSON.stringify(act.line._id, null, 2));

      let index = this.actIndex(act.line._id);
      if (index == -1) {
        // add a new running line in dashboard
        this.linesList.push({ lineId: act.line._id });
        index = this.linesList.length - 1;

        //console.log('----- dash actualTabletNumbers = ' + JSON.stringify(this.activity._id));
        axiosInstance.post("/actualTabletNumbers", { actId: act._id });
      }
      this.setLineValues(index, act, false);
      this.$refs.linesListTable.refresh();
    },

    computeAvgUnitsHour(entry) {
      if (entry.nbProducts != 0) {
        entry.avgUnitsHour = (entry.nbProducts / moment().diff(moment(entry.stdStartTime))) * 3600000;
      } else {
        entry.avgUnitsHour = 0;
      }
    },
    computeGoalUnitsHour(entry) {
      let sct = 0;
      if (entry.highEff !== true) {
        if (entry.teamNumberNotSupport > 0) sct = entry.SAM / entry.teamNumberNotSupport;
        else sct = 0;
      } else {
        sct = entry.SAM;
      }

      if (sct != 0) {
        entry.goalUnitsHour = 3600 / sct;
        entry.goalUnitsHour = Math.round(entry.goalUnitsHour);
      } else {
        entry.goalUnitsHour = 0;
      }
      if (entry.inspectLine) {
        entry.goalUnitsHour = "--";
      }
    },
    computeCycleTime: function (entry) {
      // this function is duplicated in TabletMain.vue
      if (entry.totalPtime && entry.dayRegUnits) {
        entry.cycleTime = entry.totalPtime / entry.dayRegUnits / 60000;
      } else {
        entry.cycleTime = 0;
      }
      if (entry.inspectLine) {
        entry.cycleTime = "--";
      }
    },
    computeAllDayAverageUnit(entry) {
      //console.log('----- entry.dayRegUnits ' + JSON.stringify(entry.dayRegUnits, null, 2));
      //console.log('----- entry.totalPtime ' + JSON.stringify(entry.totalPtime, null, 2));
      if (entry.totalPtime && entry.dayRegUnits) {
        entry.allDayAverageUnit = entry.dayRegUnits / (entry.totalPtime / 3600000);
      } else {
        entry.allDayAverageUnit = 0;
      }
      if (entry.inspectLine) {
        entry.allDayAverageUnit = "--";
      }
      this.setTrendArrow(entry);
    },
    nbProductsLastHour(data) {
      //console.log('----- nbProductsLastHour ' + JSON.stringify(data, null, 2));
      let index = this.actIndex(data.lineId);
      if (index != -1) {
        let entry = this.linesList[index];
        entry.lastHourUnit = data.lastHourDashboard;
        this.setTrendArrow(entry);
        this.$refs.linesListTable.refresh();
        entry.ready |= 1;
      }
    },
    setTrendArrow(entry) {
      //console.log('----- setTrendArrow');
      //console.log('----- entry.lastHourUnit = ' + entry.lastHourUnit);
      //console.log('----- entry.allDayAverageUnit = ' + entry.allDayAverageUnit);
      if (entry.lastHourUnit > entry.allDayAverageUnit) entry.trendArrow = this.arrowUp;
      else if (entry.lastHourUnit < entry.allDayAverageUnit) entry.trendArrow = this.arrowDown;
      else entry.trendArrow = this.noArrow;
    },
  },
  mounted() {
    oper.dashboard(true);

    axiosInstance.get("/getdashboard").then(() => {});
    axiosInstance.get("/lineList", { params: { isActive: true } }).then((resp) => {
      for (let i = 0; i < resp.data.length; i++) {
        resp.data[i].text = resp.data[i].name + " - " + resp.data[i].description;
        resp.data[i].value = resp.data[i]._id;
      }
      //console.log("----- get /lineList = " + JSON.stringify(resp.data, null, 2));
      this.lineSelect = resp.data;
      this.$nextTick(function () {
        this.$refs.lineSelect.checkAll();
        this.inInit = false;
      });
    });

    this.downsAndChangeoversTimer = setInterval(this.checkDownsAndChangeovers, 1000);

    EventBus.$on("actDashboard", this.actDashboard);
    EventBus.$on("lastActDashboard", this.lastActDashboard);
    EventBus.$on("actUpdateDashboard", this.actUpdateDashboard);

    EventBus.$on("stateDashboard", this.dashboardStateUpdate);
    EventBus.$on("goalDashboard", this.dashboardGoalUpdate);
    EventBus.$on("nbProductsDashboard", this.dashboardNbProducts);
    EventBus.$on("lastHourDashboard", this.nbProductsLastHour);
  },
  beforeDestroy() {
    clearInterval(this.downsAndChangeoversTimer);

    EventBus.$off("actDashboard", this.actDashboard);
    EventBus.$off("lastActDashboard", this.lastActDashboard);
    EventBus.$off("actUpdateDashboard", this.actUpdateDashboard);

    EventBus.$off("stateDashboard", this.dashboardStateUpdate);
    EventBus.$off("goalDashboard", this.dashboardGoalUpdate);
    EventBus.$off("nbProductsDashboard", this.dashboardNbProducts);
    EventBus.$off("lastHourDashboard", this.nbProductsLastHour);
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/global.scss";

.dashboardStylePercent {
  display: flex;
  border: 3px solid $eff-green;
  border-radius: 8px;
  color: $eff-green;
  display: block;
  text-align: center;
}
.dashboardStyleRed {
  border: 3px solid $eff-red;
  color: $eff-red;
}
.dashboardStyleGold {
  border: 3px solid $eff-gold;
  color: $eff-gold;
}

.dashboardDayPercent {
  display: flex;
  border: 3px solid $eff-green;
  border-radius: 8px;
  background-color: $eff-green !important;
  color: white;
  display: block;
  text-align: center;
}
.dashboardDayRed {
  border: 3px solid $eff-red;
  background-color: $eff-red !important;
}
.dashboardDayGold {
  border: 3px solid $eff-gold;
  background-color: $eff-gold !important;
}

.dashboardPtrunc {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;

  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.dashboardPtruncFullText {
  width: 100%;

  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.dashboardHideRow {
  display: none;
}

.dashboardBackCurrent {
  background-color: #d1dec8;
}
.dashboardBackDay {
  background-color: #c8dade;
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.centerArrow {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.redLine {
  color: red;
}
.blueLine {
  color: blue;
}
.blackLine {
  color: black;
}
</style>
