<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask" v-show="showLine">
      <div class="modal-container">
        <div class="table-container">
          <div
            style="display: grid; grid-template-columns: 40% 60%; margin-bottom: 10px;"
          >
            <div v-if="!gotList">
              <b-spinner label="Loading..."></b-spinner>
            </div>
            <div v-else>
              <label v-if="linesList.length == 0" class="attention">{{
                $t("selectLine.noLine")
              }}</label>
              <label v-else>{{ $t("selectLine.sLine") }}</label>
            </div>

            <div>
              <b-form-group
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <input
                    type="search"
                    id="filterInput"
                    class="filterInput"
                    ref="filterInput"
                    :placeholder="$t('selectStd.searchPHolder')"
                    @keydown="searchLine"
                  />
                </b-input-group>
              </b-form-group>
            </div>
          </div>
          <Table
            :tableFields="showFields"
            :rowsData="linesList"
            :keys="keys"
            :returnClickOnArray="true"
            :tableid="tableId"
            gridtemplatecolumns="25% 74%"
            customHeight="100%"
            v-on:rowclick="onLineSelected"
          />
        </div>
        <div class="bottomControls">
          <div class="buttons">
            <button
              class="btn btn-perfcell float-right marginTop50px"
              @click="clickCancel"
            >
              {{ $t("selectLine.cancel") }}
            </button>

            <button
              v-if="saveLineEnabled"
              class="btn btn-perfcell float-right marginTop50px marginRight10px"
              @click="clickSave"
            >
              {{ $t("selectLine.save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { axiosInstance } from "@/main";
import Table from "@/components/Table/Table.vue";
import EventBus from "@/eventBus";

export default {
  name: "SelectLine",
  template: "#modal-template",
  components: {
    Table,
  },
  props: {
    showLine: Boolean,
    doInitLine: Boolean,
  },
  data() {
    return {
      tableId: "line",
      tableChannel: "",
      keys: ["name", "description"],
      filter: null,
      linesList: [],
      showFields: [
        {
          key: "name",
          label: this.$i18n.t("selectLine.name"),
        },
        {
          key: "description",
          label: this.$i18n.t("selectLine.desc"),
        },
      ],
      selectedLine: {},
      selectedLineId: "",
      lastSelectedLineId: "",
      line_multiBox: false,
      saveLineEnabled: false,
      inInit: false,
      didSearch: false,
      gotList: false,
      localDoInitLine: false, // Local property to replace prop mutation
    };
  },
  created() {
    this.tableChannel = `table::${this.tableId}`;
    EventBus.$on("initLineWithId", this.initLineWithId); // Listen for the event
  },
  watch: {
    doInitLine(newVal) {
      this.localDoInitLine = newVal; // Sync prop with local data property
    },
    localDoInitLine(newVal) {
      if (newVal) {
        this.linesList = [];
        this.gotList = false;
        this.inInit = true;
        this.didSearch = false;

        this.$refs.filterInput.value = "";

        axiosInstance
          .get("/lineList", { params: { isActive: true } })
          .then((resp) => {
            this.linesList = resp.data;
            this.gotList = true;
            this.lastSelectedLineId = localStorage.getItem("lastSelectedLineId");

            this.selectedLine = {};
            this.selectedLineId = "";

            this.inInit = false;
          })
          .catch((err) => {
            console.log("Error fetching line list: ", err);
          });
      }
    },
    selectedLineId() {
      this.saveLineEnabled = this.selectedLineId != "";
    },
  },
  methods: {
    filterTable(row, filter) {
      let words = filter.split(" ");
      let found = false;
      words.forEach((Word) => {
        let word = Word.toLowerCase();
        if (word.length) {
          if (row.name.toLowerCase().includes(word)) found = true;
          if (row.description.toLowerCase().includes(word)) found = true;
        }
      });
      return found;
    },
    onLineSelected(item) {
      if (item.length > 0) {
        this.selectedLine = item[0];
        this.selectedLineId = item[0]._id;
        this.line_multiBox = item[0].multiBox || false;
        this.saveLineEnabled = true;
        if (!this.inInit) this.clickSave();
      } else {
        this.saveLineEnabled = false;
        if (!this.inInit && !this.didSearch)
          this.clickSave();
      }
    },
    clickSave() {
      this.selectedLine.courierStandar = this.isCourierProcess
        ? this.courierProcessType
        : "empty";
      localStorage.setItem("lastSelectedLineId", this.selectedLineId);
      this.$emit("close", { cancel: false, selectedLine: this.selectedLine });
    },
    clickCancel() {
      this.$emit("close", { cancel: true });
    },
    searchLine(input) {
      EventBus.$emit(`${this.tableChannel}::search`, input);
    },
    initLineWithId(lineId) {
      this.localDoInitLine = true; // Use local property
      this.linesList = [];
      this.gotList = false;
      this.inInit = true;
      this.didSearch = false;
      this.$refs.filterInput.value = "";

      axiosInstance
        .get("/lineList", { params: { isActive: true,automaticStart:true } })
        .then((resp) => {

          this.linesList = resp.data;
          this.gotList = true;
          this.lastSelectedLineId = lineId;

          const selectedLine = this.linesList.find(line => line._id === lineId);

          console.log(selectedLine,"INSIDE CHILD LINE ID",lineId)

          if (selectedLine) {
            this.selectedLine = selectedLine;
            this.selectedLineId = selectedLine._id;
            this.line_multiBox = selectedLine.multiBox || false;
            this.saveLineEnabled = true;
            this.clickSave(); // Automatically save the selected line
          }

          this.inInit = false;
        })
        .catch((err) => {
          console.log("Error fetching line list: ", err);
        });
    },
  },
  beforeDestroy() {
    EventBus.$off("initLineWithId", this.initLineWithId); // Remove event listener
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.modal-mask {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}
.modal-container {
  width: 66%;
  height: 90%;
  overflow-y: hidden;
  margin: 40px auto 0;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  height: 90vh;
}

.table-container {
  height: 85%;
}

@media only screen and (max-width: 800px) {
  .modal-container {
    width: 100%;
  }
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.curierProcessCheckbox {
  margin-left: 10px;
  margin-top: 60px;
}

.curierSelect {
  margin-left: 10px;
  outline: none;
  border: inherit solid 2;
  padding: 5px 7px;
}
.bottomControls {
  height: 40px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
}
.isCourierType {
  grid-column: span 2 / span 2;
  grid-row: span 1 / span 1;
}

.courierProcessType {
  grid-column: span 2 / span 2;
  grid-row: span 1 / span 1;
  padding-top: 20px;
}

.buttons {
  grid-column: span 1 / span 1;
  /* grid-row: span 2 / span 2; */
  grid-row-start: 1;
  grid-column-start: 3;
  display: flex;
  flex-flow: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
}

.filterInput {
  outline: none;
  width: 100%;
  border-radius: 10px;
  border: 2px solid #cacaca;
  padding: 3px 8px;
}

input::-ms-clear {
  display: none;
}

::-webkit-search-decoration,
::-webkit-search-cancel-button,
::-webkit-search-results-button,
::-webkit-search-results-decoration {
  display: none;
}
</style>
