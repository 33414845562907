<template>
  <!-- HEADER -->
  <div>
    <div class="g-pageHeader" style="height: 63px">
      <h1 style="margin: auto">{{ $t("cleaningFactorList.title") }}</h1>
    </div>

    <SearchBar @search="handleSearch" />

    <Table
      :cleaningFactors="cleaningFactorsToShow"
      :rowsPerPage="rowsPerPage"
      :actualPage="actualPage"
      @editItem="handleEditCleaningFactor"
      @removeItem="handleRemoveCleaningFactor"
    />

    <Footer :pages="pages" @changePage="handleChangePage" />
  </div>
</template>

<script>
import { axiosInstance } from "@/main";

import Table from "./components/Table.vue";
import SearchBar from "./components/Search-bar.vue";
import Footer from "./components/Footer.vue";

export default {
  components: {
    Table,
    Footer,
    SearchBar,
  },
  data() {
    return {
      cleaningFactors: [],
      searchValue: "",

      rowsPerPage: 15,
      // pages: 1,
      actualPage: 1,
    };
  },
  computed: {
    cleaningFactorsToShow() {
      return this.cleaningFactors.filter(({ cleaningFactorName }) => cleaningFactorName.includes(this.searchValue));
    },
    pages() {
      return Math.ceil(this.cleaningFactorsToShow.length / 15);
    },
  },
  methods: {
    handleSearch(searchValue) {
      this.searchValue = searchValue;
    },

    async fetchCleaningFactors() {
      const fetchedCleaningFactors = await axiosInstance.get("/cleaning-factor");
      this.cleaningFactors = fetchedCleaningFactors.data.cleaningFactors;
    },

    handleEditCleaningFactor(cleaningFactorId) {
      this.$router.replace({ path: `/config/cleaning-factor/${cleaningFactorId}` });
    },

    async handleRemoveCleaningFactor(cleaningFactorId) {
      const response = await axiosInstance.delete(`/cleaning-factor/${cleaningFactorId}`);
      const deletedCleaningFactor = response.data.deletedCleaningFactor;

      console.log("Cleaning factor", deletedCleaningFactor._id, "deleted, info:", deletedCleaningFactor);
      this.fetchCleaningFactors();
    },

    handleChangePage(pageToChange) {
      this.actualPage = pageToChange;
    },
  },
  mounted() {
    this.fetchCleaningFactors();
  },
};
</script>
