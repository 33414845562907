<template>
  <input
    ref="inputTextField"
    type="text"
    autocapitalize="off"
    @input="handleChange"
    class="form-control"
    :autocomplete="autocomplete"
    :placeholder="placeholder"
    @keypress.enter="submit"
  />
</template>

<script>
export default {
  props: {
    initInputText: Boolean,
    password: String,
    placeholder: String,
    autocomplete: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      localValue: "",
      realValue: "",
    };
  },
  watch: {
    initInputText: function (val) {
      if (val) {
        this.realValue = "";
        this.localValue = "";
        this.$refs.inputTextField.value = "";
      }
    },
  },
  computed: {},
  methods: {
    handleChange() {
      this.localValue = this.$refs.inputTextField.value;

      if (this.password == "true") {
        if (this.realValue.length < this.localValue.length) {
          // the user have entered a char
          this.realValue += this.localValue.charAt(this.localValue.length - 1);
          this.$refs.inputTextField.value = this.localValue.slice(0, -1) + "*";
        } else if (this.realValue.length > this.localValue.length) {
          // the user have pressed the back key
          this.realValue = this.realValue.slice(0, -1);
        }
      } else {
        this.realValue = this.localValue;
      }
      this.$emit("handleChange", this.realValue);
    },
    submit() {
      this.$emit("submit");
    },
  },
};
</script>

<style scoped></style>
