<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask" v-show="showTeam">
      <div class="modal-container">
        <div class="table-container">
          <!-- {{ this.selectedTeamIds }} -->
          <div style="
              display: grid;
              grid-template-columns: 40% 60%;
              margin-bottom: 10px;
              height: 7%;
            ">
            <div v-if="!gotList">
              <b-spinner label="Loading..."></b-spinner>
            </div>
            <div v-else>
              <label v-if="employeesList.length == 0" class="attention">{{
                $t("selectTeam.noEmployee")
              }}</label>
              <label v-else style="font-weight: bold">{{
                $t("selectTeam.title")
              }}</label>
            </div>

            <div>
              <b-form-group label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
                class="mb-0">
                <b-input-group size="sm">
                  <b-form-input v-model="inputSearchValue" type="search" id="filterInput" ref="filterInput"
                    style="outline: none; border-radius: 10px; padding: 3px 5px"
                    :placeholder="$t('selectStd.searchPHolder')" @keydown="searchEmployees"></b-form-input>
                </b-input-group>
              </b-form-group>
            </div>
          </div>

          <Table :tableFields="tableFields" :rowsData="employeesList" :keys="keys" :tableid="tableId" overlaywidth="74%"
            customHeight="100%" gridtemplatecolumns="18% 18% 19% 20% 19%" v-on:rowclick="selectEmployee" />
        </div>
        <hr />
        <div>
          {{ $t("selectTeam.newTeam") }}
          <span v-for="(emp, index) in selected" style="margin-left: 10px" :key="emp._id" class="cursorPointer" @click="
            clickRemoveEmp(emp.firstName, emp.lastName, emp.taskName, emp._id)
            ">
            <i class="fa fa-remove" style="color: black"></i>
            {{ emp.firstName }} {{ emp.lastName }} {{ emp.alias }}
            <span v-if="needTask">(<span style="color: red">{{ emp.taskName }}</span>)</span>
            <span v-if="index < selected.length - 1">,</span>
          </span>
        </div>
        <b-row>
          <b-col>
            <div class="row">
              <div class="col-md-8">
                <div v-if="taskVisible || edit" class="form-group marginTop20px">
                  <label for="task">{{ $t("selectTeam.selectTask")
                    }}<span style="color: red">{{ fullName }}</span></label>
                  <div id="task">
                    <div>
                      <MultipleSelect :data="taskList" v-model="selectedTaskId" @change="taskChange" :options="opt">
                      </MultipleSelect>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="row">
                  <div>
                    <b-button class="btn btn-perfcell btn-zeroMargin" v-if="saveTeamEnabled" @click="clickSave">
                      {{ $t("selectTeam.save") }}
                    </b-button>
                  </div>
                  <div>
                    <b-button class="btn btn-perfcell btn-zeroMargin" @click="clickCancel">
                      {{ $t("selectTeam.cancel") }}
                    </b-button>
                  </div>
                  <div>
                    <b-button class="btn btn-perfcell btn-zeroMargin" @click="clearEmployeeSelectedList">
                      {{ $t("selectTeam.clearList") }}
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <confirmAutoLogin ref="confirmSelect" :lastSelectedTeam="lastSelectedTeam" :employeesList="employeesList"
        :doInitAutoLogin="autoLoginDoInit" :showAutoLogin="showModalAutoLogin" @close="closeModalAutoLogin">
      </confirmAutoLogin>
    </div>
  </transition>
</template>

<script>
import { axiosInstance } from "@/main";
import oper from "@/stores/Operations.js";
import confirmAutoLogin from "@/components/ConfirmAutoLogin.vue";
import Table from "@/components/Table/Table.vue";
import EventBus from "@/eventBus";

export default {
  name: "SelectTeam",
  template: "#modal-template",
  props: {
    showTeam: Boolean,
    doInitTeam: Boolean,
    tabMainOk: Boolean,
    autoLogin: Number,
    actualTeam: Array,
    selectedTeamIds: Array, // New prop to receive selected team member IDs
  },
  data: function () {
    return {
      inputSearchValue: "",
      tableId: "team",
      tableChannel: "",
      tableFields: [],
      employeesSelected: [],
      keys: ["firstName", "lastName", "alias", "taskName", "lineName"],
      selectedPrevObj: [],
      edit: false,
      filter: null,
      actualTeamIds: [],
      selected: [],
      selectedIds: [],
      blocked: [],
      employeesList: [],
      sortBy: "firstName",
      sortDesc: false,
      saveTeamEnabled: false,
      gotList: false,
      teamStorageName: "",

      lastClicked: "",

      needTask: false,
      taskList: [],
      selectedTaskId: "",
      selectedTaskName: "",
      taskVisible: false,
      fullName: "",
      opt: {
        multiple: false,
        filter: true,
        placeholder: this.$i18n.t("selectTeam.taskPHolder"),
        multipleWidth: 375,
        width: 375,
        dropWidth: 375,
        position: "top",
        locale: localStorage.getItem("locale"),
      },

      lastSelectedTeam: [],
      showModalAutoLogin: false,
      autoLoginDoInit: false,
    };
  },
  components: {
    confirmAutoLogin,
    Table,
  },
  watch: {
    doInitTeam: function (val) {
      if (val) {
        console.log("----- init SelectTeam");
        //console.log("----- this.actualTeamIds = " + JSON.stringify(this.actualTeamIds));

        this.$refs.filterInput.value = "";
        this.inputSearchValue = "";

        this.gotList = false;
        this.employeesList = [];
        this.selected = [];
        this.selectedIds = [];
        this.blocked = [];
        this.sortBy = "firstName";
        this.sortDesc = false;
        this.lastClicked = "";
        this.selectedTaskId = "";
        this.selectedTaskName = "";
        this.lastSelectedTeam = [];
        this.taskVisible = false;
        this.needTask = oper.getNeedTask();

        this.actualTeamIds = [];
        this.actualTeam.forEach((emp) => {
          this.actualTeamIds.push(emp._id);
        });

        this.teamStorageName =
          "lastTeam" + oper.getLineId() + oper.getSchedId();
        this.lastSelectedTeam = JSON.parse(
          localStorage.getItem(this.teamStorageName)
        );
        if (!this.lastSelectedTeam) this.lastSelectedTeam = [];
        //console.log("----- this.lastSelectedTeam = " + JSON.stringify(this.lastSelectedTeam));

        axiosInstance
          .get("/userList", { params: { isActive: true } })
          .then((resp) => {
            //console.log("----- get /userList = " + JSON.stringify(resp.data, null, 2));
            this.employeesList = resp.data;

            this.gotList = true;

            if (this.actualTeamIds.length > 0) {
              this.selectedIds = JSON.parse(JSON.stringify(this.actualTeamIds));
              this.setSelected();
            } else if (
              this.autoLogin > 0 &&
              this.lastSelectedTeam.length != 0
            ) {
              this.showModalAutoLogin = true;
              this.autoLoginDoInit = true;
            } else this.setSelected();
          });
        axiosInstance
          .get("/taskList", {
            params: { isActive: true, onLine: oper.getLineId() },
          })
          .then((resp) => {
            for (let i = 0; i < resp.data.length; i++) {
              resp.data[i].text = resp.data[i].name;
              resp.data[i].value = resp.data[i]._id;
            }
            //console.log("----- get /taskList = " + JSON.stringify(resp.data, null, 2));
            this.taskList = [];
            resp.data.forEach((task) => {
              this.taskList.push(task);
            });
          });
      }
    },
    selectedTeamIds(newVal) {
      console.log("Selected Team IDs Changed:", newVal); // Log to check if watcher is triggered

      this.initTeamSelection(newVal);
    },
  },
  methods: {
    initTeamSelection(teamIds) {
      axiosInstance
        .get("/userList", { params: { isActive: true } })
        .then((resp) => {
          this.employeesList = resp.data;
          this.gotList = true;

          let selectedTeam = this.employeesList.filter(emp => teamIds.includes(emp._id));
          console.log(selectedTeam, "selectedTeam")
            if (selectedTeam.length > 0) {
            this.selectedIds = teamIds;
            this.selected = selectedTeam;
            this.selectedPrevObj = selectedTeam;       
            this.saveTeamEnabled = true
            // this.setSelected();

            this.apnaclickSave()
          } else {
            // Handle cases where no employees are selected or show a message
            console.warn("No employees found for the given IDs:", teamIds);
          }
        })
        .catch(error => {
          console.error("Failed to fetch user list:", error);
        });
    },
    selectEmployee(employee) {

      const baseAlias = employee.alias.split('-')[0]; // Extract base alias before `-`

    const isAlreadySelected = this.employeesList.some((e) => {
        // Check if the alias starts with the baseAlias and if e?.lineName exists
        return e.alias.startsWith(baseAlias) && e.lineName;
    });

    if (isAlreadySelected) {
        alert(`${this.$i18n.t("selectTeam.alertselectEmps")} ${baseAlias} ${this.$i18n.t("selectTeam.alertselectEmpe")}`);
    } else {
        // Proceed with adding the employee to the selected list
        console.log(`${employee.firstName} selected.`);

      this.selectedPrevObj.push(employee);
      this.inputSearchValue = "";
      const index = this.employeesSelected.indexOf(employee);
      if (index >= 0) {
        this.employeesSelected.splice(index, 1);
        this.removeEmployee(employee._id);
      } else {
        this.employeesSelected.push(employee);
        this.onEmpSelected(this.employeesSelected);
      }
    }
    },
    clearEmployeeSelectedList() {
      this.selected = [];

      this.employeesSelected = [];
      EventBus.$emit(`${this.tableChannel}::clearAll`);
    },
    searchEmployees(input) {
      EventBus.$emit(`${this.tableChannel}::search`, input);
    },

    closeModalAutoLogin: function (data) {
      //console.log("----- closeModalStart = " + JSON.stringify(data, null, 2));
      this.showModalAutoLogin = false;
      this.autoLoginDoInit = false;

      this.selectedIds = data;
      this.setSelected();
    },
    showFields() {
      let fields = [
        {
          key: "firstName",
          sortable: true,
          label: this.$i18n.t("selectTeam.firstName"),
        },
        {
          key: "lastName",
          sortable: true,
          label: this.$i18n.t("selectTeam.lastName"),
        },
        {
          key: "alias",
          sortable: true,
          label: this.$i18n.t("selectTeam.alias"),
        },
        {
          key: "lineName",
          sortable: true,
          label: this.$i18n.t("selectTeam.onLine"),
        },
      ];
      if (this.needTask)
        fields.splice(3, 0, {
          key: "taskName",
          sortable: true,
          tdClass: "taskClass",
          label: this.$i18n.t("selectTeam.task"),
        });
      return fields;
    },
    taskClass(value) {
      if (value.charAt(0) === "-") return "attention";
      return "";
    },
    filterTable(row, filter) {
      console.log("FILTER:", filter);
      let words = filter.split(" ");
      let found = false;
      words.forEach((Word) => {
        let word = Word.toLowerCase();
        if (word.length) {
          if (row.firstName.toLowerCase().includes(word)) found = true;
          if (row.lastName.toLowerCase().includes(word)) found = true;
          if (row.alias.toLowerCase().includes(word)) found = true;
        }
      });
      return found;
    },
    taskChange: function (taskId) {
      //console.log("----- taskChange = " + JSON.stringify(taskId, null, 2));
      for (let i = 0; i < this.employeesList.length; i++) {
        if (this.employeesList[i]._id == this.lastClicked) {
          let list = this.taskList;
          for (let t = 0; t < list.length; t++) {
            if (list[t]._id == taskId) {
              this.employeesList[i].taskId = list[t]._id;
              this.employeesList[i].taskName = list[t].name;
              this.employeesList[i].isSupport = list[t].isSupport;
              break;
            }
          }
          break;
        }
      }
    },
    clickRemoveEmp: function (fname, lname, taskName, empID) {
      EventBus.$emit(`${this.tableChannel}::removeItem`, empID);

      //console.log("----- clickRemoveEmp = " + JSON.stringify(empID, null, 2));

      if (this.tabMainOk == true) {
        // ask the user if he really want to delete employee
        let msg = fname + " " + lname;
        if (this.needTask) msg = msg + ", " + taskName;
        this.$bvModal
          .msgBoxConfirm(msg, {
            okTitle: this.$i18n.t("tabTeam.remove"),
            cancelTitle: this.$i18n.t("tabTeam.cancel"),
          })
          .then((value) => {
            if (value) {
              // remove the employee from team
              this.removeEmployee(empID);
            }
          });
      } else {
        // not in production so remove the employee without asking
        this.removeEmployee(empID);
      }
    },
    removeEmployee: function (empID) {
      this.taskVisible = false;
      this.selectedTaskId = "";
      this.selectedTaskName = "";
      this.selectedIds = this.selectedIds.filter((item) => item !== empID);

      for (let i = 0; i < this.employeesList.length; i++) {
        if (this.employeesList[i]._id == empID) {
          let emp = this.employeesList[i];

          let index = emp.lineId.indexOf(oper.getLineId());
          if (index > -1) {
            emp.lineId.lenght ? emp.lineId.splice(index, 1) : null;
          }
          let lineName = oper.getLineName();
          if (emp.lineName.includes(lineName + ", "))
            emp.lineName = emp.lineName.replace(lineName + ", ", "");
          else if (emp.lineName.includes(", " + lineName))
            emp.lineName = emp.lineName.replace(", " + lineName, "");
          else {
            emp.lineName = emp.lineName.replace(lineName, "        ");
            emp.taskName = emp.taskName
              ? emp.taskName.replace(emp.taskName, "")
              : null;
          }

          break;
        }
      }
      this.setSelected();
    },
    onEmpSelected(items) {

      console.log(items, "onEmpSelected")
      //console.log("----- items = " + JSON.stringify(items));

      if (items.length == 0) return;

      // this.$refs.selectTeamTable.clearSelected();

      // this.lastClicked = items[0]._id;
      this.lastClicked = items[items.length - 1]._id;

      //console.log("----- this.lastClicked = " + JSON.stringify(this.lastClicked));

      let clickedEmp = null;
      for (let i = 0; i < this.employeesList.length; i++) {
        if (this.employeesList[i]._id == this.lastClicked) {
          clickedEmp = this.employeesList[i];
          break;
        }
      }
      if (this.needTask && this.taskVisible && !this.selectedTaskId) {
        clickedEmp.taskName = this.$i18n.t("selectTeam.enterTask");
        return;
      }

      // ignore click on gray names
      if (this.blocked.includes(this.lastClicked)) {
        //console.log("----- this.lastClicked in blocked");

        this.selectedTaskId = "";
        this.selectedTaskName = "";
        this.$bvModal.msgBoxOk(this.$i18n.t("selectTeam.grey"));
        return;
      }
      // click on a green name
      if (this.selectedIds.includes(this.lastClicked)) {
        this.edit = true;
        this.selectedTaskId = clickedEmp.taskId;
        this.selectedTaskName = clickedEmp.taskName;
        this.fullName = clickedEmp.firstName + " " + clickedEmp.lastName;
        if (this.needTask && !clickedEmp.isSupport) {
          this.taskVisible = true;
        }

        return;
      }
      // click on a blank name
      else {
        // add it to selected
        this.selectedTaskId = "";
        this.selectedTaskName = "";
        this.selectedIds.push(this.lastClicked);
        this.fullName = clickedEmp.firstName + " " + clickedEmp.lastName;
        if (this.needTask) {
          if (clickedEmp.isSupport) {
            this.selectedTaskId = clickedEmp.taskId;
            this.selectedTaskName = clickedEmp.taskName;
            clickedEmp.lineId.push(oper.getLineId());
            clickedEmp.lineName =
              clickedEmp.lineName + ", " + oper.getLineName();
          } else this.taskVisible = true;
        }
        this.setSelected();
      }
      if (this.taskList.length === 1) this.clickSave();
    },
    setSelected: function () {
      this.selected = [];
      // set gray, green and unselected employees
      this.employeesList.forEach((emp) => {
        // green
        if (this.selectedIds.includes(emp._id.toString())) {

          if (!emp.lineName) {
            emp.lineId = oper.getLineId();
            emp.lineName = oper.getLineName();
          }
          if (this.needTask && !emp.taskId) {
            for (let i = 0; i < this.actualTeam.length; i++) {
              if (this.actualTeam[i]._id.toString() == emp._id.toString()) {
                if (this.actualTeam[i].taskId) {
                  emp.taskId = this.actualTeam[i].taskId;
                  emp.taskName = this.actualTeam[i].taskName;
                  emp.isSupport = this.actualTeam[i].isSupport;
                }
              }
            }
            if (!emp.taskId)
              emp.taskName = this.$i18n.t("selectTeam.enterTask");
          }
          if (this.taskList.length === 1) {
            this.taskList.forEach((row, i) => {
              if (i === 0) {
                emp.taskName = row.name;
                this.selectedTaskId = row._id;
              }
            });
            this.needTask = false;
          }
          this.selected.push(emp);

          emp._rowVariant = "success";
          console.log("working", this.selectedIds, this.selected)

        }
        // gray
        else if (
          !emp.isSupport &&
          emp.lineId &&
          emp.lineId[0].toString() != oper.getLineId().toString()
        ) {
          this.blocked.push(emp._id);
          emp._rowVariant = "secondary";
        }
        // isSupport
        else if (emp.isSupport) {
          delete emp._rowVariant;
        }
        // unselected
        else {
          delete emp._rowVariant;
          delete emp.taskId;
          delete emp.taskName;
          delete emp.lineId;
          // delete emp.lineName;
        }
      });
      if (this.needTask) {
        // sort by task name
        this.selected.sort(function (a, b) {
          var emp1 = a.taskName.toLowerCase(),
            emp2 = b.taskName.toLowerCase();
          return emp1.localeCompare(emp2);
        });
      }
      // enable/disable the save button
      this.saveTeamEnabled = this.selected.length > 0;
    },
    clickClearList: function () {
      this.taskVisible = false;
      this.selectedTaskId = "";
      this.selectedTaskName = "";
      this.selectedIds = [];
      this.setSelected();
    },
    clickSave: function () {
      this.edit = false;
      if (this.missingTask()) {
        this.$bvModal.msgBoxOk(this.$i18n.t("selectTeam.enterTask"));
      } else if (this.somethingChanged()) {
        this.saveTeam();
      } else {
        this.clickCancel();
      }
    },
    apnaclickSave: function () {
      this.edit = false;
      console.log(this.selected,"selectedbabar");
       this.$emit("close", { cancel: false, selectedTeam: this.selected });
      // if (this.$refs.confirmSelect) {
      //   this.$refs.confirmSelect.clickConfirm();
      // }
     

     
    },
    somethingChanged: function () {
      if (
        this.actualTeamIds.sort().join(",") !==
        this.selectedIds.sort().join(",")
      ) {
        // we don't have the same team
        return true;
      }
      // check if some task change
      for (let i = 0; i < this.actualTeam.length; i++) {
        for (let e = 0; e < this.selected.length; e++) {
          if (
            this.actualTeam[i]._id.toString() == this.selected[e]._id.toString()
          ) {
            if (this.actualTeam[i].taskId != this.selected[e].taskId) {
              return true;
            }
          }
        }
      }
      return false;
    },
    missingTask: function () {
      if (this.needTask) {
        for (let i = 0; i < this.employeesList.length; i++) {
          if (
            this.selectedIds.includes(this.employeesList[i]._id.toString()) &&
            !this.employeesList[i].taskId
          ) {
            this.employeesList[i].taskName = this.$i18n.t(
              "selectTeam.enterTask"
            );
            return true;
          }
        }
      }
      return false;
    },
    saveTeam: function () {
      //console.log("----- somethingDidChange");
      this.$emit("close", { cancel: false, selectedTeam: this.selected });
    },
    clickCancel: function () {
      //console.log("----- clickCancel");
      this.$emit("close", { cancel: true });
    },
  },
  created: function () {
    this.tableChannel = `table::${this.tableId}`;
    this.tableFields = [
      {
        key: "firstName",
        label: this.$i18n.t("selectTeam.firstName"),
      },
      {
        key: "lastName",
        label: this.$i18n.t("selectTeam.lastName"),
      },
      {
        key: "alias",
        label: this.$i18n.t("selectTeam.alias"),
      },
      {
        key: "taskName",
        label: this.$i18n.t("selectTeam.task"),
      },
      {
        key: "lineName",
        label: this.$i18n.t("selectTeam.onLine"),
      },
    ];
  },
  mounted() {

    console.log(this.selectedTeamIds, "selectedTeamIds");
    this.initTeamSelection(this.selectedTeamIds); // Initialize team selection on mount
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.btn-zeroMargin {
  margin: 1px !important;
}

.modal-mask {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}

.modal-container {
  width: 80%;
  height: 90%;
  margin: auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow-y: auto;
  height: 90vh;
}

@media only screen and (max-width: 800px) {
  .modal-container {
    width: 100%;
  }
}

.table-container {
  height: 80%;
}

@media only screen and (max-height: 700px) {
  .table-container {
    height: 75%;
  }
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

input::-ms-clear {
  display: none;
}

::-webkit-search-decoration,
::-webkit-search-cancel-button,
::-webkit-search-results-button,
::-webkit-search-results-decoration {
  display: none;
}

@media only screen and (max-width: 800px) {
  .modal-container {
    width: 100%;
  }
}
</style>
