<template>
  <div class="g-page-container">

    <div class="g-pageHeader" style="height: 63px;">
      <h1 style="margin: auto;">{{$t('configUser.title')}}</h1>
    </div>

    <form class="g-pageBody" @submit.prevent="onSubmit">

      <div class="form-group">
        <label for="inputFName">{{$t('configUser.first')}}</label>
        <input
          type="text"
          required
          v-model="user.firstName"
          class="form-control"
          id="inputFName"
          :placeholder="$t('configUser.firstPHolder')">
      </div>

      <div class="form-group">
        <label for="inputLName">{{$t('configUser.last')}}</label>
        <input
          type="text"
          required
          v-model="user.lastName"
          class="form-control"
          id="inputLName"
          :placeholder="$t('configUser.lastPHolder')">
      </div>

      <div class="form-group">
        <label for="inputAlias">{{$t('configUser.alias')}}</label>
        <input
          type="text"
          required
          v-model="user.alias"
          class="form-control"
          id="inputAlias"
          :placeholder="$t('configUser.aliasPHolder')">
      </div>

      <div class="form-group">
        <input type="checkbox" id="isActive" v-model="user.isActive">
        <label class="checkboxLabel" for="isActive">{{$t('configUser.active')}}</label>
      </div>

      <button class="btn btn-perfcell float-left marginTop50px" @click.prevent="clickRetour">
        {{ $t('configUser.cancel' ) }}
      </button>

      <button class="btn btn-perfcell float-left marginTop50px marginLeft10px" type="submit">
        {{ $t('configUser.submit' ) }}
      </button>

      <p v-if="errors.length" class="float-left marginTop50px marginLeft30px" :style="{ color: msgColor }">
        <b v-if="msgColor == 'red'">{{$tc('configUser.error', errors.length)}}</b>
        <ul>
          <li v-for="error in errors" :key="error">{{ error }}</li>
        </ul>
      </p>

    </form>

  </div>
</template>

<script>
import { axiosInstance } from "@/main";

export default {
  data() {
    return {
      user: {
        _id: '',
        firstName: '',
        lastName: '',
        alias: '',
        isActive: true
      },
      id: '',
      errors: [],
      msgColor: "red"
    }
  },
  methods: {
    onSubmit() {
      if( ! this.validate() )
        return;
      axiosInstance.post('/user/' + this.id, this.user)
      .then((resp) => {
        //console.log("----- onSubmit ok = " + JSON.stringify(resp.data, null, 2));

        if( resp.data == "userNameExist" ) {
          this.errors = [];
          this.msgColor = "red";
          this.errors.push(this.$i18n.t('configUser.userExist'));
        }
        else {
          this.user = resp.data;
          this.id = this.user._id;
          this.errors.push(this.$i18n.t('configUser.success'));
          this.msgColor = "green";
        }
      }).catch((err) => {
        console.log("----- onSubmit error = " + JSON.stringify(err, null, 2));
      });
    },
    validate() {
      this.errors = [];
      this.msgColor = "red";

      this.user.firstName = this.user.firstName.trim();
      this.user.lastName = this.user.lastName.trim();
      if( ! this.user.firstName.length || ! this.user.lastName.length )
        return false;
      this.user.alias = this.user.alias.trim();
      if( ! this.user.alias.length )
        return false;

      return ! this.errors.length;
    },
    clickRetour() {
      this.$router.replace("/configUsersList");
    }
  },
  mounted() {
    if( this.id != "0" ) {
      axiosInstance.get('/user/' + this.id)
      .then(resp => {
        //console.log("----- get /user = " + JSON.stringify(resp.data, null, 2));
        this.user = resp.data;
      })
      .catch(err => {
        console.log("----- get /user error = " + JSON.stringify(err, null, 2));
      });
    }
  },
  created() {
    this.id = this.$route.params.id;
    //console.log("----- this.id = " + JSON.stringify(this.id, null, 2));
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/colors.scss';

form {
  background: White;
  padding: 2rem 2rem 1rem;
  width: 100%;
}

label {
  font-weight: bold;
}

.narrow {
  width: 70px;
}
</style>
