<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask" v-show="showKeyPad">

      <div class="claculator-content centered-block">
        <div class="headerCalculator">
          <input type="text" v-model="quantity" disabled="disabled">
        </div>
        <div class="keypad-container">
          <div class="numberFields">
            <button @click="clickButton(1)" class="medium-square">1</button>
            <button @click="clickButton(2)" class="medium-square">2</button>
            <button @click="clickButton(3)" class="medium-square">3</button>
            <button @click="clickButton(4)" class="medium-square">4</button>
            <button @click="clickButton(5)" class="medium-square">5</button>
            <button @click="clickButton(6)" class="medium-square">6</button>
            <button @click="clickButton(7)" class="medium-square">7</button>
            <button @click="clickButton(8)" class="medium-square">8</button>
            <button @click="clickButton(9)" class="medium-square">9</button>
            <button @click="clickButton('dot')" class="medium-square">.</button>
            <button @click="clickButton(0)" class="medium-square">0</button>
          </div>
          <div class="operations">
            <button @click="clickButton('plus')">
              <b-icon-plus font-scale="2"></b-icon-plus>
            </button>
            <button @click="clickButton('minus')">
              <b-icon-dash font-scale="2"></b-icon-dash>
            </button>
            <button @click="clickButton('clear')">
              <b-icon-arrow-left-short font-scale="2"></b-icon-arrow-left-short>
            </button>
          </div>
          <div class="button-valid">
            <button @click="clickButton('ok')">
              <b-icon-check></b-icon-check>
              {{$t('keyPad.valid')}}
            </button>
            <button @click="clickButton('cancel')" class="pull-right">
              <b-icon-x></b-icon-x>
              {{$t('keyPad.close')}}
            </button>
          </div>
        </div>
      </div>

    </div>
  </transition>
</template>

<script>
import 'bootstrap/dist/js/bootstrap.bundle';
import { BIconPlus, BIconDash, BIconArrowLeftShort, BIconCheck, BIconX } from 'bootstrap-vue'
import oper from '@/stores/Operations.js';

export default {
  name: 'KeyPad',
  template: '#modal-template',
  props: {
    showKeyPad: Boolean,
    doInitKeyPad: Boolean
  },
  data: function() {
    return {
      quantity: '0',
      gotDot: false,
      decimals: 0
    }
  },
  components: {
    BIconPlus,
    BIconDash,
    BIconArrowLeftShort,
    BIconCheck,
    BIconX
  },
  watch: {
    'doInitKeyPad': function(val) {
      if( val ) {
        //console.log("----- init KeyPad = " + val);

        this.quantity = '0';
        this.gotDot = false;
        this.decimals = oper.getDecimals();
      }
    }
  },
  methods: {
    clickButton: function (val) {
      //console.log("----- clickButton = " + val);

      if( val == 'ok' ) {
        if( Number(val) != 0 )
          this.clickSave();
        else
          this.clickCancel();
      }
      else if( val == 'cancel' ) {
        this.clickCancel();
      }
      else if( val == 'clear' ) {
        this.quantity = '0';
        this.gotDot = false;
        this.decimals = oper.getDecimals();
      }
      else if( val == 'plus' ) {
        if( this.quantity.charAt(0) == '-' )
          this.quantity = this.quantity.substr(1);
      }
      else if( this.quantity.length < 8 ) {
        if( typeof val == 'number' ) {
          if( this.gotDot ) {
            if( this.quantity.length - 1 - this.quantity.indexOf(".") < this.decimals )
              this.quantity += Number(val);
          }
          else {
            let value = Number(this.quantity);
            if( this.quantity.charAt(0) == '-' )
              this.quantity = (value * 10 - val).toString();
            else
              this.quantity = (value * 10 + val).toString();
          }
        }
        else if( val == 'dot' ) {
          if( ! this.gotDot && this.decimals > 0 ) {
            this.quantity += ".";
            this.gotDot = true;
          }
        }
        else if( val == 'minus' ) {
          if( this.quantity.charAt(0) != '-' )
            this.quantity = '-' + this.quantity;
        }
      }
    },
    clickSave: function () {
      this.$emit('close', {gotQte: true, qte: this.quantity});
    },
    clickCancel: function () {
      this.$emit('close', {gotQte: false});
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/global.scss';

* {
  box-sizing: border-box;
}
.modal-mask {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  transition: opacity .3s ease;
}
.modal-container {
  width: 200px;
  height: 200px;
  margin: 40px auto 0;
  padding: 0;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.claculator-content {
    display:block;
    width:auto;
    max-width: 300px;
    height:auto;
    border: 3px solid $perfcell-green;
    border-radius: 5px;
    box-sizing:border-box;
    background-color: #cdcdcd;
}

.headerCalculator {
    margin-bottom: 5px;
    width: 100%;
    display:block
}

.headerCalculator input {
    width: 100%;
    height:70px;
    text-align: right;
    font-size: 50px;
    line-height: 1;
    font-weight: bold;
    color: $perfcell-green;
    border: none;
    padding: 10px 20px;
    overflow:hidden;
}

.headerCalculator input[disabled]{
    background-color: #FFF;
}

.calculator-container{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 11;
}

.keypad-container{
    margin: 20px;
}

.numberFields {
    display:block;
    margin: 0;
    width: 77%;
    font-size:0;
    overflow: hidden;
    float: left;
}

.medium-square{
    display:inline-block;
    background-color: #cdcdcd;
    color: #FFF;
    border: none;
    width: 33.33%;
    height: 55px;
    font-size: 30px;
    font-weight: bold;
    line-height: 1;
}

.medium-square.last-btn{
     width: 100%;
}

.operations {
    display: inline-block;
    margin-left: 4px;
    width: 20%;
    font-size: 0;
    color: #FFF;
    float:right;
}

.operations button {
    background-color: $perfcell-green;
    border: none;
    width: 100%;
    height: 65px;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 1;
    color: #FFF;
}

.operations button:last-of-type{
    margin-bottom: 5px;
}

.button-valid {
    display:block;
}

.button-valid button {
    background-color: $perfcell-green;
    border: none;
    width: 49%;
    height:50px;
    margin-top: 5px;
    font-size: 20px;
    color: #FFF;
}

.button-valid span{
    margin-right: 10px;
}

.centered-block {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.medium-square:hover, .medium-square:focus {
  color: $perfcell-green;
}

.medium-square:active, .medium-square:visited{
    border: none;
}

</style>
