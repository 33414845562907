<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask" v-show="showStd">
      <div class="modal-container">
        <div class="table-container">
          <div v-if="!gotList">
            <b-spinner label="Loading..."></b-spinner>
          </div>

          <div v-else-if="inSelectCat">
            <label v-if="categoriesList.length == 0" class="attention">{{
              $t("selectStd.noCat")
            }}</label>
            <label v-else class="attention">{{
              $t("selectStd.selectCat")
            }}</label>
          </div>
          <div v-else>
            <label v-if="standardsList.length == 0" class="attention">{{
              $t("selectStd.noStandard")
            }}</label>
            <label v-else class="attention">{{
              $t("selectStd.sStandard")
            }}</label>
          </div>

          <div v-if="inSelectCat" style="height: 100%;">
                  <b-form-group
              v-if="inSelectCat"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput2"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  type="search"
                  id="filterInput2"
                  ref="filterInput2"
                  style="outline: none; border-radius: 10px; padding: 3px 5px"
                  :placeholder="$t('selectStd.searchPHolder')"
                  @keydown="searchCategory"
                ></b-form-input>
              </b-input-group>
            </b-form-group>

            <Table
              :tableFields="showFieldsCat"
              :rowsData="categoriesList"
              :keys="keys_categories"
              :returnClickOnArray="true"
              :tableid="tableId[0]"
              customHeight="100%"
              gridtemplatecolumns="98%"
              @rowclick="onCatSelected"
            />
            <!-- <b-table
              class="table-big-font"
              hover
              small
              selectable
              select-mode="single"
              sticky-header="215px"
              primary-key="_id"
              selected-variant="success"
              :items="categoriesList"
              :fields="showFieldsCat"
              @row-selected="onCatSelected"
            >
            </b-table> -->
          </div>

          <b-form-group
            v-if="!inSelectCat"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                type="search"
                id="filterInput"
                ref="filterInput"
                style="outline: none; border-radius: 10px; padding: 3px 5px"
                :placeholder="$t('selectStd.searchPHolder')"
                @keydown="searchStandard"
              ></b-form-input>
            </b-input-group>
          </b-form-group>

          <Table
            v-if="!inSelectCat"
            :tableFields="showFields"
            :rowsData="standardsList"
            :keys="keys_standards"
            :tableid="tableId[1]"
            :returnClickOnArray="true"
            customHeight="100%"
            gridtemplatecolumns="32% 32% 32%"
            @rowclick="onStdSelected"
          />
        </div>

        <div>
          <hr />
        </div>
        <div>
          <input
            type="checkbox"
            id="useTakt"
            v-model="useTakt"
            style="margin-top: 15px;"
          />
          <label class="checkboxLabel" for="useTakt">{{
            $t("selectStd.useTakt")
          }}</label>
        </div>
        <div v-if="useTakt">
          <div>
            <label>{{ $t("selectStd.valueNAT") }}</label>
            <input
              v-model="valueNAT"
              type="number"
              class="form-control; display: table-cell; narrow"
            />
          </div>
          <div>
            <label>{{ $t("selectStd.valueCD") }}</label>
            <input
              v-model="valueCD"
              type="number"
              class="form-control; display: table-cell; narrow"
            />
          </div>
        </div>

        <button class="btn btn-perfcell float-right " @click="clickCancel">
          {{ $t("selectStd.cancel") }}
        </button>
        <button
          v-if="!inSelectCat"
          class="btn btn-perfcell float-right  marginRight10px"
          @click="clickChangeCat"
        >
          {{ $t("selectStd.changeCat") }}
        </button>

        <button
          v-if="saveStdEnabled"
          class="btn btn-perfcell float-right  marginRight10px"
          @click="clickSave"
        >
          {{ $t("selectStd.save") }}
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import { axiosInstance } from "@/main";
import oper from "@/stores/Operations.js";
import Table from "@/components/Table/Table.vue";
import EventBus from "@/eventBus";

export default {
  name: "SelectStandard",
  template: "#modal-template",
  components: {
    Table,
  },
  props: {
    showStd: Boolean,
    doInitStd: Boolean,
    inUseTakt: Boolean,
    inValueNAT: Number,
    inValueCD: Number,
    categoriesListParent: Array,
    gotCategoryList: Boolean,
    selectedCategoryId: String, // Add this prop
    selectedStdID: String,

  },
  data: function() {
    return {
      tableId: ["standard_1", "standard_2"],
      tableChannel: ["", ""],
      keys_categories: ["name"],
      keys_standards: ["partNumber", "description", ["category", "name"]],
      categoriesList: this.getCategoryList || [],
      showFieldsCat: [
        {
          key: "name",
          label: this.$i18n.t("selectStd.catName"),
          sortable: true,
        },
      ],
      inSelectCat: false,
         selectedCatIDPrev: this.selectedCategoryId || "",
      selectedStdIDPrev: "",

      lastSelectedStdCatId: "", // Initialize with the prop 
      standardsList: [],
      sortBy: "partNumber",
      sortDesc: false,
      showFields: [
        {
          key: "partNumber",
          label: this.$i18n.t("selectStd.stdPart"),
          sortable: true,
        },
        {
          key: "description",
          label: this.$i18n.t("selectStd.stdDesc"),
          sortable: true,
        },
        {
          key: "category.name",
          label: this.$i18n.t("selectStd.category"),
          sortable: true,
        },
      ],
      filter: null,
      selectedStandard: {},
      selectedStandardId: "",
      lastSelectedStandardId: "",

      stdStorageName: "",
      catStorageName: "",
      saveStdEnabled: false,
      gotList: false,
      inInit: false,
      didSearch: false,
      useTakt: false,
      valueNAT: 0,
      valueCD: 0,
    };
  },
  watch: {
    filter: function() {
      //console.log("----- filter");
      this.didSearch = true;
    },
        selectedCategoryId(catID) {
      this.selectedCatIDPrev = catID;
      if (catID) {
        this.getStandards(); // Fetch standards when category changes
      }
      if (!this.gotList) {
        console.log("CHECK WATCH");

        // this.getCategories(); // Re-fetch categories when category ID changes  band kea hay
      }
    },
    selectedStdId(newStdId) {
      this.selectedStdIDPrev = newStdId;
      // Optionally: Perform any additional actions when a standard is selected
    },

    doInitStd: function(val) {
      if (val) {
        console.log("----- init SelectStandard");

        this.sortBy = "partNumber";
        this.sortDesc = false;

        this.useTakt = this.inUseTakt;
        this.valueNAT = this.inValueNAT;
        this.valueCD = this.inValueCD;

        this.standardsList = [];
        // this.$refs.selectStdTable.refresh();
        this.gotList = this.gotCategoryList || false;
        this.inInit = true;
        this.didSearch = false;

        this.stdStorageName = "lastStd" + oper.getLineId() + oper.getSchedId();
        this.catStorageName = "lastCat" + oper.getLineId() + oper.getSchedId();

        this.lastSelectedStandardId = localStorage.getItem(this.stdStorageName);
        console.log(
          "----- this.lastSelectedStandardId = " + this.lastSelectedStandardId
        );
        this.lastSelectedStdCatId = localStorage.getItem(this.catStorageName)|| this.selectedCategoryId; // Use prop value if available;
        console.log(
          "----- this.lastSelectedStdCatId = " + this.lastSelectedStdCatId
        );

        this.inSelectCat = true;
        localStorage.setItem("re-call-categories", true);
        this.getCategories();
        
      }
    },
    selectedStandardId: function() {
      this.saveStdEnabled = this.selectedStandardId != "";
    },
  },
  created: function() {
    this.tableChannel[0] = `table::${this.tableId[0]}`;
    this.tableChannel[1] = `table::${this.tableId[1]}`;
  },
  methods: {
    getCategories: function() {
      console.log("First Call..");
      axiosInstance
        .get("/standardOperation", {
          params: {
            isActive: true,
            lineId: localStorage.getItem("lastSelectedLineId"),
          },
        })
        .then((resp) => {
          //console.log("----- get /categoryList = " + JSON.stringify(resp.data, null, 2));
          this.categoriesList = [
            {
              _id: "0",
              name: this.$i18n.t("selectStd.allCatStds"),
            },
          ];
          this.categoriesList = this.categoriesList.concat(resp.data);
           this.categoriesList.sort((a, b) => {
        let nameA = a.name.toLowerCase();
        let nameB = b.name.toLowerCase();
        return nameA.localeCompare(nameB);
      });

          console.log(
            "First Call Result",
            JSON.parse(JSON.stringify(this.categoriesList)),
            JSON.parse(JSON.stringify(this.getCategoryList))
          );
          localStorage.setItem("re-call-categories", true);
  // Automatically select the category if it's set
          if (this.selectedCatIDPrev) {
            // setTimeout(() => {
            this.selectCategoryById(this.selectedCatIDPrev);

            // }, 2000);
          } else {
            this.gotList = true;
          }
        
        })
        .catch((err) => {
          console.log(
            "----- get /categoryList error = " + JSON.stringify(err, null, 2)
          );
        });
    },
        selectCategoryById: function (categoryId) {
      const selectedCategory = this.categoriesList.find(
        (cat) => cat._id === categoryId
      );
      if (selectedCategory) {
        this.lastSelectedStdCatId = selectedCategory._id;
        this.selectedStdIDPrev = this.selectedStdID;
        this.inSelectCat = true;
        this.gotList = true;

        console.log(this.selectedStdIDPrev, "selectedStdIDPrev");
        // this.getStandards();
      }
    },
    selectStandardById(stdId) {
      const selectedStandard = this.standardsList.find(
        (std) => std._id === stdId
      );
      console.log(selectedStandard, "stdId");

      // let selectedStdInArr=[...selectedStandard];

      if (selectedStandard) {
        console.log(
          this.saveStdEnabled,
          "saveStdEnabled",
          this.selectedStandard
        );

        this.selectedStandard = selectedStandard;
        this.selectedStandardId = selectedStandard._id;
        this.saveStdEnabled = true;
        this.closed();
      }
    },

    closed() {
      this.$emit("close", {
        cancel: false,
        selectedStandard: this.selectedStandard,
        useTakt: this.useTakt,
        valueNAT: this.valueNAT,
        valueCD: this.valueCD,
      });
      
    EventBus.$emit('autoTeamSelection', 'Hello from Component A');

     EventBus.$emit('startProduction', 'Hello from Component A');

      
    },

    getStandards: function() {
      let query = { isActive: true };
      if (this.lastSelectedStdCatId && this.lastSelectedStdCatId.length > 0) {
        query.category = this.lastSelectedStdCatId;
      }
      query.lineId = localStorage.getItem("lastSelectedLineId");
      axiosInstance
        .get("/standardList2", {
          params: {
            ...query,
          },
        })
        .then((resp) => {
          resp.data.sort(function(a, b) {
            let std1 = a.partNumber.toLowerCase();
            let std2 = b.partNumber.toLowerCase();
            return std1.localeCompare(std2);
          });

          //console.log("----- get /standardList2 = " + JSON.stringify(resp.data, null, 2));
          this.standardsList = resp.data;
          this.gotList = true;

          this.selectedStandard = {};
          this.selectedStandardId = "";

          this.inInit = false;
             if (this.selectedStdIDPrev) {
            this.selectStandardById(this.selectedStdIDPrev);
          }

        })
        .catch((err) => {
          console.log(
            "----- get /standardList2 error = " + JSON.stringify(err, null, 2)
          );
        });
    },
    onCatSelected(item) {
      //console.log("----- item = " + JSON.stringify(item, null, 2));
      if (item.length > 0) {
        if (item[0]._id != "0") this.lastSelectedStdCatId = item[0]._id;
        else this.lastSelectedStdCatId = "";
        this.inSelectCat = false;
        this.gotList = false;
        this.getStandards();
        this.$refs.filterInput.value = "";
      }
    },
    onStdSelected(item) {
      //console.log("----- item = " + JSON.stringify(item, null, 2));
      if (item.length > 0) {
        this.selectedStandard = item[0];
        this.selectedStandardId = item[0]._id;
        this.saveStdEnabled = true;
        if (!this.inInit) this.clickSave();
      } else {
        if (!this.inInit && !this.didSearch)
          // if we arrive here it mean that the user clicked the last selected schedule at first
          this.clickSave();
      }
    },
    clickSave: function() {
      //console.log("----- clickSave, lastSelectedStandardId = " + this.selectedStandardId);
      localStorage.setItem(this.stdStorageName, this.selectedStandardId);
      localStorage.setItem(
        this.catStorageName,
        this.selectedStandard.category._id
      );

      this.$emit("close", {
        cancel: false,
        selectedStandard: this.selectedStandard,
        useTakt: this.useTakt,
        valueNAT: this.valueNAT,
        valueCD: this.valueCD,
      });
    },
    clickChangeCat: function() {
      //console.log("----- clickCancel");
      this.inInit = true;
      this.inSelectCat = true;
      this.standardsList = [];
      this.gotList = false;
      this.getCategories();
    },
    clickCancel: function() {
      //console.log("----- clickCancel");
      this.$emit("close", { cancel: true });
      this.inSelectCat = false;
      this.categoriesList = [];
      this.gotList = false;
    },
    searchStandard: function(input) {
      EventBus.$emit(`${this.tableChannel[1]}::search`, input);
    },
     searchCategory: function (input) {
      EventBus.$emit(`${this.tableChannel[0]}::search`, input);
    },
  

  },
  computed: {
    getCategoryList() {
      return this.categoriesListParent || [];
    },
    getGotList() {
      return this.gotCategoryList;
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.modal-mask {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}
.modal-container {
  width: 66%;
  height: 90%;
  margin: auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  height: 90vh;
  position: relative;
}

.table-container {
  height: 80%;
}

@media only screen and (max-width: 800px) {
  .modal-container {
    width: 100%;
  }
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

input::-ms-clear {
  display: none;
}

::-webkit-search-decoration,
::-webkit-search-cancel-button,
::-webkit-search-results-button,
::-webkit-search-results-decoration {
  display: none;
}
</style>
