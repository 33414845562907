<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask" v-show="showReport">
      <div class="modal-container">
        <div>
          <b-table
            hover
            small
            sticky-header="200px"
            :items="reportData"
            :fields="showFields"
          >
          </b-table>
        </div>

        <button
          class="btn btn-perfcell float-right marginTop50px marginRight10px"
          @click="clickSave"
        >
          {{ $t("usersReport.fermer") }}
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "UsersReport",
  template: "#modal-template",
  props: {
    showReport: Boolean,
    doInitReport: Boolean,
    reportData: Array,
  },
  data: function() {
    return {
      showFields: [
        {
          key: "entry",
          label: this.$i18n.t("usersReport.entry"),
        },
        {
          key: "status",
          label: this.$i18n.t("usersReport.status"),
        },
      ],
    };
  },
  watch: {
    doInitReport: function(val) {
      if (val) {
        console.log("----- init UsersReport");
      }
    },
  },
  methods: {
    clickSave: function() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.modal-mask {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}
.modal-container {
  width: 66%;
  height: 400px;
  margin: 40px auto 0;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
@media only screen and (max-width: 800px) {
  .modal-container {
    width: 100%;
  }
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
