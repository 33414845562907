<template>
  <div class="g-page-container" ref="pageContainer" id="myDiv">
    <div class="g-pageHeader" style="height: 63px">
      <h1 style="margin: auto">{{ $t("configUsersList.title") }}</h1>
    </div>

    <div style="padding-top: 10px">
      <div class="form-inline">
        <div class="search-text">
          <label for="searchText">{{ $t("configUsersList.search") }}</label>
          <input id="searchText" v-model="config.searchField" type="text" @keyup.enter="clickSearch()" class="form-control" />
          <button @click="clickSearch()" class="btn btn-perfcell">
            <span class="glyphicon glyphicon-search"></span>
            {{ $t("search") }}
          </button>
        </div>
        <div class="form-group checkbox search-active">
          <label for="searchActive">
            <input id="searchActive" type="checkbox" v-model="config.withInactive" @change="clickInactive()" />
            {{ $t("inactive") }}
          </label>
        </div>
        <div class="form-group" style="margin-left: 30px">
          <ReturnButton to="/config" />
        </div>
      </div>
    </div>

    <div class="g-pageBody" style="padding: 10px">
      <table>
        <thead>
          <tr>
            <th class="t-first cursorPointer" :class="getArrow('firstName')" @click="clickSort('firstName')">
              {{ $t("configUsersList.firstName") }}
            </th>
            <th class="t-last cursorPointer" :class="getArrow('lastName')" @click="clickSort('lastName')">
              {{ $t("configUsersList.lastName") }}
            </th>
            <th class="t-alias cursorPointer" :class="getArrow('alias')" @click="clickSort('alias')">
              {{ $t("configUsersList.alias") }}
            </th>
            <th class="t-edit textAlignCenter">{{ $t("configUsersList.edit") }}</th>
            <th class="t-active-inactive textAlignCenter">{{ $t("configLinesList.activeInactive") }}</th>
            <th class="t-remove textAlignCenter">{{ $t("configUsersList.remove") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in screenList" :key="e._id">
            <td :class="e.isActive ? '' : 'redText'">{{ e.firstName }}</td>
            <td :class="e.isActive ? '' : 'redText'">{{ e.lastName }}</td>
            <td :class="e.isActive ? '' : 'redText'">{{ e.alias }}</td>
            <td class="textAlignCenter cursorPointer" @click="clickEdit(e._id)"><i class="fa fa-pencil"></i></td>
            <td class="textAlignCenter cursorPointer">
              <input id="e._id" type="checkbox" @change="clickActiveInactive(e._id, e.isActive)" v-model="e.isActive" />
            </td>
            <td class="textAlignCenter cursorPointer" @click="clickRemove(e)"><i class="fa fa-remove"></i></td>
          </tr>
          <tr v-for="index in emptyRows" :key="index">
            <td>.</td>
            <td>&#8232;</td>
            <td>&#8232;</td>
            <td>&#8232;</td>
            <td>&#8232;</td>
            <td>&#8232;</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div style="margin-top: 10px">
      <b-pagination
        v-if="totalRows > pageSize"
        size="sm"
        ref="pagination"
        :total-rows="totalRows"
        :per-page="pageSize"
        v-model="config.currentPage"
        :prev-text="$t('configUsersList.previous')"
        :next-text="$t('configUsersList.next')"
      />
    </div>

    <button class="btn btn-perfcell float-left marginTop20px" @click="clickRetour">{{ $t("configUsersList.cancel") }}</button>
    <button class="btn btn-perfcell float-left marginLeft10px marginTop20px" @click="clickAddNew">{{ $t("configUsersList.addNew") }}</button>

    <button class="btn btn-perfcell float-left marginLeft10px marginTop20px" @click="clickImportUsers">{{ $t("configUsersList.importUsers") }}</button>
    <input type="file" ref="importUsers" accept=".csv" style="display: none" @change="importUsersGotFile" />
    <usersReport :doInitReport="usersReportDoInit" :showReport="showModalReport" :reportData="report" @close="closeModalReport"></usersReport>
  </div>
</template>

<script>
import { axiosInstance } from "@/main";
import EventBus from "@/eventBus";
import usersReport from "@/components/UsersReport.vue";
import admin from "@/stores/AdminState.js";
import ReturnButton from "../components/Return-button/Return-button.vue";

export default {
  data() {
    return {
      // the list received from the server
      completeList: [],
      // the list stripped by search and inactive and sorted by column headers
      strippedList: [],
      // the current elements on screen
      screenList: [],

      // computed items
      totalRows: 0,
      pageSize: 7,
      emptyRows: 0,

      // config is saved when looking at element detail then restored when coming back here
      config: {
        // when these fields are modified, the strippedList is rebuilt
        searchField: "",
        withInactive: false,

        // value modified when clicking pages navigation
        currentPage: 1,

        // values modifieds when clicking table headers
        currentSort: "firstName",
        currentSortDir: "asc",
      },

      showModalReport: false,
      usersReportDoInit: false,
      report: [],
    };
  },
  components: {
    usersReport,
    ReturnButton,
  },
  watch: {
    "config.currentPage": function () {
      //console.log("----- this.config.currentPage = " + this.config.currentPage);
      this.setScreen();
    },
  },
  methods: {
    clickActiveInactive: function (id, check) {
      if (id) {
        //comment for time being
        //         this.$bvModal.msgBoxConfirm(this.$i18n.t('configUsersList.confirmStatus'), {
        //   okTitle: this.$i18n.t('yes'),
        //   cancelTitle: this.$i18n.t('no'),
        // })
        // .then(value => {
        //   if( value ) {
        axiosInstance
          .post("/user/" + id, { isActive: check })
          .then((resp) => {
            if (resp.data == "userNameExist") {
              this.errors = [];
              this.msgColor = "red";
              this.errors.push(this.$i18n.t("configUser.userExist"));
            } else {
              this.getList();
              this.errors.push(this.$i18n.t("configUser.success"));
              this.msgColor = "green";
            }
          })
          .catch(() => {
            console.log("----- onSubmit error ");
          });
        //   }  else{
        //   this.getList();
        // }

        // })
      }
    },
    clickSort: function (s) {
      if (s === this.config.currentSort) {
        this.config.currentSortDir = this.config.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.config.currentSort = s;
      this.sortList();
    },
    clickInactive: function () {
      //console.log("----- " + this.config.withInactive);
      this.stripList();
    },
    clickSearch: function () {
      //console.log("----- search = " + this.config.searchField);
      this.config.currentPage = 1;
      this.stripList();
    },
    clickEdit(id) {
      //console.log("----- clickEdit = " + id);
      localStorage.setItem("configListSettings", JSON.stringify(this.config));
      this.$router.replace({ path: "/configUser/" + id });
    },
    clickRemove(element) {
      //console.log("----- clickRemove = " + element._id);
      this.$bvModal
        .msgBoxConfirm(element.firstName, {
          title: this.$i18n.t("configUsersList.confirm"),
          okTitle: this.$i18n.t("ok"),
          cancelTitle: this.$i18n.t("cancel"),
        })
        .then((value) => {
          //console.log("----- value = " + value);
          if (value) {
            //console.log("----- element._id = " + element._id);
            axiosInstance
              .delete("/user/" + element._id)
              .then((result) => {
                //console.log("----- onDelete ok ");
                if (result.data.error) {
                  this.$bvModal.msgBoxOk(this.$i18n.t(result.data.error));
                } else {
                  this.getList();
                }
              })
              .catch(() => {
                console.log("----- onDelete error ");
              });
          }
        });
    },
    clickRetour() {
      if (admin.isLoggedIn()) this.$router.replace("/config");
      else this.$router.replace("/operation");
    },
    clickAddNew: function () {
      //console.log("----- clickAddNew");
      localStorage.setItem("configListSettings", JSON.stringify(this.config));
      this.$router.replace({ path: "/configUser/0" });
    },
    clickImportUsers: function () {
      //console.log("----- clickImportUsers");
      this.$refs.importUsers.click();
    },
    importUsersGotFile(event) {
      let file = event.target.files;
      if (!file.length) return;
      console.log("----- file name = " + file[0].name);

      let self = this;
      let fr = new FileReader();
      fr.onload = function () {
        //console.log("----- text = " + fr.result);
        let usersList = fr.result.split(/[\r\n]+/);
        //console.log("----- usersList = " + JSON.stringify(usersList, null, 2));

        self.report = [];
        let count = 0;

        for (let i = 0; i < usersList.length; i++) {
          if (usersList[i].startsWith(",,,")) {
            continue;
          }
          if (usersList[i] == "") {
            continue;
          }
          usersList[i] = usersList[i].replace(/\"/g, "");
          let emp = usersList[i].split(",");
          if (emp.length < 4) {
            self.report.push({ entry: usersList[i], status: self.$i18n.t("configUsersList.number") });
            continue;
          }
          emp[0] = emp[0].trim();
          emp[1] = emp[1].trim();
          emp[2] = emp[2].trim();
          emp[3] = emp[3].trim();
          if (!emp[0].length || !emp[1].length || !emp[2].length) {
            self.report.push({ entry: usersList[i], status: self.$i18n.t("configUsersList.empty") });
            continue;
          }
          //console.log("----- emp = " + JSON.stringify(emp));
          let user = {
            _id: "999",
            firstName: emp[0],
            lastName: emp[1],
            alias: emp[2],
            isActive: true,
          };
          emp[3] = emp[3].toLowerCase();
          if (emp[3] == "false" || emp[3] == "non" || emp[3] == "not" || emp[3] == "inactive" || emp[3] == "inactif") {
            user.isActive = false;
          }
          count++;
          axiosInstance
            .post("/user/999", user)
            .then((resp) => {
              //console.log("----- onSubmit ok = " + JSON.stringify(resp.data, null, 2));
              self.report.push({ entry: usersList[i], status: self.$i18n.t("configUsersList." + resp.data) });
              if (--count == 0) self.endImportUsers();
            })
            .catch((err) => {
              console.log("----- onSubmit error = " + JSON.stringify(err, null, 2));
              self.report.push({ entry: usersList[i], status: self.$i18n.t("configUsersList.server") });
              if (--count == 0) self.endImportUsers();
            });
        }
      };
      fr.readAsText(file[0]);
    },
    endImportUsers() {
      this.getList();
      this.showModalReport = true;
      this.usersReportDoInit = true;
    },
    closeModalReport: function () {
      //console.log("----- closeModalReport");
      this.usersReportDoInit = false;
      this.showModalReport = false;
    },
    getArrow: function (s) {
      if (s === this.config.currentSort) {
        return this.config.currentSortDir === "asc" ? "headerSortDown" : "headerSortUp";
      } else return "none";
    },
    stripList: function () {
      this.strippedList = [];
      let search = this.config.searchField.toLowerCase();
      for (let i = 0; i < this.completeList.length; i++) {
        let good = true;
        if (
          search != "" &&
          !this.completeList[i].firstName.toLowerCase().includes(search) &&
          !this.completeList[i].lastName.toLowerCase().includes(search) &&
          !this.completeList[i].alias.toLowerCase().includes(search)
        )
          good = false;
        if (!this.config.withInactive && !this.completeList[i].isActive) good = false;
        if (good) this.strippedList.push(this.completeList[i]);
      }
      this.sortList();
    },
    sortList: function () {
      this.strippedList.sort((a, b) => {
        let modifier = 1;
        if (this.config.currentSortDir === "desc") modifier = -1;

        if (this.config.currentSort == "alias" && !isNaN(a[this.config.currentSort]) && !isNaN(b[this.config.currentSort])) {
          if (+a[this.config.currentSort] < +b[this.config.currentSort]) return -1 * modifier;
          if (+a[this.config.currentSort] > +b[this.config.currentSort]) return 1 * modifier;
        }
        if (this.config.currentSort == "alias" && !isNaN(a[this.config.currentSort])) {
          return -1 * modifier;
        }
        if (this.config.currentSort == "alias" && !isNaN(b[this.config.currentSort])) {
          return 1 * modifier;
        } else {
          if (a[this.config.currentSort] < b[this.config.currentSort]) return -1 * modifier;
          if (a[this.config.currentSort] > b[this.config.currentSort]) return 1 * modifier;
        }
        return 0;
      });
      this.totalRows = this.strippedList.length;
      //console.log("----- strippedList.length = " + this.strippedList.length);
      this.setScreen();
    },
    setScreen: function () {
      this.screenList = this.strippedList.slice((this.config.currentPage - 1) * this.pageSize, this.config.currentPage * this.pageSize);
      this.emptyRows = this.pageSize - this.screenList.length;
    },
    computeNbRows: function (pageHeight) {
      if (pageHeight > 450) {
        let nbPages = 6 + Math.trunc((pageHeight - 470) / 45);
        if (this.pageSize != nbPages) {
          this.pageSize = nbPages;
          //console.log("----- this.pageSize = " + nbPages);
          this.setScreen();
        }
      }
    },
    getList: function () {
      //console.log("----- getList");
      axiosInstance
        .get("/userList")
        .then((resp) => {
          //console.log("----- get /userList = " + JSON.stringify(resp.data, null, 2));
          this.completeList = resp.data;

          this.stripList();
        })
        .catch((err) => {
          console.log("----- get /userList error = " + JSON.stringify(err, null, 2));
        });
    },
    redimUserList: function (dim) {
      this.computeNbRows(dim.height);
    },
  },
  mounted() {
    if (localStorage.configListSettings) this.config = JSON.parse(localStorage.getItem("configListSettings"));

    this.getList();

    EventBus.$on("mainViewDim", this.redimUserList);
    this.computeNbRows(this.$refs.pageContainer.clientHeight);
  },
  beforeDestroy() {
    EventBus.$off("mainViewDim", this.redimUserList);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/global.scss";
@import "@/assets/styles/table.scss";

.t-first {
  width: 30%;
}
.t-last {
  width: 30%;
}
.t-alias {
  width: 20%;
}
.t-edit {
  width: 10%;
}
.t-active-inactive {
  width: 15%;
}
.t-remove {
  width: 10%;
}
</style>
