<template>
  <div class="sidebar">
    <div class="sidebar-backdrop" @click="closeSidebarPanel" v-if="isPanelOpen"></div>
    <transition name="slide">
      <div v-if="isPanelOpen"
         class="sidebar-panel">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
  import { store, mutations } from './store.js'

  export default {
    methods: {
      closeSidebarPanel: mutations.toggleNav
    },
    computed: {
      isPanelOpen() {
        return store.isNavOpen
      }
    }
  }
</script>

<style lang="scss">
@import '@/assets/styles/global.scss';

.slide-enter-active,
.slide-leave-active
{
  transition: transform 0.2s ease;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
  transition: all 150ms ease-in 0s
}
.sidebar-backdrop {
  background-color: rgba(0,0,0,.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 20;
}
.sidebar-panel {
  overflow-y: auto;
  background-color: $perfcell-green;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 21;
  padding: 3rem 10px 2rem 5px;
  width: 300px;
}
</style>
