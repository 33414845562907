<template>
  <div class="controlContainer">
    <table>
      <tr>
        <td class="customImage" v-if="customBonuses.length > 0">
          <img @click="addCustomBonus(0)" :src="customBonuses[0].url" />
        </td>
        <td class="customImage" v-if="customBonuses.length > 1">
          <img @click="addCustomBonus(1)" :src="customBonuses[1].url" />
        </td>
        <td class="customImage" v-if="customBonuses.length > 2">
          <img @click="addCustomBonus(2)" :src="customBonuses[2].url" />
        </td>
      </tr>
      <tr>
        <td class="invisible">X</td>
      </tr>
      <tr>
        <td class="customImage" v-if="customBonuses.length > 3">
          <img @click="addCustomBonus(3)" :src="customBonuses[3].url" />
        </td>
        <td class="customImage" v-if="customBonuses.length > 4">
          <img @click="addCustomBonus(4)" :src="customBonuses[4].url" />
        </td>
        <td class="customImage" v-if="customBonuses.length > 5">
          <img @click="addCustomBonus(5)" :src="customBonuses[5].url" />
        </td>
      </tr>
    </table>

    <table class="bottom">
      <tr>
        <td>
          <img v-if="inDowntime" id="comment4" @click="clickDownTime" src="../assets/down_actif.png" />
          <img v-else id="comment4" @click="clickDownTime" src="../assets/down.png" />
        </td>
        <td>
          <img v-if="inChangeover" id="comment5" @click="clickChangeOver" src="../assets/changeover_actif.png" />
          <img v-else id="comment5" @click="clickChangeOver" src="../assets/changeover.png" />
        </td>
      </tr>
      <tr>
        <td class="invisible">X</td>
      </tr>
      <tr>
        <td class="clean-score-container" @click="handleCleanScoreAssignClick">
          <img src="/clean-icon.svg" alt="Clean score point image" class="clean-score-logo" />
        </td>
        <td>
          <img v-if="scannerOn" @click="clickScanner" src="../assets/scan_on.png" />
          <img v-else @click="clickScanner" src="../assets/scan_off.png" />
        </td>
      </tr>
      <tr>
        <td class="invisible">X</td>
      </tr>
      <tr>
        <td>
          <img @click="clickEndProd" src="../assets/power.png" />
        </td>
        <td>
          <img @click="clickHelp" src="../assets/help.png" />
        </td>
      </tr>
    </table>

    <b-popover target="comment4" triggers="hover" placement="top" variant="primary">
      <template #title
        ><div class="textAlignCenter">
          {{ $t("tabControl.title4") }}
        </div></template
      >
      <div class="textAlignCenter">
        {{ $t("tabControl.comment43") }}<br />
        {{ $t("tabControl.comment40") }}<br />
        {{ $t("tabControl.comment41") }}<br />
        {{ $t("tabControl.comment42") }}
      </div>
    </b-popover>
    <b-popover target="comment5" triggers="hover" placement="top" variant="primary">
      <template #title
        ><div class="textAlignCenter">
          {{ $t("tabControl.title5") }}
        </div></template
      >
      <div class="textAlignCenter">
        {{ $t("tabControl.comment50") }}<br />
        {{ $t("tabControl.comment51") }}<br />
        {{ $t("tabControl.comment52") }}
      </div>
    </b-popover>

    <selectDown :doInitDown="selectDownDoInit" :showDown="showModalDown" @close="closeModalDown"></selectDown>

    <selectChangeover :doInitChangeover="selectChangeoverDoInit" :showChangeover="showModalChangeover" @close="closeModalChangeover"></selectChangeover>

    <endProd :doInitEndProd="endProdDoInit" :showEndProd="showModalEndProd" @close="closeModalEndProd"></endProd>

    <selectSms :doInitSms="selectSmsDoInit" :showSms="showModalSms" @close="closeModalSelectSms"></selectSms>

    <selectHelp :doInitHelp="selectHelpDoInit" :showHelp="showModalHelp" @close="closeModalSelectHelp"></selectHelp>
  </div>
</template>

<script>
import oper from "@/stores/Operations.js";
import { axiosInstance } from "@/main";
import selectDown from "@/components/SelectDown.vue";
import selectChangeover from "@/components/SelectChangeover.vue";
import selectSms from "@/components/SelectSms.vue";
import endProd from "@/components/EndProduction.vue";
import globals from "@/globals.js";
import EventBus from "@/eventBus";
import { baseURL } from "../main";
import selectHelp from "@/components/SelectHelp.vue";

export default {
  data: function () {
    return {
      endProdDoInit: false,
      showModalEndProd: false,
      selectDownDoInit: false,
      showModalDown: false,
      selectChangeoverDoInit: false,
      showModalChangeover: false,
      selectSmsDoInit: false,
      showModalSms: false,
      selectHelpDoInit: false,
      showModalHelp: false,
      tabMainOk: false,
      actState: -1,
      customBonuses: [],
      cOverBegin: false,
      atBeginning: false,
      scannerOn: false,
      inDowntime: false,
      inChangeover: false,
    };
  },
  components: {
    selectDown,
    selectChangeover,
    selectSms,
    selectHelp,
    endProd,
  },
  methods: {
    clickButton: function () {},
    addCustomBonus: function (index) {
      //console.log("----- addCustomBonus index = " + index);
      if (this.tabMainOk) {
        let data = {};
        data.actId = oper.getActivityId();
        data.custom = this.customBonuses[index];

        let message = this.customBonuses[index].minutes.toString() + this.$i18n.t("tabControl.minutes");
        this.$bvModal
          .msgBoxConfirm(message, {
            title: data.custom.description,
            okTitle: this.$i18n.t("ok"),
            cancelTitle: this.$i18n.t("cancel"),
            hideHeaderClose: false,
          })
          .then((value) => {
            if (value == true || value == null) {
              axiosInstance.post("/addCustomBonus", data);
            }
          });
      }
    },
    askDown: function () {
      if (this.tabMainOk && !this.inDowntime) {
        this.$bvModal
          .msgBoxConfirm(this.$i18n.t("selectHelp.total"), {
            okTitle: this.$i18n.t("yes"),
            cancelTitle: this.$i18n.t("no"),
          })
          .then((value) => {
            if (value) {
              this.clickDownTime();
            }
          });
      }
    },
    clickScanner: function () {
      //console.log("----- clickScanner");
      if (this.tabMainOk) {
        if (this.extScanner) {
          this.$bvModal.msgBoxOk(this.$i18n.t("configLine.extScanner"));
        } else {
          this.scannerOn = !this.scannerOn;
          oper.scanner(this.scannerOn);
          this.$emit("endProduction");
        }
      }
    },
    clickChangeOver: function () {
      //console.log("----- clickChangeOver");

      if (this.tabMainOk) {
        if (this.actState != globals.STATECHANGEOVER && this.actState != globals.STATEDOWN) {
          this.showModalChangeover = true;
          this.selectChangeoverDoInit = true;
        } else if (this.actState == globals.STATECHANGEOVER) {
          let actId = oper.getActivityId();
          axiosInstance.post("/endchangeover", { actId: actId });
        }
      }
    },
    closeModalChangeover: function (data) {
      //console.log("----- closeModalChangeover = " + JSON.stringify(data, null, 2));
      this.selectChangeoverDoInit = false;
      this.showModalChangeover = false;

      if (!data.cancel) {
        let actId = oper.getActivityId();
        axiosInstance.post("/changeover", {
          actId: actId,
          changeover: data.selectedChangeover,
          atBeginning: this.atBeginning,
        });
        this.atBeginning = false;
      }
    },
    clickDownTime: function () {
      //console.log("----- clickDownTime");
      if (this.tabMainOk) {
        if (this.actState != globals.STATEDOWN && this.actState != globals.STATECHANGEOVER) {
          this.showModalDown = true;
          this.selectDownDoInit = true;
        } else if (this.actState == globals.STATEDOWN) {
          let actId = oper.getActivityId();
          axiosInstance.post("/enddown", { actId: actId });
        }
      }
    },
    closeModalDown: function (data) {
      //console.log("----- closeModalDown = " + JSON.stringify(data, null, 2));
      this.selectDownDoInit = false;
      this.showModalDown = false;

      if (!data.cancel) {
        let actId = oper.getActivityId();
        axiosInstance.post("/downtime", {
          actId: actId,
          down: data.selectedDown,
        });

        if (data.selectedDown.autoMsg) this.clickSendSms();
      }
    },
    clickSendSms: function () {
      this.showModalSms = true;
      this.selectSmsDoInit = true;
    },
    closeModalSelectSms: function (data) {
      //console.log("----- closeModalSelectSms = " + JSON.stringify(data, null, 2));
      this.selectSmsDoInit = false;
      this.showModalSms = false;

      if (!data.cancel) {
        delete data.cancel;
        axiosInstance.post("/sendsms", data);
        this.$bvModal.msgBoxOk("ok").then(() => {
          this.askDown();
        });
      } else this.askDown();
    },
    clickHelp: function () {
      this.showModalHelp = true;
      this.selectHelpDoInit = true;
    },
    closeModalSelectHelp: function (data) {
      //console.log("----- closeModalSelectHelp = " + JSON.stringify(data, null, 2));
      this.selectHelpDoInit = false;
      this.showModalHelp = false;

      if (!data.cancel) {
        console.log("----- data.helpType = " + data.helpType);

        this.$bvModal
          .msgBoxConfirm(this.$i18n.t("selectHelp.message"), {
            okTitle: this.$i18n.t("yes"),
            cancelTitle: this.$i18n.t("no"),
          })
          .then((value) => {
            if (value) {
              this.clickSendSms();
            } else {
              this.askDown();
            }
          });
      }
    },
    clickEndProd: function () {
      if (this.tabMainOk) {
        this.showModalEndProd = true;
        this.endProdDoInit = true;
      }
    },
    closeModalEndProd: function (data) {
      //console.log("----- closeModalEndProd = " + JSON.stringify(data, null, 2));
      this.endProdDoInit = false;
      this.showModalEndProd = false;

      if (data.approved) {
        let act = oper.getCurrentActivity();
        axiosInstance.post("/endprod", { actId: act._id });
      }
    },
    controlUpdateFactorySetup: function (data) {
      if (data) {
        this.customBonuses = data.customBonuses;
        this.customBonuses.forEach((custom) => {
          custom.url = baseURL + "/images/customIcons/" + custom.myIcon;
        });
      }
      //console.log("----- this.customBonuses = " + JSON.stringify(this.customBonuses, null, 2));
    },
    prodJustStart: function () {
      if (this.cOverBegin) {
        // we just stated production
        this.$bvModal
          .msgBoxConfirm(this.$i18n.t("tabControl.prepOrProd1") + oper.getLineName() + this.$i18n.t("tabControl.prepOrProd2"), {
            okTitle: this.$i18n.t("tabControl.produce"),
            cancelTitle: this.$i18n.t("tabControl.preparing"),
          })
          .then((value) => {
            if (!value) {
              // the user select preparing
              this.atBeginning = true;
              this.clickChangeOver();
            }
          });
      }
    },
    controlUpdateActivity: function (data) {
      //console.log("----- controlUpdateActivity = " + JSON.stringify(data, null, 2));
      this.tabMainOk = !!data._id;
      if (data.line) {
        this.extScanner = "extScanner" in data.line ? data.line.extScanner : false;
        this.cOverBegin = "cOverBegin" in data.line ? data.line.cOverBegin : false;
      }
      if (this.tabMainOk && this.extScanner && !oper.getScanner()) {
        this.scannerOn = true;
        oper.scanner(this.scannerOn);
      }
    },
    controlUpdateState: function (data) {
      //console.log("----- controlUpdateState = " + JSON.stringify(data, null, 2));
      this.actState = data.state;
      this.inDowntime = !!(this.actState == globals.STATEDOWN);
      this.inChangeover = !!(this.actState == globals.STATECHANGEOVER);
    },
    controlUpdateScanner: function (state) {
      //console.log("----- controlUpdateScanner = " + state);
      this.scannerOn = state;
    },
    handleCleanScoreAssignClick() {
      if (oper.getCurrentActivity()._id) this.$emit("AssignCleanScore");
    },
  },
  mounted() {
    this.controlUpdateActivity(oper.getCurrentActivity());
    EventBus.$on("updateActivity", this.controlUpdateActivity);

    this.controlUpdateFactorySetup(oper.getFactorySetup());
    EventBus.$on("factorySetup", this.controlUpdateFactorySetup);

    EventBus.$on("state", this.controlUpdateState);

    this.scannerOn = oper.getScanner();
    EventBus.$on("scanner", this.controlUpdateScanner);

    EventBus.$on("prodJustStart", this.prodJustStart);
  },
  beforeDestroy() {
    EventBus.$off("updateActivity", this.controlUpdateActivity);
    EventBus.$off("factorySetup", this.controlUpdateFactorySetup);
    EventBus.$off("state", this.controlUpdateState);
    EventBus.$off("scanner", this.controlUpdateScanner);
    EventBus.$off("prodJustStart", this.prodJustStart);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/global.scss";

.controlContainer {
  height: 100%;
  -moz-box-sizing: border-box;
  position: relative;
}

table {
  table-layout: fixed;
  width: 100%;
  text-align: center;
}

.invisible {
  line-height: 3px;
  color: $light-grey;
}

.bottom {
  position: absolute;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.customImage img {
  max-height: 64px;
  max-width: 64px;
  min-height: 64px;
  min-width: 64px;

  background-color: $perfcell-green;
  padding: 5px; // Minimum border width

  // Keep aspect ratio
  height: auto;
  width: 100%;
  object-fit: contain;
}

.clean-score-container {
  width: 87px;
  border-radius: 5px;
  max-height: 64px;
  box-sizing: border-box;
  padding: 0 5px;
  background: #4e8085;
}

.clean-score-logo {
  max-width: 65px;
  height: 50px;
}
</style>
