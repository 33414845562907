let STATENOT = -1; // the activity is over
let STATEPROD = 0; // the activity is running
let STATEBREAK = 1; // we are in a break time
let STATEBREAK1 = 1; // we are in break1 time
let STATEBREAK2 = 2; // we are in break2 time
let STATELUNCH = 3; // we are in lunch time
let STATEDOWN = 4; // we are in down time
let STATECHANGEOVER = 5; // we are in changeover time

let SCHEDSTARTDAY = 0; // use the starting day of the schedule in reports
let SCHEDENDDAY = 1; // use the ending day of the schedule in reports
let SCHEDSPLIT = 2; // split the schedule in the two days

let CATNORMAL = 0;
let CATREJECT1 = 1;
let CATREJECT2 = 2;
let CATOTHER = 3;
let CATNB = 4;

export default {
  STATENOT,
  STATEPROD,
  STATEBREAK,
  STATEBREAK1,
  STATEBREAK2,
  STATELUNCH,
  STATEDOWN,
  STATECHANGEOVER,
  SCHEDSTARTDAY,
  SCHEDENDDAY,
  SCHEDSPLIT,
  CATNORMAL,
  CATREJECT1,
  CATREJECT2,
  CATOTHER,
  CATNB,
};
