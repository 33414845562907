<template>
  <div class="return-button-container">
    <ReturnButton :to="to" />
  </div>
</template>

<script>
import ReturnButton from "./Return-button.vue";

export default {
  components: {
    ReturnButton,
  },
  props: {
    to: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.return-button-container {
  margin: 1rem 0;
  padding: 0 1rem;
  display: flex;
  justify-content: end;
}
</style>
