<template>
  <div class="g-page-container">

    <div class="g-pageHeader" style="height: 63px;">
      <h1 style="margin: auto;">{{$t('configCategory.title')}}</h1>
    </div>

    <form class="g-pageBody" @submit.prevent="onSubmit">

      <div class="form-group">
        <label for="inputName">{{$t('configCategory.name')}}</label>
        <input
          type="text"
          required
          v-model="category.name"
          class="form-control"
          id="inputName"
          :placeholder="$t('configCategory.namePHolder')">
      </div>

      <div class="form-group">
        <label class="radio-inline"><b-form-radio v-model="category.prodType" name="prodType" value="0" :disabled="notEnabled">{{$t('configCategory.normal')}}</b-form-radio></label>
      </div>
      <div class="form-group">
        <label class="radio-inline"><b-form-radio v-model="category.prodType" name="prodType" value="1" :disabled="notEnabled">{{textReject}}</b-form-radio></label>
      </div>

      <div v-if="category.prodType == 1 && isON1" class="form-group">
        <label for="relatedDefects">{{$t('configCategory.relatedDefects')}}</label>
        <div id="relatedDefects">
          <MultipleSelect
            :data="defectsList"
            v-model="category.defectsList"
            multiple

            :options="opt" >
          </MultipleSelect>
        </div>
      </div>

      <!--
      <div class="form-group">
        <label class="radio-inline"><b-form-radio v-model="category.prodType" name="prodType" value="2" :disabled="notEnabled">{{$t('configCategory.reject2')}}</b-form-radio></label>
      </div>
      -->

      <div class="form-group">
        <label class="radio-inline"><b-form-radio v-model="category.prodType" name="prodType" value="3" :disabled="notEnabled">{{$t('configCategory.other')}}</b-form-radio></label>
      </div>

      <div class="form-group marginTop10px">
        <input type="checkbox" id="isActive" v-model="category.isActive">
        <label class="checkboxLabel" for="isActive">{{$t('configCategory.active')}}</label>
      </div>

      <button class="btn btn-perfcell float-left marginTop50px" @click.prevent="clickRetour">
        {{ $t('configCategory.cancel' ) }}
      </button>

      <button class="btn btn-perfcell float-left marginTop50px marginLeft10px" type="submit">
        {{ $t('configCategory.submit' ) }}
      </button>

      <p v-if="errors.length" class="float-left marginTop50px marginLeft30px" :style="{ color: msgColor }">
        <b v-if="msgColor == 'red'">{{$t('configCategory.error', errors.length)}}</b>
        <ul>
          <li v-for="error in errors" :key="error">{{ error }}</li>
        </ul>
      </p>

    </form>

  </div>
</template>

<script>
import { axiosInstance } from "@/main";

export default {
  data() {
    return {
      category: {
        _id: '',
        name: '',
        prodType: 'normal',
        defectsList: [],
        isActive: true,
        
      },
     
      notEnabled: false,
      id: '',
      errors: [],
      msgColor: "red",
      textReject: "",
      isON1: false,
      defectsList: [],
      opt: {
        multiple: true,
        filter: true,
        placeholder: this.$i18n.t('configCategory.defectsPHolder'),
        multipleWidth: 500,
        width: 500,
        dropWidth: 500,
        locale: localStorage.getItem("locale")
      },
      
    }
  },
  methods: {
    onSubmit() {
      if( ! this.validate() )
        return;
        
      axiosInstance.post('/category/' + this.id, this.category)
      .then((resp) => {
        //console.log("----- onSubmit ok = " + JSON.stringify(resp.data, null, 2));

        if( resp.data == "categoryNameExist" ) {
          this.errors = [];
          this.msgColor = "red";
          this.errors.push(this.$i18n.t('configCategory.categoryExist'));
        }
        else {
          this.category = resp.data;
          this.id = this.category._id;
          this.errors.push(this.$i18n.t('configCategory.success'));
          this.msgColor = "green";
        }
      }).catch((err) => {
        console.log("----- onSubmit error = " + JSON.stringify(err, null, 2));
      });
    },
    validate() {
      this.errors = [];
      this.msgColor = "red";

      this.category.name = this.category.name.trim();
      if( ! this.category.name.length )
        return false;

      return ! this.errors.length;
    },
    clickRetour() {
      this.$router.replace("/configCategoriesList");
    }
  },
  mounted() {
    if( process.env.VUE_APP_CLIENT_NAME.startsWith('goodfood') )
      this.textReject = this.$i18n.t('configCategory.rejectGF');
    else
      this.textReject = this.$i18n.t('configCategory.rejectOther');
    if( this.id != "0" ) {
      axiosInstance.get('/category/' + this.id)
      .then(resp => {
        //console.log("----- get /category = " + JSON.stringify(resp.data, null, 2));
        this.category = resp.data;
        this.notEnabled = true;
      })
      .catch(err => {
        console.log("----- get /category error = " + JSON.stringify(err, null, 2));
      });
    }
    else {
      this.notEnabled = false;
    }

    if( process.env.VUE_APP_CLIENT_NAME.startsWith('goodfoodon1') ) {
      this.isON1 = true;
      axiosInstance.get('/defectsList/', {"params": { "isActive": true }})
      .then(resp => {
        //console.log("----- get /defectsList = " + JSON.stringify(resp.data, null, 2));
        for( let i=0; i<resp.data.length; i++ ) {
          resp.data[i].value = resp.data[i]._id;
        }
        this.defectsList = resp.data;
      }).catch(err => {
        console.log("----- get /defectsList error = " + JSON.stringify(err, null, 2));
      });
    }

        

  },
  created() {
    this.id = this.$route.params.id;
    //console.log("----- this.id = " + JSON.stringify(this.id, null, 2));
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/colors.scss';

form {
  background: White;
  padding: 2rem 2rem 1rem;
  width: 100%;
}

label {
  font-weight: bold;
}

.narrow {
  width: 70px;
}
</style>
