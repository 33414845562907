<template>
  <div class="g-page-container">

    <div class="g-pageHeader" style="height: 63px;">
      <h1 style="margin: auto;">{{$t('configLine.title')}}</h1>
    </div>

    <form class="g-pageBody" @submit.prevent="onSubmit">

      <div class="form-group">
        <label for="inputName">{{$t('configLine.name')}}</label>
        <input
          type="text"
          v-model="line.name"
          class="form-control"
          id="inputName"
          :placeholder="$t('configLine.namePHolder')">
      </div>

      <div class="form-group">
        <label for="inputDescription">{{$t('configLine.desc')}}</label>
        <input
          type="text"
          v-model="line.description"
          class="form-control"
          id="inputDescription"
          :placeholder="$t('configLine.descPHolder')">
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-md-3">
            <input type="checkbox" id="supportLine2" v-model="line.supportLine2">
            <label class="checkboxLabel marginRight10px" for="supportLine2">{{$t('configLine.supportLine2')}}</label>
          </div>
          <div v-if="line.supportLine2 == true" class="col-md-9">
            <div class="row">
              <label class="radio-inline">
              <b-form-radio v-model="line.allSched" name="allSched" value=false>{{$t('configLine.notAllSched')}}
              </b-form-radio>
              </label>
            </div>
            <div class="row" style="margin-top: -5px;">
              <label class="radio-inline">
              <b-form-radio v-model="line.allSched" name="allSched" value=true :disabled="notEnabled">{{$t('configLine.allSched')}}
              </b-form-radio>
              </label>
            </div>
          </div>
        </div>
        <label for="associateLines2">{{$t('configLine.associateLines2')}}</label>
        <div id="associateLines2">
          <MultipleSelect
            :data="lineList"
            v-model="line.associateLines2"
            multiple
            :options="opt" >
          </MultipleSelect>
        </div>
      </div>

      <div class="form-group">
        <input type="checkbox" id="extScanner" v-model="line.extScanner">
        <label class="checkboxLabel marginRight10px" for="extScanner">{{$t('configLine.extScanner')}}</label>
        <label for="scannerLines">{{$t('configLine.scannerLines')}}</label>
        <div id="scannerLines">
          <MultipleSelect
            :data="lineList"
            v-model="line.scannerLines"
            multiple
            :options="opt2" >
          </MultipleSelect>
        </div>
      </div>

      <div class="form-group">
        <label for="scannerLines">{{$t('configLine.selectCleaningFactor')}}</label>
        <div id="scannerLines">
          <MultipleSelect
            :data="cleaningFactorNameList"
            v-model="selectedCleaningFactor"
            :options="optCleaningFactor" >
          </MultipleSelect>
        </div>
      </div>

      <div class="form-group">
        <label for="batchSize">{{$t('configLine.batch')}}</label>
        <br>
        <input v-model="line.batchSize" id="batchSize" type="number" min="1" max="999999">
      </div>

      <div class="form-group">
        <label for="minutes-added-standard-change">
          {{ $t('configLine.minutes_added_standard_change') }}
        </label>
        <br>
        <input v-model.number="minutesEarnedOnChangeStdToDisplay" type="number" id="minutes-added-standard-change">
      </div>

      <div class="form-group">
        <input type="checkbox" id="inspectLine" v-model="line.inspectLine">
        <label class="checkboxLabel" for="inspectLine">{{$t('configLine.inspectLine')}}</label>
      </div>
       <!-- <div class="form-group">
        <input type="checkbox" id="automaticStart" v-model="line.automaticStart">
        <label class="checkboxLabel" for="automaticStart">{{$t('configLine.automaticStart')}}</label>
      </div> -->

      <div class="form-group">
        <input type="checkbox" id="supApproval" v-model="line.supApproval"     @change="onSupApprovalChange">
        <label class="checkboxLabel" for="supApproval">{{$t('configLine.supApproval')}}</label>
      </div>

      <div class="form-group" v-if="line.supApproval">
        <!-- <label for="schedule">{{$t('configLine.selectSchedule')}}</label> -->
        <div id="schedule">
          <MultipleSelect
              :data="schedList"
              v-model="schedResult"
              multiple
              :options="optScheds"
              >
            </MultipleSelect>
        </div>
      </div>

      <div class="form-group">
        <input type="checkbox" id="tabNoEff" v-model="line.tabNoEff">
        <label class="checkboxLabel" for="tabNoEff">{{$t('configLine.tabNoEff')}}</label>
      </div>

      <div class="form-group">
        <input type="checkbox" id="needTasks" v-model="line.needTasks">
        <label class="checkboxLabel" for="needTasks">{{$t('configLine.needTasks')}}</label>
      </div>

      <div class="form-group">
        <input type="checkbox" id="highEff" v-model="line.highEff">
        <label class="checkboxLabel" for="highEff">{{$t('configLine.highEff')}}</label>
      </div>

      <div class="form-group">
        <input type="checkbox" id="cOverBegin" v-model="line.cOverBegin">
        <label class="checkboxLabel" for="cOverBegin">{{$t('configLine.cOverBegin')}}</label>
      </div>
     
      <!-- <div class="form-group">
        <input type="checkbox" id="multiBox" v-model="line.multiBox">
        <label class="checkboxLabel" for="multiBox">{{$t('configLine.multiBox')}}</label>
      </div> -->
 <div class="form-group">
        <input type="checkbox" id="isActive" v-model="line.isActive">
        <label class="checkboxLabel" for="isActive">{{$t('configLine.active')}}</label>
      </div>
      <button class="btn btn-perfcell float-left marginTop50px" @click.prevent="clickRetour">
        {{ $t('configLine.cancel' ) }}
      </button>

      <button class="btn btn-perfcell float-left marginTop50px marginLeft10px" type="submit">
        {{ $t('configLine.submit' ) }}
      </button>

      <p v-if="errors.length" class="float-left marginTop50px marginLeft30px" :style="{ color: msgColor }">
        <b v-if="msgColor == 'red'">{{$tc('configLine.error', errors.length)}}</b>
        <ul>
          <li v-for="error in errors" :key="error">{{ error }}</li>
        </ul>
      </p>

    </form>

  </div>
</template>

<script>
import { axiosInstance } from "@/main";

export default {
  data() {
    return {
      line: {
        _id: '',
        name: '',
        description: '',
        supportLine2: false,
        inspectLine: false,
        associateLines2: [],
        batchSize: 1,
        tabNoEff: false,
        isActive: true,
        multiBox:false,
        supApproval: false,
        extScanner: false,
        scannerLines: [],
        clonedFrom: '',
        needTasks: false,
        allSched: false,
        automaticStart:false,
        highEff: false,
        cOverBegin: false,
        secondsEarnedOnChangeStd: 0
      },
      schedList:[],
      schedResult: [],
      listChange( name ) {
      console.log("----- listChange = " + name);
      this.error = '';
    },
    optScheds: {
        multiple: true,
        filter: true,
        placeholder: this.$i18n.t('configLine.selectSchedule'),
        multipleWidth: 375,
        width: 375,
        dropWidth: 375,
        locale: localStorage.getItem("locale")
      },
      minutesEarnedOnChangeStd: 0.00,
      id: '',
      clone: false,
      errors: [],
      msgColor: "red",
      lineList: [],
      cleaningFactorList: [],
      cleaningFactorNameList: [],
      selectedCleaningFactor: '',
      opt: {
        multiple: true,
        filter: true,
        placeholder: this.$i18n.t('configLine.lines2PHolder'),
        multipleWidth: 500,
        width: 500,
        dropWidth: 500,
        locale: localStorage.getItem("locale")
      },
      opt2: {
        multiple: true,
        filter: true,
        placeholder: this.$i18n.t('configLine.scannerPHolder'),
        multipleWidth: 500,
        width: 500,
        dropWidth: 500,
        locale: localStorage.getItem("locale")
      },
      optCleaningFactor: {
        multiple: false,
        filter: true,
        placeholder: this.$i18n.t('configLine.selectCleaningFactor'),
        multipleWidth: 500,
        width: 500,
        dropWidth: 500,
        locale: localStorage.getItem("locale")
      },
      withoutcleaningFactor: 'no cleaning factor'
    }
  },
  computed: {
    minutesEarnedOnChangeStdToDisplay: {
      get: function () {
        return this.minutesEarnedOnChangeStd.toFixed(2)
      },
      set: function (newValue) {
        this.minutesEarnedOnChangeStd = newValue
      }
    }
  },
  methods: {
    onSupApprovalChange() {
      if (!this.line.supApproval) {
        this.schedResult = []; // Clear the array when supApproval is false
      }
    },
    onSubmit() {
      // console.log(this.line,"TWEWS")
      
      if( ! this.validate() )
        return;
      if( this.id != "0" && ! this.clone ) {
        this.sendToServer( false );
      }
      else {
        this.$bvModal.msgBoxConfirm( this.$i18n.t('configLine.assignTasks'), {
          okTitle: this.$i18n.t('configLine.oui'),
          cancelTitle: this.$i18n.t('configLine.non'),
        })
        .then(value => {
          console.log("----- value = " + JSON.stringify(value, null, 2));
          this.sendToServer( value );
        });
      }
    },
    sendToServer( addToTasks ) {


      console.log(this.schedResult,"schedResult")
      this.line.cleaningFactor = this.findByCleaningFactorName(this.selectedCleaningFactor)._id
      this.line.secondsEarnedOnChangeStd = this.minutesEarnedOnChangeStd * 60

      this.line.supervisorApprovalSchedules=this.schedResult;
      axiosInstance.post('/line/' + this.id, {line: this.line, addToTasks: addToTasks })
      .then((resp) => {
        //console.log("----- onSubmit ok = " + JSON.stringify(resp.data, null, 2));
        if( resp.data == "lineNameExist" ) {
          this.errors = [];
          this.msgColor = "red";
          this.errors.push(this.$i18n.t('configLine.lineExist'));
        }
        else {
          this.line = resp.data;
          this.id = this.line._id;
          this.errors.push(this.$i18n.t('configLine.success'));
          this.msgColor = "green";
        }
      }).catch(() => {
        console.log("----- onSubmit error ");
      });
    },
    validate() {
      this.errors = [];
      this.msgColor = "red";

      if( this.line.name.length < 2 )
        this.errors.push(this.$i18n.t('configLine.nameLength'));

      // Verification of cleaning factor (actually disable)
      // if (this.selectedCleaningFactor === "" || this.selectedCleaningFactor === undefined) this.errors.push(this.$i18n.t('configLine.selectCleaningFactor'))

      return ! this.errors.length;
    },
    clickRetour() {
      this.$router.replace("/configLinesList");
    },
    findByCleaningFactorId (id) {
      return this.cleaningFactorList.find(({ _id }) => _id === id)
    },
    findByCleaningFactorName (name) {
      if (!name || name === '' || name === this.withoutcleaningFactor) return { _id: '' }
      return this.cleaningFactorList.find(({ cleaningFactorName }) => cleaningFactorName === name)
    }
  },
  mounted() {
    //console.log("----- this.id = " + JSON.stringify(this.id, null, 2));
    if( this.id.startsWith('-') ) {
      console.log("----- clone");
      this.clone = true;
      this.id = this.id.substring(1);
    }
    if( this.id != "0" ) {
      axiosInstance.get('/line/' + this.id)
      .then(resp => {
        //console.log("----- get /line = " + JSON.stringify(resp.data, null, 2));
        this.line = resp.data;
        console.log(this.line.supervisorApprovalSchedules,"this.line")
        if( this.clone ) {
          this.line.clonedFrom = this.line._id;
          this.line._id = '';
          this.line.name = '';
        }
        if( ! this.line.allSched ) {
          this.line.allSched = false;
        }
        if (!this.line.secondsEarnedOnChangeStd) {
          this.line.secondsEarnedOnChangeStd = 0
        } else {
          this.minutesEarnedOnChangeStd = Math.floor(this.line.secondsEarnedOnChangeStd / 60)
        }
      })
      .catch(err => {
        console.log("----- get /line error = " + JSON.stringify(err, null, 2));
      });
    }

    axiosInstance.get('/lineList', {})
    .then(resp => {
        for( let i=0; i<resp.data.length; i++ ) {
          resp.data[i].text = resp.data[i].name + ' - ' + resp.data[i].description;
          resp.data[i].value = resp.data[i]._id;
        }
        //console.log("----- get /lineList = " + JSON.stringify(resp.data, null, 2));
        this.lineList = resp.data;
    }).catch(err => {
        console.log("----- get /lineList error = " + JSON.stringify(err, null, 2));
    });

    const asyncMounted = async () => {
      const cleaningFactorResponse = await axiosInstance.get('/cleaning-factor')
      this.cleaningFactorList = cleaningFactorResponse.data.cleaningFactors
      this.cleaningFactorNameList = [
        this.withoutcleaningFactor,
        ...cleaningFactorResponse.data.cleaningFactors.map(({ cleaningFactorName }) => cleaningFactorName)
      ]
      if (this.line.cleaningFactor) this.selectedCleaningFactor = JSON.parse(JSON.stringify((this.findByCleaningFactorId(this.line.cleaningFactor)).cleaningFactorName))
      else this.selectedCleaningFactor = this.withoutcleaningFactor
    }

    axiosInstance.get('/scheduleList', {"params": { "isActive": true }})
    .then(resp => {
        for( let i=0; i<resp.data.length; i++ ) {
          resp.data[i].text = resp.data[i].description;
          resp.data[i].value = resp.data[i]._id;
        }
        //console.log("----- get /scheduleList = " + JSON.stringify(resp.data, null, 2));
        let list = JSON.stringify(resp.data);
        this.schedList = JSON.parse(list);
        console.log(this.schedList,"schedList")
        console.log(this.line.supervisorApprovalSchedules,"this.line next")

        this.schedResult = this.line.supervisorApprovalSchedules.filter(id => 
        this.schedList.some(schedule => schedule._id === id)
    );
        // this.schedResult = [];
    }).catch(err => {
        console.log("----- get /scheduleList error = " + JSON.stringify(err, null, 2));
    });

    asyncMounted()
  },
  created() {
    this.id = this.$route.params.id;
    //console.log("----- this.id = " + JSON.stringify(this.id, null, 2));
  },
  
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/colors.scss';

form {
  background: White;
  padding: 2rem 2rem 1rem;
  width: 100%;
}

label {
  font-weight: bold;
}

</style>
