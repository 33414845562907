<template>
  <div class="g-page-container">
    <div class="g-pageHeader" style="height: 63px">
      <h1 style="margin: auto">{{ $t("cleaningFactorDetail.title") }}</h1>
    </div>
    <div style="min-heigth: 50px; color: transparent">space</div>
    <Form
      :key="formKey"
      :cleaningFactorInfo="cleaningFactorInfo"
      :selectedLines="selectedLines"
      :cleaningFactorId="cleaningFactorId"
      @redirectToCleaningfactorId="handleRedirect"
    />
  </div>
</template>

<script>
import { axiosInstance } from "@/main";

import Form from "./components/Form.vue";

export default {
  components: {
    Form,
  },
  data() {
    return {
      cleaningFactorId: "",
      cleaningFactorInfo: null,
      formKey: 0,
      linesUseSelectedCleaningFactor: [],
    };
  },
  computed: {
    selectedLines() {
      return this.linesUseSelectedCleaningFactor.map(({ name }) => name);
    },
  },
  methods: {
    async fetchCleaningFactorInfo() {
      const response = await axiosInstance.get(`/cleaning-factor/${this.cleaningFactorId}`);
      return response.data;
    },
    async fetchLines() {
      const linesReponse = await axiosInstance.get(`/line/by-cleaning-factor/${this.cleaningFactorId}`);
      return linesReponse.data.lines;
    },
    async asyncMounted() {
      if (this.cleaningFactorId.toString() !== "new") {
        const [cleaningFactorData, linesData] = await Promise.all([this.fetchCleaningFactorInfo(), this.fetchLines()]);
        this.cleaningFactorInfo = cleaningFactorData;
        this.linesUseSelectedCleaningFactor = linesData;
      } else {
        this.cleaningFactorInfo = {
          _id: "",
          cleaningFactorName: "",
          itemNames: ["", "", "", "", ""],
        };
        this.linesUseSelectedCleaningFactor = [];
      }

      this.formKey++;
    },
    handleRedirect(id) {
      this.$router.replace(`/config/cleaning-factor/${id}`);
      this.cleaningFactorId = this.$route.params.cleaningFactorId;
      this.asyncMounted();
    },
  },
  mounted() {
    this.cleaningFactorId = this.$route.params.cleaningFactorId;

    this.asyncMounted();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/colors.scss";
form {
  background: White;
  padding: 2rem 2rem 1rem;
  width: 100%;
}

label {
  font-weight: bold;
}
</style>
