<template>
  <div id="app" style="display: table; margin: 0 auto;">
    <!-- <Navbar/> -->
    <div :style="part1">
      <router-view></router-view>
    </div>
    <div :style="part2">
      <TheFooter></TheFooter>
    </div>
  </div>
</template>

<script>
import TheFooter from "./components/TheFooter.vue";
import EventBus from "@/eventBus";
// import Navbar from "./components/Nav/NavBar.vue";
// pour des informations sur les valeurs utilisées voir le commentaire du 13 janvier 2020 à cette adresse
// https://perfcell.atlassian.net/wiki/spaces/PER/pages/238845953/Nouveau+code+de+Perfcell?focusedCommentId=291930120#comment-291930120

export default {
  name: "app",
  data() {
    return {
      pageFormatType: -1,
      currentPageType: -1,
      pageHeight: -1,
      pageWidth: -1,
      part1: {
        height: 0,
        width: 0,

        marginTop: 0,
        marginBottom: 0,
        marginRight: "10px",
        marginLeft: "10px",

        padding: 0,

        background: "#cdcdcd",
        border: "5px solid white",
        overflowY: "auto",
      },
      part2: {
        height: 0,
        width: 0,

        marginTop: 0,
        marginBottom: 0,
        marginRight: "10px",
        marginLeft: "10px",

        padding: 0,

        overflow: "hidden",
      },
      resizeTimerOn: false,
    };
  },
  methods: {
    handleResize() {
      if (this.resizeTimerOn) return;
      this.resizeTimerOn = true;
      let self = this;
      // wait a little to prevent a multitude of resize few ms aparts
      setTimeout(function() {
        let h = window.innerHeight;
        let w = window.innerWidth;
        //console.log("----- h = " + h + " , w = " + w);

        if (
          self.pageFormatType != self.currentPageType ||
          self.pageHeight != h ||
          self.pageWidth != w
        ) {
          self.pageFormatType = self.currentPageType;
          self.pageHeight = h;
          self.pageWidth = w;

          if (self.pageFormatType == 2 && w > 1000) w = 1000;
          //console.log("----- w = " + JSON.stringify(w, null, 2));

          let height = 0.85 * h;
          let width = w - 20;

          self.part1.height = height + "px";
          self.part1.width = width + "px";
          self.part1.marginTop = 0.02 * h + "px";
          self.part1.padding = 0.02 * h + "px";

          height = height - 10 - 0.04 * h;
          EventBus.$emit("mainViewDim", { width: width, height: height });

          height = 0.13 * h;

          self.part2.height = height + "px";
          self.part2.width = width + "px";

          EventBus.$emit("footerViewDim", { width: width, height: height });
        }

        self.resizeTimerOn = false;
      }, 200);
    },
    appChangePage(data) {
      //console.log("----- appChangePage = " + data);

      if (
        data.startsWith("operation") ||
        data.startsWith("admin") ||
        data.startsWith("dashboard") ||
        data.endsWith("Chart")
      )
        // full browser width
        this.currentPageType = 1;
      // reduced width
      else this.currentPageType = 2;

      if (this.pageFormatType != this.currentPageType) this.handleResize();
    },
  },
  components: {
    TheFooter,
    // Navbar,
  },
  mounted() {
    this.pageFormatType = 0;
    this.currentPageType = 1;
    this.pageHeight = window.innerHeight;
    this.pageWidth = window.innerWidth;
    this.handleResize();
    EventBus.$on("changePage", this.appChangePage);
  },
  created() {
    console.log("----- App.vue created()");
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    console.log("----- App.vue destroyed()");
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
