<template>
  <div>
    <table>
      <thead>
        <tr>
          <!-- <th v-if="inDev" class="dev-client">
            <p>{{ clientName }}</p>
          </th>
          <th v-else-if="testSite" class="test-client">
            <h3>{{ testClientName }}</h3>
          </th> -->
          <th class="c-logo">
            <img :src="logoClient" :style="imgDim" />
          </th>
          <th class="date-time">
            <h3>{{ date }}</h3>
            <h2>{{ time }}</h2>
          </th>
          <th class="p-logo">
            <img
              src="../assets/logo_perfcell.png"
              :width="logoWidth"
              :height="logoHeight"
            />
          </th>
          <th class="hamb">
            <nav class="main-nav">
              <Burger></Burger>
            </nav>
          </th>
        </tr>
      </thead>
    </table>

    <Sidebar v-if="!getDriverLogin()">
      <ul class="sidebar-panel-nav">
        <ul class="languages">
          <li><a href="#" @click="langEN()">EN</a></li>
          <li><a href="#" @click="langFR()">FR</a></li>
        </ul>
        <li>
          <a href="#" @click="jumpConfig()">{{ $t("footer.config") }}</a>
        </li>
        <li>
          <a href="#" @click="jumpUsers()">{{ $t("config.Employees") }}</a>
        </li>
        <li>
          <a href="#" @click="jumpOper()">{{ $t("footer.oper") }}</a>
        </li>
        <li><br /></li>
        <li>
          <a href="#" @click="jumpBI()">{{ $t("footer.BI") }}</a>
        </li>
        <li>
          <a href="#" @click="jumpDashboard()">{{ $t("footer.dashboard") }}</a>
        </li>
        <!-- <li>
          <a href="#" @click="inboundDash()">{{ $t("footer.inboundDash") }}</a>
        </li> -->
        <li>
          <a href="#" @click="jumpDashboard2()">{{
            $t("footer.dashboard2")
          }}</a>
        </li>
        <li>
          <a href="#" @click="jumpReports()">{{ $t("footer.reports") }}</a>
        </li>
        <li><br /></li>
        <li v-if="isAdmin">
          <a href="#" @click="logout()">{{ $t("footer.logout") }}</a>
        </li>
        <li v-if="isAdmin">
          <a href="#" @click="quit()">{{ $t("footer.quit") }}</a>
        </li>
      </ul>
      <p class="bottom-sidebar">{{ $t("footer.perfcellId") }} : {{ myId }}</p>
    </Sidebar>
    <Sidebar v-else>
      <ul class="sidebar-panel-nav">
        <li><a href="#" @click="langEN()">EN</a></li>
        <li><a href="#" @click="langFR()">FR</a></li>
      </ul>
    </Sidebar>
  </div>
</template>

<script>
import moment from "moment-timezone";
import client from "@/stores/ClientState.js";
import admin from "@/stores/AdminState.js";
import Burger from "@/components/Menu/Burger.vue";
import Sidebar from "@/components/Menu/Sidebar.vue";
import { mutations } from "@/components/Menu/store.js";
import EventBus from "@/eventBus";
import { baseURL } from "../main";
import oper from "@/stores/Operations.js";
import globals from "@/globals.js";

export default {
  data: function () {
    return {
      isAdmin: false,
      imgDim: {
        display: "block",
        maxWidth: 0,
        maxHeight: 0,
        width: "auto",
        height: "auto",
      },
      logoWidth: 0,
      logoHeight: 0,
      logoClient: baseURL + "/images/logo_client.png",
      date: "",
      time: "",
      timeZoneFooter: "",
      myId: this.getMyId(),
      timerOn: false,
      inDownTime: false,
      inChangeover: false,
      timeStartDown: 0,
      actState: -1,
      inDev: false,
      testSite: false,
      clientName: "",
      testClientName: "",
    };
  },
  components: {
    Burger,
    Sidebar,
  },
  methods: {
    getDriverLogin() {
      let { driver } = client.decryptToken() || false;
      return driver;
    },
    getMyId() {
      let id = client.getMyId();
      return (
        id.substring(0, 5) + "." + id.substring(5, 10) + "." + id.substring(10)
      );
    },
    jumpOper: function () {
      mutations.toggleNav();

      oper.dashboard(false);
      this.$router.replace("/operation");
    },
    jumpConfig: function () {
      mutations.toggleNav();
      if (admin.isLoggedIn()) this.$router.replace("/config");
      else this.$router.replace("/admin/1");
    },
    jumpUsers: function () {
      mutations.toggleNav();

      this.$router.replace("/configUsersList");
    },
    jumpDashboard: function () {
      mutations.toggleNav();

      this.$router.replace("/dashboard");
    },
    inboundDash: function () {
      mutations.toggleNav();

      this.$router.replace("/inbound");
    },
    jumpDashboard2: function () {
      mutations.toggleNav();

      this.$router.replace("/dashboard2");
    },
    jumpReports: function () {
      mutations.toggleNav();

      this.$router.replace("/reports");
    },
    jumpBI: function () {
      mutations.toggleNav();

      this.$router.replace("/chartsbi");
    },
    langEN: function () {
      mutations.toggleNav();

      localStorage.setItem("locale", "en");
      this.$i18n.locale = "en";
      moment.locale("en");
    },
    langFR: function () {
      mutations.toggleNav();

      localStorage.setItem("locale", "fr");
      this.$i18n.locale = "fr";
      moment.locale("fr");
    },
    logout: function () {
      mutations.toggleNav();

      admin.logOut();
      this.$router.replace("/operation");
    },
    quit: function () {
      mutations.toggleNav();

      this.$bvModal
        .msgBoxConfirm(this.$i18n.t("footer.confirm"), {
          okTitle: this.$i18n.t("ok"),
          cancelTitle: this.$i18n.t("cancel"),
        })
        .then((value) => {
          if (value) {
            client.logOut();
          }
        });
    },
    footerUpdateFactory(factory) {
      //console.log('----- Footer footerUpdateFactory = ' + JSON.stringify(factory, null, 2));
      if (factory) {
        this.logoClient =
          baseURL + "/images/logo_client.png?timestamp=" + new Date().getTime();
        this.timeZoneFooter = factory.timeZone;
        moment.tz.setDefault(this.timeZoneFooter);
      }
    },
  },
  mounted() {
    if (process.env.NODE_ENV == "development") {
      this.inDev = true;
      this.clientName = process.env.VUE_APP_CLIENT_NAME;
    }
    if (process.env.VUE_APP_CLIENT_NAME.includes("test")) {
      this.testSite = true;
      if (process.env.VUE_APP_CLIENT_NAME == "airbosstest")
        this.testClientName = "TEST AIRBOSS";
      else if (process.env.VUE_APP_CLIENT_NAME == "goodfoodmontrealtest")
        this.testClientName = "TEST GF QC1";
      else if (process.env.VUE_APP_CLIENT_NAME == "goodfoodon1test")
        this.testClientName = "TEST GF ON1";
      else if (process.env.VUE_APP_CLIENT_NAME == "goodfoodab2test")
        this.testClientName = "TEST GF AB2";
      else if (process.env.VUE_APP_CLIENT_NAME == "goodfoodbc1test")
        this.testClientName = "TEST GF BC1";
      else if (process.env.VUE_APP_CLIENT_NAME == "valleytest")
        this.testClientName = "TEST VALLEY";
    }

    let self = this;
    let selfI18n = this.$i18n;

    oper.getFactory(this.footerUpdateFactory);
    this.footerUpdateFactory(oper.getFactory());

    EventBus.$on("socketOpen", () => {
      this.footerUpdateFactory(oper.getFactory());
    });

    EventBus.$on("factory", this.footerUpdateFactory);

    EventBus.$on("adminStateChange", (adminState) => {
      // show or hide administrator options in menu
      this.isAdmin = adminState;
    });

    EventBus.$on("footerViewDim", (dim) => {
      //console.log("----- dim.height = " + JSON.stringify(dim.height, null, 2));
      //console.log("----- dim.width = " + JSON.stringify(dim.width, null, 2));
      this.logoWidth = 0.95 * dim.height;
      this.logoHeight = 0.95 * dim.height;

      this.imgDim.maxWidth = 0.2 * dim.width + "px";
      this.imgDim.maxHeight = 0.95 * dim.height + "px";
    });

    setInterval(function () {
      if (self.timeZoneFooter == "") {
        self.date = selfI18n.t("footer.timezone");
        self.time = "";
      } else {
        self.date = moment().format(selfI18n.t("footer.date"));
        self.time = moment().format("h:mm:ss a");
      }
      if (self.inDownTime) EventBus.$emit("updateDownTime", self.timeStartDown);
      if (self.inChangeover)
        EventBus.$emit("updateChangeover", self.timeStartChangeover);
    }, 1000);

    // this is here so message will appear on any screen when received
    EventBus.$on("notice", (msg) => {
      this.$bvModal.msgBoxOk(this.$i18n.t(msg));
    });
    EventBus.$on("msgToLine", (msg) => {
      //console.log('-----> msgToLine = ' + JSON.stringify( msg, null, 2) );
      if (msg == "doareload") {
        window.location.reload(true);
      } else if (msg == "DoInspection") {
        let m1 = selfI18n.t("footer.inspect1");
        let m2 = selfI18n.t("footer.inspect2");
        let m3 = selfI18n.t("footer.inspect3");
        const h = this.$createElement;
        const vNodesMsg = h("div", { style: "background-color: #ffff00;" }, [
          h("p", { style: "text-align: center; font-size: 20px;" }, "."),
          h("p", { style: "text-align: center; font-size: 22px;" }, m1),
          h("p", { style: "text-align: center; font-size: 22px;" }, m2),
          h("p", { style: "text-align: center; font-size: 22px;" }, m3),
          h("p", { style: "text-align: center; font-size: 20px;" }, "."),
        ]);
        this.$bvModal.msgBoxOk([vNodesMsg], {
          size: "lg",
          buttonSize: "lg",
          headerClass: "border-bottom-0",
          footerClass: "border-top-0",
          centered: true,
        });
      } else {
        msg = msg.replace(/\n/g, "<br>");
        const h = this.$createElement;
        const msgVNode = h("div", { domProps: { innerHTML: msg } });
        this.$bvModal.msgBoxOk(msgVNode);
      }
    });

    // downtime timer is here because the footer have an always running second timer
    // so it remove the assle of managing start/stop a down timer in others modules
    EventBus.$on("state", (msg) => {
      //console.log("----- footer UpdateState = " + JSON.stringify(msg, null, 2));
      this.actState = msg.state;
      this.inDownTime = !!(msg.state == globals.STATEDOWN);
      this.inChangeover = !!(msg.state == globals.STATECHANGEOVER);
      this.timeStartDown = msg.timeStartDown;
      this.timeStartChangeover = msg.timeStartChangeover;
    });

    //console.log('-----> localStorage = ' + JSON.stringify( localStorage, null, 2) );
  },
  created() {},
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/global.scss";

@media screen and (max-width: 600px) {
  .dev-client {
    font-size: 12px;
  }
  .test-client h3 {
    color: red;
    font-size: 12px;
  }
  .date-time {
    width: 50%;
    padding: 0;
    margin: 0;
    text-align: center;
    white-space: nowrap;
    color: $perfcell-green;
  }
  .date-time h3,
  h2 {
    font-size: 1rem;
  }
  .p-logo img {
    width: 50px;
    height: 50px;
  }
}

table {
  table-layout: fixed;
  width: 100%;
}
.test-client h3 {
  color: red;
}
/*
th {
  border: 1px solid black;
}
*/
.c-logo {
  position: absolute;
  padding: 0;
  margin: 0;
  width: 20%;
  text-align: left;
}
.date-time {
  width: 70%;
  padding: 0;
  margin: 0;
  text-align: center;
  white-space: nowrap;
  color: $perfcell-green;
}
.date-time h2,
h3 {
  line-height: 0.8;
}
.p-logo {
  position: absolute;
  padding: 0;
  margin: 0;
  width: auto;
  text-align: right;
}
.hamb {
  padding: 0;
  margin: 0;
  width: 7%;
  text-align: right;
}

.main-nav {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.8rem;
}
ul.sidebar-panel-nav,
ul.languages {
  list-style-type: none;
}
ul.sidebar-panel-nav a {
  color: White;
  text-decoration: none;
  font-size: 1.3rem;
  display: block;
  padding-bottom: 0.5em;
}

ul.sidebar-panel-nav > ul.languages {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding: 0;
}

.bottom-sidebar {
  position: absolute;
  bottom: 0;
  margin: auto;
  text-align: center;
  padding: 10px;
  font-size: 18px;
  z-index: -1;
}
</style>
