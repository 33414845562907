<template>
  <div
    v-if="showModal"
    class="overlay"
    @click.stop="showOverlay && handleClick"
    :style="`position: ${position}; background: ${overlayColor}; ${!showOverlay && 'pointer-events: none'}`"
  >
    <slot />
  </div>
</template>

<script>
export default {
  methods: {
    handleClick(event) {
      if ([...event.target.classList].includes("overlay")) this.handleClickOutside();
    },
    handleClickOutside() {
      this.$emit("close");
    },
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    showOverlay: {
      type: Boolean,
      default: true,
    },
    position: {
      type: String,
      default: "absolute",
    },
    overlayColor: {
      type: String,
      default: "rgba(0 0 0 / 0.4)",
    },
  },
};
</script>

<style scoped>
.overlay {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0 0 0 / 0.4); */
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;
  animation: fade-in 300ms linear;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
