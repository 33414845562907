<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask" v-show="showDefect">
      <div class="modal-container">
        <div>
          <div v-if="!gotDefectsList">
            <b-spinner label="Loading..."></b-spinner>
          </div>
          <div v-else>
            <label v-if="defectsList.length == 0" class="attention">{{
              $t("selectDefect.noDefect")
            }}</label>
            <label v-else style="font-weight: bold;">{{
              $t("selectDefect.sDefect")
            }}</label>
          </div>
          <b-table
            class="table-big-font"
            ref="defectsTable"
            hover
            small
            selectable
            select-mode="single"
            selected-variant="success"
            sticky-header="140px"
            primary-key="_id"
            :items="defectsList"
            :fields="showDefectFields"
            @row-selected="onDefectSelected"
          >
          </b-table>
        </div>

        <div>
          <div class="form-group">
            <label for="inputComment">{{
              $t("selectDefect.inputComment")
            }}</label>
            <input
              type="text"
              v-model="comment"
              class="form-control"
              id="inputComment"
              :placeholder="$t('selectDefect.enterText')"
            />
          </div>
        </div>

        <button
          class="btn btn-perfcell float-right marginTop40px"
          @click="clickCancel"
        >
          {{ $t("selectDefect.cancel") }}
        </button>

        <button
          v-if="saveDefectEnabled"
          class="btn btn-perfcell float-right marginTop40px marginRight10px"
          @click="clickSave"
        >
          {{ $t("selectDefect.save") }}
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import { axiosInstance } from "@/main";
import oper from "@/stores/Operations.js";
import EventBus from "@/eventBus";

export default {
  name: "SelectDefect",
  template: "#modal-template",
  props: {
    showDefect: Boolean,
    doInitDefect: Boolean,
    category: String,
  },
  data: function() {
    return {
      defectsList: [],
      showDefectFields: [
        {
          key: "text",
          thStyle: { display: "none" },
        },
      ],
      selectedDefect: {},
      gotDefectsList: false,
      comment: "",
      saveDefectEnabled: false,
      didStopScanner: false,
    };
  },
  components: {},
  watch: {
    doInitDefect: function(val) {
      if (val) {
        console.log("----- init SelectDefect");

        this.selectedDefect = {};
        this.comment = "";
        this.saveDefectEnabled = false;

        this.defectsList = [];
        //this.$refs.defectsTable.refresh();
        this.gotDefectsList = false;

        if (oper.getScanner()) {
          EventBus.$emit("scanner", false);
          this.didStopScanner = true;
        }

        let params = { params: { isActive: true } };
        if (process.env.VUE_APP_CLIENT_NAME.startsWith("goodfoodon1"))
          params = { params: { isActive: true, category: this.category } };

        axiosInstance
          .get("/defectsList/", params)
          .then((resp) => {
            //console.log("----- get /defectsList = " + JSON.stringify(resp.data, null, 2));
            this.defectsList = resp.data;
            this.gotDefectsList = true;

            this.selectedDefect = {};
          })
          .catch((err) => {
            console.log(
              "----- get /defectsList error = " + JSON.stringify(err, null, 2)
            );
          });
      }
    },
  },
  methods: {
    onDefectSelected(item) {
      //console.log("----- item = " + JSON.stringify(item, null, 2));
      if (item.length) {
        this.selectedDefect = item[0];
        this.saveDefectEnabled = true;
      }
    },
    clickSave: function() {
      let data = {
        cancel: false,
        defect: this.selectedDefect,
        comment: this.comment,
      };
      if (this.didStopScanner) EventBus.$emit("scanner", true);
      this.$emit("close", data);
    },
    clickCancel: function() {
      if (this.didStopScanner) EventBus.$emit("scanner", true);
      this.$emit("close", { cancel: true });
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.modal-mask {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}
.modal-container {
  width: 66%;
  height: 400px;
  margin: auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
@media only screen and (max-width: 800px) {
  .modal-container {
    width: 100%;
  }
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
