<template>
  <div>
    <div v-if="!tabLineOk">
      <label class="attention" @click="clickSelectLine">{{
        $t("tabLine.noLine")
      }}</label>
    </div>
    <div v-else class="cursorPointer" @click="clickSelectLine">
      <h1>{{ actualLine.name }}</h1>
      <p id="lineDescription">
        {{ actualLine.description }}
      </p>
      <b-popover
        target="lineDescription"
        triggers="hover"
        placement="top"
        variant="primary"
      >
        {{ actualLine.description }}
      </b-popover>
    </div>

    <div v-if="!tabLineOk">
      <label></label>
    </div>
    <div v-else-if="!tabSchedOk">
      <label class="attention" @click="clickSelectSched">{{
        $t("tabLine.noSched")
      }}</label>
    </div>
    <div v-else class="cursorPointer smallerText" @click="clickSelectSched">
      <b>{{ actualSched.description }}</b>
    </div>

    <selectLine
      :doInitLine="selectLineDoInit"
      :showLine="showModalLine"
      @close="closeModalLine"
    ></selectLine>
    <selectSched
      :doInitSched="selectSchedDoInit"
      :showSched="showModalSched"
      :schedulesList="selectSchedList"
      :autoselectSchdId="autoselectSchdId"
      @close="closeModalSched"
    ></selectSched>
  </div>
</template>

<script>
import { axiosInstance } from "@/main";
import selectLine from "@/components/SelectLine.vue";
import selectSched from "@/components/SelectSchedule.vue";
import EventBus from "@/eventBus";
import oper from "@/stores/Operations.js";
import socket from "@/stores/Socket.js";

export default {
  data: function () {
    return {
      showModalLine: false,
      selectLineDoInit: false,

      showModalSched: false,
      selectSchedDoInit: false,
      selectSchedList: [],

      tabLineOk: false,
      tabSchedOk: false,
      tabMainOk: false,
      selectedId: "",
      actualLine: {},
      actualSched: {},
      autoselectSchdId: "",
      waitingForAct: false,
    };
  },
  components: {
    selectLine,
    selectSched,
  },
  methods: {
    clickSelectLine: function () {
      //console.log("----- clickSelectLine");
      this.showModalLine = true;
      this.selectLineDoInit = true;
    },
    clickSelectSched: function (lineId = "") {
      console.log(lineId, "LINE IDDDD");
      // this.selectSchedDoInit = true;
      // if (!lineId || lineId == "") {
      //   lineId = this.selectedId;
      // }

      if (this.tabMainOk) {
        this.$bvModal.msgBoxOk(this.$i18n.t("tabLine.schedLock"), {
          okTitle: this.$i18n.t("ok"),
        });
      } else {
        axiosInstance
          .get("/scheduleRunning", {
            // params: {
            //   selectedlineId: lineId ? lineId : "", // Example additional parameter
            // },
          })
          .then((resp) => {
            console.log(
              "----- get /scheduleRunning = " +
                JSON.stringify(resp.data, null, 2)
            );

            // if we have only one schedule possible then use it
            if (resp.data.length == 1) {
              let selectedScheduleId = resp.data[0]._id;
              localStorage.setItem(
                "lastSelectedScheduleId",
                selectedScheduleId
              );
              this.closeModalSched({
                cancel: false,
                selectedSchedule: resp.data[0],
              });
            }
            // otherwise open the shcedule selection screen
            else {
              this.selectSchedList = resp.data;
              this.showModalSched = true;
              this.selectSchedDoInit = true;
            }
          })
          .catch((err) => {
            console.log(
              "----- get /scheduleRunning error = " +
                JSON.stringify(err, null, 2)
            );
          });
      }
    },
    closeModalLine: function (data) {
      //console.log("----- closeModalLine = " + JSON.stringify(data, null, 2));
      this.selectLineDoInit = false;
      this.showModalLine = false;

      // test if user has selected a different line
      if (
        !data.cancel &&
        (!this.actualLine._id || this.actualLine._id != data.selectedLine._id)
      ) {
        let params = {
          line: data.selectedLine,
        };
        axiosInstance.post("/changeline", params);
        this.waitingForAct = true;
        oper.scanner(false);
        // localStorage.setItem("runningLine", "");
      }
    },
    closeModalSched: function (data) {
      console.log("----- closeModalSched = " + JSON.stringify(data, null, 2));
      this.selectSchedDoInit = false;
      this.showModalSched = false;

      if (!data.cancel) {
        let params = {
          sched: data.selectedSchedule,
        };
        console.log(params, "params");
        axiosInstance.post("/changeschedule", params);
        let query = { isActive: true };
        query.lineId = localStorage.getItem("lastSelectedLineId");
        axiosInstance
          .get("/standardList2", {
            params: {
              ...query,
            },
          })
          .then((resp) => {
            if (resp.data.length === 1) {
              let changeStdData = resp.data[0];
              changeStdData.inProduction = this.tabMainOk;
              changeStdData.actId = oper.getActivityId();
              axiosInstance.post("/changestd", changeStdData);
            }
          });
      }
    },
    lineUpdateActivity: function (data) {
      console.log(
        "----- lineUpdateActivity = " + JSON.stringify(data, null, 2)
      );

      this.tabMainOk = !!data._id;
      if (this.tabMainOk) {
        localStorage.setItem("runningLine", data._id);
      }

      if (data.line && data.line._id) {
        this.actualLine = data.line;
        this.tabLineOk = true;
      } else {
        this.actualLine = {};
        this.tabLineOk = false;
      }

      if (data.sched && data.sched.sched._id) {
        this.actualSched = data.sched.sched;
        this.tabSchedOk = true;
      } else {
        this.actualSched = {};
        this.tabSchedOk = false;
      }

      if (this.waitingForAct && !this.tabMainOk) {
        // this.clickSelectSched(data.line._id);
        this.selectedId = data.line._id;
      }
      this.waitingForAct = false;

      this.selectLineDoInit = false;
      this.showModalLine = false;
      this.selectSchedDoInit = false;
      this.showModalSched = false;
    },
  },
  mounted() {
    //console.log("----- TabLine mounted()");

    this.lineUpdateActivity(oper.getCurrentActivity());
    EventBus.$on("updateActivity", this.lineUpdateActivity);

    // this code is to get back with the running line that was there
    // when one of the following even did happend:
    //   the browser got closed
    //   the user navigate to another web site
    //   and specially, we got "doareload" from the server
    // if we just come back from configuration then
    //   tabMainOk is set true in lineUpdateActivity()
    // wait one second for socket connection
    let self = this;
    // setTimeout(function() {
    let runningLine = localStorage.getItem("runningLine");
    //console.log("----- runningLine = " + runningLine);
    if (
      runningLine &&
      !self.tabMainOk &&
      socket.getSocketId() != "unidentified"
    ) {
      axiosInstance.get("/lineForAct/" + runningLine).then((resp) => {
        //console.log("----- get /lineForAct = " + JSON.stringify(resp.data, null, 2));

        // check if activity is still in operation and check again if we are not running
        if (resp.data && !self.tabMainOk) {
          // all good, load that line
          let params = {
            line: { _id: resp.data },
          };
          axiosInstance.post("/changeline", params);
          self.waitingForAct = true;
        }
        // localStorage.setItem("runningLine", "");
      });
    }

    let lastSelectedLineId = localStorage.getItem("lastSelectedLineId");

    if (runningLine && lastSelectedLineId) {
      oper
        .getPrevActivity(runningLine, lastSelectedLineId)
        .then((res) => {
          console.log(res.data, "data");
          // return res.data.line._id;
          return res.data;

          // self.showModalLine = true;
        })
        .then((data) => {
          let lineId = data.line._id;
          // let schedID = data.sched.sched._id;
          this.autoselectSchdId = data.sched.sched._id;
          console.log(data, "idid");

          self.selectLineDoInit = true;
          EventBus.$emit("initLineWithId", lineId); // Emit event to init line with ID
        });
      // console.log(activityData,"activityData");
    }
    // }, 1000);
  },
  beforeDestroy() {
    //console.log("----- TabLine beforeDestroy()");

    EventBus.$off("updateActivity", this.lineUpdateActivity);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/global.scss";

h1 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.1;
  color: $perfcell-green;
  margin: 0;
}

.smallerText {
  font-size: 14px;
}

#lineDescription {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 100%;
  margin-bottom: 0;
}
</style>
