<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask" v-show="showConfirm">
      <div class="modal-container">
        <div class="bodyContainer">
          <div style="padding: 10px 20px;">
            <div v-if="courierProcessType.toLowerCase() === 'inbound'">
              <span class="courierTitle">{{ $t('selectStd.courierId') }} </span>
              <span class="courierProcess">{{ barcodeParts.courierId }}</span>
              <span class="courierTitle"> ?</span>
            </div>
            <div v-else>
              <span class="courierTitle">{{ $t('selectStd.routeNumber') }} </span>
              <span class="courierProcess">{{ barcodeParts.routeNumber }}</span>
              <span class="courierTitle"> ?</span>
            </div>
          </div>
          
        </div>
        <footer class="footerContainer">
          <button class="button btn btn-perfcell" @click="clickCancel">
            <span>{{ $t('selectStd.no') }}</span>
          </button>
          <button class="button btn btn-perfcell" @click="clickOk">
            <span>{{ $t('selectStd.yes') }}</span>
          </button>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
import EventBus from "@/eventBus";

export default {
  name: 'courierConfirm',
  template: '#modal-template',
  props: {
    showConfirm: Boolean,
    barcodeParts: Object,
    courierProcessType: String
  },
  data: function () {
    return {

    }
  },
  methods: {
    clickOk: function () {
      this.$emit('close', { isCorrectCourier: true });
      if (this.courierProcessType === 'inbound' || this.courierProcessType === 'outbound') {
        EventBus.$emit('updateCourierProcess', { 
        courierMethod: this.courierProcessType,
        matchId: this.courierProcessType === 'inbound' ? this.barcodeParts.courierId : this.barcodeParts.routeNumber
      })
      }
    },
    clickCancel: function () {
      this.$emit('close', { isCorrectCourier: false })
    }
  }
}
</script>

<style>
  * {
    box-sizing: border-box;
  }
  .modal-mask {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s ease;
  }
  .modal-container {
    width: 450px;
    height: 150px;
    margin: 40px auto 0;
    padding: 20px 30px 0px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }
  .modal-enter {
    opacity: 0;
  }
  .modal-leave-active {
    opacity: 0;
  }
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .bodyContainer {
    height: 40%;
  }

  .footerContainer {
    height: 20%;
    display: flex;
    flex-flow: row-reverse;
    gap: 20px;
    padding-top: 20px;
    justify-items: center;
  }

  .title {
    font-size: 1.5rem;
    font-weight: 600;
  }
  
  .section {
    margin: 0px;
    padding: 0px 10px;
    letter-spacing: -0.2rem;
  }

  .button {
    padding: 5px 7px;
    height: 40px;
    width: 100px;
  }

  .courierTitle {
    font-size: 1.2rem;
  }

  .courierProcess {
    font-size: 1.4rem;
    font-weight: bolder;
  }

  @media screen and (max-width: 400px) {
    .modal-container {
      width: 100%;
    }
  }

</style>