<template>
  <button class="return-button btn btn-perfcell" @click="handleClick">
    <span style="color: white">{{ $t("return") }}</span>
  </button>
</template>

<script>
export default {
  methods: {
    handleClick() {
      this.$router.replace(this.to);
    },
  },
  props: {
    to: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
.return-button {
  transition: all 300ms ease-in-out;
  outline: none;
}

.return-button:hover {
  filter: brightness(1.2);
}

.return-button:active {
  filter: brightness(1.1);
}
</style>
