<template>
  <ModalTemplate :showModal="showModal" @close="handleCloseModal">
    <div class="modal-container">
      <!-- HEADER -->
      <div class="modal-header">
        <h3>
          Assign clean score <span v-if="haveCleanFactor" class="text-sm">({{ cleaningFactorName }})</span>
        </h3>
      </div>

      <!-- BODY -->
      <div v-if="isLoading" class="modal-body">
        <span>Loading...</span>
      </div>
      <div v-else-if="haveCleanFactor" class="modal-body">
        <div v-for="task of tasks" :key="task.name" class="task-item">
          <span class="task-name">{{ task.name }}</span>
          <div class="califications">
            <div v-for="calification of califications" :key="calification.name" class="calification" @click="handleCalificationClick(`${task.name}-${calification.name}`)">
              <span for="dirty-calification">{{ calification.name }}</span>
              <input v-model="task.value" id="dirty-calification" :ref="`${task.name}-${calification.name}`" type="radio" :name="task.name" :value="calification.value" />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="modal-body">
        <h3>This line has not a cleaning factor</h3>
      </div>

      <!-- FOOTER -->
      <div class="modal-footer">
        <span v-if="haveCleanFactor">Calification: {{ scorePoints }}/{{ maxScorePoints }}</span>
        <button class="btn btn-cancel" @click="handleCloseModal">Cancel</button>
        <button v-if="haveCleanFactor" class="btn btn-perfcell" @click="handleAssignCleaningScore" :disabled="cleaningScoreAssignIsLoading">
          {{ cleaningScoreAssignIsLoading ? "Loading" : "Assign score" }}
        </button>
      </div>
    </div>
  </ModalTemplate>
</template>

<script>
import { axiosInstance } from "@/main";
import oper from "@/stores/Operations.js";
import ModalTemplate from "./Modal-template.vue";

export default {
  components: {
    ModalTemplate,
  },
  data() {
    return {
      haveCleanFactor: false,
      califications: [
        {
          name: "Dirty",
          value: 0,
        },
        {
          name: "Clean",
          value: 1,
        },
        {
          name: "Very clean",
          value: 2,
        },
      ],
      cleaningScoreAssignIsLoading: false,
      cleaningFactorName: "",
      tasks: [],
      isLoading: true,
    };
  },
  computed: {
    scorePoints() {
      return this.tasks.reduce((acumulator, { value }) => acumulator + value, 0);
    },
    highestValue() {
      const sortedCalifications = [...this.califications].sort((a, b) => {
        if (a.value > b.value) return 1;
        if (b.value > a.value) return -1;
        return 0;
      });

      return sortedCalifications[sortedCalifications.length - 1].value;
    },
    maxScorePoints() {
      return this.highestValue * this.tasks.length;
    },
  },
  methods: {
    getCurrentActivity() {
      const activityInfo = oper.getCurrentActivity();
      if (!activityInfo || !activityInfo._id) return null;
      return activityInfo;
    },
    updateCleaningFactorOfCurrentAct(cleaningFactorId) {
      const act = this.getCurrentActivity();
      oper.setCurrentActivity({ ...act, line: { ...act.line, cleaningFactor: cleaningFactorId } });
    },
    handleCloseModal() {
      this.$emit("close");
    },
    handleCalificationClick(reference) {
      this.$refs[reference][0].click();
    },
    async handleAssignCleaningScore() {
      this.cleaningScoreAssignIsLoading = true;
      const currentAct = this.getCurrentActivity();
      const employeesId = currentAct.team.map(({ emp }) => emp._id);

      const response = await axiosInstance.put(`/cleaning-score/${currentAct._id}`, {
        maxPointsPerTask: this.highestValue,
        tasks: this.tasks,
        users: employeesId,
        line: currentAct.line,
      });

      this.cleaningScoreAssignIsLoading = false;
      if (![200, 201].includes(response.status)) {
        console.error("An error occur in assign cleaning score", response);
        return;
      }
      this.handleCloseModal();
    },
    async tryToGetCleaningScore({ activityId }) {
      try {
        const cleaningScoreResponse = await axiosInstance.get(`/cleaning-score/${activityId}`);
        return cleaningScoreResponse.data.cleaningScore;
      } catch (error) {
        return false;
      }
    },
    async fetchCleaningFactorTasksByLine({ lineId, activityId, tryToGetCleaningScore = false }) {
      const CleaningFactorResponse = await axiosInstance.get(`/line/${lineId}/cleaning-factor`);
      const cleaningFactorData = CleaningFactorResponse.data.cleaningFactor;
      if (!cleaningFactorData) {
        this.cleaningFactorName = "";
        this.tasks = [];
        return;
      }

      this.updateCleaningFactorOfCurrentAct(cleaningFactorData._id);
      this.cleaningFactorName = cleaningFactorData.cleaningFactorName;
      this.haveCleanFactor = true;

      if (tryToGetCleaningScore) {
        const cleaningScoreData = await this.tryToGetCleaningScore({ activityId });
        if (cleaningScoreData) {
          this.tasks = cleaningScoreData.tasks;
          this.isLoading = false;
          return;
        }
      }
      this.tasks = cleaningFactorData.itemNames.map((itemName) => ({ name: itemName, value: 0 }));
      this.isLoading = false;
    },
    async fetchCleaningFactorTasks({ cleaningFactorId, activityId, lineId }) {
      try {
        const [cleaningScoreResponse, cleaningFactorResponse] = await Promise.all([
          axiosInstance.get(`/cleaning-score/${activityId}`),
          axiosInstance.get(`/cleaning-factor/${cleaningFactorId}`),
        ]);
        const cleaningScoreData = cleaningScoreResponse.data.cleaningScore;
        this.cleaningFactorName = cleaningFactorResponse.data.cleaningFactorName;
        this.tasks = cleaningScoreData.tasks;
        this.haveCleanFactor = true;
        this.isLoading = false;
      } catch (error) {
        this.fetchCleaningFactorTasksByLine({ lineId, activityId });
      }
    },
  },
  watch: {
    showModal(showModal) {
      this.haveCleanFactor = false;
      this.isLoading = true;
      if (!showModal) return;
      const activity = this.getCurrentActivity();
      const cleaningFactorId = activity.line.cleaningFactor;
      if (!cleaningFactorId) {
        return this.fetchCleaningFactorTasksByLine({ lineId: activity.line._id, activityId: activity._id, tryToGetCleaningScore: true });
      }

      const activityId = activity._id;
      this.fetchCleaningFactorTasks({ cleaningFactorId, activityId, lineId: activity.line._id });
    },
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.text-sm {
  font-size: 1rem;
}
.modal-container {
  width: 100%;
  height: fit-content;
  min-height: 280px;
  max-height: 90vh;
  max-width: 500px;
  display: 100px 1fr 100px;
  overflow-y: auto;
}

.modal-body {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.task-item .califications {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
}

.task-item .task-name {
  font-weight: 600;
  font-size: 1.2rem;
}

.task-item .califications .calification {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

.modal-footer {
  display: grid;
  grid-template-columns: 1fr 100px 150px;
}

.btn {
  transition: all 300ms ease-in-out;
}

.btn-perfcell {
  border: 1px solid #4e8085;
}

.btn-perfcell:hover {
  color: white !important;
  filter: brightness(1.2);
}

.btn-cancel {
  border: 1px solid black;
}

.btn-cancel:hover {
  background: rgba(0 0 0 / 0.1);
}
</style>
