import { axiosInstance } from "@/main";
import moment from "moment-timezone";
import socket from "@/stores/Socket.js";
import router from "@/router";
import VueJwtDecode from "vue-jwt-decode";

function isLoggedIn() {
  return localStorage.getItem("token") != null;
}

function logOut() {
  delete axiosInstance.defaults.headers.common["Authorization"];
  localStorage.removeItem("token");
  socket.closeSocket();
  router.replace("/login");
}
function decryptToken() {
  return VueJwtDecode.decode(localStorage.getItem("token"));
}
function logIn(theToken) {
  console.log("----- client logIn");
  localStorage.setItem("token", theToken);
  let oldMyId = getMyId();
  localStorage.setItem("myId", new Date().getTime());
  axiosInstance.defaults.headers.common["Authorization"] = theToken;

  let log = {
    event: "new Id",
    time: moment(),
    text1: oldMyId,
    text2: getMyId(),
  };
  axiosInstance
    .post("/log", log)
    .then(() => {
      console.log("----- post /log ok");
    })
    .catch(() => {
      console.log("----- post /log error ");
    });
}

function getMyId() {
  let myId = localStorage.getItem("myId");
  if (!myId) myId = "0000000000000";
  return myId;
}

function init(myAxios) {
  myAxios.interceptors.response.use(
    (response) => {
      //console.log("----- axios.interceptors.response ok");
      return response;
    },
    function(error) {
      //if (error.response.status === 401 ) {
      if (error.message.indexOf("401") != -1) {
        //console.log("----- axios.interceptors.response 401");
        logOut();
      }
      return Promise.reject(error);
    }
  );

  myAxios.interceptors.request.use(
    (config) => {
      //console.log("----- config = " + JSON.stringify(config, null, 2));

      if (!config.params) {
        config.params = {};
      }
      config.params.timestamp = new Date().getTime();
      config.params.socketid = socket.getSocketId();

      const token = localStorage.getItem("token");
      //console.log("----- axios.interceptors.request, token = " + JSON.stringify(token, null, 2));
      if (token) {
        config.headers["Authorization"] = token;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
}

export default { init, isLoggedIn, logOut, logIn, getMyId, decryptToken };
