import 'multiple-select/dist/multiple-select.css'

import './jquery.js'
import Vue from 'vue'
import 'multiple-select/dist/multiple-select.js'
// if following file is missing, copy it from current folder
import 'multiple-select/dist/locale/multiple-select-fr.js'
import MultipleSelect from 'multiple-select/src/vue/MultipleSelect.vue'

Vue.component('MultipleSelect', MultipleSelect)
