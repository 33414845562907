<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask" v-show="showAutoLogin">
      <div class="modal-container">
        <div style="margin-bottom: 30px;">
          {{ $tc("confirmAutoLogin.titre", lastSelectedTeam.length) }}
        </div>

        <h1 v-for="member in lastSelectedEmployees" :key="member._id">
          <p class="cursorPointer" @click="clickRemoveEmp(member._id)">
            <i class="fa fa-remove" style="color:black"></i>
            {{ member.firstName }} {{ member.lastName }} {{ member.alias }}
            <br />
          </p>
        </h1>

        <button
          class="btn btn-perfcell float-left marginTop50px marginRight10px"
          @click="clickConfirm"
        >
          {{ $t("confirmAutoLogin.confirm") }}
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ConfirmAutoLogin",
  template: "#modal-template",
  props: {
    showAutoLogin: Boolean,
    doInitAutoLogin: Boolean,
    lastSelectedTeam: Array,
    employeesList: Array,
  },
  data: function() {
    return {
      lastSelectedEmployees: [],
    };
  },
  watch: {
    doInitAutoLogin: function(val) {
      if (val) {
        this.lastSelectedEmployees = [];

        this.lastSelectedTeam.forEach((emp) => {
          for (let i = 0; i < this.employeesList.length; i++) {
            if (emp == this.employeesList[i]._id.toString()) {
              console.log(emp,"emp",this.employeesList[i])
              if (!this.employeesList[i].alias.includes("-")) {
                this.lastSelectedEmployees.push(this.employeesList[i]);
              }

              break;
            }
          }
        });
        this.lastSelectedEmployees.sort(function(a, b) {
          let emp1 = a.firstName.toLowerCase();
          let emp2 = b.firstName.toLowerCase();
          return emp1.localeCompare(emp2);
        });
      }
    },
  },
  methods: {
    clickRemoveEmp: function(id) {
      for (var i = 0; i < this.lastSelectedEmployees.length; i++)
        if (this.lastSelectedEmployees[i]._id.toString() == id.toString()) {
          this.lastSelectedEmployees.splice(i, 1);
          break;
        }
    },
    clickConfirm: function() {
      let selected = [];
      for (var i = 0; i < this.lastSelectedEmployees.length; i++)
        selected.push(this.lastSelectedEmployees[i]._id.toString());
      this.$emit("close", selected);
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.modal-mask {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}
.modal-container {
  width: 550px;
  height: 450px;
  margin: 40px auto 0;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

h1 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.1;
  color: $perfcell-green;
  margin: 0;
}
</style>
