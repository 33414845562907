<template>
  <Modal :showModal="show" position="fixed" overlayColor="transparent" :showOverlay="false">
    <div class="custom-modal-container">
      <span class="message">{{ $t("quantityProducedModal.orderComplete") }}</span>
      <div class="footer">
        <button @click="handleAnswer('no')" class="outline">{{ $t("no") }}</button>
        <button @click="handleAnswer('yes')" class="fill">{{ $t("yes") }}</button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "./Modal-template.vue";
export default {
  components: {
    Modal,
  },
  methods: {
    handleAnswer(answer) {
      this.$emit("onAnswer", answer);
    },
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.custom-modal-container {
  pointer-events: auto;
  background: whitesmoke;
  display: grid;
  grid-template-rows: 1fr 40px;
  padding: 10px;
  max-width: 720px;
  min-width: 600px;
  min-height: 150px;
  gap: 1rem;
  box-shadow: 0 0 10px rgba(0 0 0 / 0.3);
  align-items: center;
}

.message {
  font-weight: 500;
  font-size: 24px;
  text-align: center;
}

.footer {
  width: 100%;
  display: flex;
  place-content: center;
  gap: 1rem;
}

button {
  width: 100px;
  height: 40px;
  border: none;
  outline: none;
  font-weight: 600;
  cursor: pointer;
}

button.fill {
  background: #508084;
  color: white;
  border: 2px solid #508084;
}
button.outline {
  border: 2px solid #508084;
  color: #508084;
  background: transparent;
}
</style>
