import Vue from "vue";
import App from "./App.vue";
import router from "@/router";

// initialisation du graphique de l'appli
import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue);
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// multiple-select
import "./plugins/select";

// initialisation du language de l'appli
import i18n from "@/plugins/i18n";
import moment from "moment-timezone";
moment.locale(i18n.locale);

// initialisation de la communication avec le serveur
import axios from "axios";
export let baseURL = "";
export let baseReportsURL = "";
if (process.env.NODE_ENV === "production") {
  console.log("----- ENV = production");
  baseURL = process.env.VUE_APP_SERVER;
  baseReportsURL = process.env.VUE_APP_REPORTS_SERVER;
} else {
  console.log("----- ENV = development");
}
export const axiosInstance = axios.create({
  baseURL: baseURL,
});
export const axiosInstanceReports = axios.create({
  baseURL: baseReportsURL,
});

import socket from "@/stores/Socket.js";
import client from "@/stores/ClientState.js";
// router.onReady est utilisé pour retarder l'initialisation apres que tous les modules soient operationels
router.onReady(() => {
  // initialisation de l'autorisation à utiliser Perfcell
  client.init(axiosInstance);

  // on start, router will go to /operation if client.isLoggedIn and to /login if not
  // here we initiate connection to the server and start the Spinner that will be cleared after connected
  if (client.isLoggedIn()) {
    socket.openSocket();
    router.replace("/serverDown/1");
  }
});

// une ligne de code qui se retrouve dans toutes les applis Vue.js
Vue.config.productionTip = false;

// ces deux variables ne sont utilisées que pour être affichées dans ConfigFactory
// mais sont déclarées ici pour pouvoir les logger au démarrage de l'appli
// appVersion is also used to validate version with the server
export const appVersion = require("../package.json").version || "";
export const appReleaseDate = require("../package.json").released;
console.log("----- Client = " + process.env.VUE_APP_CLIENT_NAME);
console.log("----- Vue app version = " + appVersion);
console.log("----- Vue app released = " + appReleaseDate);

// let's go
new Vue({
  router,
  i18n,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
