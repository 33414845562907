<template>
  <div>
    <div v-if="!tabLineOk || !tabSchedOk">
      <p>{{ $t("tabTeam.emptyTeam") }}</p>
    </div>
    <div v-else-if="!tabTeamOk">
      <label class="attention cursorPointer" @click="clickSelectTeam(null)">{{
        $t("tabTeam.noTeam")
      }}</label>
    </div>
    <div v-else>
      <table>
        <thead>
          <tr>
            <th class="cursorPointer" @click="clickSelectTeam(null)">
              {{ $t("tabTeam.add") }}<img src="../assets/add.png" />
              <br />
              {{ $t("tabTeam.actual") }}{{ actualTeam.length }}
              {{ $tc("tabTeam.members", actualTeam.length) }}
            </th>
          </tr>
        </thead>
      </table>
      <br />

      <b-row v-for="member in actualTeam" :key="member._id" class="members">
        <b-card class="text-left card-custom">
          <b-popover
            target="remove"
            triggers="hover"
            placement="top"
            variant="primary"
          >
            {{ $t("tabTeam.btn_remove") }}
          </b-popover>
          <b-popover
            target="edit"
            triggers="hover"
            placement="top"
            variant="primary"
          >
            {{ $t("tabTeam.btn_edit") }}
          </b-popover>

          <b-row>
            <b-col
              ><b-button
                variant="danger"
                class="btn-remove"
                size="sm"
                id="remove"
                @click="
                  clickSelectTeam(
                    member.firstName,
                    member.lastName,
                    member.alias,
                    member.taskName,
                    member._id
                  )
                "
              >
                <i class="fa fa-remove"></i>
              </b-button>
              <p class="p-member">
                {{ member.firstName }} {{ member.lastName }} {{ member.alias }}
              </p></b-col
            >
          </b-row>
          <b-row style="margin-top:10px;color:black">
            <b-col class="padding-0" xl="12">
              <b-button
                class="btn-edit"
                variant="success"
                id="edit"
                size="sm"
                @click="clickSelectTeam(null)"
              >
                <i class="fa fa-edit"></i>
              </b-button>
              <p class="p-member">{{ member.taskName }}</p>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
    </div>

    <selectTeam
      ref="selectTeam"
      :doInitTeam="selectTeamDoInit"
      :showTeam="showModalTeam"
      :actualTeam="actualTeam"
      :tabMainOk="tabMainOk"
      :autoLogin="autoLogin"
      :selectedTeamIds="selectedTeamIds"

      @close="closeModalTeam"
    ></selectTeam>
    <selectMoveToLine
      :doInitMoveToLine="moveToLineDoInit"
      :showMoveToLine="showMoveToLine"
      @close="closeModalMoveToLine"
    ></selectMoveToLine>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { axiosInstance } from "@/main";
import selectTeam from "@/components/SelectTeam.vue";
import EventBus from "@/eventBus";
import oper from "@/stores/Operations.js";
import selectMoveToLine from "@/components/SelectMoveToLine.vue";
export default {
  data: function() {
    return {
      showModalTeam: false,
      selectTeamDoInit: false,
      buttons: [
        {
          caption: '<i class="fa fa-edit"></i>',
          state: false,
          func: () => this.clickSelectTeam(null),
        },
        {
          caption: '<i class="fa fa-remove"></i>',
          state: false,
          func: (fname, lname, alias, taskName, id) =>
            this.clickSelectTeam(fname, lname, alias, taskName, id),
        },
      ],
      tabLineOk: false,
      tabSchedOk: false,
      tabTeamOk: false,
      tabMainOk: false,
      autoLogin: 0,

      actualTeam: [],
      needTask: false,
      startTime: 0,

      moveToLineDoInit: false,
      showMoveToLine: false,
      moveEmp: "",
      moveLineId: "",
      moveTask: {},
      selectedTeamIds: [] // New data property to store selected team IDs
    };
  },
  components: {
    selectTeam,
    selectMoveToLine,
  },
  methods: {
    clickSelectTeam: function(fname, lname, alias, taskName, id) {
      //console.log("----- clickSelectTeam = " + JSON.stringify(alias, null, 2));
      if (fname) {
        // an employee name was clicked
        if (this.actualTeam.length == 1 && this.tabMainOk == true) {
          // this is the last employee in team
          // tell the user he cannot delete
          this.$bvModal
            .msgBoxConfirm(this.$i18n.t("tabTeam.last"), {
              okTitle: this.$i18n.t("tabTeam.editTeam"),
              cancelTitle: this.$i18n.t("tabTeam.cancel"),
            })
            .then((value) => {
              if (value) {
                // the user select to edit team
                this.showModalTeam = true;
                this.selectTeamDoInit = true;
              }
            });
        } else {
          if (this.tabMainOk == true) {
            let msg = fname + " " + lname;
            if (this.needTask) msg = msg + ", " + taskName;

            if (oper.getMoveToLine()) {
              // ask if delete or move to other line
              this.$bvModal
                .msgBoxConfirm(this.$i18n.t("tabTeam.moveToLine"), {
                  title: msg,
                  okTitle: this.$i18n.t("tabTeam.moveIt"),
                  cancelTitle: this.$i18n.t("tabTeam.deleteIt"),
                })
                .then((value) => {
                  if (value === true) {
                    // move to other line
                    this.showMoveToLine = true;
                    this.moveToLineDoInit = true;

                    this.moveEmp = {};
                    for (let i = 0; i < this.actualTeam.length; i++) {
                      if (this.actualTeam[i]._id == id) {
                        this.moveEmp = this.actualTeam[i];
                        break;
                      }
                    }
                  } else if (value === false) {
                    // remove the employee from team
                    this.removeEmployee(alias);
                  }
                  // else value is null because we cliked outside the popup
                });
            } else {
              // ask the user if he really want to delete employee
              this.$bvModal
                .msgBoxConfirm(msg, {
                  okTitle: this.$i18n.t("tabTeam.remove"),
                  cancelTitle: this.$i18n.t("tabTeam.cancel"),
                })
                .then((value) => {
                  if (value) {
                    // remove the employee from team
                    this.removeEmployee(alias);
                  }
                });
            }
          } else {
            // not in production so remove the employee without asking
            this.removeEmployee(alias);
          }
        }
      } else {
        // user clicked team header so go to edit team
        this.showModalTeam = true;
        this.selectTeamDoInit = true;
      }
    },
    removeEmployee: function(alias) {
      let newTeam = JSON.parse(JSON.stringify(this.actualTeam));
      for (let i = 0; i < newTeam.length; i++) {
        if (newTeam[i].alias == alias) {
          newTeam.splice(i, 1);
          this.changeTeam(newTeam);
          break;
        }
      }
    },
    closeModalTeam: function(data) {
      //console.log("----- closeModalTeam = " + JSON.stringify(data, null, 2));
      this.selectTeamDoInit = false;
      this.showModalTeam = false;

      if (!data.cancel) {
        this.changeTeam(data.selectedTeam);
      }
    },
    closeModalMoveToLine: function(data) {
      //console.log("----- closeModalMoveToLine = " + JSON.stringify(data, null, 2));
      this.showMoveToLine = false;
      this.moveToLineDoInit = false;

      if (!data.cancel) {
        this.moveLineId = data.selectedLineId;
        this.moveTask = data.selectedTask;
        this.removeEmployee(this.moveEmp.alias);
      } else {
        this.moveEmp = {};
      }
    },
    changeTeam: function(team) {
      console.log("----- changeTeam = " + JSON.stringify(team, null, 2));
      let changeTeamData = {};
      changeTeamData.team = team;
      changeTeamData.inProduction = this.tabMainOk;
      changeTeamData.actId = oper.getActivityId();
      changeTeamData.lineId = oper.getLineId();

      axiosInstance.post("/changeteam", changeTeamData).then((result) => {
        //console.log("----- changeteam result = " + JSON.stringify(result.data, null, 2));

        if (result.data.error) {
          let msg = this.$i18n.t("tabTeam." + result.data.error);
          if (Array.isArray(result.data.list)) {
            result.data.list.forEach((emp) => {
              msg =
                msg +
                emp.firstName +
                " " +
                emp.lastName +
                " " +
                emp.alias +
                ", on line: " +
                emp.lineName +
                ", ";
            });
          }
          this.$bvModal.msgBoxOk(msg).then(() => {
            this.teamRemoveEmps(result.data.list);
          });
        } else if (Object.keys(this.moveEmp).length !== 0) {
          let moveEmpData = {
            emp: this.moveEmp,
            lineId: this.moveLineId,
            task: this.moveTask,
          };
          axiosInstance.post("/moveEmpToLine", moveEmpData);
        }
      });
    },
    teamUpdateActivity: function(data) {
      console.log('----- team teamUpdateActivity = ' + JSON.stringify(data, null, 2));
            console.log('data team teamAct' , data);

      
      if (data.sched && data.sched.sched)
        this.autoLogin = data.sched.sched.teamSnapshot;
      else this.autoLogin = 0;

      if (data.team && data.team.length > 0) {
        this.actualTeam = [];
        data.team.forEach((emp) => {
          emp.emp.taskId = emp.taskId;
          emp.emp.taskName = emp.taskName;
          emp.emp.isSupport = emp.isSupport;
          this.actualTeam.push(emp.emp);
        });
        if (this.needTask) {
          let noTask = this.$i18n.t("selectTeam.enterTask");
          // sort by task name
          this.actualTeam.sort(function(a, b) {
            if (!a.taskName) a.taskName = noTask;
            if (!b.taskName) b.taskName = noTask;
            var emp1 = a.taskName.toLowerCase(),
              emp2 = b.taskName.toLowerCase();
            return emp1.localeCompare(emp2);
          });
        } else {
          // sort by name
          this.actualTeam.sort(function(a, b) {
            var emp1 = a.firstName.toLowerCase(),
              emp2 = b.firstName.toLowerCase();
            return emp1.localeCompare(emp2);
          });
        }
        this.tabTeamOk = true;
        this.takeTeamSnapshot();
      } else {
        this.actualTeam = [];
        this.tabTeamOk = false;
      }
      EventBus.$emit('TabletTeam::teamLenght', this.actualTeam.length)
      this.tabLineOk = !!(data.line && data.line._id);
      this.tabSchedOk = !!(data.sched && data.sched.sched._id);
      this.tabMainOk = !!data._id;

      this.showModalStart = false;
      this.startDoInit = false;

      this.startTime = data.startTime;
      this.needTask = oper.getNeedTask();
    },
    teamUpdateFactorySetup() {
      this.needTask = oper.getNeedTask();
    },
    teamRemoveEmps(empList) {
      //console.log("----- teamRemoveEmps = " + JSON.stringify(empList, null, 2));
      empList.forEach((emp) => {
        this.removeEmployee(emp.alias);
      });
    },
    takeTeamSnapshot() {
      if (
        this.autoLogin == 1 &&
        this.startTime &&
        moment().isAfter(moment(this.startTime).add(60, "minutes"))
      ) {
        return;
      }
      let teamIds = [];
      if (this.autoLogin != 0) {
        this.actualTeam.forEach((emp) => {
          teamIds.push(emp._id);
        });
      }
      localStorage.setItem(
        "lastTeam" + oper.getLineId() + oper.getSchedId(),
        JSON.stringify(teamIds)
      );
    },
    autoSelectTeam:function(){
    let runningLine = localStorage.getItem("runningLine");
      //console.log("----- runningLine = " + runningLine);
    let lastSelectedLineId = localStorage.getItem("lastSelectedLineId");

      if (runningLine && lastSelectedLineId) {
         oper.getPrevActivity(runningLine,lastSelectedLineId).then(res=>{
          // this.teamUpdateActivity(res.data)
          // console.log(res.data,"data team")
         return res.data
        // self.showModalLine = true;
         }).then(data=>{
          console.log(data.team,"REOLVE")

          const prevTeam = data.team.map(member => member.emp._id);
          this.selectedTeamIds = prevTeam; // Set the selected team IDs
          if (this.$refs.selectTeam) {
           this.$refs.selectTeam.initTeamSelection(prevTeam);
          }
  

         });
        // console.log(activityData,"activityData");

        
      }
    }

  },
  mounted() {
    //console.log("----- TabTeam mounted()");

    this.needTask = oper.getNeedTask();
    EventBus.$on("factorySetup", this.teamUpdateFactorySetup);

    this.teamUpdateActivity(oper.getCurrentActivity());
    EventBus.$on("updateActivity", this.teamUpdateActivity);

    EventBus.$on("teamRemoveEmps", this.teamRemoveEmps);


      // let runningLine = localStorage.getItem("runningLine");
      // //console.log("----- runningLine = " + runningLine);

      // if (runningLine) {
      //    oper.getPrevActivity(runningLine).then(res=>{
      //     // this.teamUpdateActivity(res.data)
      //     // console.log(res.data,"data team")
      //    return res.data
      //   // self.showModalLine = true;
      //    }).then(data=>{
      //     console.log(data.team,"REOLVE")

      //     const prevTeam = data.team.map(member => member.emp._id);
      //     this.selectedTeamIds = prevTeam; // Set the selected team IDs
      //     if (this.$refs.selectTeam) {
      //      this.$refs.selectTeam.initTeamSelection(prevTeam);
      //     }
  

      //    });
      //   // console.log(activityData,"activityData");

        
      // },
  },
   created() {
    EventBus.$on('autoTeamSelection', this.autoSelectTeam);
  },
  beforeDestroy() {
    //console.log("----- TabTeam beforeDestroy()");
    EventBus.$off('autoTeamSelection',this.autoSelectTeam);

    EventBus.$off("factorySetup", this.teamUpdateFactorySetup);
    EventBus.$off("updateActivity", this.teamUpdateActivity);
    EventBus.$off("teamRemoveEmps", this.teamRemoveEmps);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/global.scss";
.card-custom {
  width: 100%;
  margin-bottom: 1px;
}
table {
  table-layout: fixed;
  width: 100%;
  padding: 5px;
  margin: 0px, 100px, 0px, 0px;
  border: 1px solid black;
}

th {
  padding: 5px;
}

.members {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.1;
  color: $perfcell-green;
  padding: 0px 16px;
}
.padding-0 {
  padding-right: 0;
}
.padding-1 {
  padding-left: 0;
}
.btn-remove {
  line-height: 12px;
  /* width: 18px; */
  font-size: 8pt;
  font-family: tahoma;
  margin-right: 2px;
  position: absolute;
  top: 0;
  left: 0;
  padding: 2px;
}
.btn-edit {
  line-height: 12px;
  /* width: 18px; */
  font-size: 8pt;
  font-family: tahoma;
  margin-right: 2px;
  position: absolute;
  top: 0;
  left: 0;
  padding: 2px;
}
.card-body {
  height: 60px;
  padding-top: 5px;
}
.p-member {
  margin-left: 4px;
  margin-bottom: 0;
}
</style>
